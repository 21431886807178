import React, { useState, useEffect, useContext , useRef } from 'react';
import { Link, Routes, Route, useNavigate, useLocation , Outlet } from 'react-router-dom';
import './profile.css';
import EditProfile from './editProfile/editProfile';
import { UserContext, useUser } from '../context/userContext';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { User, Key, Settings, LogOut, KeyRound } from 'lucide-react';
const Dashboard = () => {
    const { user, profileData, setProfileData ,  getCurrentLoggedInUser } = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [activeNav, setActiveNav] = useState(location.pathname.split('/')[2] || 'profile');
    const fileInputRef = useRef(null);
    const userId = user?.userId;
    // const [profileData, setProfileData] = useState(null);



    // useEffect(() => {
    //     const fetchProfileData = () => {
    //         const storedProfileData = localStorage.getItem('parsedProfileAttributes');
            
    //         if (storedProfileData) {
    //             setProfileData(JSON.parse(storedProfileData));
    //         } else {
    //             // Fallback to the old key if the new one is not available
    //             const oldStoredProfileData = localStorage.getItem('profileAttributes');
    //             if (oldStoredProfileData) {
    //                 setProfileData(JSON.parse(oldStoredProfileData));
    //             }
    //         }
    //     };

    //     fetchProfileData();
    // }, []);

    console.log("Profile Data:", profileData);
    console.log("User:", user);

    const handleNavClick = (path) => {
        setActiveNav(path);
        navigate(`/profile/${path}`);
    };

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleFileSelect = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                await uploadProfileImage(file);
                // After successful upload, fetch updated profile data
                const updatedProfileData = await getCurrentLoggedInUser(userId);
                if (updatedProfileData) {
                    const parsedResponse = JSON.parse(updatedProfileData.data.response);
                    setProfileData(parsedResponse);
                }
            } catch (error) {
                console.error('Error uploading profile image:', error);
                // Handle error (e.g., show error message to user)
            }
        }
    };

    const uploadProfileImage = async (file) => {
        const body = new FormData();
        body.append("doc[att][]", file);
        body.append("onepgr_apicall", "1");
        body.append("xhr_flag", "1");
        body.append("clientname", "web_meet");
        body.append("clientappid", "22");
        body.append("clientappkey", "33");
        try {
            const response = await axios.post("https://onepgr.com/users/update_profile_api", body, {
                withCredentials: true
            });
            console.log('Upload successful:', response.data);
            return response.data;
        } catch (error) {
            console.error('Upload failed:', error);
            throw error;
        }
    };

    const NavItem = ({ id, label }) => (
        <Link
            to={`/profile/${id}`}
            className={`nav-item ${activeNav === id ? 'active' : ''}`}
            onClick={() => setActiveNav(id)}
        >
            {label}
        </Link>
    );



    return (
        <div className="profile-container-wrapper">
            <h3>Dashboard</h3>
            <div className="profile-dashboard">
                <div className="profile-left-panel">
                <div className="profile-summary">
                        <div className="profile-image" onClick={handleImageClick}>
                            <img src={user.profilePic} alt="Profile" />
                            <div className="camera-icon"><FontAwesomeIcon icon={faCamera} /></div>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileSelect}
                                accept="image/*"
                            />
                        </div>
                        <h2>{profileData?.user_name}</h2>
                    </div>
                    <nav>
                        <div 
                            className={`nav-item ${activeNav === 'edit' ? 'active' : ''}`} 
                            role="button" 
                            tabIndex={0}
                            onClick={() => handleNavClick('edit')}
                        >
                            <User className="navbar-item-icon" />
                            Edit Profile
                        </div>
                        {/* <div 
                            className={`nav-item ${activeNav === 'accounts' ? 'active' : ''}`} 
                            role="button" 
                            tabIndex={0}
                            onClick={() => handleNavClick('accounts')}
                        >
                            <KeyRound className="navbar-item-icon" />
                            Application Accounts
                        </div> */}
                        <div 
                            className={`nav-item ${activeNav === 'settings' ? 'active' : ''}`} 
                            role="button" 
                            tabIndex={0}
                            onClick={() => handleNavClick('settings')}
                        >
                            <Settings className="navbar-item-icon" />
                            Account Settings
                        </div>
                        {/* <div 
                            className={`nav-item ${activeNav === 'password' ? 'active' : ''}`} 
                            role="button" 
                            tabIndex={0}
                            onClick={() => handleNavClick('password')}
                        >
                            <Key className="navbar-item-icon" />
                            Change Password
                        </div> */}
                    </nav>
                </div>
               
                <div className="profile-right-panel">
                    <Outlet context={{ profileData }} />
                </div>
          
            </div>
        </div>
    );
};





export default Dashboard;