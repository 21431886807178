// // // UserContext.js
// import React, { createContext, useState, useContext } from 'react';

// export const UserContext = createContext();

// export const useUser = () => useContext(UserContext);

// export const UserProvider = ({ children }) => {
//     const [user, setUser] = useState(null); // Initially, no user is logged in

//     // Method to update user data
//     const loginUser = (userData) => {
//         setUser(userData);
//     };

//     // Method to clear user data upon logout
//     const logoutUser = () => {
//         setUser(null);
//     };

//     return (
//         <UserContext.Provider value={{ user, loginUser, logoutUser }}>
//             {children}
//         </UserContext.Provider>
//     );
// };


// UserContext.js
import React, { createContext, useState, useContext, useEffect, useCallback  } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';


export const UserContext = createContext();
export const useUser = () => useContext(UserContext);



const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const formatDate = (date) => {
    return new Date(date).toLocaleString('en-US', {
        day: '2-digit',
        month: 'short',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        timeZone: 'UTC'
    }).replace(',', '');
};

const refreshFileList = async () => {
    try {
        const response = await fetch('https://crawl.onepgr.com:3002/list-files');
        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }
        const fileList = await response.json();
        // Assuming you will use fileList somewhere after fetching
    } catch (error) {
        console.error('Error fetching file list:', error);
    }
};




export const UserProvider = ({ children }) => {
    const location = useLocation();
    const [user, setUser] = useState(() => JSON.parse(localStorage.getItem('user'))); // Load user from local storage on init
    const [isSelectFileActive, setIsSelectFileActive] = useState(false);
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    
    const [lockerDetails, setLockerDetails] = useState(null);
    const [selectedLocker, setSelectedLocker] = useState(null);
    const [allFiles, setAllFiles] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [allTables, setAllTables] = useState(null);
    const [activeTab, setActiveTab] = useState('AllItems');
    const [activeItem, setActiveItem] = useState({ menuKey: '', lockerToken: '' });
    const [fileContent, setFileContent] = useState({ url: '', type: '' });
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const [linksData, setLinksData] = useState(null);
    const [isLoadingLinks, setIsLoadingLinks] = useState(false)
    const [refreshLockerTrigger, setRefreshLockerTrigger] = useState(false);
    const [allObjects, setAllObjects] = useState(null);
    const [profileData, setProfileData] = useState(null);
    const userId = user?.userId;
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
   
console.log(user)

    // useEffect(() => {
    //     // Sync user state with local storage
    //     localStorage.setItem('user', JSON.stringify(user));
    //     if (user && JSON.stringify(user) ) {
    //         fetchLockerDetails(); 
    //         fetchProfileData()
    //     }
    // }, []);

    // useEffect(() => {
    //     const fetchProfileData = () => {
    //         const storedProfileData = localStorage.getItem('parsedProfileAttributes');
            
    //         if (storedProfileData) {
    //             setProfileData(JSON.parse(storedProfileData));
    //         } else {
    //             // Fallback to the old key if the new one is not available
    //             const oldStoredProfileData = localStorage.getItem('profileAttributes');
    //             if (oldStoredProfileData) {
    //                 setProfileData(JSON.parse(oldStoredProfileData));
    //             }
    //         }
    //     };

    //     fetchProfileData();
    // }, []);


    useEffect(() => {
        checkExistingSession();
    }, []);

    const checkExistingSession = async () => {
        const sessionId = cookies.get('api_sessionid');
        // const sessionId = 'a5bd5503f097ac89ae8b1707cd959c5f'
        const userId = cookies.get('api_userid');
        // const userId = '4991'
        console.log(sessionId , userId)
        if (sessionId && userId) {
            try {
                const response = await getCurrentLoggedInUser(userId);
                const responseData = JSON.parse(response.data.response);
                const userData = {
                    name: responseData.user_name,
                    profilePic: responseData.user_photo_url,
                    userId: responseData.user_id,
                    sessionToken: sessionId
                };
    
                console.log('User data:', userData)
                loginUser(userData);
            } catch (error) {
                console.error('Error fetching user data:', error);
              
            }
        } else {
            console.log('No session found');
            navigate('/login');
        }
        setIsLoading(false);
    };

    const getCurrentLoggedInUser = async (userId) => {
        console.log('Fetching current logged in user data for user:', userId);
        const body = new FormData();
        body.append("id", userId);
    
        try {
            const response = await axios.post(
                'https://onepgr.com/users/get_profile_attributes?onepgr_apicall=1&xhr_flag=1',
                body,
                { withCredentials: true }
            );
    
            const parsedResponse = JSON.parse(response.data.response);
            console.log('Current User Data Response:', parsedResponse);
    
            // Parse nested JSON strings
            const parsedAttributes = {
                ...parsedResponse,
                api_keys: parsedResponse.api_keys ? JSON.parse(parsedResponse.api_keys) : null,
                app_flags: parsedResponse.app_flags ? JSON.parse(parsedResponse.app_flags) : null,
                profile_items: parsedResponse.profile_items ? JSON.parse(parsedResponse.profile_items) : null,
                session_flags: parsedResponse.session_flags ? JSON.parse(parsedResponse.session_flags) : null,
                session_flags2: parsedResponse.session_flags2 ? JSON.parse(parsedResponse.session_flags2) : null
            };
    console.log(parsedAttributes)
            // Store parsed user data in localStorage
            localStorage.setItem('parsedProfileAttributes', JSON.stringify(parsedAttributes));
            
            return response;
        } catch (error) {
            console.error('Error fetching current logged in user data:', error);
            return null;
        }
    };




    const fetchProfileData = () => {
        const storedProfileData = localStorage.getItem('parsedProfileAttributes');
        
        if (storedProfileData) {
            setProfileData(JSON.parse(storedProfileData));
        } 
    };

    console.log("Profile Data:", profileData);

    // useEffect(() => {
    //     if (user && user.userId && (lockerDetails === null || refreshLockerTrigger)) {
            
    //         fetchLockerDetails();
    //     }
    // }, [lockerDetails]);

    // console.log('Locker details:', lockerDetails);

    const fetchLockerDetails = async () => {
        try {
            const response = await axios.get('https://pages.onepgr.com/session/get-data-bank-items', {
                params: { user_id: user?.userId },
            });
            if (response.data.data.length > 0) {
                setLockerDetails(response.data.data); 

              
            } else {
                console.log('No locker details found');
                setLockerDetails(null);
                // setTotalItems(response.data.length)

            }
        } catch (error) {
            console.error('Error fetching locker details:', error);
            setLockerDetails(null);
        }
    };

    const triggerFetchLockerDetails = () => {
        setRefreshLockerTrigger(prevState => !prevState);
    };



    const fetchDataSourceFiles = async () => {
        try {
            const response = await axios.get('https://pages.onepgr.com/session/get-data-bank-source-files', {
                params: {
                    user_id: user?.userId,
                    page_num: currentPage,
                    nitems: itemsPerPage,
                },
            });
            console.log(response.data);
            if (response.data.status === 1) {
                // Filter out files with object_type = 'link'
                const filteredFiles = response.data.data.filter(file => file.object_type !== 'link');

                setAllFiles(filteredFiles);
                setTotalItems(response.data.total_items_count);
                console.log(totalItems) // Log totalItems
            } else {
                console.error('Error fetching data source files:', response.data.message);
                return null;
            }
        } catch (error) {
            console.error('Error fetching data source files:', error);
            return null;
        }
    };

    useEffect(() => {
        if (location.pathname === '/home/files') {
            fetchDataSourceFiles();
        }
    }, [location, currentPage, itemsPerPage]);


    const fetchDataBankTables = async () => {
        try {
            // Determine the parameters based on the current pathname
            const isSearchPage = location.pathname === '/search';
            const params = isSearchPage ?
                { user_id: user?.userId } : // For /search, only send userId
                { user_id: user?.userId, page_num: currentPage, nitems: itemsPerPage }; // For other pages, send pagination info

            // Adjusting the URL and parameters according to the new endpoint details
            const response = await axios.get('https://pages.onepgr.com/session/get-data-bank-tables', { params });

            if (response.data.status === 1) {
                setAllTables(response.data.data);
                setTotalItems(response.data.total_items_count);
                // console.log('Tables fetched successfully.', response.data.data);
            } else {
                console.error('Error fetching data bank tables:', response.data.message);
                return null;
            }
        } catch (error) {
            console.error('Error fetching data bank tables:', error);
            return null;
        }
    };

    useEffect(() => {
        // This will trigger fetchDataBankTables when location.pathname is either /home/Tables or /search
        if (location.pathname === '/home/tables' || location.pathname === '/search') {
            fetchDataBankTables();
        }
    }, [location, currentPage, itemsPerPage]);

    const fetchLinks = async () => {
        setIsLoadingLinks(true)
        try {
            const response = await axios.get('https://pages.onepgr.com/session/get-data-bank-source-files', {
                params: {
                    user_id: user?.userId,
                    locker_id: selectedLocker,
                    object_type: 'link',
                },
            });
            // Filter the response data to include only files with object_type: 'link'
            const filteredLinks = response.data.data.filter(file => file.object_type === 'link');
            setLinksData(filteredLinks);
            console.log(filteredLinks)
            setTotalItems(filteredLinks.length)
            setIsLoadingLinks(false)
        } catch (error) {
            console.error('Error fetching source files:', error);
        }
    };

    useEffect(() => {
        if (location.pathname === '/home/links') {
            fetchLinks();
        }
    }, [location, selectedLocker]);



    const fetchAllObjects = async () => {
        console.log('Fetching all databank objects');
        try {
            const response = await axios.get('https://pages.onepgr.com/session/get-all-databank-objects', {
                params: { user_id: user?.userId, root: 1 },
            });

            console.log(response.data);
            if (response.data.data.length > 0) {
                setAllObjects(response.data.data); // Store all databank objects
                console.log('Total objects:', response.data.data.length);
                setTotalItems(response.data.data.length)
            } else {
                console.log('No databank objects found');
                setAllObjects([]);
            }
        } catch (error) {
            console.error('Error fetching databank objects:', error);
            setAllObjects([]);
        }
    };

    useEffect(() => {
        if (location.pathname === '/home/objects') {
            fetchAllObjects();
        }
    }, [location]);





    const updateItemsPerPage = (newItemsPerPage) => {
        setItemsPerPage(newItemsPerPage);
    };

    // const loginUser = useCallback((userData) => {
    //     setUser(userData);
    // }, []);

    // const logoutUser = () => {
    //     setUser(null);
    //     localStorage.removeItem('user'); // Clear user data from local storage
    //     setLockerDetails([]);
    // };

    const loginUser = useCallback((userData) => {
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
        fetchLockerDetails(userData.userId);
    }, []);

    const logoutUser = useCallback(async () => {
        try {
            const sessionToken = cookies.get('api_sessionid');
            
            const formData = new FormData();
            formData.append('session_token', sessionToken);

            await axios.post('https://onepgr.com/sessions/destroy_api?onepgr_apicall=1&xhr_flag=1', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true
            });

            // Clear user data and cookies
            setUser(null);
            localStorage.removeItem('user');
            localStorage.removeItem('parsedProfileAttributes');
            cookies.remove('api_userid');
            cookies.remove('api_sessionid');
            cookies.remove('auth_cookie');

            // Redirect to login page
            navigate('/login');
        } catch (error) {
            console.error('Error during logout:', error);
            // Even if the API call fails, we should still clear local data
            setUser(null);
            localStorage.removeItem('user');
            localStorage.removeItem('parsedProfileAttributes');
            cookies.remove('api_userid');
            cookies.remove('api_sessionid');
            cookies.remove('auth_cookie');
            navigate('/login');
        }
    }, [navigate]);

    const selectLocker = (lockerId) => {
        console.log("Selecting locker:", lockerId);
        setSelectedLocker(lockerId);
    };

    const triggerFileSelection = () => {
        console.log('File selection triggered!');
        setIsSelectFileActive(true);
    };

    const resetFileSelection = () => {
        setIsSelectFileActive(false);
    };

    const toggleSidebar = () => {
        setIsSidebarCollapsed(!isSidebarCollapsed);
    };


    const viewFile = (fileKey) => {
        console.log(fileKey)
        const region = 'us-west-2';
        const bucketName = 'onepgr-databank-bucket';
        // Split the full URL to extract only the key
        const isEncoded = decodeURIComponent(fileKey) !== fileKey; // Decode if necessary
        const decodedFileKey = isEncoded ? decodeURIComponent(fileKey) : fileKey;
        // Split the URL to extract only the key part if it's a full URL
        const splitUrl = decodedFileKey.split('/');
        const keyPath = splitUrl.slice(3).join('/');
        // Re-encode the necessary parts
        const encodedFileKey = encodeURIComponent(keyPath);
        const fileUrl = `https://${bucketName}.s3.${region}.amazonaws.com/${encodedFileKey}`;
        let fileType = 'unsupported';
        // Handle image file types
        if (keyPath.match(/\.(jpe?g|png|gif|svg)$/i)) {
            fileType = 'image';
        }
        // Handle text files
        else if (keyPath.endsWith('.txt')) {
            fileType = 'text';
        }
        // Handle Excel files
        else if (keyPath.match(/\.(xls|xlsx|csv)$/i)) {
            fileType = 'xls';
        }
        // Handle PowerPoint presentations
        else if (keyPath.match(/\.(ppt|pptx)$/i)) {
            fileType = 'ppt';
        }
        // Handle PDF documents
        else if (keyPath.endsWith('.pdf')) {
            fileType = 'pdf';
        }
        // Handle video files
        else if (keyPath.match(/\.(mp4|webm|ogg)$/i)) {
            fileType = 'video';
        }
        setFileContent({ url: fileUrl, type: fileType });
        setIsOverlayOpen(true); // Show overlay
    };

    return (
        <UserContext.Provider value={{
            user,
            lockerDetails,
            selectedLocker,
            allFiles,
            allTables,
            currentPage,
            itemsPerPage,
            totalItems,
            activeTab,
            setActiveTab,
            activeItem,
            setActiveItem,
            fetchLockerDetails,
            fetchDataBankTables,
            fetchDataSourceFiles,
            updateItemsPerPage,
            setCurrentPage,
            setItemsPerPage,
            selectLocker,
            setSelectedLocker,
            loginUser,
            logoutUser,
            isSelectFileActive,
            triggerFileSelection,
            resetFileSelection,
            formatFileSize,
            formatDate,
            refreshFileList,
            setIsSelectFileActive,
            isSidebarCollapsed,
            toggleSidebar,
            viewFile,
            linksData,
            fetchLinks,
            isLoadingLinks,
            refreshLockerTrigger,
            triggerFetchLockerDetails,
            allObjects,
            fetchAllObjects,
            profileData,
            getCurrentLoggedInUser

        }}>
            {children}
            {isOverlayOpen && (
                <div className="overlay-s3">
                    <div className="file-content">
                        {fileContent.type === 'image' && (
                            <img src={fileContent.url} alt="File" />
                        )}
                        {fileContent.type === 'text' && (
                            <iframe src={fileContent.url} title="Text File"></iframe>
                        )}
                        {fileContent.type === 'xls' && (
                            <iframe className="xls-iframe" src={`https://docs.google.com/gview?url=${encodeURIComponent(fileContent.url)}&embedded=true`} frameBorder="0">
                            </iframe>
                        )}
                        {fileContent.type === 'pdf' && (
                            <iframe src={fileContent.url} title="PDF File"></iframe>
                        )}
                        {fileContent.type === 'ppt' && (
                            <p>PPT files cannot be previewed directly. <a href={fileContent.url} target="_blank" rel="noopener noreferrer">Download file</a>.</p>
                        )}
                        {fileContent.type === 'video' && (
                            <video controls className='video-player'>
                                <source src={fileContent.url} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        )}
                        {fileContent.type === 'unsupported' && (
                            <p>Unsupported file type.</p>
                        )}
                    </div>
                    <i className="fas fa-times clear-icon view-file-cancel-btn " onClick={() => setIsOverlayOpen(false)}></i>
                </div>
            )}
        </UserContext.Provider>
    );
};
