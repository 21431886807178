import React, { useState, useContext, useEffect, useRef } from 'react';
import './tableSearch.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DropdownData from './dropdownData';
import { UserContext, useUser } from '../context/userContext';
import Notification from '../notification /notification';
import LoadingOverlay from '../loadingOverlay/loadingOverlay';
import { Country, State, City } from 'country-state-city';

const { TITLES, TEAM_SIZES, INDUSTRIES, CountriesData } = DropdownData;




const PersonInfoFilter = ({ showTablesDropdown = true, selectedLockers, setSelectedLockers }) => {
    const [selectedTable, setSelectedTable] = useState('');
    const { allTables, fetchDataBankTables } = useUser();
    const { user } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [filtersApplied, setFiltersApplied] = useState(false);
    const [isToggled, setIsToggled] = useState(false);
    const [selectedTables, setSelectedTables] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [selectedTableState, setSelectedTableState] = useState({});
    const [selectedLeadCountry, setSelectedLeadCountry] = useState('');
    const [selectedLeadState, setSelectedLeadState] = useState('');
    const [selectedLeadCity, setSelectedLeadCity] = useState('');
    const [selectedCompanyCountry, setSelectedCompanyCountry] = useState('');
    const [selectedCompanyState, setSelectedCompanyState] = useState('');
    const [selectedCompanyCity, setSelectedCompanyCity] = useState('');
    const [inputRefs, setInputRefs] = useState({});
    const companyStateInputRef = useRef(null);
    const titleInputRef = useRef(null);
    const companyNameInputRef = useRef(null);
    const industryInputRef = useRef(null);
    const teamSizeInputRef = useRef(null);
    const companyCityInputRef = useRef(null);
    const companyCountryInputRef = useRef(null);
    const leadCountryInputRef = useRef(null);
    const leadStateInputRef = useRef(null);
    const leadCityInputRef = useRef(null);
    const [tableDatabaseMap, setTableDatabaseMap] = useState({});
    const [notification, setNotification] = useState({
        type: '', // 'success', 'error', 'info'
        message: ''
    });

    console.log(selectedLockers)
    console.log(allTables)

    useEffect(() => {
        fetchDataBankTables();
    }, []);

    // useEffect(() => {
    //     if (allTables.length > 0 && selectedLockers.length > 0) {
    //         const filteredTables = allTables.filter(table =>
    //             selectedLockers.includes(table.locker_id)
    //         );
    //         const filteredTableNames = filteredTables.map(table => table.name);
    //         setSelectedTables(filteredTableNames);
    //     } else {
    //         const allTableNames = allTables.map(table => table.name);
    //         setSelectedTables(allTableNames);
    //     }
    // }, [allTables, selectedLockers]);


    useEffect(() => {
        if (allTables && allTables.length > 0) {
            const newTableDatabaseMap = {};
            allTables.forEach(table => {
                newTableDatabaseMap[table.name] = table.database || 'rds';
            });
            setTableDatabaseMap(newTableDatabaseMap);
            if (selectedLockers && selectedLockers.length === 0) {
                // If selectedLockers is empty, set selectedTables to an empty array
                // and clear the selectedTableState
                setSelectedTables([]);
                setSelectedTableState({});
            } else if (selectedLockers && selectedLockers.length > 0) {
                // If selectedLockers has values
                const filteredTables = allTables.filter(table =>
                    selectedLockers.includes(table.locker_id)
                );
                const filteredTableNames = filteredTables.map(table => table.name);
                setSelectedTables(filteredTableNames);
                setSelectedTableState(filteredTableNames.reduce((acc, tableName) => ({ ...acc, [tableName]: true }), {}));
            }
        }
    }, [allTables, selectedLockers]);

    console.log(selectedTables)
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // console.log(allTables)

    const handleInputRef = (field, ref) => {
        setInputRefs(prevRefs => ({
            ...prevRefs,
            [field]: ref
        }));
    };

    const [personFilters, setPersonFilters] = useState({
        firstName: '',
        lastName: '',
        email: '',
        title: ''
    });
    const [companyFilters, setCompanyFilters] = useState({
        companyName: '',
        industry: '',
        teamSize: '',
        companyDomain: ''
    });
    const [phoneFilters, setPhoneFilters] = useState({
        work: '',
        personal: '',
        company: ''
    });

    const [companyLocationFilters, setCompanyLocationFilters] = useState({
        companyCity: '',
        state: '',
        companyCountry: '',
        companyZip: ''
    })

    const [leadLocationFilters, setLeadLocationFilters] = useState({
        leadCity: '',
        leadState: '',
        leadCountry: ''
    });


    const [activePersonDropdown, setActivePersonDropdown] = useState(null);
    const [activeCompanyDropdown, setActiveCompanyDropdown] = useState(null);
    const [personFilterTags, setPersonFilterTags] = useState({
        firstName: [],
        lastName: [],
        email: [],
        title: []
    });
    const [companyFilterTags, setCompanyFilterTags] = useState({
        companyName: [],
        industry: [],
        teamSize: [],
        companyDomain: []
    });
    const [companyLocationFilterTags, setCompanyLocationFilterTags] = useState({
        companyCity: [],
        companyState: [],
        companyCountry: [],
        companyZip: []
    });
    const [leadLocationFilterTags, setLeadLocationFilterTags] = useState({
        leadCity: [],
        leadState: [],
        leadCountry: []
    });

    const handleTogglePersonDropdown = (field) => {
        setActivePersonDropdown(activePersonDropdown === field ? null : field);
    };
    const handleToggleCompanyDropdown = (field) => {
        setActiveCompanyDropdown(activeCompanyDropdown === field ? null : field);
    };



    // For company filters
    const handleCompanyFilterChange = (e, field) => {
        setCompanyFilters(prevState => ({
            ...prevState,
            [field]: e.target.value  // Removed .trim()
        }));
    };

    // For person filters
    const handlePersonFilterChange = (e, field) => {
        setPersonFilters(prevState => ({
            ...prevState,
            [field]: e.target.value  // Removed .trim()
        }));
    };

    // For company location filters
    const handleCompanyLocationChange = (e, field) => {
        const { value } = e.target;
        setCompanyLocationFilters(prevState => ({
            ...prevState,
            [field]: value
        }));

        if (field === 'companyCountry') {
            setSelectedCompanyCountry(value);
            setSelectedCompanyState('');
            setSelectedCompanyCity('');
            setCompanyLocationFilters(prevFilters => ({
                ...prevFilters,
                companyState: '',
                companyCity: ''
            }));
        } else if (field === 'companyState') {
            setSelectedCompanyState(value);
            setSelectedCompanyCity('');
            setCompanyLocationFilters(prevFilters => ({
                ...prevFilters,
                companyCity: ''
            }));
        }
    };
    // For lead location filters
    const handleLeadLocationChange = (e, field) => {
        const { value } = e.target;
        setLeadLocationFilters(prevState => ({
            ...prevState,
            [field]: value
        }));

        if (field === 'leadCountry') {
            setSelectedLeadCountry(value);
            setSelectedLeadState('');
            setSelectedLeadCity('');
            setLeadLocationFilters(prevFilters => ({
                ...prevFilters,
                leadState: '',
                leadCity: ''
            }));
        } else if (field === 'leadState') {
            setSelectedLeadState(value);
            setSelectedLeadCity('');
            setLeadLocationFilters(prevFilters => ({
                ...prevFilters,
                leadCity: ''
            }));
        }
    };

    const handleLeadLocationKeyPress = (e, field) => {
        if (e.key === 'Enter') {
            e.preventDefault();  // Prevent default form submission
            applyLeadLocationFilter(field);
        }
    };


    const handlePersonKeyPress = (e, field) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            applyPersonFilter(field);
        }
    };

    const handleCompanyKeyPress = (e, field) => {
        if (e.key === 'Enter') {
            applyCompanyFilter(field);
        }
    };

    const handleCompanyLocationKeyPress = (event, field) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            applyCompanyLocationFilter(field);
        }
    };

    const applyLeadLocationFilter = (field) => {
        const value = leadLocationFilters[field];
        if (value !== '') {
            if (leadLocationFilterTags[field].includes(value)) {
                alert('Filter already added');
                setLeadLocationFilters(prevFilters => ({
                    ...prevFilters,
                    [field]: ''
                }));
                return;
            }
            setLeadLocationFilterTags(prevTags => ({
                ...prevTags,
                [field]: [...prevTags[field], value]
            }));
            setLeadLocationFilters(prevFilters => ({
                ...prevFilters,
                [field]: ''
            }));
        }
    };

    const applyCompanyLocationFilter = (field) => {
        const value = companyLocationFilters[field].trim();
        if (value !== '') {
            let tagField = field;
            if (field === 'companyState') {
                tagField = 'companyState/Region';
            }
            console.log(`${tagField} filter: ${value}`);

            if (companyLocationFilterTags[tagField] && companyLocationFilterTags[tagField].includes(value)) {
                alert('Filter already added');
                setCompanyLocationFilters(prevState => ({
                    ...prevState,
                    [field]: ''
                }));
                return;
            }

            setCompanyLocationFilterTags(prevState => ({
                ...prevState,
                [tagField]: [...(prevState[tagField] || []), value]
            }));

            setCompanyLocationFilters(prevState => ({
                ...prevState,
                [field]: ''
            }));
        }
    };

    const applyPersonFilter = (field) => {
        const value = personFilters[field];
        if (value !== '') {
            // Apply your filter logic here
            console.log(`${field} filter: ${value}`);

            if (personFilterTags[field] && personFilterTags[field].includes(value)) {
                alert('Filter already added');
                setPersonFilters(prevState => ({
                    ...prevState,
                    [field]: ''
                }));
                return;
            }
            // Add filter to filter tags
            setPersonFilterTags(prevState => ({
                ...prevState,
                [field]: [...prevState[field], value]
            }));
            // Clear the input field after applying the filter
            setPersonFilters(prevState => ({
                ...prevState,
                [field]: ''
            }));
        }
    };

    const applyCompanyFilter = (field) => {
        const value = companyFilters[field];
        if (value !== '') {
            // Apply your filter logic here
            console.log(`${field} filter: ${value}`);

            if (companyFilterTags[field] && companyFilterTags[field].includes(value)) {
                alert('Filter already added');
                setCompanyFilters(prevState => ({
                    ...prevState,
                    [field]: ''
                }));
                return;
            }
            // Add filter to filter tags
            setCompanyFilterTags(prevState => ({
                ...prevState,
                [field]: [...prevState[field], value]
            }));
            // Clear the input field after applying the filter
            setCompanyFilters(prevState => ({
                ...prevState,
                [field]: ''
            }));
        }
    };
    const removePersonTag = (field, index) => {
        setPersonFilterTags(prevState => ({
            ...prevState,
            [field]: prevState[field].filter((_, i) => i !== index)
        }));
    };

    const removeCompanyTag = (field, index) => {
        setCompanyFilterTags(prevState => ({
            ...prevState,
            [field]: prevState[field].filter((_, i) => i !== index)
        }));
    };
    const removeCompanyLocationTag = (field, index) => {
        setCompanyLocationFilterTags(prevTags => ({
            ...prevTags,
            [field]: prevTags[field].filter((_, i) => i !== index)
        }));
    };

    const removeLeadLocationTag = (field, index) => {
        setLeadLocationFilterTags(prevTags => ({
            ...prevTags,
            [field]: prevTags[field].filter((_, i) => i !== index)
        }));
    };


    const clearAllFilters = () => {
        setPersonFilters({
            firstName: '',
            lastName: '',
            email: '',
            title: ''
        });
        setPersonFilterTags({
            firstName: [],
            lastName: [],
            email: [],
            title: []
        });
        setCompanyFilters({
            companyName: '',
            industry: '',
            teamSize: '',
            companyDomain: ''
        });
        setCompanyFilterTags({
            companyName: [],
            industry: [],
            teamSize: [],
            companyDomain: []
        });
        setCompanyLocationFilters({
            companyCity: '',
            companyState: '',
            companyCountry: '',
            companyZip: ''
        });
        setCompanyLocationFilterTags({
            companyCity: [],
            companyState: [],
            companyCountry: [],
            companyZip: []
        });
        setLeadLocationFilters({
            leadCity: '',
            leadState: '',
            leadCountry: ''
        });

        // Clear tags for lead location filters
        setLeadLocationFilterTags({
            leadCity: [],
            leadState: [],
            leadCountry: []
        });
    };

    const applyFilters = async () => {
        // Combine person and company filters into one object with all active tags
        const filters = {
            ...Object.fromEntries(Object.entries(personFilterTags).filter(([_, tags]) => tags.length > 0)),
            ...Object.fromEntries(Object.entries(companyFilterTags).filter(([_, tags]) => tags.length > 0)),
            ...Object.fromEntries(Object.entries(companyLocationFilterTags).filter(([_, tags]) => tags.length > 0)),
            ...Object.fromEntries(Object.entries(leadLocationFilterTags).filter(([_, tags]) => tags.length > 0))
        };

        if (Object.keys(filters).length === 0) {
            setNotification({ type: 'error', message: 'Apply filters to search' });
            return;
        }

        console.log(filters);
        setIsLoading(true);

        try {
            const requestData = {
                filters,
                tables: selectedTables.map(tableName => ({
                    name: tableName,
                    database: tableDatabaseMap[tableName]
                })),

            };

            const response = await axios.post('https://crawl.onepgr.com:3002/search-specified-tables', requestData);

            console.log('Search results:', response.data);

            // Construct query parameters from filters
            const queryParams = new URLSearchParams();
            for (const [key, value] of Object.entries(filters)) {
                if (value.length > 0) {
                    // Ensure that only non-empty filters are added
                    queryParams.append(key, value.join(','));
                }
            }

            let route;
            let state;
            console.log('selectedTables:', selectedTables);
            if (selectedTables.length === 1) {
                route = `/tables/${selectedTables[0]}?${queryParams.toString()}`;
                state = { data: response.data, tableName: selectedTables[0] };
            } else if (selectedTables.length > 1) {
                route = `/tables/selected-tables?${queryParams.toString()}`;
                state = { data: response.data, tableNames: selectedTables };
            } else if (selectedTables.length === 0) {
                alert('Select Table(s) to search');
            }

            navigate(route, { state });
            setIsLoading(false);
            // Handle display of search results here or manage state to show them
        } catch (error) {
            // Check if the error response has data and a message
            if (error.response && error.response.data && error.response.data.message) {
                setNotification({ type: 'error', message: error.response.data.message }); // Use the message from the response
                setIsLoading(false);
            } else {
                // Default to a generic error message if none is provided
                setNotification({ type: 'error', message: 'An error occurred. Please try again later.' });
                setIsLoading(false);
            }
        }
    };

    const totalActiveFilters = () => {
        return Object.values({ ...personFilterTags, ...companyFilterTags, ...companyLocationFilterTags, ...leadLocationFilterTags }).reduce((acc, tags) => acc + tags.length, 0);
    };

    useEffect(() => {
        if (allTables && allTables.length > 0 && selectedTables.length === 0) {
            handleSelectAll();
        }
    }, [allTables]);
    
    const handleSelectAll = () => {
        if (selectedTables.length === allTables.length) {
            setSelectedTables([]);
            setSelectedTableState({}); // Clear the selectedTableState when unchecking "Select All"
        } else {
            const allTableNames = allTables.map(table => table.name);
            setSelectedTables(allTableNames);
            setSelectedTableState(allTableNames.reduce((acc, tableName) => ({ ...acc, [tableName]: true }), {})); // Set all tables as selected in selectedTableState
        }
    };

    const handleChange = (event) => {
        const tableName = event.target.value;
        const isChecked = event.target.checked;

        setSelectedTableState(prevState => ({
            ...prevState,
            [tableName]: isChecked
        }));

        setSelectedTables(prevSelectedTables => {
            if (isChecked) {
                return [...prevSelectedTables, tableName];
            } else {
                return prevSelectedTables.filter(name => name !== tableName);
            }
        });
    };

    const handleToggle = (event) => {
        setIsToggled(event.target.checked); // Update toggle state based on checkbox
    };

    return (
        <div className='filter-search'>
            <div className='filter-buttons'>
                <h3>Filters</h3>
                {totalActiveFilters() > 0 && (
                    <div className="filter-count">
                        <span>{totalActiveFilters()} </span>
                        <button onClick={clearAllFilters}>x</button>
                    </div>
                )}



            </div>
            <hr />
            {showTablesDropdown && (
                <div className='table-select-content'>
                    {/* <div className='toggle-switch-search'>
                    All Tables
                    <label className="switch">
                        <input type="checkbox" id="toggle" onChange={handleToggle} />
                        <span className="slider round"></span>
                    </label>
                </div> */}
                    {!isToggled && (
                        <div className="table-select-wrapper">
                            <label htmlFor="table-select" className='table-select-label'>Select Tables:</label>
                            <div className="table-select-input" ref={dropdownRef}>
                                <input
                                    type="text"
                                    id="table-select"
                                    placeholder="-- Select table(s) --"
                                    value={selectedTables.join(', ')}
                                    title={selectedTables.join(', ')}
                                    readOnly
                                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}

                                />
                                {isDropdownOpen && (
                                    <div className="table-select-dropdown">
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={selectedTables.length === allTables.length}
                                                onChange={handleSelectAll}

                                            />
                                            Select All
                                        </label>
                                        {allTables.map(table => (
                                            <label key={table.name} className={`table-select-option ${selectedTableState[table.name] ? 'selected' : ''}`}>
                                                <input
                                                    type="checkbox"
                                                    value={table.name}
                                                    checked={selectedTableState[table.name] || false}
                                                    onChange={handleChange}
                                                />
                                                {table.name} <p>[{table.database || 'RDS'}]</p>
                                            </label>
                                        ))}

                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}

            <div className='filter-cards'>
                <div className="person-info-filter">
                    <h4>Prospect Information</h4>
                    <div className="dropdown">
                        <button className="dropdown-toggle" onClick={() => handleTogglePersonDropdown('firstName')}>
                            First Name <span className={`arrow ${activePersonDropdown === 'firstName' ? 'up' : ''}`}>▼</span>
                        </button>
                        {activePersonDropdown === 'firstName' &&
                            <div className="dropdown-content">
                                <input
                                    type="text"
                                    value={personFilters.firstName}
                                    onChange={(e) => handlePersonFilterChange(e, 'firstName')}
                                    onKeyPress={(e) => handlePersonKeyPress(e, 'firstName')}
                                    placeholder="Search first name"
                                />
                            </div>
                        }
                        {personFilterTags.firstName.map((tag, index) => (
                            <div key={index} className="filter-tag">
                                {tag}
                                <button onClick={() => removePersonTag('firstName', index)}>x</button>
                            </div>
                        ))}
                    </div>

                    <div className="dropdown">
                        <button className="dropdown-toggle" onClick={() => handleTogglePersonDropdown('lastName')}>
                            Last Name <span className={`arrow ${activePersonDropdown === 'lastName' ? 'up' : ''}`}>▼</span>
                        </button>
                        {activePersonDropdown === 'lastName' &&
                            <div className="dropdown-content">
                                <input
                                    type="text"
                                    value={personFilters.lastName}
                                    onChange={(e) => handlePersonFilterChange(e, 'lastName')}
                                    onKeyPress={(e) => handlePersonKeyPress(e, 'lastName')}
                                    placeholder="Search last name"
                                />
                            </div>
                        }
                        {personFilterTags.lastName.map((tag, index) => (
                            <div key={index} className="filter-tag">
                                {tag}
                                <button onClick={() => removePersonTag('lastName', index)}>x</button>
                            </div>
                        ))}
                    </div>

                    <div className="dropdown">
                        <button className="dropdown-toggle" onClick={() => handleTogglePersonDropdown('email')}>
                            Email <span className={`arrow ${activePersonDropdown === 'email' ? 'up' : ''}`}>▼</span>
                        </button>
                        {activePersonDropdown === 'email' &&
                            <div className="dropdown-content">
                                <input
                                    type="text"
                                    value={personFilters.email}
                                    onChange={(e) => handlePersonFilterChange(e, 'email')}
                                    onKeyPress={(e) => handlePersonKeyPress(e, 'email')}
                                    placeholder="Search email"
                                />
                            </div>
                        }
                        {personFilterTags.email.map((tag, index) => (
                            <div key={index} className="filter-tag">
                                {tag}
                                <button onClick={() => removePersonTag('email', index)}>x</button>
                            </div>
                        ))}
                    </div>

                    <div className="dropdown">
                        <button className="dropdown-toggle" onClick={() => handleTogglePersonDropdown('title')}>
                            Title <span className={`arrow ${activePersonDropdown === 'title' ? 'up' : ''}`}>▼</span>
                        </button>
                        {activePersonDropdown === 'title' && (
                            <div className="dropdown-content">
                                <input
                                    type="text"
                                    ref={titleInputRef}
                                    value={personFilters.title}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        handlePersonFilterChange(e, 'title');
                                        if (selectedValue !== '' && TITLES.includes(selectedValue)) {
                                            if (!personFilterTags.title.includes(selectedValue)) {
                                                setPersonFilterTags(prevState => ({
                                                    ...prevState,
                                                    title: [...prevState.title, selectedValue]
                                                }));
                                            }
                                            setPersonFilters(prevState => ({
                                                ...prevState,
                                                title: ''
                                            }));
                                        }
                                    }}

                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            applyPersonFilter('title');
                                            if (titleInputRef.current) {
                                                titleInputRef.current.blur();
                                            }
                                        }
                                    }}

                                    placeholder="Search title"
                                    list="title-options"
                                />
                                <datalist id="title-options">
                                    {TITLES.map(title => (
                                        <option key={title} value={title} />
                                    ))}
                                </datalist>

                            </div>

                        )}
                        {personFilterTags.title.map((tag, index) => (
                            <div key={index} className="filter-tag">
                                {tag}
                                <button onClick={() => removePersonTag('title', index)}>x</button>
                            </div>
                        ))}
                    </div>

                </div>
                <div className="person-info-filter">
                    <h4>Prospect Location</h4>
                    <div className="dropdown">
                        <button className="dropdown-toggle" onClick={() => handleToggleCompanyDropdown('leadCountry')}>
                            Lead Country <span className={`arrow ${activeCompanyDropdown === 'leadCountry' ? 'up' : ''}`}>▼</span>
                        </button>
                        {activeCompanyDropdown === 'leadCountry' && (
                            <div className="dropdown-content">
                                <input
                                    type="text"
                                    ref={leadCountryInputRef}
                                    value={leadLocationFilters.leadCountry}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        handleLeadLocationChange(e, 'leadCountry');
                                        setSelectedLeadCountry(selectedValue);
                                        setSelectedLeadState('');
                                        setSelectedLeadCity('');
                                        setLeadLocationFilters((prevFilters) => ({
                                            ...prevFilters,
                                            leadState: '',
                                            leadCity: '',
                                        }));
                                        if (
                                            selectedValue !== '' &&
                                            Country.getAllCountries().some((country) => country.name === selectedValue)
                                        ) {
                                            if (!leadLocationFilterTags.leadCountry.includes(selectedValue)) {
                                                setLeadLocationFilterTags(prevState => ({
                                                    ...prevState,
                                                    leadCountry: [...prevState.leadCountry, selectedValue]
                                                }));
                                            }
                                            setLeadLocationFilters(prevState => ({
                                                ...prevState,
                                                leadCountry: ''
                                            }));
                                        }
                                    }}

                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            applyLeadLocationFilter('leadCountry');
                                            if (leadCountryInputRef.current) {
                                                leadCountryInputRef.current.blur();
                                            }
                                        }
                                    }}

                                    placeholder="Search lead country"
                                    list="company-country-options"
                                />
                            </div>
                        )}
                        <datalist id="company-country-options">
                            {Country.getAllCountries().map((country) => (
                                <option key={country.isoCode} value={country.name} />
                            ))}
                        </datalist>
                        {leadLocationFilterTags.leadCountry.map((tag, index) => (
                            <div key={index} className="filter-tag">
                                {tag}
                                <button onClick={() => removeLeadLocationTag('leadCountry', index)}>x</button>
                            </div>
                        ))}
                    </div>
                    <div className="dropdown">
                        <button className="dropdown-toggle" onClick={() => handleToggleCompanyDropdown('leadState')}>
                            Lead State <span className={`arrow ${activeCompanyDropdown === 'leadState' ? 'up' : ''}`}>▼</span>
                        </button>
                        {activeCompanyDropdown === 'leadState' && (
                            <div className="dropdown-content">
                                <input
                                    type="text"
                                    ref={leadStateInputRef}
                                    value={leadLocationFilters.leadState}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        handleLeadLocationChange(e, 'leadState');
                                        setSelectedLeadState(selectedValue);
                                        setSelectedLeadCity('');
                                        setLeadLocationFilters((prevFilters) => ({
                                            ...prevFilters,
                                            leadCity: '',
                                        }));
                                        if (
                                            selectedValue !== '' &&
                                            State.getStatesOfCountry(
                                                Country.getAllCountries().find(
                                                    (country) => country.name === selectedLeadCountry
                                                )?.isoCode
                                            ).some((state) => state.name === selectedValue)
                                        ) {
                                            if (!leadLocationFilterTags.leadState.includes(selectedValue)) {
                                                setLeadLocationFilterTags(prevState => ({
                                                    ...prevState,
                                                    leadState: [...prevState.leadState, selectedValue]
                                                }));
                                            }
                                            setLeadLocationFilters(prevState => ({
                                                ...prevState,
                                                leadState: ''
                                            }));
                                        }
                                    }}

                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            applyLeadLocationFilter('leadState');
                                            if (leadCountryInputRef.current) {
                                                leadCountryInputRef.current.blur();
                                            }
                                        }
                                    }}

                                    placeholder="Search lead state"
                                    list="lead-state-options"
                                />
                            </div>
                        )}
                        <datalist id="lead-state-options">
                            {selectedLeadCountry &&
                                State.getStatesOfCountry(
                                    Country.getAllCountries().find(
                                        (country) => country.name === selectedLeadCountry
                                    )?.isoCode
                                ).map((state) => (
                                    <option key={state.isoCode} value={state.name} />
                                ))}
                        </datalist>
                        {leadLocationFilterTags.leadState.map((tag, index) => (
                            <div key={index} className="filter-tag">
                                {tag}
                                <button onClick={() => removeLeadLocationTag('leadState', index)}>x</button>
                            </div>
                        ))}
                    </div>
                    <div className="dropdown">
                        <button className="dropdown-toggle" onClick={() => handleToggleCompanyDropdown('leadCity')}>
                            Lead City <span className={`arrow ${activeCompanyDropdown === 'leadCity' ? 'up' : ''}`}>▼</span>
                        </button>
                        {activeCompanyDropdown === 'leadCity' && (
                            <div className="dropdown-content">
                                <input
                                    type="text"
                                    ref={leadCityInputRef}
                                    value={leadLocationFilters.leadCity}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        handleLeadLocationChange(e, 'leadCity');
                                        setSelectedLeadCity(selectedValue);
                                        if (
                                            selectedValue !== '' &&
                                            selectedLeadCountry && selectedLeadState &&
                                            City.getCitiesOfState(
                                                Country.getAllCountries().find(
                                                    (country) => country.name === selectedLeadCountry
                                                )?.isoCode,
                                                State.getStatesOfCountry(
                                                    Country.getAllCountries().find(
                                                        (country) => country.name === selectedLeadCountry
                                                    )?.isoCode
                                                ).find((state) => state.name === selectedLeadState)?.isoCode
                                            ).some((city) => city.name === selectedValue)
                                        ) {
                                            if (!leadLocationFilterTags.leadCity.includes(selectedValue)) {
                                                setLeadLocationFilterTags(prevState => ({
                                                    ...prevState,
                                                    leadCity: [...prevState.leadCity, selectedValue]
                                                }));
                                            }
                                            setLeadLocationFilters(prevState => ({
                                                ...prevState,
                                                leadCity: ''
                                            }));
                                        }
                                    }}

                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            applyLeadLocationFilter('leadCity');
                                            if (leadCountryInputRef.current) {
                                                leadCountryInputRef.current.blur();
                                            }
                                        }
                                    }}

                                    placeholder="Search lead city"
                                    list="lead-city-options"
                                />
                            </div>
                        )}
                        <datalist id="lead-city-options">
                            {selectedLeadCountry && selectedLeadState &&
                                City.getCitiesOfState(
                                    Country.getAllCountries().find(
                                        (country) => country.name === selectedLeadCountry
                                    )?.isoCode,
                                    State.getStatesOfCountry(
                                        Country.getAllCountries().find(
                                            (country) => country.name === selectedLeadCountry
                                        )?.isoCode
                                    ).find((state) => state.name === selectedLeadState)?.isoCode
                                ).map((city) => (
                                    <option key={city.name} value={city.name} />
                                ))}
                        </datalist>
                        {leadLocationFilterTags.leadCity.map((tag, index) => (
                            <div key={index} className="filter-tag">
                                {tag}
                                <button onClick={() => removeLeadLocationTag('leadCity', index)}>x</button>
                            </div>
                        ))}
                    </div>





                </div>
                <div className='person-info-filter'>
                    <h4>Company Information</h4>
                    <div className="dropdown">
                        <button className="dropdown-toggle" onClick={() => handleToggleCompanyDropdown('companyName')}>
                            Company Name <span className={`arrow ${activeCompanyDropdown === 'companyName' ? 'up' : ''}`}>▼</span>
                        </button>
                        {activeCompanyDropdown === 'companyName' &&
                            <div className="dropdown-content">
                                <input
                                    type="text"
                                    value={companyFilters.companyName}
                                    onChange={(e) => handleCompanyFilterChange(e, 'companyName')}
                                    onKeyPress={(e) => handleCompanyKeyPress(e, 'companyName')}
                                    placeholder="Search company name"
                                />
                                {companyFilterTags.companyName.map((tag, index) => (
                                    <div key={index} className="filter-tag">
                                        {tag}
                                        <button onClick={() => removeCompanyTag('companyName', index)}>x</button>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>

                    <div className="dropdown">
                        <button className="dropdown-toggle" onClick={() => handleToggleCompanyDropdown('industry')}>
                            Industry <span className={`arrow ${activeCompanyDropdown === 'industry' ? 'up' : ''}`}>▼</span>
                        </button>
                        {activeCompanyDropdown === 'industry' &&
                            <div className="dropdown-content">
                                <input
                                    type="text"
                                    ref={industryInputRef}
                                    value={companyFilters.industry}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        handleCompanyFilterChange(e, 'industry');
                                        if (selectedValue !== '' && INDUSTRIES.includes(selectedValue)) {
                                            if (!companyFilterTags.industry.includes(selectedValue)) {
                                                setCompanyFilterTags(prevState => ({
                                                    ...prevState,
                                                    industry: [...prevState.industry, selectedValue]
                                                }));
                                            }
                                            setCompanyFilters(prevState => ({
                                                ...prevState,
                                                industry: ''
                                            }));
                                        }
                                    }}

                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            applyCompanyFilter('industry');
                                            if (industryInputRef.current) {
                                                industryInputRef.current.blur();
                                            }
                                        }
                                    }}

                                    placeholder="Search industry"
                                    list="industry-options"
                                />
                                <datalist id="industry-options">
                                    {INDUSTRIES.map(industry => (
                                        <option key={industry} value={industry} />
                                    ))}
                                </datalist>

                            </div>
                        }
                        {companyFilterTags.industry.map((tag, index) => (
                            <div key={index} className="filter-tag">
                                {tag}
                                <button onClick={() => removeCompanyTag('industry', index)}>x</button>
                            </div>
                        ))}
                    </div>

                    <div className="dropdown">
                        <button className="dropdown-toggle" onClick={() => handleToggleCompanyDropdown('teamSize')}>
                            Team Size <span className={`arrow ${activeCompanyDropdown === 'teamSize' ? 'up' : ''}`}>▼</span>
                        </button>
                        {activeCompanyDropdown === 'teamSize' &&
                            <div className="dropdown-content">
                                <input
                                    type="text"
                                    ref={teamSizeInputRef}
                                    value={companyFilters.teamSize}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        handleCompanyFilterChange(e, 'teamSize');
                                        if (selectedValue !== '' && TEAM_SIZES.includes(selectedValue)) {
                                            if (!companyFilterTags.teamSize.includes(selectedValue)) {
                                                setCompanyFilterTags(prevState => ({
                                                    ...prevState,
                                                    teamSize: [...prevState.teamSize, selectedValue]
                                                }));
                                            }
                                            setCompanyFilters(prevState => ({
                                                ...prevState,
                                                teamSize: ''
                                            }));
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            applyCompanyFilter('teamSize');
                                            if (teamSizeInputRef.current) {
                                                teamSizeInputRef.current.blur();
                                            }
                                        }
                                    }}

                                    placeholder="Search team size"
                                    list="teamSize-options"
                                />
                                <datalist id="teamSize-options">
                                    {TEAM_SIZES.map(title => (
                                        <option key={title} value={title} />
                                    ))}
                                </datalist>

                            </div>
                        }
                        {companyFilterTags.teamSize.map((tag, index) => (
                            <div key={index} className="filter-tag">
                                {tag}
                                <button onClick={() => removeCompanyTag('teamSize', index)}>x</button>
                            </div>
                        ))}
                    </div>

                    <div className="dropdown">
                        <button className="dropdown-toggle" onClick={() => handleToggleCompanyDropdown('companyDomain')}>
                            Company Domain <span className={`arrow ${activeCompanyDropdown === 'companyDomain' ? 'up' : ''}`}>▼</span>
                        </button>
                        {activeCompanyDropdown === 'companyDomain' &&
                            <div className="dropdown-content">
                                <input
                                    type="text"
                                    value={companyFilters.companyDomain}
                                    onChange={(e) => handleCompanyFilterChange(e, 'companyDomain')}
                                    onKeyPress={(e) => handleCompanyKeyPress(e, 'companyDomain')}
                                    placeholder="Search company domain"
                                />
                                {companyFilterTags.companyDomain.map((tag, index) => (
                                    <div key={index} className="filter-tag">
                                        {tag}
                                        <button onClick={() => removeCompanyTag('companyDomain', index)}>x</button>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                </div>
                <div className="person-info-filter">
                    <h4>Company Location</h4>

                    <div className="dropdown">
                        <button className="dropdown-toggle" onClick={() => handleToggleCompanyDropdown('companyCountry')}>
                            Company Country <span className={`arrow ${activeCompanyDropdown === 'companyCountry' ? 'up' : ''}`}>▼</span>
                        </button>
                        {activeCompanyDropdown === 'companyCountry' && (
                            <div className="dropdown-content">
                                <input
                                    type="text"
                                    ref={companyCountryInputRef}
                                    value={companyLocationFilters.companyCountry}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        handleCompanyLocationChange(e, 'companyCountry');
                                        setSelectedCompanyCountry(selectedValue);
                                        setSelectedCompanyState('');
                                        setSelectedCompanyCity('');
                                        setCompanyLocationFilters((prevFilters) => ({
                                            ...prevFilters,
                                            companyState: '',
                                            companyCity: '',
                                        }));
                                        if (
                                            selectedValue !== '' &&
                                            Country.getAllCountries().some((country) => country.name === selectedValue)
                                        ) {
                                            if (!companyLocationFilterTags.companyCountry.includes(selectedValue)) {
                                                setCompanyLocationFilterTags(prevState => ({
                                                    ...prevState,
                                                    companyCountry: [...prevState.companyCountry, selectedValue]
                                                }));
                                            }
                                            setCompanyLocationFilters(prevState => ({
                                                ...prevState,
                                                companyCountry: ''
                                            }));
                                        }
                                    }}

                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            applyCompanyLocationFilter('companyCountry');
                                            if (companyCountryInputRef.current) {
                                                companyCountryInputRef.current.blur();
                                            }
                                        }
                                    }}


                                    placeholder="Search Company Country"
                                    list="company-country-options"
                                />
                            </div>
                        )}
                        <datalist id="company-country-options">
                            {Country.getAllCountries().map((country) => (
                                <option key={country.isoCode} value={country.name} />
                            ))}
                        </datalist>
                        {companyLocationFilterTags.companyCountry.map((tag, index) => (
                            <div key={index} className="filter-tag">
                                {tag}
                                <button onClick={() => removeCompanyLocationTag('companyCountry', index)}>x</button>
                            </div>
                        ))}
                    </div>

                    <div className="dropdown">
                        <button className="dropdown-toggle" onClick={() => handleToggleCompanyDropdown('companyState')}>
                            Company State <span className={`arrow ${activeCompanyDropdown === 'companyState' ? 'up' : ''}`}>▼</span>
                        </button>
                        {activeCompanyDropdown === 'companyState' && (
                            <div className="dropdown-content">
                                <input
                                    type="text"
                                    ref={companyStateInputRef}
                                    value={companyLocationFilters.companyState}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        handleCompanyLocationChange(e, 'companyState');
                                        setSelectedCompanyState(selectedValue);
                                        setSelectedCompanyCity('');
                                        setCompanyLocationFilters((prevFilters) => ({
                                            ...prevFilters,
                                            companyCity: '',
                                        }));

                                        if (
                                            selectedValue !== '' &&
                                            selectedCompanyCountry &&
                                            State.getStatesOfCountry(
                                                Country.getAllCountries().find(
                                                    (country) => country.name === selectedCompanyCountry
                                                )?.isoCode
                                            ).some((state) => state.name === selectedValue)
                                        ) {
                                            setCompanyLocationFilterTags((prevState) => {
                                                const companyStateRegion = prevState['companyState/Region'] || [];
                                                if (!companyStateRegion.includes(selectedValue)) {
                                                    return {
                                                        ...prevState,
                                                        'companyState/Region': [...companyStateRegion, selectedValue],
                                                    };
                                                }
                                                return prevState;
                                            });

                                            setCompanyLocationFilters((prevState) => ({
                                                ...prevState,
                                                companyState: '',
                                            }));
                                        }
                                    }}


                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            applyCompanyLocationFilter('companyState');
                                            if (companyStateInputRef.current) {
                                                companyStateInputRef.current.blur();
                                            }
                                        }
                                    }}

                                    placeholder="Search Company State"
                                    list="company-state-options"
                                />
                            </div>
                        )}
                        <datalist id="company-state-options">
                            {selectedCompanyCountry &&
                                State.getStatesOfCountry(
                                    Country.getAllCountries().find(
                                        (country) => country.name === selectedCompanyCountry
                                    )?.isoCode
                                ).map((state) => (
                                    <option key={state.isoCode} value={state.name} />
                                ))}
                        </datalist>
                        {companyLocationFilterTags["companyState/Region"] && companyLocationFilterTags["companyState/Region"].map((tag, index) => (
                            <div key={index} className="filter-tag">
                                {tag}
                                <button onClick={() => removeCompanyLocationTag("companyState/Region", index)}>x</button>
                            </div>
                        ))}
                    </div>

                    <div className="dropdown">
                        <button className="dropdown-toggle" onClick={() => handleToggleCompanyDropdown('companyCity')}>
                            Company City <span className={`arrow ${activeCompanyDropdown === 'companyCity' ? 'up' : ''}`}>▼</span>
                        </button>
                        {activeCompanyDropdown === 'companyCity' && (
                            <div className="dropdown-content">
                                <input
                                    type="text"
                                    ref={companyCityInputRef}
                                    value={companyLocationFilters.companyCity}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        handleCompanyLocationChange(e, 'companyCity');
                                        setSelectedCompanyCity(selectedValue);
                                        if (
                                            selectedValue !== '' &&
                                            selectedCompanyCountry &&
                                            selectedCompanyState &&
                                            City.getCitiesOfState(
                                                Country.getAllCountries().find(
                                                    (country) => country.name === selectedCompanyCountry
                                                )?.isoCode,
                                                State.getStatesOfCountry(
                                                    Country.getAllCountries().find(
                                                        (country) => country.name === selectedCompanyCountry
                                                    )?.isoCode
                                                ).find((state) => state.name === selectedCompanyState)?.isoCode
                                            ).some((city) => city.name === selectedValue)
                                        ) {
                                            if (!companyLocationFilterTags.companyCity.includes(selectedValue)) {
                                                setCompanyLocationFilterTags(prevState => ({
                                                    ...prevState,
                                                    companyCity: [...prevState.companyCity, selectedValue]
                                                }));
                                            }
                                            setCompanyLocationFilters(prevState => ({
                                                ...prevState,
                                                companyCity: ''
                                            }));
                                        }
                                    }}

                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            applyCompanyLocationFilter('companyCity');
                                            if (companyCityInputRef.current) {
                                                companyCityInputRef.current.blur();
                                            }
                                        }
                                    }}

                                    placeholder="Search Company City"
                                    list="company-city-options"
                                />
                            </div>
                        )}
                        <datalist id="company-city-options">
                            {selectedCompanyCountry && selectedCompanyState &&
                                City.getCitiesOfState(
                                    Country.getAllCountries().find(
                                        (country) => country.name === selectedCompanyCountry
                                    )?.isoCode,
                                    State.getStatesOfCountry(
                                        Country.getAllCountries().find(
                                            (country) => country.name === selectedCompanyCountry
                                        )?.isoCode
                                    ).find((state) => state.name === selectedCompanyState)?.isoCode
                                ).map((city) => (
                                    <option key={city.name} value={city.name} />
                                ))}
                        </datalist>
                        {companyLocationFilterTags.companyCity.map((tag, index) => (
                            <div key={index} className="filter-tag">
                                {tag}
                                <button onClick={() => removeCompanyLocationTag('companyCity', index)}>x</button>
                            </div>
                        ))}
                    </div>


                    <div className="dropdown">
                        <button className="dropdown-toggle" onClick={() => handleToggleCompanyDropdown('companyZip')}>
                            Company Zip  <span className={`arrow ${activeCompanyDropdown === 'companyZip' ? 'up' : ''}`}>▼</span>
                        </button>
                        {activeCompanyDropdown === 'companyZip' && (
                            <div className="dropdown-content">
                                <input
                                    type="text"
                                    value={companyLocationFilters.companyZip}
                                    onChange={(e) => handleCompanyLocationChange(e, 'companyZip')}
                                    onKeyPress={(e) => handleCompanyLocationKeyPress(e, 'companyZip', applyCompanyLocationFilter)}
                                    placeholder="Search Company Zip "
                                />
                            </div>
                        )}
                        {companyLocationFilterTags.companyZip.map((tag, index) => (
                            <div key={index} className="filter-tag">
                                {tag}
                                <button onClick={() => removeCompanyLocationTag('companyZip', index)}>x</button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="filter-actions-btn">
                <button onClick={clearAllFilters} className='filters-reset-btn'>Reset</button>
                <button onClick={applyFilters}>Submit</button>

            </div>
            {notification.message && (
                <Notification type={notification.type} message={notification.message} />
            )}

            {isLoading && <LoadingOverlay />}
        </div>

    );
};

export default PersonInfoFilter;
