import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';

import './databankMainPage.css';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faTable, faSearch, faExchangeAlt, faLink, faLock, faDatabase, faFilePdf, faFileCsv, faFilePowerpoint, faFileExcel, faFileImage, faFileArchive, } from '@fortawesome/free-solid-svg-icons';
import debounce from 'lodash/debounce';
import { UserContext, useUser } from '../context/userContext';
import Breadcrumbs from '../breadcrumbs/breadcrumbs';



const useDebounce = (callback, delay) => {
    const timeoutRef = useRef(null);
    const isSearchingRef = useRef(false);

    const debouncedCallback = useCallback(
        (...args) => {
            if (!isSearchingRef.current) {
                isSearchingRef.current = true;
                timeoutRef.current = setTimeout(() => {
                    callback(...args);
                    isSearchingRef.current = false;
                }, delay);
            } else {
                clearTimeout(timeoutRef.current);
                timeoutRef.current = setTimeout(() => {
                    callback(...args);
                    isSearchingRef.current = false;
                }, delay);
            }
        },
        [callback, delay]
    );

    return debouncedCallback;
};

const DataBank = ({ onTabChange }) => {
    const { user } = useContext(UserContext);
    const userId = user?.userId
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('all-items');
    const [isFocused, setIsFocused] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const { formatDate, viewFile } = useUser()
    const [isLockerSelected, setIsLockerSelected] = useState(false);





    useEffect(() => {
        // Check if the current URL is a selected locker route
        const isLockerRoute = location.pathname.includes('/home/lockers/');
        setIsLockerSelected(isLockerRoute);
    }, [location]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            const searchContainer = document.querySelector('.databank-search-bar');
            if (searchContainer && !searchContainer.contains(event.target)) {
                setIsFocused(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        // This effect will update the activeTab state based on the URL path
        const pathSegments = location.pathname.split('/'); // Get all segments of the URL
        if (pathSegments.includes('lockers')) {
            setActiveTab('lockers');
        } else if (pathSegments.includes('all-items')) {
            setActiveTab('all-items');
        } else if (pathSegments.includes('files')) {
            setActiveTab('files');
        } else if (pathSegments.includes('tables')) {
            setActiveTab('tables');
        } else if (pathSegments.includes('links')) {
            setActiveTab('links');
        } else {
            setActiveTab('all-items'); // Set default to 'Lockers' if no relevant path segment is found
            navigate('/home/all-items'); // Navigate to 'Lockers' by default
        }
    }, [location, navigate]);


    const handleTabClick = (tab) => {
        if (typeof onTabChange === 'function') {
            onTabChange(tab);
        }
        navigate(`/home/${tab}`);

    };

    const callSearchAPI = useCallback(
        async (query) => {
            if (query !== '') {
                try {
                    const response = await fetch(
                        `https://pages.onepgr.com/session/data-bank-search?user_id=${userId}&search=${encodeURIComponent(query)}`
                    );
                    console.log(response);
                    const responseData = await response.json();

                    // Check if the API response has a 'data' property and it is an array
                    if (responseData && Array.isArray(responseData.data)) {
                        setSearchResults(responseData.data);
                    } else {
                        // Handle the case when the 'data' property is not an array or doesn't exist
                        console.error('Invalid API response:', responseData);
                        setSearchResults([]);
                    }
                } catch (error) {
                    console.error('Error fetching search results:', error);
                }
            } else {
                setSearchResults([]);
            }
        },
        [userId]
    );

    const debouncedHandleSearchChange = useDebounce(callSearchAPI, 500);

    const handleSearchChange = useCallback(
        (event) => {
            const query = event.target.value;
            setSearchQuery(query);
            debouncedHandleSearchChange(query);
        },
        [debouncedHandleSearchChange]
    );

    const handleResultClick = (result) => {
        console.log('clicked')
        if (result.object_type === 'file') {
            // Call view file function
            viewFile(result.source_url);
        } else if (result.object_type === 'table') {
            // Navigate to /tables
            navigate('/tables');
        } else if (result.object_type === 'link') {
            // Open the link in a new tab
            window.open(result.name, '_blank');
        } else if (result.object_type === 'item') {
            // Navigate to /lockers
            navigate(`/home/lockers/${result.token}/AllItems`);
        }
        setSearchQuery('');
        setSearchResults([]);
        setIsFocused(false);
    };

    const handleInputBlur = (event) => {
        // Check if the click target is within the search dropdown container or the advanced search content div
        const searchDropdownContainer = event.currentTarget.parentNode.querySelector('.search-dropdown-container');
        const advancedSearchContent = event.currentTarget.parentNode.querySelector('.advanced-search-content');
        if (
            searchDropdownContainer &&
            !searchDropdownContainer.contains(event.relatedTarget) &&
            !advancedSearchContent.contains(event.relatedTarget)
        ) {
            setIsFocused(false);
            if (searchQuery === '') {
                setSearchResults([]);
            }
        }
    };

    const getIconByExtension = (url) => {
        if (!url) return faLock; // Default if no URL is provided
        const extension = url.split(".").pop().toLowerCase(); // Extract extension
        switch (extension) {
            case "pdf":
                return faFilePdf;
            case "csv":
                return faFileCsv;
            case "pptx":
                return faFilePowerpoint;
            case "xlsx":
                return faFileExcel;
            case "png":
            case "jpg":
            case "jpeg":
            case "gif":
                return faFileImage;
            case "zip":
                return faFileArchive;
            default:
                return faLock; // Default icon for unsupported or unknown file types
        }
    };

    const handleAdvancedSearch = () => {
        navigate(`/search`)
    }
    const handleClickAdvanced = (event) => {
        // Prevent the default behavior of the click event
        event.preventDefault();

        // Focus the input and move the cursor to the end
        const searchInput = document.querySelector('.databank-search-input');
        if (searchInput) {
            searchInput.focus();
            searchInput.selectionStart = searchInput.selectionEnd = searchInput.value.length;
        }
    };

    const SearchDropdown = () => {
        return (
            <div className='search-content'>
                <div className="search-dropdown">
                    {Array.isArray(searchResults) ? (
                        searchResults.map((result) => (
                            <div
                                key={result.id}
                                className="search-result global-search-results"
                                title={result.object_type === 'table' ? result.source_url : result.name}
                                onClick={() => handleResultClick(result)}
                            >
                                {/* Display the result data */}
                                <div className="result-icon">
                                    {/* Display the appropriate icon based on the result type */}
                                    {result.object_type === 'file' && <FontAwesomeIcon icon={getIconByExtension(result.name)} />}
                                    {result.object_type === 'table' && <FontAwesomeIcon icon={faTable} />}
                                    {result.object_type === 'item' && <FontAwesomeIcon icon={faLock} />}
                                    {result.object_type === 'link' && <FontAwesomeIcon icon={faLink} />}
                                    {/* Add more conditions for other result types */}
                                </div>
                                <div className="result-details">
                                    <div className="result-name">{result.name}</div>
                                    <div className="result-date">{formatDate(result.date)}</div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div>No search results found.</div>
                    )}
                    
                </div>

                <div className='advanced-search-content' onClick={handleClickAdvanced}>
                    <button className='advanced-search-button' onClick={handleAdvancedSearch}>Advanced Search</button>
                </div>
            </div>
        );
    };


    return (
        <header className="databank-header">
            {!isLockerSelected ? (
                <>
                    <p>Welcome to Databank</p>

                    <div className={`databank-search-bar ${isFocused ? 'focused' : ''} ${searchResults.length > 0 ? 'has-results' : ''}`}>
                        <div className={`databank-search-container ${isFocused ? 'focused' : ''}`}>
                            <div className="icon-card databank-searchIcon">
                                <FontAwesomeIcon className="databank-icon-search" icon={faSearch} />
                            </div>
                            <input
                                type="text"
                                placeholder="Search in Databank"
                                className="databank-search-input"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                onFocus={() => setIsFocused(true)}
                                onBlur={handleInputBlur}
                            />
                        </div>
                        <div className='search-dropdown-container'>
                            {isFocused && <SearchDropdown />}
                        </div>
                    </div>


                    <nav className='databank-nav'>

                        <button
                            className={`databank-nav-btn ${activeTab === 'all-items' ? 'active' : ''}`}
                            onClick={() => handleTabClick('all-items')}
                        >
                            <FontAwesomeIcon icon={faDatabase} className="font-icon" /> <span> All Items</span>
                        </button>
                        <button
                            className={`databank-nav-btn ${activeTab === 'lockers' ? 'active' : ''}`}
                            onClick={() => handleTabClick('lockers')}
                        >
                            <FontAwesomeIcon icon={faLock} className="font-icon" /> <span> Lockers</span>
                        </button>
                        <button
                            className={`databank-nav-btn ${activeTab === 'tables' ? 'active' : ''}`}
                            onClick={() => handleTabClick('tables')}
                        >
                            <FontAwesomeIcon icon={faTable} className="font-icon" /> <span> Tables</span>
                        </button>
                        <button
                            className={`databank-nav-btn ${activeTab === 'files' ? 'active' : ''}`}
                            onClick={() => handleTabClick('files')}
                        >
                            <FontAwesomeIcon icon={faFileAlt} className="font-icon" /> <span> Files</span>
                        </button>

                        <button
                            className={`databank-nav-btn ${activeTab === 'links' ? 'active' : ''}`}
                            onClick={() => handleTabClick('links')}
                        >
                            <FontAwesomeIcon icon={faLink} className="font-icon" /> <span> Links</span>
                        </button>
                    </nav>
                    <div className='databank-outlet'>
                        <Outlet />
                    </div>
                </>
            ) : (
                <div className="databank-outlet">
                    <Outlet />
                </div>
            )}
        </header>
    );
};

export default DataBank;