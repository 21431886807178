import React, { useState, useContext, useRef, useEffect } from 'react';
import './lockers.css'; // Make sure to create a corresponding CSS file
import { UserContext, useUser } from '../../context/userContext';
import { faLock, faFilePdf, faFileCsv, faFilePowerpoint, faFileExcel, faFileImage, faVideo, faFileUpload, faAdd, faTable, faLink, faFileArchive, faEllipsisV, faEllipsisH, faFileVideo, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import Pagination from '../../s3dataBase/fileList/pagination';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import LockerNameModal from './lockerNameModal';
import LockerItemDetails from '../selectedLocker/selectedlocker';
import axios from 'axios';
import Notification from '../../notification /notification';
import DropdownActionsMenu from './dropdownComponent';
import ActionsIcon from '../../actionsIcon/actionsIcon';
import FileRepository from '../../s3dataBase/fileList/fileList';
import FileUploadComponent from '../../s3dataBase/fileUpload/fileUpload';
import { useRefresh } from '../../refreshContext /refreshContext';

const Lockers = ({ index }) => {
    const { user } = useContext(UserContext);
    const { formatDate, allFiles, allTables, linksData, fetchLinks, linkDetails, allObjects, fetchAllObjects, totalItems, setCurrentPage, setItemsPerPage, fetchDataBankTables, fetchDataSourceFiles } = useUser();
    const location = useLocation();
    const [currentPageLocal, setCurrentPageLocal] = useState(1);
    const [itemsPerPageLocal, setItemsPerPageLocal] = useState(10);
    const navigate = useNavigate();
    const [selectedLockerItem, setSelectedLockerItem] = useState(null);
    const pathSegments = location.pathname.split('/');
    const activeTab = pathSegments[pathSegments.length - 1]; // 'lockers', 'myFiles', 'myTables', 'myLinks'
    const [notification, setNotification] = useState({ message: '', type: '' });
    const userId = user?.userId
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newLockerName, setNewLockerName] = useState('');
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const dropdownContainerRef = useRef(null);
    const { viewFile } = useUser();
    const [currentItem, setCurrentItem] = useState(null);
    const [overlayInfoOpen, setOverlayInfoOpen] = useState(false);
    const [itemDetails, setItemDetails] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [editableFileName, setEditableFileName] = useState('');
    const [newTag, setNewTag] = useState('');
    const [inviteUrl, setInviteUrl] = useState('');
    const [showInviteOverlay, setShowInviteOverlay] = useState(false)
    const [copyIcon, setCopyIcon] = useState(false);
    const [lockerDetails, setLockerDetails] = useState(null)
    const [lockerTotalItems, setLockerTotalItems] = useState()
    const [selectedLockerToken, setSelectedLockerToken] = useState()
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const [droppedFiles, setDroppedFiles] = useState([]);
    const [link, setLink] = useState('');
    const [isOverlayLoading, setIsOverlayLoading] = useState(false);
    const { triggerRefreshLinks, triggerRefreshAllItems } = useRefresh()
    // const [allObjects, setAllObjects] = useState([])
    const [isDragging, setIsDragging] = useState(false);
    const { refreshFileList } = useRefresh();
    const { triggerFetchLockerDetails } = useUser();
    const [dropDownLockerDetails, setDropDownLockerDetails] = useState(null);
    const [password, setPassword] = useState(null);
    const currentDateTime = new Date().toISOString();
    const [sortOrder, setSortOrder] = useState({
        name: null,
        date: 'desc',
        owner: null,
        location: null,
    });

    console.log(password)
    console.log(activeTab)
    console.log(allObjects)
    


    // const toggleDropdown = (index) => {
    //     console.log('toggleDropdown called with index:', index);
    //     setOpenDropdownId(openDropdownId === index ? null : index);
    // };
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };


    useEffect(() => {
        if (location.pathname === '/home/lockers') {
            fetchLockerDetails(1);
        }
    }, [location]);

    useEffect(() => {
        if (location.pathname === '/home/all-items') {
            fetchAllObjects();
        }
    }, [location]);


    useEffect(() => {
        const handleDragOver = (e) => {
            e.preventDefault();
            setIsDragging(true);
        };

        const handleDrop = (e) => {
            e.preventDefault();
            setIsDragging(false);
            const files = e.dataTransfer.files;
            if (files.length) {
                setDroppedFiles(files);
            }
        };
        const handleDragLeave = (e) => {
            e.preventDefault();
            setIsDragging(false);
        };
        // Listen for drag and drop events on the window
        window.addEventListener('dragover', handleDragOver);
        window.addEventListener('drop', handleDrop);
        window.addEventListener('dragleave', handleDragLeave);

        return () => {
            window.removeEventListener('dragover', handleDragOver);
            window.removeEventListener('drop', handleDrop);
            window.removeEventListener('dragleave', handleDragLeave);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // const fetchAllObjects = async () => {
    //     console.log('Fetching all databank objects');
    //     try {
    //         const response = await axios.get('https://pages.onepgr.com/session/get-all-databank-objects', {
    //             params: { user_id: user?.userId, root: 1 },
    //         });

    //         console.log(response.data);
    //         if (response.data.data.length > 0) {
    //             setAllObjects(response.data.data); // Store all databank objects
    //             console.log('Total objects:', response.data.data.length);
            
    //         } else {
    //             console.log('No databank objects found');
    //             setAllObjects([]);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching databank objects:', error);
    //         setAllObjects([]);
    //     }
    // };



    const fetchLockerDetails = async (root) => {
        console.log('setching locker details')
        try {
            const response = await axios.get('https://pages.onepgr.com/session/get-data-bank-items', {
                params: { user_id: user?.userId, root },
            });


            if (response.data.data.length > 0) {
                setLockerDetails(response.data.data); // Store all locker details

                setLockerTotalItems(response.data.data.length)
            } else {
                console.log('No locker details found');
                setLockerDetails(null);
                setLockerTotalItems(response.data.length)

            }
        } catch (error) {
            console.error('Error fetching locker details:', error);
            setLockerDetails(null);
        }
    };

    useEffect(() => {
        fetchDropdownLockerDetails()
    }, [])

    const fetchDropdownLockerDetails = async () => {
        try {
            const response = await axios.get('https://pages.onepgr.com/session/get-data-bank-items', {
                params: { user_id: user?.userId },
            });
            if (response.data.data.length > 0) {
                setDropDownLockerDetails(response.data.data); // Store all locker details


            } else {
                console.log('No locker details found');
                setDropDownLockerDetails(null);


            }
        } catch (error) {
            console.error('Error fetching locker details:', error);
            setDropDownLockerDetails(null);
        }
    };


    useEffect(() => {
        const fetchItemInfo = async () => {
            if (overlayInfoOpen && currentItem) {
                try {
                    let response;
                    let itemInfo;

                    if (activeTab === 'tables') {
                        response = await axios.get(`https://pages.onepgr.com/session/get-data-bank-table-info?user_id=${currentItem.user_id}&token=${currentItem.token}`);
                        itemInfo = response.data.data.table_info;
                    } else if (activeTab === 'lockers') {
                        response = await axios.get(`https://pages.onepgr.com/session/get-data-bank-item-info?user_id=${currentItem.user_id}&token=${currentItem.token}`);
                        itemInfo = response.data.data.item_info;
                    } else {
                        response = await axios.get(`https://pages.onepgr.com/session/get-data-bank-file-info?user_id=${currentItem.user_id}&token=${currentItem.token}`);
                        itemInfo = response.data.data.file_info;
                    }

                    console.log(itemInfo);

                    setItemDetails((prevItemDetails) => ({
                        ...prevItemDetails,
                        [currentItem._id]: itemInfo ? {
                            comments: itemInfo.comments ?? '',
                            companyInfo: itemInfo.company_info ?? '',
                            tags: itemInfo.tags ? (typeof itemInfo.tags === 'string' ? itemInfo.tags.split(', ') : [itemInfo.tags]) : []
                        } : {
                            comments: '',
                            companyInfo: '',
                            tags: []
                        }
                    }));
                } catch (error) {
                    console.error(`Error fetching ${activeTab === 'tables' ? 'table' : activeTab === 'lockers' ? 'locker' : 'file'} information:`, error);
                }
            }
        };

        fetchItemInfo();
    }, [overlayInfoOpen, currentItem, activeTab]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownContainerRef.current &&
                !dropdownContainerRef.current.contains(event.target) &&
                !event.target.closest('.LockerDropdownActions') &&
                openDropdownId !== null
            ) {
                setOpenDropdownId(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownContainerRef, openDropdownId]);

    const handleLockerItemClick = (lockerItem) => {
        setSelectedLockerItem(lockerItem);
        const database = lockerItem.database
        console.log(database)
        if (activeTab === 'all-items') {
            switch (lockerItem.object_type) {
                case 'table':
                    navigate(`/tables/${lockerItem.name}`, { 
                        state: { 
                            database: database || 'rds' // Provide a default value if database is undefined
                        } 
                    });
                    break;
                case 'file':
                    viewFile(lockerItem.source_url);
                    break;
                case 'link':
                    window.open(lockerItem.name, '_blank');
                    break;
                case 'item':
                    navigate(`/home/lockers/${lockerItem.token}`);
                    break;
                default:
                    console.log('Unknown object type');
            }
        } else if (activeTab === 'tables') {
            navigate(`/tables/${lockerItem.name}`, { 
                state: { 
                     database
                } 
            });
        } else if (activeTab === 'files') {
            viewFile(lockerItem.source_url);
        } else if (activeTab === 'lockers') {
            navigate(`/home/lockers/${lockerItem.token}`);
        } else if (activeTab === 'links') {
            window.open(lockerItem.name, '_blank');
        }
    };



    let currentDetails = [];
    let headerText = '';

    switch (activeTab) {

        case 'all-items':
            currentDetails = allObjects;
            headerText = 'All Items';
            break;
        case 'lockers':
            currentDetails = lockerDetails;
            headerText = 'All Lockers';
            break;
        case 'files':
            currentDetails = allFiles;
            headerText = 'All Files';
            break;
        case 'tables':
            currentDetails = allTables;
            headerText = 'All Tables';
            break;
        case 'links':
            currentDetails = linksData;
            headerText = 'All Links';
            break;
        default:
            currentDetails = []; // Default to an empty array if no known tab is active
            headerText = 'Details';
            break;
    }


    if (!currentDetails) {
        return <div></div>; // Handle the case where details are not yet fetched
    }
    const getIconByExtension = (url) => {
        if (!url) return faLock;  // Default if no URL is provided
        const extension = url.split('.').pop().toLowerCase(); // Extract extension
        switch (extension) {
            case 'pdf':
                return faFilePdf;
            case 'csv':
                return faFileCsv;
            case 'pptx':
                return faFilePowerpoint;
            case 'xlsx':
                return faFileExcel;
            case 'png':
            case 'jpg':
            case 'jpeg':
            case 'gif':
                return faFileImage;
            case 'zip':
                return faFileArchive;
            case 'mp4':
            case 'webm':
            case 'ogg':
                return faFileVideo;
            default:
                return faLock; // Default icon for unsupported or unknown file types
        }
    };

    const getIconForDetail = (detail) => {
        if (activeTab === 'all-items') {
            switch (detail.object_type) {
                case 'table':
                    return faTable;
                case 'file':
                    return getIconByExtension(detail.source_url);
                case 'link':
                    return faLink;
                case 'item':
                    return faLock;
                default:
                    return faLock; // Use default lock icon for unknown object types
            }
        } else if (activeTab === 'files') {
            return getIconByExtension(detail.source_url);
        } else if (activeTab === 'tables') {
            return faTable;
        } else if (activeTab === 'lockers') {
            return faLock;
        } else if (activeTab === 'links') {
            return faLink;
        } else {
            return faLock; // Use default lock icon for any other tabs
        }
    };

    const addLocker = async (lockerName) => {
        try {

            // Send the POST request to the API
            const response = await axios.post('https://pages.onepgr.com/session/create-data-bank-item', {
                user_id: userId,
                name: lockerName, // Use the generated name
                owner: user?.name.split('@')[0],
                object_type: 'item',
                date: currentDateTime

            });

            console.log('API response:', response.data);
            if (response.status === 200) {
                setNotification({ message: 'Locker added successfully!', type: 'success' });
                fetchLockerDetails(1)
                fetchAllObjects()
                triggerFetchLockerDetails()
            } else {
                setNotification({ message: 'Failed to add locker.', type: 'error' });
            }
            // Handle the response as needed
        } catch (error) {
            console.error('Error creating locker:', error);
            // Handle the error as needed
        }
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = (name) => {
        setIsModalOpen(false);
        setNewLockerName(name);
        if (name) {
            addLocker(name);
        }
    };


    const handleSortClick = (field) => {
        setSortOrder((prevSortOrder) => ({
            ...prevSortOrder,
            [field]: prevSortOrder[field] === 'asc' ? 'desc' : 'asc',
        }));
    };

    // Inside your Lockers component
    const getSortedDetails = () => {
        const sorted = [...currentDetails];
        Object.entries(sortOrder).forEach(([field, order]) => {
            if (order) {
                sorted.sort((a, b) => {
                    const fieldA = a[field] ? a[field].toLowerCase() : '';
                    const fieldB = b[field] ? b[field].toLowerCase() : '';
                    if (fieldA < fieldB) return order === 'asc' ? -1 : 1;
                    if (fieldA > fieldB) return order === 'asc' ? 1 : -1;
                    return 0;
                });
            }
        });
        return sorted;
    };

    const deleteLocker = async (token) => {
        try {
            // Perform the API call to delete the locker
            const response = await axios.delete(`https://pages.onepgr.com/session/delete-data-bank-item/${token}`);

            if (response.status === 200) {
                // Show success notification
                setNotification({ message: 'Locker deleted successfully!', type: 'success' });
                fetchLockerDetails(1);
                fetchAllObjects()
            }


        } catch (error) {
            console.error('Error deleting locker:', error);
            setNotification({ message: 'Failed to delete locker.', type: 'error' });
        }
    };



    const deleteFile = async (fileKey, fileId) => {

        try {
            // Prepare requests for both endpoints
            const deleteFromS3Request = fetch('https://crawl.onepgr.com:3002/delete-from-s3', {
                method: 'POST', // Using POST as specified
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ fileName: fileKey }), // Assuming fileKey is the fileName for S3
            });

            const deleteDataSourceFileRequest = fetch(`https://pages.onepgr.com/session/delete-data-bank-source-file/${fileId}`, {
                method: 'DELETE', // Using DELETE as required by this endpoint
                headers: {
                    'Content-Type': 'application/json',
                },
                // No body required for this request
            });
            const responses = await Promise.all([deleteFromS3Request, deleteDataSourceFileRequest]);

            // Check if both responses are OK
            responses.forEach(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
            });

            // Refresh the file list after successful deletion
            fetchDataSourceFiles();
            fetchAllObjects()
            setNotification({ message: 'File deleted successfully!', type: 'success' });

        } catch (error) {
            console.error('Error deleting file:', error);

            // Optionally, display an error message to the user
            setNotification({ message: 'Failed to delete File.', type: 'error' });

        }
    };


    const deleteTable = async (fileId, tableName) => {

        const FileId = fileId
        const TableName = tableName
        try {
            // Endpoint for deleting data by file ID
            const endpointOne = `https://pages.onepgr.com/session/delete-data-bank-table/${FileId}`;

            // Endpoint for deleting the table by table name
            const endpointTwo = `https://crawl.onepgr.com:3002/delete-table/${TableName}`; // Here fileIdOrTableName is interpreted as tableName

            // Make concurrent requests to both endpoints
            await Promise.all([
                axios.delete(endpointOne),
                axios.delete(endpointTwo)
            ]);

            // Handle success
            fetchDataBankTables()// Refresh the table list after deletion
            fetchAllObjects()
            setNotification({ message: 'Table deleted successfully!', type: 'success' });


        } catch (error) {
            console.error('Error deleting file or table:', error);
            // Handle error case

        }
    };
    const deleteLink = async (fileId, linkName) => {
        // setIsOverlayLoading(true);
        try {
            // Make a DELETE request to the server to delete the link
            const serverResponse = await axios.delete(`https://crawl.onepgr.com:3002/delete-link/${linkName}`);

            if (serverResponse.status === 200) {
                // Make a DELETE request to the database to delete the link
                const databaseResponse = await axios.delete(`https://pages.onepgr.com/session/delete-data-bank-source-file/${fileId}`);

                if (databaseResponse.status === 200) {
                    // Refresh the links data after successful deletion
                    // setIsOverlayLoading(false);
                    await fetchLinks();
                    fetchAllObjects()
                    // Show success notification
                    setNotification({ type: "success", message: "Link deleted successfully!" });
                } else {
                    setNotification({ message: 'Failed to delete the link from the database', type: 'error' });

                }
            } else {
                setNotification({ message: 'Failed to delete the link from the server', type: 'error' });

            }
        } catch (error) {
            console.error('Error deleting link:', error);
            setNotification({ message: 'Failed to delete the link.', type: 'error' });

        } finally {
            // setIsOverlayLoading(false);
        }
    };

    const openOverlayWithFileInfo = (detail) => {
        setCurrentItem(detail);
        setOverlayInfoOpen(true);
    };



    const openOverlayWithEditFileDetails = (detail) => {
        setCurrentItem(detail);
        setIsEditMode(true);

        if (!itemDetails[detail._id]) {
            setItemDetails({
                ...itemDetails,
                [detail._id]: {
                    comments: '',
                    companyInfo: '',
                    tags: []
                }
            });
        }
        setOverlayInfoOpen(true);
    };

    const handleInviteClick = (detail) => {
        setOverlayInfoOpen(false)
        // Construct the URL with current domain, path, and query parameters
        const currentDomain = window.location.origin;
        const userId = detail.user_id;
        const token = detail.token;
        const invitePath = `/view?user_id=${userId}&token=${token}`;
        const constructedUrl = `${currentDomain}${invitePath}`;

        // Set the constructed URL to the state
        setInviteUrl(constructedUrl);


        // Show the invite overlay
        setShowInviteOverlay(true);
    };

    const handleInviteLockerClick = (detail) => {
        setOverlayInfoOpen(false)
        // Construct the URL with current domain and locker token
        const currentDomain = window.location.origin;
        const token = detail.token;
        const inviteLockerPath = `/lockers/${token}?password=${detail.password}`;
        const constructedLockerUrl = `${currentDomain}${inviteLockerPath}`;

        // Set the constructed locker URL to the state
        setInviteUrl(constructedLockerUrl);

        // Show the invite overlay for locker
        setShowInviteOverlay(true);
    };

    const handleInviteTableClick = (detail) => {
        setOverlayInfoOpen(false)
        // Construct the URL with current domain and table name
        const currentDomain = window.location.origin;
        const name = detail.name;
        const inviteTablePath = `/tables/${name}`;
        const constructedTableUrl = `${currentDomain}${inviteTablePath}`;

        // Set the constructed table URL to the state
        setInviteUrl(constructedTableUrl);

        // Show the invite overlay for table
        setShowInviteOverlay(true);
    };

    const handleCopyClick = () => {
        const constructedUrl = inviteUrl;

        const textarea = document.getElementById('inviteTextarea');
        if (textarea) {
            textarea.value = constructedUrl;
            textarea.select();
            document.execCommand('copy');

            // Set copyIcon to true to display the tick icon
            setCopyIcon(true);

            // Revert to the copy icon after 2 seconds
            setTimeout(() => {
                setCopyIcon(false);
            }, 2000);
        }
    };


    const closeInviteOverlay = () => {
        setShowInviteOverlay(false);
    };

    const closeOverlayInfo = () => {
        setOverlayInfoOpen(false);
        setIsEditMode(false); // Optionally reset edit mode to off when closing the overlay
    };

    const updateFileDetails = (fileId, detailType, value) => {
        setItemDetails(prev => ({
            ...prev,
            [fileId]: {
                ...prev[fileId],
                [detailType]: value,
            },
        }));
    };

    const removeTag = (fileId, tagIndex) => {
        const updatedTags = [...itemDetails[fileId].tags];
        updatedTags.splice(tagIndex, 1); // Remove the tag at tagIndex
        updateFileDetails(fileId, 'tags', updatedTags);
    };


    const addTag = (newTagValue) => {
        // Guard clause to check if currentItem is set and newTagValue is not empty
        if (!currentItem || !newTagValue.trim()) return;

        const fileId = currentItem._id; // ID of the current file
        const existingTags = itemDetails[fileId]?.tags || []; // Current tags or an empty array if none

        // Create a new array with the existing tags plus the new one
        const updatedTags = [...existingTags, newTagValue.trim()];

        // Update fileDetails state with the new array of tags
        setItemDetails(prevDetails => ({
            ...prevDetails,
            [fileId]: {
                ...prevDetails[fileId],
                tags: updatedTags
            }
        }));
    };
    const toggleEditMode = () => {
        setIsEditMode((prevMode) => !prevMode);
    };



    const saveChanges = async () => {
        console.log(activeTab)
        setOverlayInfoOpen(false);
        const itemId = currentItem._id;
        updateFileDetails(currentItem._id, 'name', editableFileName);
        const currentDateTime = new Date().toISOString();
        let requestBody;
        const lockerToken = selectedLockerToken
        
        if (activeTab === 'tables') {
            requestBody = {
                name: editableFileName,
                source_url: currentItem.source_url || '',
                table_info: {
                    comments: itemDetails[itemId]?.comments || '',
                    company_info: itemDetails[itemId]?.companyInfo || '',
                    tags: itemDetails[itemId]?.tags.join(', ') || ''
                },
                date: currentDateTime,
           
            };
        } else if (activeTab === 'lockers' || (activeTab === 'all-items' && currentItem.object_type === 'item')) {
            requestBody = {
                name: editableFileName,
                item_url: currentItem.item_url || '',
                item_info: {
                    comments: itemDetails[itemId]?.comments || '',
                    company_info: itemDetails[itemId]?.companyInfo || '',
                    tags: itemDetails[itemId]?.tags.join(', ') || '',
                },
                date: currentDateTime,
             
            };
        } else {
            requestBody = {
                name: editableFileName,
                source_url: currentItem.source_url,
                type: "Check",
                file_info: {
                    comments: itemDetails[itemId]?.comments || '',
                    company_info: itemDetails[itemId]?.companyInfo || '',
                    tags: itemDetails[itemId]?.tags.join(', ') || ''
                },
                date: currentDateTime,
               
            };
        }
        if (password) {
            requestBody.password = password;
          }

        if (lockerToken) {
            requestBody.locker_id = lockerToken;
        }

        try {
            let response;

            if (activeTab === 'tables' || (activeTab === 'all-items' && currentItem.object_type === 'table')) {
                response = await axios.put(`https://pages.onepgr.com/session/update-data-bank-table/${itemId}`, requestBody);
            } else if (activeTab === 'lockers' || (activeTab === 'all-items' && currentItem.object_type === 'item')) {
                response = await axios.put(`https://pages.onepgr.com/session/update-data-bank-item/${itemId}`, requestBody);
            } else {
                response = await axios.put(`https://pages.onepgr.com/session/update-data-bank-source-file/${itemId}`, requestBody);
            }

            console.log(response.data);

            const updatedItemDetails = {
                ...itemDetails,
                [itemId]: requestBody.item_info || requestBody.file_info
            };
            setItemDetails(updatedItemDetails);

            if (activeTab === 'tables') {
                fetchDataBankTables();
                fetchAllObjects();
                triggerFetchLockerDetails()
                setNotification({ message: "Table info updated successfully!", type: 'success' });
            } else if (activeTab === 'lockers') {
                fetchLockerDetails(1);
                triggerFetchLockerDetails()
                setNotification({ message: "Locker info updated successfully!", type: 'success' });
            } else if (activeTab === 'all-items') {
                fetchAllObjects();
                triggerFetchLockerDetails()
                setNotification({ message: "File info updated successfully!", type: 'success' });
            } else {
                fetchDataSourceFiles();
                fetchAllObjects();
                triggerFetchLockerDetails()
                setNotification({ message: "File info updated successfully!", type: 'success' });
            }
            setPassword('');
            setTimeout(() => setNotification(''), 3000);
        } catch (error) {
            console.error(`Error updating ${activeTab === 'tables' ? 'table' : activeTab === 'lockers' ? 'locker' : 'file'} info:`, error);
            setNotification(`Failed to update ${activeTab === 'tables' ? 'table' : activeTab === 'lockers' ? 'locker' : 'file'} info.`);
            setTimeout(() => setNotification(''), 3000);
        }
    };


    const clearDroppedFiles = () => {
        setDroppedFiles([]);
    };
    const handleCloseOverlay = () => {
        setShowOverlay(false);
        setLink('');
    };

    const handleLinkChange = (e) => {
        setLink(e.target.value);
    };

    const handleAddLink = () => {
        setShowOverlay(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!link.trim()) {
            alert('Please enter or paste a link to submit.');
            setIsOverlayLoading(false);
            return;
        }
        handleCloseOverlay();
        setIsOverlayLoading(true);

        try {
            const response = await axios.post('https://crawl.onepgr.com:3002/store-link', { link });
            const storedLink = response.data.link;
            console.log('Stored link:', storedLink);

            // Send the link to the specified endpoint
            const linkData = {
                user_id: userId,
                name: storedLink,
                object_type: 'link',
                owner: user?.name.split('@')[0],
            };


            const linkResponse = await axios.post(
                'https://pages.onepgr.com/session/create-data-bank-source-file',
                linkData
            );

            console.log('Create source file response:', linkResponse.data);

            setNotification({ message: 'Link Uploaded successfully!', type: 'success' });
            triggerRefreshAllItems()

        } catch (error) {
            console.error('Error:', error);
            setNotification({ message: 'Failed to upload link.', type: 'error' });
        } finally {
            setIsOverlayLoading(false);
        }
    };

    const triggerFileSelection = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.multiple = true;
        fileInput.addEventListener('change', handleFileSelection);
        fileInput.click();
    };

    const handleFileSelection = (event) => {
        const files = Array.from(event.target.files);
        setDroppedFiles(files);
    };



    return (
        <div className={`locker-container  ${isDragging ? 'dragging' : ''}`}>
            {activeTab === 'lockers' && selectedLockerItem ? (
                <LockerItemDetails lockerItem={selectedLockerItem} /> // Render LockerItemDetails if a locker item is selected
            ) : (
                <>

                    <div className="locker-header">
                        <div className='locker-header-text'>
                            <p>{headerText}</p>
                            {/* {activeTab === 'lockers' && (
                            <button className="locker-header-toggle-button" onClick={openModal}>
                                <span> + </span> Create Locker
                            </button>
                        )} */}
                        </div>
                        <div className='upload-component'>
                            {(activeTab === 'all-items' || activeTab === 'files') && (
                                <FileUploadComponent
                                    refreshFileList={refreshFileList}
                                    droppedFiles={droppedFiles}
                                    clearDroppedFiles={clearDroppedFiles}
                                    fetchAllObjects={fetchAllObjects}

                                />

                            )}
                        </div>
                        {activeTab !== 'tables' && (
                            <div className='create-new'>
                                <button className='create-new-btn' onClick={toggleDropdown} ref={buttonRef}>
                                    <span> + </span>
                                    Create
                                </button>
                                {isOpen && (
                                    <div className="dropdown create-dropdown" ref={dropdownRef}>
                                        {activeTab === 'all-items' && (
                                            <>
                                                <p onClick={triggerFileSelection}>
                                                    <FontAwesomeIcon icon={faFileUpload} className="create-new-icon" /> Upload File(s)
                                                </p>
                                                <p onClick={handleAddLink}>
                                                    <FontAwesomeIcon icon={faAdd} className="create-new-icon" /> Add Link
                                                </p>
                                                <p onClick={openModal}>
                                                    <FontAwesomeIcon icon={faLock} className="create-new-icon" /> Create Locker
                                                </p>
                                            </>
                                        )}
                                        {activeTab === 'lockers' && (
                                            <p onClick={openModal}>
                                                <FontAwesomeIcon icon={faLock} className="create-new-icon" /> Create Locker
                                            </p>
                                        )}
                                        {activeTab === 'files' && (
                                            <p onClick={triggerFileSelection}>
                                                <FontAwesomeIcon icon={faFileUpload} className="create-new-icon" /> Upload File(s)
                                            </p>
                                        )}
                                        {activeTab === 'links' && (
                                            <p onClick={handleAddLink}>
                                                <FontAwesomeIcon icon={faAdd} className="create-new-icon" /> Add Link
                                            </p>
                                        )}
                                        <LockerNameModal
                                            isOpen={isModalOpen}
                                            onClose={closeModal}
                                            newLockerName={newLockerName}
                                            setNewLockerName={setNewLockerName}
                                            fetchAllObjects={fetchAllObjects}
                                        />
                                    </div>

                                )}
                                {showOverlay && (
                                    <div className="overlay" >
                                        <div className="link-input-container">
                                            <h4>Add Link</h4>
                                            <input
                                                type="text"
                                                placeholder="Enter or paste your link here"
                                                value={link}
                                                onChange={handleLinkChange}
                                            />
                                            <div className="link-btn-container">
                                                <button className="close-link-overlay-btn" onClick={handleCloseOverlay}>
                                                    Close
                                                </button>
                                                <button className="upload-btn" onClick={handleSubmit}>
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                    </div>

                    <div className="locker-outlet">
                        {activeTab === 'files' ? (
                            <FileRepository lockerId="" />
                        ) : (
                            <>

                                <div className="locker-list">
                                    <div className="locker-item locker-item-header">
                                        <div className="locker-name" onClick={() => handleSortClick('name')}>
                                            Name <FontAwesomeIcon className='sort-icon sort-name-icon' icon={sortOrder.name === 'asc' ? faArrowUp : faArrowDown} />
                                        </div>
                                        <div className="locker-reason" onClick={() => handleSortClick('date')}>
                                            Last Updated <FontAwesomeIcon className='sort-icon' icon={sortOrder.date === 'asc' ? faArrowUp : faArrowDown} />
                                        </div>
                                        <div className="locker-owner" onClick={() => handleSortClick('owner')}>
                                            Owner <FontAwesomeIcon className='sort-icon' icon={sortOrder.owner === 'asc' ? faArrowUp : faArrowDown} />
                                        </div>
                                        <div className="locker-location" onClick={() => handleSortClick('location')}>
                                            Location <FontAwesomeIcon className='sort-icon' icon={sortOrder.location === 'asc' ? faArrowUp : faArrowDown} />
                                        </div>
                                        <div className="locker-options">Actions</div>
                                    </div>
                                    {getSortedDetails().length === 0 ? (
                                        <div className="no-items-message">
                                            {activeTab === 'all-items' && 'No items found'}
                                            {activeTab === 'tables' && 'No tables found'}
                                            {activeTab === 'links' && 'No links found'}
                                            {activeTab === 'lockers' && 'No lockers found'}
                                            {activeTab === 'files' && 'No files found'}
                                        </div>
                                    ) : (

                                        getSortedDetails().map((detail, index) => (
                                            <div
                                                className="locker-item"
                                                key={index}
                                            // Add click handler
                                            >
                                                <div className="locker-name" onClick={() => handleLockerItemClick(detail)} title={detail.name}>
                                                    <div className='font-icon-container'>
                                                        <FontAwesomeIcon icon={getIconForDetail(detail)} className="icon-folder-lock" />
                                                    </div>
                                                    <span className="locker-name-text" >{detail.name}</span>
                                                </div>
                                                <div className="locker-reason">{formatDate(detail.date)}</div>
                                                <div className="locker-owner">{detail.owner || 'undefined'}</div>
                                                <div className="locker-location">{detail.location || 'My Databank'}</div>
                                                {/* <div className="locker-options">
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="tooltip-save">More Actions</Tooltip>}
                                    >
                                        <div className="ellipsish">
                                            <FontAwesomeIcon icon={faEllipsisH}  />
                                        </div>
                                    </OverlayTrigger>
                                </div> */}
                                                <div className="locker-options" ref={dropdownContainerRef}>
                                                    {/* <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-save`}>More Actions</Tooltip>}>
                                        <div className="ellipsish" >
                                            <FontAwesomeIcon icon={faEllipsisH} onClick={() => toggleDropdown(index)} />
                                        </div>
                                    </OverlayTrigger>
                                    {openDropdownId === index && (
                                        <DropdownActionsMenu
                                            isOpen={openDropdownId === index}
                                            toggleDropdown={toggleDropdown}
                                            detail={detail}
                                            deleteLocker={deleteLocker}
                                            deleteFile={deleteFile}
                                            activeTab={activeTab}
                                        />
                                    )} */}

                                                    <ActionsIcon
                                                        key={`actions-icon-${activeTab}-${detail._id}`}
                                                        detail={detail}
                                                        deleteLocker={deleteLocker}
                                                        deleteFile={deleteFile}
                                                        activeTab={activeTab}
                                                        openOverlayWithFileInfo={() => openOverlayWithFileInfo(detail)}
                                                        viewFile={viewFile}
                                                        openOverlayWithEditFileDetails={() => openOverlayWithEditFileDetails(detail)}
                                                        handleInviteClick={handleInviteClick}
                                                        isEditMode={isEditMode}
                                                        overlayInfoOpen={overlayInfoOpen}
                                                        currentItem={currentItem}
                                                        closeOverlayInfo={closeOverlayInfo}
                                                        removeTag={removeTag}
                                                        itemDetails={itemDetails}
                                                        saveChanges={saveChanges}
                                                        editableFileName={editableFileName}
                                                        setEditableFileName={setEditableFileName}
                                                        updateFileDetails={updateFileDetails}
                                                        addTag={addTag}
                                                        toggleEditMode={toggleEditMode}
                                                        newTag={newTag}
                                                        setNewTag={setNewTag}
                                                        showInviteOverlay={showInviteOverlay}
                                                        closeInviteOverlay={closeInviteOverlay}
                                                        inviteUrl={inviteUrl}
                                                        copyIcon={copyIcon}
                                                        handleCopyClick={handleCopyClick}
                                                        handleInviteLockerClick={handleInviteLockerClick}
                                                        handleInviteTableClick={handleInviteTableClick}
                                                        deleteTable={deleteTable}
                                                        deleteLink={deleteLink}
                                                        setSelectedLockerToken={setSelectedLockerToken}
                                                        dropDownLockerDetails={dropDownLockerDetails}
                                                        password={password}
                                                        setPassword={setPassword}
                                                    />
                                                </div>
                                            </div>

                                        ))
                                    )}
                                    <Pagination
                                        itemsPerPage={itemsPerPageLocal}
                                        totalItems={activeTab === 'lockers' ? lockerTotalItems : totalItems}
                                        paginate={(pageNumber) => {
                                            setCurrentPageLocal(pageNumber);
                                            setCurrentPage(pageNumber);
                                        }}
                                        currentPage={currentPageLocal}
                                        setItemsPerPage={(itemsPerPage) => {
                                            setItemsPerPageLocal(itemsPerPage);
                                            setItemsPerPage(itemsPerPage);
                                        }}
                                    />
                                </div>
                            </>
                        )}
                    </div>


                </>


            )}

            {notification.message && (
                <Notification type={notification.type} message={notification.message} />
            )}
        </div>
    );
};

export default Lockers;