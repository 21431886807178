// TitleDropdown.js
import React from 'react';

export const TITLES = [
    'Account Manager', 'Account Executive', 'Accountant', 'Administrative Assistant', 'Analyst',
    'Assistant', 'Assistant Manager', 'Associate', 'Biomedical Equipment Technician', 'Board Member',
    'Bookkeeper', 'Business Analyst', 'Business Development Manager', 'Business Development Specialist',
    'Business Specialist', 'Cashier', 'Certified Nursing Assistant', 'Chief Executive Officer',
    'Chief Security Officer', 'Civil Servant', 'Co-Founder', 'Communications Specialist', 'Consultant',
    'Customer Service Representative', 'Customer Service Specialist', 'Director', 'Editor', 'Electrician',
    'Employee', 'Engineer', 'Executive', 'Field Specialist', 'Finance Manager', 'Finance Specialist',
    'Food Server', 'Food Service Worker', 'Founder', 'General Manager', 'General Technician',
    'Graphic Designer', 'Homemaker', 'Human Resources Assistant', 'Human Resources Manager',
    'Human Resources Specialist', 'Instructor', 'Intern', 'International Specialist', 'Java Software Engineer',
    'Key Account', 'Key Account Manager', 'Keyholder', 'Laborer', 'Managing Director', 'Manager',
    'Marketing Specialist', 'National Account Specialist', 'National Sales Specialist', 'Network Administrator',
    'Network Engineer', 'Network Specialist', 'Nurse', 'Office Assistant', 'Office Associate', 'Office Manager',
    'Operations Manager', 'Operational Specialist', 'Owner', 'Principal', 'Program Coordinator', 'Project Manager',
    'Project Specialist', 'Quality Analyst', 'Quality Assurance Analyst', 'Quality Assurance Engineer',
    'Quality Assurance Manager', 'Quality Assurance Specialist', 'Quality Control Engineer', 'Quality Control Inspector',
    'Quality Engineer', 'Quality Specialist', 'Quantity Surveyor', 'Radiology Nurse', 'Receptionist', 'Registered Nurse',
    'Research Analyst', 'Research Assistant', 'Researcher', 'Retired', 'Sales Manager', 'Sales Specialist',
    'Salesperson', 'Secretary', 'Self-Employed', 'Senior Software Engineer', 'Software Engineer', 'Student',
    'Supervisor', 'Teacher', 'Technician', 'Undergraduate', 'Unemployed', 'Vice President', 'Web Developer',
    'Worker', 'Writer', 'Xray Technician', 'Xray Technologist', 'Yoga Instructor', 'Youth Coordinator',
    'Youth Development Specialist', 'Youth Director', 'Youth Pastor', 'Youth Specialist', 'Zonal Head',
    'Zonal Manager', 'Zonal Sales Manager'
];

const TEAM_SIZES = [
    "1-10",
    "11-50",
    "51-200",
    "201-500",
    "501-1000",
    "1001-5000",
    "5001-10000",
    "10001+"
];



const CountriesData = {
    UnitedStates: {
        Alabama: ['Birmingham', 'Montgomery', 'Mobile', 'Huntsville', 'Tuscaloosa'],
        Alaska: ['Anchorage', 'Fairbanks', 'Juneau', 'Sitka', 'Ketchikan'],
        Arizona: ['Phoenix', 'Tucson', 'Mesa', 'Chandler', 'Gilbert'],
        Arkansas: ['Little Rock', 'Fort Smith', 'Fayetteville', 'Springdale', 'Jonesboro'],
        California: ['Los Angeles', 'San Francisco', 'San Diego', 'Sacramento', 'San Jose'],
        Colorado: ['Denver', 'Colorado Springs', 'Aurora', 'Fort Collins', 'Lakewood'],
        Connecticut: ['Bridgeport', 'New Haven', 'Stamford', 'Hartford', 'Waterbury'],
        Delaware: ['Wilmington', 'Dover', 'Newark', 'Middletown', 'Smyrna'],
        Florida: ['Miami', 'Orlando', 'Jacksonville', 'Tampa', 'St. Petersburg'],
        Georgia: ['Atlanta', 'Augusta', 'Columbus', 'Macon', 'Savannah'],
        Hawaii: ['Honolulu', 'East Honolulu', 'Pearl City', 'Hilo', 'Kailua'],
        Idaho: ['Boise', 'Nampa', 'Meridian', 'Idaho Falls', 'Pocatello'],
        Illinois: ['Chicago', 'Aurora', 'Rockford', 'Joliet', 'Naperville'],
        Indiana: ['Indianapolis', 'Fort Wayne', 'Evansville', 'South Bend', 'Carmel'],
        Iowa: ['Des Moines', 'Cedar Rapids', 'Davenport', 'Sioux City', 'Iowa City'],
        Kansas: ['Wichita', 'Overland Park', 'Kansas City', 'Topeka', 'Olathe'],
        Kentucky: ['Louisville', 'Lexington', 'Bowling Green', 'Owensboro', 'Covington'],
        Louisiana: ['New Orleans', 'Baton Rouge', 'Shreveport', 'Metairie', 'Lafayette'],
        Maine: ['Portland', 'Lewiston', 'Bangor', 'South Portland', 'Auburn'],
        Maryland: ['Baltimore', 'Frederick', 'Rockville', 'Gaithersburg', 'Bowie'],
        Massachusetts: ['Boston', 'Worcester', 'Springfield', 'Cambridge', 'Lowell'],
        Michigan: ['Detroit', 'Grand Rapids', 'Warren', 'Sterling Heights', 'Ann Arbor'],
        Minnesota: ['Minneapolis', 'St. Paul', 'Rochester', 'Duluth', 'Bloomington'],
        Mississippi: ['Jackson', 'Gulfport', 'Southaven', 'Hattiesburg', 'Biloxi'],
        Missouri: ['Kansas City', 'St. Louis', 'Springfield', 'Columbia', 'Independence'],
        Montana: ['Billings', 'Missoula', 'Great Falls', 'Bozeman', 'Butte'],
        Nebraska: ['Omaha', 'Lincoln', 'Bellevue', 'Grand Island', 'Kearney'],
        Nevada: ['Las Vegas', 'Henderson', 'Reno', 'North Las Vegas', 'Sparks'],
        NewHampshire: ['Manchester', 'Nashua', 'Concord', 'Derry', 'Dover'],
        NewJersey: ['Newark', 'Jersey City', 'Paterson', 'Elizabeth', 'Trenton'],
        NewMexico: ['Albuquerque', 'Las Cruces', 'Rio Rancho', 'Santa Fe', 'Roswell'],
        NewYork: ['New York City', 'Buffalo', 'Rochester', 'Yonkers', 'Syracuse'],
        NorthCarolina: ['Charlotte', 'Raleigh', 'Greensboro', 'Durham', 'Winston-Salem'],
        NorthDakota: ['Fargo', 'Bismarck', 'Grand Forks', 'Minot', 'West Fargo'],
        Ohio: ['Columbus', 'Cleveland', 'Cincinnati', 'Toledo', 'Akron'],
        Oklahoma: ['Oklahoma City', 'Tulsa', 'Norman', 'Broken Arrow', 'Lawton'],
        Oregon: ['Portland', 'Eugene', 'Salem', 'Gresham', 'Hillsboro'],
        Pennsylvania: ['Philadelphia', 'Pittsburgh', 'Allentown', 'Erie', 'Reading'],
        RhodeIsland: ['Providence', 'Warwick', 'Cranston', 'Pawtucket', 'East Providence'],
        SouthCarolina: ['Columbia', 'Charleston', 'North Charleston', 'Mount Pleasant', 'Rock Hill'],
        SouthDakota: ['Sioux Falls', 'Rapid City', 'Aberdeen', 'Brookings', 'Watertown'],
        Tennessee: ['Nashville', 'Memphis', 'Knoxville', 'Chattanooga', 'Clarksville'],
        Texas: ['Houston', 'Austin', 'Dallas', 'San Antonio', 'Fort Worth'],
        Utah: ['Salt Lake City', 'West Valley City', 'Provo', 'West Jordan', 'Orem'],
        Vermont: ['Burlington', 'South Burlington', 'Rutland', 'Barre', 'Montpelier'],
        Virginia: ['Virginia Beach', 'Norfolk', 'Chesapeake', 'Richmond', 'Newport News'],
        Washington: ['Seattle', 'Spokane', 'Tacoma', 'Vancouver', 'Bellevue'],
        WestVirginia: ['Charleston', 'Huntington', 'Morgantown', 'Parkersburg', 'Wheeling'],
        Wisconsin: ['Milwaukee', 'Madison', 'Green Bay', 'Kenosha', 'Racine'],
        Wyoming: ['Cheyenne', 'Casper', 'Laramie', 'Gillette', 'Rock Springs'],
    },
    Canada: {
        Ontario: ['Toronto', 'Ottawa', 'Mississauga', 'Brampton', 'Hamilton'],
        Quebec: ['Montreal', 'Quebec City', 'Laval', 'Gatineau', 'Longueuil'],
        BritishColumbia: ['Vancouver', 'Surrey', 'Burnaby', 'Richmond', 'Abbotsford'],
        Alberta: ['Calgary', 'Edmonton', 'Red Deer', 'Lethbridge', 'St. Albert'],
        Manitoba: ['Winnipeg', 'Brandon', 'Steinbach', 'Thompson', 'Portage la Prairie'],
        Saskatchewan: ['Saskatoon', 'Regina', 'Prince Albert', 'Moose Jaw', 'Swift Current'],
        NovaScotia: ['Halifax', 'Sydney', 'Truro', 'New Glasgow', 'Amherst'],
        NewBrunswick: ['Moncton', 'Saint John', 'Fredericton', 'Bathurst', 'Miramichi'],
        NewfoundlandandLabrador: ['St. John\'s', 'Conception Bay South', 'Mount Pearl', 'Paradise', 'Corner Brook'],
        PrinceEdwardIsland: ['Charlottetown', 'Summerside', 'Stratford', 'Cornwall', 'Montague'],
    },
    UnitedKingdom: {
        England: ['London', 'Birmingham', 'Manchester', 'Liverpool', 'Newcastle upon Tyne'],
        Scotland: ['Glasgow', 'Edinburgh', 'Aberdeen', 'Dundee', 'Inverness'],
        Wales: ['Cardiff', 'Swansea', 'Newport', 'Wrexham', 'Barry'],
        NorthernIreland: ['Belfast', 'Derry', 'Lisburn', 'Newtownabbey', 'Bangor'],
    },
    Australia: {
        NewSouthWales: ['Sydney', 'Newcastle', 'Wollongong', 'Albury', 'Dubbo'],
        Victoria: ['Melbourne', 'Geelong', 'Ballarat', 'Bendigo', 'Shepparton'],
        Queensland: ['Brisbane', 'Gold Coast', 'Sunshine Coast', 'Townsville', 'Cairns'],
        WesternAustralia: ['Perth', 'Mandurah', 'Bunbury', 'Geraldton', 'Kalgoorlie'],
        SouthAustralia: ['Adelaide', 'Mount Gambier', 'Whyalla', 'Murray Bridge', 'Port Augusta'],
        Tasmania: ['Hobart', 'Launceston', 'Devonport', 'Burnie', 'Ulverstone'],
        AustralianCapitalTerritory: ['Canberra', 'Queanbeyan', 'Murrumbateman', 'Googong', 'Sutton'],
        NorthernTerritory: ['Darwin', 'Alice Springs', 'Palmerston', 'Katherine', 'Tennant Creek'],
    },
};


const INDUSTRIES = [
    'Renewable Energy Semiconductor Manufacturing',
    'Political Organizations',
    'Gambling Facilities and Casinos',
    'Primary and Secondary Education',
    'Warehousing and Storage',
    'Religious Institutions',
    'Legislative Offices',
    'International Trade and Development',
    'Maritime Transportation',
    'Airlines and Aviation',
    'IT Services and IT Consulting',
    'Hospitals and Health Care',
    'Retail',
    'Advertising Services',
    'Financial Services',
    'Software Development',
    'Business Consulting and Services',
    'Food and Beverage Services',
    'Technology, Information and Internet',
    'Manufacturing',
    'Chiropractors',
    'Defense and Space Manufacturing',
    'Optometrists',
    'Computer Hardware Manufacturing',
    'Computer Networking Products',
    'Transportation Equipment Manufacturing',
    'Physical, Occupational and Speech Therapists',
    'Semiconductor Manufacturing',
    'Telecommunications',
    'Law Practice',
    'Housing Programs',
    'Legal Services',
    'Biotechnology Research',
    'Family Planning Centers',
    'Medical Practices',
    'Transportation Programs',
    'Utilities Administration',
    'Pharmaceutical Manufacturing',
    'Outpatient Care Centers',
    'Veterinary Services',
    'Medical Equipment Manufacturing',
    'Space Research and Technology',
    'Motor Vehicle Parts Manufacturing',
    'Personal Care Product Manufacturing',
    'Retail Apparel and Fashion',
    'Sporting Goods Manufacturing',
    'Tobacco Manufacturing',
    'Medical and Diagnostic Laboratories',
    'Retail Groceries',
    'Food and Beverage Manufacturing',
    'Oil Extraction',
    'Computers and Electronics Manufacturing',
    'Natural Gas Extraction',
    'Furniture and Home Furnishings Manufacturing',
    'Home Health Care Services',
    'Embedded Software Products',
    'Entertainment Providers',
    'Mobile Computing Software Products',
    'Ambulance Services',
    'Desktop Computing Software Products',
    'IT System Custom Software Development',
    'Travel Arrangements',
    'IT System Operations and Maintenance',
    'Hospitality',
    'IT System Installation and Disposal',
    'Restaurants',
    'IT System Training and Support',
    'Hospitals',
    'Spectator Sports',
    'IT System Data Services',
    'IT System Testing and Evaluation',
    'Movies, Videos, and Sound',
    'Broadcast Media Production and Distribution',
    'Museums, Historical Sites, and Zoos',
    'Artists and Writers',
    'Performing Arts',
    'Recreational Facilities',
    'Banking',
    'Insurance',
    'Nursing Homes and Residential Care Facilities',
    'Real Estate',
    'Investment Banking',
    'Investment Management',
    'Accounting',
    'Construction',
    'Wholesale Building Materials',
    'Architecture and Planning',
    'Civil Engineering',
    'Internet News',
    'Aviation and Aerospace Component Manufacturing',
    'Blogs',
    'Motor Vehicle Manufacturing',
    'Interior Design',
    'Chemical Manufacturing',
    'Social Networking Platforms',
    'Machinery Manufacturing',
    'Household and Institutional Furniture Manufacturing',
    'Business Intelligence Platforms',
    'Mining',
    'Business Content',
    'Oil and Gas',
    'Data Security Software Products',
    'Shipbuilding',
    'Utilities',
    'Mobile Gaming Apps',
    'Textile Manufacturing',
    'Internet Publishing',
    'Paper and Forest Product Manufacturing',
    'Media and Telecommunications',
    'Railroad Equipment Manufacturing',
    'Blockchain Services',
    'Farming',
    'Services for the Elderly and Disabled',
    'Ranching',
    'Dairy Product Manufacturing',
    'Office Furniture and Fixtures Manufacturing',
    'Fisheries',
    'Community Services',
    'Higher Education',
    'Education Administration Programs',
    'Research Services',
    'Mattress and Blinds Manufacturing',
    'Armed Forces',
    'Administration of Justice',
    'International Affairs',
    'Emergency and Relief Services',
    'Government Administration',
    'Executive Offices',
    'Law Enforcement',
    'Vocational Rehabilitation Services',
    'Public Safety',
    'Public Policy Offices',
    'Child Day Care Services',
    'Newspaper Publishing',
    'Performing Arts and Spectator Sports',
    'Book and Periodical Publishing',
    'Printing Services',
    'Information Services',
    'Libraries',
    'Theater Companies',
    'Environmental Services',
    'Freight and Package Transportation',
    'Dance Companies',
    'Individual and Family Services',
    'Civic and Social Organizations',
    'Consumer Services',
    'Circuses and Magic Shows',
    'Truck Transportation',
    'Sports Teams and Clubs',
    'Racetracks',
    'Market Research',
    'Public Relations and Communications Services',
    'Design Services',
    'Non-profit Organizations',
    'Fundraising',
    'Strategic Management Services',
    'Writing and Editing',
    'Staffing and Recruiting',
    'Wholesale Motor Vehicles and Parts',
    'Professional Training and Coaching',
    'Venture Capital and Private Equity Principals',
    'Translation and Localization',
    'Computer Games',
    'Events Services',
    'Retail Art Supplies',
    'Museums',
    'Appliances, Electrical, and Electronics Manufacturing',
    'Online Audio and Video Media',
    'Wholesale Furniture and Home Furnishings',
    'Historical Sites',
    'Nanotechnology Research',
    'Retail Art Dealers',
    'Musicians',
    'Zoos and Botanical Gardens',
    'Transportation, Logistics, Supply Chain and Storage',
    'Plastics Manufacturing',
    'Computer and Network Security',
    'Wireless Services',
    'Amusement Parks and Arcades',
    'Alternative Dispute Resolution',
    'Security and Investigations',
    'Facilities Services',
    'Outsourcing and Offshoring Consulting',
    'Wellness and Fitness Services',
    'Alternative Medicine',
    'Media Production',
    'Animation and Post-production',
    'Leasing Non-residential Real Estate',
    'Capital Markets',
    'Wholesale Photography Equipment and Supplies',
    'Think Tanks',
    'Philanthropic Fundraising Services',
    'Golf Courses and Country Clubs',
    'E-Learning Providers',
    'Wholesale',
    'Wholesale Computer Equipment',
    'Skiing Facilities',
    'Wholesale Import and Export',
    'Industrial Machinery Manufacturing',
    'Photography',
    'Human Resources Services',
    'Retail Office Equipment',
    'Mental Health Care',
    'Graphic Design',
    'Beverage Manufacturing',
    'Accommodation and Food Services',
    'Wholesale Metals and Minerals',
    'Retail Luxury Goods and Jewelry',
    'Glass, Ceramics and Concrete Manufacturing',
    'Packaging and Containers Manufacturing',
    'Hotels and Motels',
    'Automation Machinery Manufacturing',
    'Wholesale Appliances, Electrical, and Electronics',
    'Government Relations Services'
];


export default {TITLES, TEAM_SIZES , INDUSTRIES , CountriesData};




 