import React, { useState, useContext, useEffect } from 'react';
import { UserContext, useUser } from '../../context/userContext';
import './fileUpload.css'
import { useOutletContext } from 'react-router-dom';
import { useRefresh } from '../../refreshContext /refreshContext';


function FileUploadComponent({ droppedFiles, clearDroppedFiles, lockerId, fetchAllObjects }) {

    // const { lockerId } = useOutletContext(); 
    console.log(lockerId);
    const { formatFileSize, formatDate, lockerDetails } = useUser();
    const { isSelectFileActive, resetFileSelection, setIsSelectFileActive } = useContext(UserContext);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isFilesSelected, setIsFilesSelected] = useState(false);
    const { user } = useContext(UserContext);
    const userId = user?.userId;
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const currentDateTime = new Date().toISOString();
    const { triggerRefreshFiles, triggerRefreshAllItems } = useRefresh();


    const owner = user?.name.split('@')[0];
    console.log(owner);

    useEffect(() => {
        if (setIsSelectFileActive && isSelectFileActive) {
            document.getElementById('fileUpload').click();
            setIsSelectFileActive(false);
        }
    }, [isSelectFileActive, setIsSelectFileActive]);




    useEffect(() => {
        if (droppedFiles.length > 0) {
            setSelectedFiles(droppedFiles);
            setIsFilesSelected(true);
            clearDroppedFiles(); // Clear the dropped files in the parent state after setting them as selected
        }
    }, [droppedFiles, clearDroppedFiles]);

    const handleFileChange = (event) => {
        console.log("File input changed");
        const files = event.target.files;
        setSelectedFiles(files);
        setIsFilesSelected(files.length > 0);
    };






    const uploadFiles = async () => {
        const formData = new FormData();
        for (let i = 0; i < selectedFiles.length; i++) {
            formData.append('file', selectedFiles[i]);
        }

        try {
            setUploading(true);
            const uploadResponse = await fetch('https://crawl.onepgr.com:3002/upload', {
                method: 'POST',
                body: formData,
            });

            if (!uploadResponse.ok) {
                throw new Error('Server responded with ' + uploadResponse.status);
            }

            const contentLength = +uploadResponse.headers.get('Content-Length');
            let receivedLength = 0;
            let chunks = [];

            const reader = uploadResponse.body.getReader();

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                chunks.push(value);
                receivedLength += value.length;

                const progress = Math.round((receivedLength / contentLength) * 100);
                setUploadProgress(progress);
            }

            const blob = new Blob(chunks);
            const uploadResult = JSON.parse(await blob.text());

            console.log('Files uploaded successfully:', uploadResult);


            if (uploadResult.data && uploadResult.data.length > 0) {
                // Process each file
                for (const fileData of uploadResult.data) {
                    // Decode the file name to handle spaces and special characters
                    const decodedFileName = decodeURIComponent(fileData.Key);

                    // Use the decoded file name for further processing
                    const fileName = decodedFileName.includes('.') ? decodedFileName.substring(decodedFileName.lastIndexOf('/') + 1) : 'unknown';
                    const fileType = fileName.split('.').pop();
                    const lastUpdated = formatDate(fileData.LastModified);
                    const fileSize = formatFileSize(fileData.Size);
                    const sourceUrl = fileData.Location;
                    const lockerID = lockerId
                    const body = {
                        user_id: userId,
                        locker_id: lockerID,
                        source_url: sourceUrl,
                        name: fileName,
                        date: currentDateTime,
                        owner: user?.name.split('@')[0],
                        size: fileSize,
                        type: fileType,
                        object_type: 'file'
                    };

                    console.log('Sending to data bank:', body);

                    // Now send this information to create the data bank table entry
                    const createDataBankTableResponse = await fetch('https://pages.onepgr.com/session/create-data-bank-source-file', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(body) // Passing the body object here
                    });

                    if (!createDataBankTableResponse.ok) {
                        throw new Error(`Server responded with ${createDataBankTableResponse.status}`);
                    }
                    setSuccessMessage(`Successfully uploaded ${selectedFiles.length} ${selectedFiles.length === 1 ? 'file' : 'files'}.`);

                    console.log('refreshfilelist')
                    // Optionally, clear the message after 5 seconds
                    setTimeout(() => {
                        setSuccessMessage('');
                    }, 3000);
                    console.log('Data bank table entry created successfully for:', fileName);
                }

                // Reset states after successful operations
                setSelectedFiles([]);
                setIsFilesSelected(false);

                // fetchLockerItems()
                setUploadProgress(0);
                setUploading(false);
                triggerRefreshFiles();
                triggerRefreshAllItems();
                fetchAllObjects()
            } else {
                console.error('No files in the upload response');
            }
        } catch (error) {
            console.error('Error:', error);
            // Resetting progress and uploading states in case of error

            setTimeout(() => {
                setErrorMessage('Error uploading');
            }, 3000);
            setUploadProgress(0);
            setUploading(false);
        }
    };



    const cancelFileSelection = () => {
        setSelectedFiles([]);
        setIsFilesSelected(false);
    };

    return (
        <div className="file-upload">

            <input
                type="file"
                id="fileUpload"
                style={{ display: 'none' }}
                multiple
                onChange={handleFileChange}
            />
            {!isFilesSelected && (
                <label htmlFor="fileUpload" className="drag-drop-label">
                    Drag and drop files or click to choose files
                </label>
            )}

            <div id="selectedFilesContainer">
                {Array.from(selectedFiles).map((file, index) => (
                    <div key={index}>{file.name}</div>
                ))}
            </div>


            {uploading ? (
                // Displayed during the upload
                <div className='uploadingFiles'>
                    <p>Uploading {selectedFiles.length} {selectedFiles.length === 1 ? 'file' : 'files'}...</p>
                    <div className="progress-bar">
                        <div className="progress">
                            <div className="animated-progress"></div>
                        </div>
                    </div>

                </div>
            ) : isFilesSelected ? (
                <div className='filesBtn'>
                    <button id="cancelButton" onClick={cancelFileSelection}>Cancel</button>
                    <button id="uploadButton" onClick={() => {
                        setUploading(true);
                        uploadFiles();
                    }}>Upload</button>
                </div>
            ) : null}
            {successMessage && (
                <div className="success-message">
                    {successMessage}
                </div>
            )}
            {errorMessage && (
                <div className="error-message">
                    {errorMessage}
                </div>
            )}
        </div>
    );

};


export default FileUploadComponent;
