import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { useUser, UserContext } from '../context/userContext';
import './lockerSearch.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faFileAlt, faTable, faLock, faLink, faArrowDown, faArrowUp, faFile, faFilePdf, faFileCsv, faFilePowerpoint, faFileExcel, faFileImage, faFileArchive, } from '@fortawesome/free-solid-svg-icons';
import debounce from "lodash/debounce";
import Pagination from '../s3dataBase/fileList/pagination';
import Notification from '../notification /notification'
import PersonInfoFilter from './tablesSearch';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const LockerSearch = ({ lockerId }) => {
    const { user, viewFile } = useContext(UserContext);
    const { loading, formatDate , triggerFetchLockerDetails} = useUser();
    const [selectedOption, setSelectedOption] = useState('');
    const [searchKeyword, setSearchKeyword] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [sortOrder, setSortOrder] = useState('asc');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [notification, setNotification] = useState({ message: '', type: '' });
    const [isLoadingItems, setIsLoadingItems] = useState(true);
    const [activeTab, setActiveTab] = useState('tables');
    const [selectedLockers, setSelectedLockers] = useState([]);
    const [selectedLockerState, setSelectedLockerState] = useState({});
    const [isLockerDropdownOpen, setIsLockerDropdownOpen] = useState(false);
    const location = useLocation();
    const [lockerDetails, setLockerDetails] = useState()
    // const [lockerDetails , setLockerDetails] = useState(null)
    const lockerDropdownRef = useRef(null);

    const userId = user?.userId;

    console.log(selectedLockers)
    console.log(lockerId)
    console.log(lockerDetails)
    
   

    useEffect(() => {
        if (lockerId && lockerDetails && lockerDetails.length > 0) {
            const locker = lockerDetails.find((locker) => locker.token === lockerId);
            if (locker) {
                setSelectedLockers([lockerId]);
                setSelectedLockerState({ [lockerId]: true });
            }
        }
    }, [lockerId, lockerDetails]);

   
    useEffect(() => {
        if (searchKeyword !== '') {
            fetchSearchResults(searchKeyword, selectedLockers);
        } else if (searchKeyword === '') {
            setSearchResults([])
        }
    }, [currentPage, itemsPerPage, searchKeyword]);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (lockerDropdownRef.current && !lockerDropdownRef.current.contains(event.target)) {
                setIsLockerDropdownOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    const toggleSort = () => {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const debouncedLockerSearch = useCallback(
        debounce((keyword, selectedLockers) => fetchSearchResults(keyword, selectedLockers), 500),
        []
    );

    useEffect(() => {
       
          fetchLockerDetails();
        
      }, []);


    const fetchLockerDetails = async () => {
        try {
            const response = await axios.get('https://pages.onepgr.com/session/get-data-bank-items', {
                params: { user_id: user?.userId },
            });

          
            if (response.data.data.length > 0) {
                setLockerDetails(response.data.data); // Store all locker details

                setTotalItems(response.data.data.length)
            } else {
                console.log('No locker details found');
                setLockerDetails(null);
                setTotalItems(response.data.length)

            }
        } catch (error) {
            console.error('Error fetching locker details:', error);
            setLockerDetails(null);
        }
    };



    const handleSearchChange = (event) => {
        const keyword = event.target.value;
        setSearchKeyword(keyword);
        if (keyword === '') {
            setSearchResults([]);
        } else {
            debouncedLockerSearch(keyword, selectedLockers);
        }
    };


    const handleChange = (event) => {
        const selectedLockerId = event.target.value;
        setSelectedOption(selectedLockerId);
    };

    const fetchSearchResults = async (keyword, selectedLockers) => {
        setIsLoadingItems(true);
        try {
            const lockerIds = selectedLockers.map(locker => `"${locker}"`).join(',');
            const response = await fetch(
                `https://pages.onepgr.com/session/get-data-bank-source-files?user_id=${userId}&search=${keyword}&locker_id=[${lockerIds}]&page_num=${currentPage}&nitems=${itemsPerPage}`
            );
            const data = await response.json();

            console.log(data);
            // Filter the search results based on the selected locker IDs
            const filteredResults = data.data && data.data.length > 0
                ? data.data.filter(result => selectedLockers.includes(result.locker_id))
                : [];
            console.log(filteredResults);
            setSearchResults(filteredResults);
            setIsLoadingItems(false);
            setTotalItems(data.total_items_count);
        } catch (error) {
            console.error('Error fetching search results:', error);
            setIsLoadingItems(false);
        }
    };


    const handleLinkClick = (e, link) => {
        e.preventDefault();
        window.open(link, '_blank');
    };


    const getIconByExtension = (url) => {
        if (!url) return faLock; // Default if no URL is provided
        const extension = url.split(".").pop().toLowerCase(); // Extract extension
        switch (extension) {
            case "pdf":
                return faFilePdf;
            case "csv":
                return faFileCsv;
            case "pptx":
                return faFilePowerpoint;
            case "xlsx":
                return faFileExcel;
            case "png":
            case "jpg":
            case "jpeg":
            case "gif":
                return faFileImage;
            case "zip":
                return faFileArchive;
            default:
                return faFile;
        }
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };


    const handleLockerChange = (event) => {
        const { value, checked } = event.target;
        setSelectedLockers((prevSelectedLockers) =>
            checked
                ? [...new Set([...prevSelectedLockers, value])]
                : prevSelectedLockers.filter((locker) => locker !== value)
        );
        setSelectedLockerState((prevState) => ({
            ...prevState,
            [value]: checked,
        }));
    };

    useEffect(() => {
        if (lockerDetails && lockerDetails.length > 0 && selectedLockers.length === 0) {
            handleSelectAllLockers(null, true);
        }
    }, [lockerDetails]);

    const handleSelectAllLockers = (event, initialSelection = false) => {
        const checked = initialSelection || event.target.checked;
        setSelectedLockers(checked ? lockerDetails.map((locker) => locker.token) : []);
        setSelectedLockerState(
            lockerDetails.reduce((state, locker) => {
                state[locker.token] = checked;
                return state;
            }, {})
        );
    };
    

    return (
        <div>
            <div className='locker-search'>
                <div className='locker-search-tabs'>
                    <div
                        className={`locker-search-tab ${activeTab === 'tables' ? 'active' : ''}`}
                        onClick={() => handleTabClick('tables')}
                    >
                        Tables
                    </div>
                    <div
                        className={`locker-search-tab ${activeTab === 'files-and-links' ? 'active' : ''}`}
                        onClick={() => handleTabClick('files-and-links')}
                    >
                        Files and Links
                    </div>
                </div>
                <div className='locker-search-dropdown'>
                    <div className="locker-select-container">
                        <label htmlFor="locker-select" className='select-locker-label'>Select Lockers:</label>
                        <div className="locker-select-input" ref={lockerDropdownRef}>
                            <input
                                type="text"
                                id="locker-select"
                                placeholder="-- Select locker(s) --"
                                value={selectedLockers.length > 0
                                    ? selectedLockers
                                        .map(
                                            (token) =>
                                                lockerDetails.find((locker) => locker.token === token)
                                                    ?.name
                                        )
                                        .join(", ")
                                    : ""
                                }
                                title={
                                    selectedLockers.length > 0
                                        ? selectedLockers
                                            .map(
                                                (token) =>
                                                    lockerDetails.find((locker) => locker.token === token)
                                                        ?.name
                                            )
                                            .join(", ")
                                        : ""
                                }
                                readOnly
                                onClick={() => setIsLockerDropdownOpen(!isLockerDropdownOpen)}
                            />
                            {isLockerDropdownOpen && (
                                <div className="locker-select-dropdown-content">
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={selectedLockers.length === lockerDetails.length}
                                            onChange={handleSelectAllLockers}
                                        />
                                        Select All
                                    </label>
                                    {loading ? (
                                        <label className='locker-select-option'>Loading...</label>
                                    ) : (
                                        lockerDetails &&
                                        lockerDetails.map((locker) => {
                                            const parentLocker = lockerDetails.find((l) => l.token === locker.parent_id);

                                            return (
                                                <label
                                                    key={locker.token}
                                                    className={`locker-select-option ${selectedLockerState[locker.token] ? 'selected' : ''}`}
                                                    title= {parentLocker ? `${parentLocker.name} / ${locker.name}` : locker.name}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        value={locker.token}
                                                        checked={selectedLockers.includes(locker.token)}
                                                        onChange={handleLockerChange}
                                                    />
                                                    {parentLocker ? `${parentLocker.name} / ${locker.name}` : locker.name}
                                                </label>
                                            );
                                        })
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    {activeTab === 'files-and-links' && (
                        <div className={`search-bar locker-search-bar ${activeTab === 'files-and-links' ? 'show' : 'hide'}`}>
                            <div className="input-container">
                                <div className="icon-card searchIcon">
                                    <FontAwesomeIcon icon={faSearch} />
                                </div>
                                <input
                                    type="text"
                                    id="searchInput"
                                    placeholder="Search Locker Items..."
                                    value={searchKeyword}
                                    onChange={handleSearchChange}
                                    className='search-input locker-search-input-bar'
                                />
                            </div>
                        </div>
                    )}
                </div>


                {notification.message && (
                    <Notification type={notification.type} message={notification.message} />
                )}
            </div>

            {activeTab === 'files-and-links' && searchResults.length > 0 && (
                <div className="rdsfiles locker-search-results">
                    <div className="table-header locker-search-results-header">
                        <span>
                            Name
                            <FontAwesomeIcon
                                icon={sortOrder === 'asc' ? faArrowDown : faArrowUp}
                                className="sort-icon"
                                onClick={toggleSort}
                            />
                        </span>
                        <span>Last Updated</span>
                    </div>
                    <div className='locker-search-results-container'>
                        <ul className="table-list">
                            {searchResults.map((result) => (
                                <li key={result.id} className="rdstable-item locker-search-item">
                                    {result.object_type === 'link' ? (
                                        <div className="tablename-icon locker-search-item-container" title={result.name}>
                                            <div className="result-icon locker-search-item-icon">
                                                <FontAwesomeIcon icon={faLink} />
                                            </div>
                                            <div className="result-details locker-search-result-details">
                                                <span className="rdstablename locker-item-name" onClick={(e) => handleLinkClick(e, result.name)}>{result.name}</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="tablename-icon locker-search-item-container">
                                            <div className="result-icon locker-search-item-icon">
                                                {result.object_type === 'file' && (
                                                    <FontAwesomeIcon icon={getIconByExtension(result.name)} />
                                                )}
                                                {result.object_type === 'table' && (
                                                    <FontAwesomeIcon icon={faTable} />
                                                )}
                                            </div>
                                            <div className="result-details locker-search-result-details">
                                                <span className="rdstablename locker-item-name" onClick={() => viewFile(result.source_url)}>{result.name}</span>
                                            </div>
                                        </div>
                                    )}
                                    <div className="table-actions-icon-rds locker-search-last-updated">
                                        <div className="result-date locker-search-result-date">{formatDate(result.date)}</div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <Pagination
                        itemsPerPage={itemsPerPage}
                        totalItems={totalItems}
                        paginate={setCurrentPage}
                        currentPage={currentPage}
                        setItemsPerPage={setItemsPerPage}
                    />

                </div>
            )}
            <div className='table-filters'>
                {activeTab === 'tables' && (
                    <PersonInfoFilter
                        showTablesDropdown={false}
                        selectedLockers={selectedLockers}
                        setSelectedLockers={setSelectedLockers}
                    />
                )}
            </div>
        </div>
    );
};

export default LockerSearch;