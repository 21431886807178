
import React, { useContext, useState, useRef, useEffect } from 'react';
import './navbar.css';
import onepgrLogo from '../../images/onepgr-logo.webp';
import { UserContext, useUser } from '../context/userContext'; // Import the UserContext
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import profilepic from '../../images/profilrpic.jpg'
import DropdownSidebar from '../sideBar/dropdownSidebar';
import { User, Key, Settings, LogOut } from 'lucide-react';

const NavBar = () => {
    const { user } = useContext(UserContext); // Access user information from context
    const [showDropdown, setShowDropdown] = useState(false);
    const navigate = useNavigate(); // Hook for navigation
    const { logoutUser } = useContext(UserContext);
    const { isSidebarCollapsed, toggleSidebar } = useUser();
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);
    const profileRef = useRef(null);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (profileRef.current && !profileRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [profileRef]);

    const handleClick = (route) => {
        navigate(route);
    };

    const handleMouseEnter = () => {
        setShowDropdown(true);
    };

    const handleMouseLeave = () => {
        setShowDropdown(false);
    };

    const handleLogout = () => {
        // Destructure logoutUser from context
        logoutUser(); // Use logoutUser to clear the user context
        localStorage.removeItem('user'); // Clear user data from localStorage
        localStorage.removeItem('profileAttributes');
        // navigate('/login'); // Redirect to login page
    };






    return (
        <nav className="navbar">
            <div className="logo">
                <div className="hamburger-menu" onClick={toggleSidebar}>
                    <div className="line1"></div>
                    <div className="line2"></div>
                    <div className="line3"></div>
                </div>
                <img src={onepgrLogo} alt="Logo" />
            </div>

            {user && (
                <div className="profile-section"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <div className="profile-info">
                        <img src={user.profilePic || profilepic} alt="Profile" className="profile-pic" />
                        <span>{user.name.split('@')[0]}</span>
                        {/* <button ref={buttonRef} onClick={() => setShowDropdown(!showDropdown)} className="dropdown-button">▼</button> */}
                    </div> 
                    <div className={`navbar-dropdown-menu ${showDropdown ? 'active' : ''}`}>
                        <div
                            onClick={() => handleClick('/profile/edit')}
                            className="navbar-dropdown-item"
                            role="button"
                            tabIndex={0}
                        >
                            <User className="navbar-dropdown-icon" />
                            Profile
                        </div>
                        <div
                            onClick={() => handleClick('/profile/settings')}
                            className="navbar-dropdown-item"
                            role="button"
                            tabIndex={0}
                        >
                            <Settings className="navbar-dropdown-icon" />
                            Account Settings
                        </div>
                        {/* <a href="#application-accounts" className="navbar-dropdown-item">
                            <Key className="navbar-dropdown-icon" />
                            Application Accounts
                        </a> */}
                        {/* <a href="#account-settings" className="navbar-dropdown-item">
                            <Settings className="navbar-dropdown-icon" />
                            Account Settings
                        </a> */}
                        {/* <a href="#change-password" className="navbar-dropdown-item">
                            <Key className="navbar-dropdown-icon" />
                            Change Password
                        </a> */}
                        <button onClick={handleLogout} className="navbar-dropdown-item navbar-dropdown-signout">
                            <LogOut className="navbar-dropdown-icon" />
                            Sign Out
                        </button>
                    </div>
                </div>
            )}
            <DropdownSidebar isSidebarCollapsed={isSidebarCollapsed} toggleSidebar={toggleSidebar} />
        </nav>
    );
};

export default NavBar;
