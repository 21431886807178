// refreshContext.js
import React, { createContext, useContext,useState } from 'react';
import axios from 'axios';

const RefreshContext = createContext();

export const RefreshProvider = ({ children }) => {
  const [refreshTrigger, setRefreshTrigger] = useState(false);
const [ allItemsRefresh, setAllItemsRefresh] = useState(false)
const [ refreshLinks, setRefreshLinks] = useState(false)


  const refreshFileList = async (userId, lockerId, currentPage, itemsPerPage, searchTerm) => {
    console.log(lockerId)
    const baseApiUrl = `https://pages.onepgr.com/session/get-data-bank-source-files`;
    const queryParams = new URLSearchParams({
        user_id: userId,
        page_num: currentPage,
        nitems: itemsPerPage,
        object_type: 'file'
    });

    if (lockerId && lockerId.length > 0) {
      queryParams.append('locker_id', JSON.stringify(lockerId));
    }
    // If there's a search term, add it to the query params
    if (searchTerm) {
        queryParams.append('search', searchTerm);
    }

    try {
        const response = await fetch(`${baseApiUrl}?${queryParams.toString()}`);
        const data = await response.json();
        console.log(data);
        if (response.ok && data.status === 1) {
            // Filter out files with object_type = 'link'
            const filteredFiles = data.data;
            console.log(filteredFiles)
            return {
              files: filteredFiles,
              totalItems: data.total_items_count,
          };
           
        } else {
            console.error('Failed to fetch files:', data.message);
            return [];
        }
    } catch (error) {
        console.error('Error fetching files:', error);
        return [];
    }
};

const fetchLockerItems = async (userId, lockerId) => {
  try {
    const response = await axios.get('https://pages.onepgr.com/session/get-all-databank-objects', {
      params: {
        user_id: userId,
        locker_id: lockerId,
      },
    });
   
    return response.data.data;
  } catch (error) {
    console.error('Error fetching locker items:', error);
    throw error;
  }
};


const fetchLinks = async (userId, lockerIds) => {
  try {
    const queryParams = new URLSearchParams({
      user_id: userId,
      object_type: 'link',
    });

    if (lockerIds && lockerIds.length > 0) {
      queryParams.append('locker_id', JSON.stringify(lockerIds));
    }

    const response = await axios.get('https://pages.onepgr.com/session/get-data-bank-source-files', {
      params: queryParams,
    });
    console.log(response.data.data);
    // Filter the response data to include only files with object_type: 'link'
    const filteredLinks = response.data.data;
    return filteredLinks;
  } catch (error) {
    console.error('Error fetching source files:', error);
    return [];
  }
};

  const triggerRefreshFiles = () => {
    setRefreshTrigger(prevState => !prevState);
};
const triggerRefreshAllItems = () =>{
  setAllItemsRefresh(prevState => !prevState);
}
const triggerRefreshLinks = () =>{
  setRefreshLinks(prevState => !prevState);
}


  return (
    <RefreshContext.Provider value={{ refreshFileList, refreshLinks,fetchLockerItems,triggerRefreshFiles, triggerRefreshLinks,triggerRefreshAllItems,refreshTrigger ,fetchLinks ,allItemsRefresh}}>
      {children}
    </RefreshContext.Provider>
  );
};

export const useRefresh = () => useContext(RefreshContext);