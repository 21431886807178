import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './accountSettings.css';
import { UserContext } from '../../context/userContext';
import { Info } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const AccountSettings = () => {
  const { user } = useContext(UserContext);
  const apiProviders = [
    { name: 'OpenAI', id: 'open_ai', fields: ['API Key', 'API Secret'] },
    { name: 'Skrapp', id: 'skrapp_io', fields: ['API Key', 'API Secret'] },
    { name: 'Seamless.AI', id: 'seamless_ai', fields: ['API Key', 'API Secret'] },
    { name: 'Synthesia', id: 'synthesia', fields: ['API Key', 'API Secret'] },
    { 
      name: 'Apollo.io', 
      id: 'apollo_io', 
      fields: ['Name your key', 'API Key'], 
      showInfo: true,
      tooltip: "To get your Apollo.io API key: 1. Log in to Apollo.io 2. Go to https://developer.apollo.io/keys in new tab 3. Create a new API key 4. Copy the key and paste it here"
    },
    { 
      name: 'Leadmagic.io', 
      id: 'leadmagic_io', 
      fields: ['Name your key', 'API Key'], 
      showInfo: true,
      tooltip: "To get your Leadmagic.io API key: 1. Log in to Leadmagic.io 2. Navigate to Account Settings > API 3. Copy the generated key and paste it here"
    },
  ];


  const [inputValues, setInputValues] = useState({});
  const [userDetails, setUserDetails] = useState(null);
  const [editedProviders, setEditedProviders] = useState({});

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const fetchUserDetails = async () => {
    const body = new FormData();
    body.append("id", user.userId);
    try {
      const response = await axios.post(
        'https://onepgr.com/users/get_profile_attributes?onepgr_apicall=1&xhr_flag=1',
        body,
        { withCredentials: true }
      );

      const profileData = JSON.parse(response.data.response);
      console.log('Profile Attributes Response:', profileData);
      setUserDetails(profileData);

      if (profileData.api_keys) {
        const apiKeys = JSON.parse(profileData.api_keys);
        console.log('API Keys:', apiKeys);
        const initialInputValues = {};
        apiProviders.forEach(provider => {
          const providerKeys = apiKeys[provider.id];
          if (providerKeys) {
            if (provider.id === 'apollo_io' || provider.id === 'leadmagic_io') {
              initialInputValues[provider.id] = {
                field1: providerKeys.name || '',
                field2: providerKeys.key || ''
              };
            } else {
              initialInputValues[provider.id] = {
                field1: providerKeys.key || '',
                field2: providerKeys.secret || ''
              };
            }
          } else {
            initialInputValues[provider.id] = { field1: '', field2: '' };
          }
        });
        setInputValues(initialInputValues);
      }
    } catch (error) {
      console.error('Error fetching profile attributes:', error);
    }
  };

  const handleInputChange = (providerId, field, value) => {
    setInputValues(prev => ({
      ...prev,
      [providerId]: {
        ...prev[providerId],
        [field]: value
      }
    }));
    setEditedProviders(prev => ({
      ...prev,
      [providerId]: true
    }));
  };

  const isButtonActive = (providerId) => {
    return editedProviders[providerId];
  };

  const handleSave = async (providerId) => {
    let apiKeys = userDetails.api_keys ? JSON.parse(userDetails.api_keys) : {};

    const provider = apiProviders.find(p => p.id === providerId);
    if (provider.id === 'apollo_io' || provider.id === 'leadmagic_io') {
      apiKeys[providerId] = {
        name: inputValues[providerId]?.field1 || '',
        key: inputValues[providerId]?.field2 || ''
      };
    } else {
      apiKeys[providerId] = {
        key: inputValues[providerId]?.field1 || '',
        secret: inputValues[providerId]?.field2 || ''
      };
    }

    const payLoadData = {
      api_keys: JSON.stringify(apiKeys)
    };

    try {
      await saveUserApiSecretDetails(payLoadData);
      console.log(`Saved API keys for ${providerId}`);

      setEditedProviders(prev => ({
        ...prev,
        [providerId]: false
      }));

      await fetchUserDetails();
    } catch (error) {
      console.error(`Error saving API keys for ${providerId}:`, error);
    }
  };

  const saveUserApiSecretDetails = async (payLoadData) => {
    let body = new FormData();
    body.append("onepgr_apicall", "1");
    body.append("service_name", "onepgr");
    body.append("xhr_flag", "1");
    body.append("api_keys", payLoadData.api_keys);

    try {
      await axios.post("https://onepgr.com/users/set_user_attributes", body, { withCredentials: true });
    } catch (error) {
      throw error;
    }
  };

  return (
    <div className="api-keys-container">
      <h2 className="api-keys-title">API Keys</h2>
      {apiProviders.map((provider) => (
        <div key={provider.id} className="api-provider">
                <h3 className="provider-name">
            {provider.name}
            {provider.showInfo && (
              <OverlayTrigger
                key={`top-info-${provider.id}`}
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-api-info`} className="custom-tooltip">
                    {provider.tooltip}
                  </Tooltip>
                }
              >
                <span className="info-icon-wrapper">
                  <FontAwesomeIcon 
                    icon={faInfoCircle} 
                    className="info-icon"
                  />
                </span>
              </OverlayTrigger>
            )}
          </h3>
          <div className="api-inputs">
            <input
              type="text"
              placeholder={provider.fields[0]}
              className="api-input"
              value={inputValues[provider.id]?.field1 || ''}
              onChange={(e) => handleInputChange(provider.id, 'field1', e.target.value)}
              title={`${provider.name} ${provider.fields[0]}`}
            />
            <input
              type="text"
              placeholder={provider.fields[1]}
              className="api-input"
              value={inputValues[provider.id]?.field2 || ''}
              onChange={(e) => handleInputChange(provider.id, 'field2', e.target.value)}
              title={`${provider.name} ${provider.fields[1]}`}
            />
            <button
              className={`save-button ${isButtonActive(provider.id) ? 'active' : ''}`}
              onClick={() => handleSave(provider.id)}
              disabled={!isButtonActive(provider.id)}
            >
              Save
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AccountSettings;