import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate, Outlet, useLocation } from 'react-router-dom';
import { useUser } from '../../context/userContext';
import "./selectedLocker.css"
import FileRepository from '../../s3dataBase/fileList/fileList';
import { faLock, faFileUpload, faUpload, faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserContext } from '../../context/userContext';
import axios from 'axios';
import FileUploadComponent from '../../s3dataBase/fileUpload/fileUpload';
import { useRefresh } from '../../refreshContext /refreshContext';
import Notification from '../../notification /notification';
import LockerNameModal from '../lockers/lockerNameModal';



const LockerItemDetails = () => {
    const { lockerName, tabName, lockerToken } = useParams();
    const { activeTab, setActiveTab, selectedLocker, setIsSelectFileActive } = useUser();
    const navigate = useNavigate();
    const location = useLocation();
    const { lockerDetails, fetchLockerDetails } = useUser();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState(tabName || 'AllItems');
    const [showOverlay, setShowOverlay] = useState(false);
    const [newLockerName, setNewLockerName] = useState('');
    const [link, setLink] = useState('');
    const [isOverlayLoading, setIsOverlayLoading] = useState(false);
    // Find the lockerItem object based on the lockerName parameter
    const lockerItem = lockerDetails?.find(item => item.token === lockerToken);
    const { user } = useContext(UserContext);
    const userId = user?.userId;
    const [notification, setNotification] = useState({ message: '', type: '' });
    const [droppedFiles, setDroppedFiles] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { refreshFileList } = useRefresh();
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);
    const { triggerRefreshLinks, triggerRefreshAllItems } = useRefresh()
    const [isDragging, setIsDragging] = useState(false);

    let lockerId = ''; // Initialize lockerId
    if (selectedLocker) {
        lockerId = selectedLocker.token;
    } else if (lockerToken) {
        const lockerItem = lockerDetails?.find(item => item.token === lockerToken);
        if (lockerItem) {
            lockerId = lockerItem.token;
        }
    }

    console.log(lockerId)
    console.log(lockerDetails)

    useEffect(() => {
        if ((lockerToken || selectedLocker?.token) && (!lockerDetails || lockerDetails.length === 0)) {
            fetchLockerDetails();
        }
    }, [lockerToken, selectedLocker, fetchLockerDetails, lockerDetails]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleDragOver = (e) => {
            e.preventDefault();
            setIsDragging(true);
        };

        const handleDrop = (e) => {
            e.preventDefault();
            setIsDragging(false);
            const files = e.dataTransfer.files;
            if (files.length) {
                setDroppedFiles(files);
            }
        };
        const handleDragLeave = (e) => {
            e.preventDefault();
            setIsDragging(false);
        };
        // Listen for drag and drop events on the window
        window.addEventListener('dragover', handleDragOver);
        window.addEventListener('drop', handleDrop);
        window.addEventListener('dragleave', handleDragLeave);

        return () => {
            window.removeEventListener('dragover', handleDragOver);
            window.removeEventListener('drop', handleDrop);
            window.removeEventListener('dragleave', handleDragLeave);
        };
    }, []);


    useEffect(() => {
        if (lockerId) {
            navigate(`/home/lockers/${lockerId}/${activeTab}`, { replace: true });
        }
    }, [activeTab, lockerId, navigate]);

    function getInitialTab(pathname) {
        const match = pathname.match(/\/(AllItems|MyFiles|MyTables|MyLinks)$/);
        return match ? match[1] : 'AllItems';
    }
    useEffect(() => {
        // This effect adjusts the selected tab based on the current pathname
        // This ensures that tab state updates when the user uses browser navigation buttons
        const newTab = getInitialTab(location.pathname);
        if (newTab !== selectedTab) {
            setSelectedTab(newTab);
        }
    }, [location.pathname]);

    useEffect(() => {
        // Clean up function
        return () => {
            setActiveTab('AllItems');
        };
    }, [setActiveTab]);

    const handleTabClick = (newTab) => {
        setActiveTab(newTab); // Update activeTab in context
    };

    if (!lockerItem) {
        return <div>Loading...</div>; // or any other fallback content
    }

    console.log(lockerId);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const triggerFileSelection = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.multiple = true;
        fileInput.addEventListener('change', handleFileSelection);
        fileInput.click();
    };

    const handleFileSelection = (event) => {
        const files = Array.from(event.target.files);
        setDroppedFiles(files);
    };

    const clearDroppedFiles = () => {
        setDroppedFiles([]);
    };
    const handleCloseOverlay = () => {
        setShowOverlay(false);
        setLink('');
    };

    const handleLinkChange = (e) => {
        setLink(e.target.value);
    };

    const handleAddLink = () => {
        setShowOverlay(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!link.trim()) {
            alert('Please enter or paste a link to submit.');
            setIsOverlayLoading(false);
            return;
        }
        handleCloseOverlay();
        setIsOverlayLoading(true);

        try {
            const response = await axios.post('https://crawl.onepgr.com:3002/store-link', { link });
            const storedLink = response.data.link;
            console.log('Stored link:', storedLink);

            // Send the link to the specified endpoint
            const linkData = {
                user_id: userId,
                locker_id: lockerId,
                name: storedLink,
                object_type: 'link',
                owner: user?.name.split('@')[0],
            };
            console.log(lockerId)

            const linkResponse = await axios.post(
                'https://pages.onepgr.com/session/create-data-bank-source-file',
                linkData
            );

            console.log('Create source file response:', linkResponse.data);

            setNotification({ message: 'Link Uploaded successfully!', type: 'success' });
            triggerRefreshAllItems()
        } catch (error) {
            console.error('Error:', error);
            setNotification({ message: 'Failed to upload link.', type: 'error' });
        } finally {
            setIsOverlayLoading(false);
        }
    };

    const closeModal = (name) => {
        setIsModalOpen(false);
        setNewLockerName(name);
        if (name) {
            addLocker(name);
        }
    };

    const addLocker = async (lockerName) => {
        try {

            // Send the POST request to the API
            const response = await axios.post('https://pages.onepgr.com/session/create-data-bank-item', {
                user_id: userId,
                parent_id: lockerId,
                name: lockerName, // Use the generated name
                owner: user?.name.split('@')[0],
                object_type: 'item'
            });

            console.log('API response:', response.data);
            if (response.status === 200) {
                setNotification({ message: 'Locker added successfully!', type: 'success' });
                triggerRefreshAllItems()
            } else {
                setNotification({ message: 'Failed to add locker.', type: 'error' });
            }
            // Handle the response as needed
        } catch (error) {
            console.error('Error creating locker:', error);
            // Handle the error as needed
        }
    };
    const openModal = () => {
        setIsModalOpen(true);
    };


    return (
        <div className={`selectedLocker-container ${isDragging ? 'dragging' : ''}`}>
            <div className="selectedLocker-header">
                <div className='locker-name-create-btn'>
                    <p className='locker-name selected-locker-name'> <FontAwesomeIcon icon={faLock} className="selected-locker-icon" />{lockerItem ? lockerItem.name : ''}</p>
                    {(activeTab === 'AllItems' || activeTab === 'MyFiles') && (
                        <div className='upload-component'>
                            <FileUploadComponent
                                refreshFileList={refreshFileList}
                                droppedFiles={droppedFiles}
                                clearDroppedFiles={clearDroppedFiles}
                                lockerId={lockerId}
                            />
                        </div>
                    )}
                    <div className='create-new'>
                        <button className='create-new-btn' onClick={toggleDropdown} ref={buttonRef}>
                            <span> + </span>
                            Create
                        </button>
                        {isOpen && (
                            <div className="dropdown" ref={dropdownRef}>
                                <p onClick={triggerFileSelection}> <FontAwesomeIcon icon={faFileUpload} className="create-new-icon" /> Upload File(s)</p>
                                <p onClick={handleAddLink}> <FontAwesomeIcon icon={faAdd} className="create-new-icon" /> Add Link</p>
                                <p onClick={openModal}> <FontAwesomeIcon icon={faLock} className="create-new-icon" /> Create Locker</p>
                                <LockerNameModal
                                    isOpen={isModalOpen}
                                    onClose={closeModal}
                                    newLockerName={newLockerName}
                                    setNewLockerName={setNewLockerName}
                                />
                            </div>

                        )}
                        {showOverlay && (
                            <div className="overlay" >
                                <div className="link-input-container">
                                    <h4>Add Link</h4>
                                    <input
                                        type="text"
                                        placeholder="Enter or paste your link here"
                                        value={link}
                                        onChange={handleLinkChange}
                                    />
                                    <div className="link-btn-container">
                                        <button className="close-link-overlay-btn" onClick={handleCloseOverlay}>
                                            Close
                                        </button>
                                        <button className="upload-btn" onClick={handleSubmit}>
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <hr />
                <div className='tab-container'>
                    <div id='allitems-tab' className={`tab ${activeTab === 'AllItems' ? 'selected' : ''}`}
                        onClick={() => handleTabClick('AllItems')}>
                        <span className="checkmark"></span> All Items
                    </div>
                    <div className={`tab ${activeTab === 'MyTables' ? 'selected' : ''}`}
                        onClick={() => handleTabClick('MyTables')}>
                        <span className="square"></span> My Tables
                    </div>
                    <div id='myfiles-tab' className={`tab ${activeTab === 'MyFiles' ? 'selected' : ''}`}
                        onClick={() => handleTabClick('MyFiles')}>
                        <span className="checkmark"></span> My Files
                    </div>

                    <div id='myLinks-tab' className={`tab ${activeTab === 'MyLinks' ? 'selected' : ''}`}
                        onClick={() => handleTabClick('MyLinks')}>
                        <span className="square"></span> My Links
                    </div>


                </div>
            </div>
            {notification.message && (
                <Notification type={notification.type} message={notification.message} />
            )}

            <Outlet key={`${lockerId}-${activeTab}`} context={{ lockerId: lockerId, refreshFileList: refreshFileList, activeTab: activeTab }} />
        </div>
    );
};

export default LockerItemDetails;