


import React, { useEffect, useState } from 'react';
import './App.css';
import Login from './components/loginForm/loginForm';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { UserProvider } from './components/context/userContext';
import ProtectedRoute from './components/protectedRoute/protectedRoute';
import Home from './components/home/home';
import Layout from './components/layout/layout';
import ViewFileComponent from './components/view-file/viewFile';
import MyFilesComponent from './components/s3dataBase/s3DataBase'; // Import the MyFilesComponent
import MyTablesComponent from './components/RDS-Database/rdsFileRepository'; // Import the MyTablesComponent
import SearchComponent from './components/search/search-section'; // Import the SearchComponent
import FindDuplicatesComponent from './components/dataApps/findDuplicates/findDuplicates'; // Import the FindDuplicatesComponent
import Links from './components/links/myLinks';
import TableView from './components/tableview/tableView';
import { Outlet, useParams } from 'react-router-dom';
import ResultsTable from './components/resultsTable/resultsTable';
import DataBank from './components/databankMain/databankMainPage';
import Lockers from './components/databankMain/lockers/lockers';
import LockerItemDetails from './components/databankMain/selectedLocker/selectedlocker';
import AllLockerItems from './components/allLockerItems/allLockerItems';
import Breadcrumbs from './components/breadcrumbs/breadcrumbs';
import { RefreshProvider } from './components/refreshContext /refreshContext';
import SharedWithMe from './components/SharedWithMe/SharedWithMe';
import LockerComponent from './components/SharedWithMe/sharedLockerComponent';
import SharedWithMeContent from './components/SharedWithMe/sharedWithMeContent';
import EnrichDataApp from './components/dataApps/enrichData/enrichData';
import Dashboard from './components/profile/profile';
import EditProfile from './components/profile/editProfile/editProfile';
import AccountStettings from './components/profile/accountSettings/accountSettings';

// import DataSharingAppComponent from './components/data-sharing-app/dataSharingApp'; // Import the DataSharingAppComponent
// import AdminComponent from './components/admin/admin'; // Import the AdminComponent


function ShareLinkRedirect() {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname;
  const searchParams = new URLSearchParams(location.search);
  const password = searchParams.get('password');

  useEffect(() => {
    if (path.startsWith('/lockers/')) {
      const token = path.split('/')[2];
      const user = localStorage.getItem('user');
      if (user) {
        navigate(`/shared-with-me/lockers/${token}/all-items`, { state: { password } });
      } else {
        const returnTo = `/shared-with-me/lockers/${token}/all-items`;
        navigate(`/login?returnTo=${encodeURIComponent(returnTo)}`, { state: { password } });
      }
    }
  }, [path, password, navigate]);

  return null;
}
function App() {
  const { lockerId } = useParams();

  console.log('Locker ID:', lockerId);


  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  console.log(isAuthenticated)

  useEffect(() => {
    const checkAuthentication = () => {
      const user = localStorage.getItem('user');
      setIsAuthenticated(user);
      setIsLoading(false);
    };

    checkAuthentication();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  console.log(isAuthenticated)

  return (
    <Router>
      <RefreshProvider>
        <UserProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/view" element={<Layout><ViewFileComponent /></Layout>} />
            {/* <Route path="/" element={<Login />} /> */}

            <Route path="/" element={<Navigate to="/home/all-items" replace />} />

            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Layout>
                    <Home />
                  </Layout>
                </ProtectedRoute>
              }
            >

              <Route path="tables" element={<Navigate to="/home/tables" replace />} />
              <Route path="home" element={<DataBank />}>
                <Route index element={<p></p>} />
                <Route path="all-items" element={<Lockers />} />
                <Route path="lockers" element={<Lockers />} />
                <Route path="files" element={<Lockers />} />
                <Route path="tables" element={<Lockers />} />
                <Route path="links" element={<Lockers />} />

                <Route path="lockers/:lockerToken" element={<LockerItemDetails />}>
                  <Route path="AllItems" element={<AllLockerItems key={lockerId} />} />
                  <Route path="MyFiles" element={<MyFilesComponent key={lockerId} />} />
                  <Route path="MyTables" element={<MyTablesComponent key={lockerId} />} />
                  <Route path="MyLinks" element={<Links key={lockerId} />} />
                  <Route path="lockers/:token" element={<LockerComponent />} />
                </Route>

              </Route>
              <Route path="/lockers/:token" element={<ShareLinkRedirect />} />


              <Route path="files" element={<MyFilesComponent />} />

              <Route path="tables" element={<Outlet />}>
                <Route index element={<Navigate to="/home/tables" replace />} />
                <Route path=":tableName" element={<TableView />} />
              </Route>
              <Route path="links" element={<Links />} />
              <Route path="search" element={<SearchComponent />} />
              <Route path="shared-with-me" element={<SharedWithMe />}>
                <Route index element={<Navigate to="all-items" replace />} />
                <Route path="all-items" element={<SharedWithMeContent />} />
                <Route path="lockers" element={<SharedWithMeContent />} />
                <Route path="tables" element={<SharedWithMeContent />} />
                <Route path="lockers/:token/:tab" element={<LockerComponent />} />
              </Route>
              <Route path="/lockers/:token" element={<LockerComponent />} />
              <Route path="searchactions" element={<ResultsTable />} />
              <Route path="apps/find-duplicates" element={<FindDuplicatesComponent />} />
              <Route path="apps/find-prospect" element={<EnrichDataApp />} />
              <Route path="/profile" element={<Dashboard />}>
                <Route index element={<EditProfile />} />
                <Route path="edit" element={<EditProfile />} />
                <Route path="accounts" element={<h2>Application Accounts</h2>} />
                <Route path="settings" element={<AccountStettings />} />
                <Route path="password" element={<h2>Change Password</h2>} />
              </Route>
            </Route>

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </UserProvider>
      </RefreshProvider>
    </Router>
  );
}

export default App;