


import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { UserContext } from '../context/userContext';

const ProtectedRoute = ({ children }) => {
  const { user } = useContext(UserContext);
  const location = useLocation();

  if (!user) {
    // Construct the returnTo URL
    const returnTo = `${location.pathname}${location.search}`;
    
    // Construct the login URL with the returnTo parameter
    const loginUrl = `/login?returnTo=${encodeURIComponent(returnTo)}`;

    return <Navigate to={loginUrl} state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;


