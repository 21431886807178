import React, { useContext } from 'react';
import './sharedTable.css'
import { useUser } from '../context/userContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faTrashAlt, faTable, faFile, faLink, faEye } from '@fortawesome/free-solid-svg-icons';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../../components/context/userContext';
const TableComponent = ({ data, onDelete, lockerName , isLoading}) => {
    console.log(lockerName);
    const { formatDate, viewFile } = useUser();
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const userId = user?.userId;
    const location = useLocation();

    const isLockersRoute = location.pathname === '/shared-with-me/lockers';
    const handleItemClick = (item) => {
        navigate(`/shared-with-me/lockers/${item.token}/all-items`, {
            state: { name: item.name, owner_id: item.user_id, token: item.token }
        });
    };

    const getIconForObjectType = (object_type) => {

        switch (object_type) {
            case 'item':
                return faLock;
            case 'table':
                return faTable;
            case 'file':
                return faFile;
            case 'link':
                return faLink;
            default:
                return faFile; // Default icon if type is unknown
        }
    };

    const handleDelete = (event, item) => {
        event.stopPropagation();
        onDelete(item._id);
    };
    const handleTableClick = (tableName) => {
        navigate(`/tables/${tableName}`);
    };


    const getViewTooltip = (object_type) => {
        switch (object_type) {
            case 'item':
                return 'View Locker';
            case 'table':
                return 'View Table';
            case 'file':
                return 'View File';
            case 'link':
                return 'View Link';
            default:
                return 'View';
        }
    };

    const handleView = (event, item) => {
        event.stopPropagation();
        switch (item.object_type) {
            case 'item':
                handleItemClick(item);
                break;
            case 'table':
                // Add logic for viewing table
                handleTableClick(item.name);
                console.log('View table:', item);
                break;
            case 'file':
                // Add logic for viewing file
                viewFile(item.source_url);
                console.log('View file:', item);
                break;
            case 'link':
                // Add logic for viewing link
                console.log('View link:', item);
                break;
            default:
                console.log('View item:', item);
        }
    };

    return (
        <>
        {isLoading ? (
            <div className="spinner"></div>
        ) : (
        <table>
            <thead>
                <tr className="shared-table-header">
                    <th className='shared-item-header'>Name</th>
                    <th className='shared-item-header'>Last Updated</th>
                    <th className='shared-item-header shared-item-owner'>Owner</th>
                    <th className='shared-item-header shared-item-action'>Actions</th>
                </tr>
            </thead>
            <tbody >
                {data.map((item) => (
                    <tr key={item._id} className='shared-table-body'>
                        <td
                            className='shared-item-name'
                            title={item.name}
                            onClick={(event) => {
                                if (item.object_type === 'item') {
                                    handleItemClick(item);
                                } else {
                                    handleView(event, item);
                                }
                            }}
                        >
                            <FontAwesomeIcon
                                icon={getIconForObjectType(item.object_type)}
                                className='shared-item-icon'
                            />
                            {item.name}
                        </td>
                        <td className='shared-item-details'>{formatDate(item.date)}</td>
                        <td className='shared-item-details shared-item-owner'>{item.owner}</td>
                        <td className='shared-item-action'>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id={`tooltip-view`}>{getViewTooltip(item.object_type)}</Tooltip>}
                            >
                                <Button variant="light" onClick={(event) => {
                                    event.stopPropagation();
                                    handleView(event, item)
                                }}>
                                    <FontAwesomeIcon icon={faEye} />
                                </Button>
                            </OverlayTrigger>
                            {isLockersRoute && (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id={`tooltip-delete`}>Delete</Tooltip>}
                                >
                                    <Button variant="light" onClick={(event) => {
                                        event.stopPropagation();
                                        handleDelete(event, item);

                                    }}>
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </Button>
                                </OverlayTrigger>
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
        )}
        </>
    );
};

export default TableComponent;