import React, { useState, useEffect , useContext} from 'react';
import './sharedLockerComponent.css';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import TableComponent from './sharedTable';
import { UserContext} from '../../components/context/userContext';
const LockerComponent = () => {
  const [activeTab, setActiveTab] = useState('all-items');
  const { token, tab } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [sharedObjects, setSharedObjects] = useState([]);
  const { user } = useContext(UserContext);
  const userId = user?.userId;
  const [lockerName, setLockerName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  // Extract the state values
  const { name, owner_id , password } = location.state || {}; 

  console.log(password, 'password',token, 'token',userId, 'userId',owner_id, 'owner_id')

  const handleNewSharedLink = async () => {
    setIsLoading(true);
    if (!user || !user.userId) {
      console.error('User ID not available');
      return;
    }

    try {
      const response = await axios.post('https://pages.onepgr.com/session/add-shared-user', {
        locker_id: token,
        passcode: password,
        guest_user_id: userId,
      });
      // Handle the response as needed
      console.log('Shared user added:', response.data);
      setSharedObjects(response.data.sharedLockerObjects);
      console.log('Shared user added:', response.data.sharedLocker.name);
      setLockerName(response.data.sharedLocker.name);
      setIsLoading(false);
    } catch (error) {
      console.error('Error adding shared user:', error);
      // Handle the error as needed
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    if (tab) {
      setActiveTab(tab);
    } else {
      setActiveTab('all-items');
    }

    // If we have a password, it means this is a newly shared link
    if (token && password) {
      handleNewSharedLink();
    } else {
     
    }
  }, [tab, password, token]);

  useEffect(() => {
    fetchSharedObjects();
  }, []);


  

  const fetchSharedObjects = async () => {
   setIsLoading(true);
    try {
      const response = await axios.get(`https://pages.onepgr.com/session/get-shared-locker-contents`, {
        params: {
          owner_id: owner_id,
          locker_id: token
        }
      });
      setSharedObjects(response.data.data || []);
      console.log(response.data.data);
      setIsLoading(false);
    } catch (err) {
      console.error('Error fetching shared objects:', err);
    
      setSharedObjects([]);
    } finally {
     setIsLoading(false);
    }
  };


  

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    if (password) {
      navigate(`/lockers/${token}/${tabName}?password=${password}`);
    } else {
      navigate(`/shared-with-me/lockers/${token}/${tabName}`);
    }
  };

  return (
    <div className="shared-locker-component">
      <div className="shared-locker-header">
        <h2>
          <i className="fas fa-lock"></i>
          <span>{name} {lockerName}</span>
        </h2>
        <ul className="shared-locker-tabs">
          <li
            className={`shared-locker-tab ${activeTab === 'all-items' ? 'active' : ''}`}
            onClick={() => handleTabClick('all-items')}
          >
            All Items
          </li>
          <li
            className={`shared-locker-tab ${activeTab === 'tables' ? 'active' : ''}`}
            onClick={() => handleTabClick('tables')}
          >
            Tables
          </li>
          <li
            className={`shared-locker-tab ${activeTab === 'files' ? 'active' : ''}`}
            onClick={() => handleTabClick('files')}
          >
            Files
          </li>
          <li
            className={`shared-locker-tab ${activeTab === 'links' ? 'active' : ''}`}
            onClick={() => handleTabClick('links')}
          >
            Links
          </li>
        </ul>
      </div>
      <div className="shared-locker-content">
      {activeTab === 'all-items' && (
          <div className="shared-lockerItems-table">
            {sharedObjects? (
              <TableComponent data={sharedObjects} lockerName={lockerName} isLoading={isLoading}/>
            ) : (
              <p>No shared objects found.</p>
            )}
          </div>
        )}

        {activeTab === 'tables' && (
          <div>Tables content for {name} (Owner_id: {owner_id})</div>
        )}
        {activeTab === 'files' && (
          <div>Files content for {name} (Owner_id: {owner_id})</div>
        )}
        {activeTab === 'links' && (
          <div>Links content for {name} (Owner_id: {owner_id})</div>
        )}
      </div>
    </div>
  );
};

export default LockerComponent;