import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import axios from 'axios';
import './sharedWithMe.css';
import { UserContext, useUser } from '../context/userContext';
import TableComponent from './sharedTable'; // Replace with the path to your shared


const SharedWithMe = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const password = searchParams.get('password');
    const { user } = useContext(UserContext);
    const userId = user?.userId;
    const [activeSharedTab, setactiveSharedTab] = useState('All Items');
    const [sharedLockers, setSharedLockers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const tabs = [
        { label: 'All Items', route: '/shared-with-me/all-items' },
        { label: 'Lockers', route: '/shared-with-me/lockers' },
        { label: 'Tables', route: '/shared-with-me/tables' },
        // { label: 'Files', route: '/shared-with-me/files' },
        // { label: 'Links', route: '/shared-with-me/links' },
    ];

    const isLockerRoute = location.pathname.includes('/shared-with-me/lockers/') && location.pathname.split('/').length > 4;


    console.log(sharedLockers)


    useEffect(() => {
        const currentPath = location.pathname;
        const activeSharedTab = tabs.find((tab) => currentPath.includes(tab.route.split('/')[2]));
        if (activeSharedTab) {
            setactiveSharedTab(activeSharedTab.label);
        } else {
            setactiveSharedTab('All Items');
            navigate('/shared-with-me/all-items');
        }
        fetchData()

    }, [location.pathname, navigate, activeSharedTab]);







    const fetchData = async () => {
        setIsLoading(true);
        if (activeSharedTab === 'Lockers' || activeSharedTab === 'All Items') {
            try {
                const response = await axios.get(`https://pages.onepgr.com/session/get-shared-objects?user_id=${userId}`);
                setSharedLockers(response.data.data);
                console.log(response.data.data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching shared lockers:', error);
                setIsLoading(false);
            }
        }
    };

    const handleTabClick = (tab) => {
        setactiveSharedTab(tab.label);
        if (token && password) {
            navigate(`${tab.route}?token=${token}&password=${password}`);
        } else {
            navigate(tab.route);
        }
    };


    return (
        <div className="shared-with-me-container">
            {!isLockerRoute && (
                <div className='shared-with-me-header'>
                    <p>Shared With Me</p>
                    <div className="shared-with-me-tab-container">
                        {tabs.map((tab, index) => (
                            <div
                                key={index}
                                className={`shared-with-me-tab ${activeSharedTab === tab.label ? 'active' : ''}`}
                                onClick={() => handleTabClick(tab)}
                            >
                                {tab.label}
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <Outlet context={{ activeSharedTab, sharedLockers, setSharedLockers, fetchData, isLoading, setIsLoading }} />
        </div>
    );
};

export default SharedWithMe;