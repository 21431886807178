import React, { useState, useContext, useEffect } from 'react';
import './uploadOverlay.css';
import { UserContext, useUser } from '../context/userContext';
import axios from 'axios';

function UploadOverlay({ onClose, onUpload, data, tableName }) {
  // const {lockerDetails} = useUser()
  const [fileName, setFileName] = useState('');
  const [selectedLocker, setSelectedLocker] = useState('');
  const [lockerDetails, setLockerDetails] = useState(null);
  const { user,formatDate} = useContext(UserContext);

  console.log(tableName)

  useEffect(() => {
    fetchLockerDetails();
    prepopulateFileName();
  }, []);

  const fetchLockerDetails = async () => {
    try {
      const response = await axios.get('https://pages.onepgr.com/session/get-data-bank-items', {
        params: { user_id: user?.userId },
      });
      if (response.data.data.length > 0) {
        setLockerDetails(response.data.data); // Store all locker details

      } else {
        console.log('No locker details found');
        setLockerDetails(null);

      }
    } catch (error) {
      console.error('Error fetching locker details:', error);
      setLockerDetails(null);
    }
  };
  const prepopulateFileName = () => {
    const timestamp = formatDate(new Date());
    const prepopulatedFileName = `${tableName}_${timestamp}`;
    setFileName(prepopulatedFileName);
  };

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const handleFileNameChange = (e) => {
    const capitalizedFileName = capitalizeWords(e.target.value);
    setFileName(capitalizedFileName);
  };

  const handleUpload = () => {
    onUpload(fileName, selectedLocker, data);
  };

  console.log(lockerDetails)
  return (
    <div className="upload-overlay">
      <div className="upload-overlay-content">
        <h3>Upload CSV to Locker</h3>
        <div className="form-group">
          <label htmlFor="fileName">Enter File Name:</label>
          <input
            type="text"
            id="fileName"
            value={fileName}
            onChange={handleFileNameChange}
          />
        </div>
        <div className="form-group dropdown-file-group">
          <label htmlFor="optionSelect">Select Locker:</label>
          <select
            id="optionSelect"
            value={selectedLocker}
            onChange={(e) => setSelectedLocker(e.target.value)}
          >
            <option value="">-- Select Locker --</option>
            {lockerDetails && lockerDetails.map((item) => (
              <option key={item._id} value={item.token}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="button-group">
          <button onClick={onClose} className='cancle-btn-uploadoverlay'>Cancel</button>
          <button onClick={handleUpload}>Upload</button>
        </div>
      </div>
    </div>
  );
}

export default UploadOverlay;