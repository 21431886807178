// SharedWithMeContent.js
import React, { useContext, useState }  from 'react';
import { useLocation } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom';
import TableComponent from './sharedTable';
import { UserContext } from '../context/userContext';
import axios from 'axios';

const SharedWithMeContent = () => {
    const { activeSharedTab, sharedLockers, setSharedLockers , fetchData , isLoading, setIsLoading} = useOutletContext();
    const { user } = useContext(UserContext);
    const userId = user?.userId;
    

    const handleDelete = async (itemId) => {
        console.log('Item ID:', itemId);
        setIsLoading(true);
        try {
            const item = sharedLockers.find(locker => locker._id === itemId);
            if (!item) {
                console.error('Item not found');
                return;
            }

            const updatedSharedUsers = item.shared_users.filter(id => id !== userId);

            const response = await axios.put(`https://pages.onepgr.com/session/update-data-bank-item/${itemId}`, {
                shared_users: updatedSharedUsers,
            });

            if (response.status === 200) {
                // Remove the item from the local state
                setSharedLockers(prevLockers => prevLockers.filter(locker => locker._id !== itemId));
                console.log('Item removed from shared list successfully');
                fetchData()
                setIsLoading(false);
            } else {
                throw new Error('Failed to update databank item');
                
            }
        } catch (error) {
            console.error('Error removing item from shared list:', error);
            // Handle error (e.g., show error message to user)
            setIsLoading(false);
        }
    };
  return (
    <div className="shared-with-me-tab-content">
      {activeSharedTab === 'All Items' && <div>Content for All Items tab</div>}
      {activeSharedTab === 'Lockers' && (
        <div>
          {Array.isArray(sharedLockers) ? (
            <TableComponent data={sharedLockers} onDelete={handleDelete} isLoading={isLoading}/>
          ) : (
            <div>No shared lockers found.</div>
          )}
        </div>
      )}
      {activeSharedTab === 'Tables' && <div>Content for Tables tab</div>}
    </div>
  );
};

export default SharedWithMeContent;