// RdsUploadComponent.js

import React, { useState, useContext, useEffect } from 'react';
// ... other imports
import TablesNavigationBar from '../search-nav/search-nav';
import FilterDropdown from './tablesSearch';
import './search.css'
import { Outlet } from 'react-router-dom';
import LinkSearch from './linkSearch';
import FileSearch from './filesSearch';
import LockerSearch from './lockerSearch';
import { useLocation } from 'react-router-dom';


function SearchSection() {
  const [activeNavTab, setActiveNavTab] = useState('search');
  const location = useLocation();
  const { lockerId } = location.state || {};
  

  return (
    <div>
      <TablesNavigationBar setActiveTab={setActiveNavTab} />
      <div className='all-search'>
      {activeNavTab === 'My Lockers' && (
       
      <LockerSearch lockerId={lockerId} />
     )}
      {activeNavTab === 'My Tables' && (
       
        <FilterDropdown/>
      )}
      {activeNavTab === 'My Files' && (
       
        <FileSearch/>
      )}

      {activeNavTab === 'My Links' && (
      
      <LinkSearch/>
      )}
    </div>
    <Outlet /> 
    </div>
  );
}

export default SearchSection;
