import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios'; // If you're using Axios
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faUserPlus, faTrashAlt, faArrowDown, faSyncAlt, faArrowUp, faEllipsisV, faSearch, faEdit, faCheck, faTimes, faCopy, faClipboard, faTable, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import './rdsFileRepo.css'
import io from 'socket.io-client';
import { UserContext, useUser } from '../context/userContext';
import Pagination from '../s3dataBase/fileList/pagination';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { useOutletContext, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import InviteOverlay from '../s3dataBase/fileList/shareitemComponent';

function RdsUploadComponent({ }) {
    const { user } = useContext(UserContext);
    const { lockerId } = useOutletContext();
    const { lokerId } = useParams()
    const [tables, setTables] = useState([]);
    const [selectedTable, setSelectedTable] = useState('');
    const [tableContent, setTableContent] = useState([]);
    const [isLoadingContent, setIsLoadingContent] = useState(false);
    const [isLoadingTables, setIsLoadingTables] = useState(true);
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [isOverlayLoading, setIsOverlayLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [isTableUpdated, setIsTableUpdated] = useState(false);
    const [sortOrder, setSortOrder] = useState('asc');
    const [newTag, setNewTag] = useState('');
    const [editableFileName, setEditableFileName] = useState('');
    const [tableDetails, setTableDetails] = useState({});
    const [currentFile, setCurrentFile] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [overlayInfoOpen, setOverlayInfoOpen] = useState(false);
    const [inviteUrl, setInviteUrl] = useState('');
    const [showInviteOverlay, setShowInviteOverlay] = useState(false)
    const [copyIcon, setCopyIcon] = useState(false);
    const navigate = useNavigate();
    const [displayedTables, setDisplayedTables] = useState([]);
    const [sortLastUpdated, setSortLastUpdated] = useState(null);
    const userId = user?.userId;
    const searchTimeout = useRef(null);
    const [selectedLockerToken, setSelectedLockerToken] = useState()
    const { lockerDetails, formatDate } = useUser();
    const [password, setPassword] = useState('');
    const [showDropdown, setShowDropdown] = useState(null);

    const [selectedLocker, setSelectedLocker] = useState(
        lockerDetails?.find((locker) =>
            locker.token === (currentFile?.locker_id)
        )?.name || ''
    );

    console.log(selectedLocker)

    useEffect(() => {
        const selectedLockerObj = lockerDetails?.find(
            (locker) => locker.name === selectedLocker
        );
        if (selectedLockerObj) {
            setSelectedLockerToken(selectedLockerObj.token);

        }
    }, [selectedLocker, lockerDetails, setSelectedLockerToken]);

    const toggleSortLastUpdated = () => {
        let sortedTables;
        if (sortLastUpdated === 'asc') {
            // Currently ascending, toggle to original
            sortedTables = [...displayedTables].sort((a, b) => new Date(b.date) - new Date(a.date));
            setSortLastUpdated('desc');
        } else {
            // Either descending or original, sort by ascending (oldest to newest)
            sortedTables = [...displayedTables].sort((a, b) => new Date(a.date) - new Date(b.date));
            setSortLastUpdated('asc');
        }
        setDisplayedTables(sortedTables);
    };


    // const predefinedQueries = [
    //     { label: "Select a query...", value: "" },
    //     { label: "Insert data into a table", value: "INSERT INTO <TableName> (<ColumnName1>, <ColumnName2>, <ColumnName3>, ..., <ColumnNameN>)\nVALUES (<Value1>, <Value2>, <Value3>, ..., <ValueN>);" },
    //     { label: "Select all data from a table", value: "SELECT * FROM <TableName>;" },
    //     { label: "Select specific columns from a table", value: "SELECT <ColumnName1>, <ColumnName2> FROM <TableName>;" },
    //     { label: "Update data in a table", value: "UPDATE <TableName>\nSET <ColumnName1> = '<NewValue1>', <ColumnName2> = '<NewValue2>'\nWHERE <ConditionColumnName> = '<ConditionValue>';" },
    //     { label: "Delete data from a table", value: "DELETE FROM <TableName> WHERE <ConditionColumnName> = '<ConditionValue>';" },
    //     { label: "Join two tables", value: "SELECT <TableName1>.<ColumnName1>, <TableName2>.<ColumnName2>\nFROM <TableName1>\nJOIN <TableName2> ON <TableName1>.<ColumnName> = <TableName2>.<ColumnName>;" },
    //     { label: "Aggregate function: Calculate average", value: "SELECT AVG(<ColumnName>) AS average_value FROM <TableName>;" },
    //     { label: "Aggregate function: Count rows", value: "SELECT COUNT(*) AS total_rows FROM <TableName>;" },
    //     { label: "Grouping data", value: "SELECT <ColumnName>, COUNT(*) AS count\nFROM <TableName>\nGROUP BY <ColumnName>;" },
    //     { label: "Alter table: Add column", value: "ALTER TABLE <TableName> ADD <NewColumnName> <DataType>;" },
    //     { label: "Alter table: Modify column", value: "ALTER TABLE <TableName> MODIFY <ColumnName> <NewDataType>;" },
    //     { label: "Alter table: Drop column", value: "ALTER TABLE <TableName> DROP COLUMN <ColumnName>;" },
    //     { label: "Drop a table", value: "DROP TABLE <TableName>;" }
    // ]


    useEffect(() => {
        if (tableContent.length > 0) {
            setIsTableUpdated(true);
        } else {
            setIsTableUpdated(false);
        }
    }, [tableContent]);

    useEffect(() => {
        const sortedTables = sortTables(displayedTables, sortOrder);
        setDisplayedTables(sortedTables);
    }, [sortOrder]);

    const toggleSort = () => {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };


    const sortTables = (tables, order) => {
        return tables.sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (order === 'asc') {
                return nameA.localeCompare(nameB);
            } else {
                return nameB.localeCompare(nameA);
            }
        });
    };



    const fetchTables = async () => {
        setIsLoadingTables(true);
        const baseURL = 'https://pages.onepgr.com/session/get-data-bank-tables';
        const queryParams = new URLSearchParams({
            user_id: userId, // Assuming you have a 'userId' state or context
            page_num: currentPage, // The current page number from your pagination state
            nitems: itemsPerPage, // Number of items per page from your pagination state
            locker_id: lockerId,
        });
        // Optionally add locker_id if provided


        try {
            const response = await axios.get(`${baseURL}?${queryParams.toString()}`);
            if (response.data && response.data.status === 1) {
                setTables(response.data.data);
                setDisplayedTables(response.data.data); // Also update displayedTables

                setTotalItems(response.data.total_items_count)


            } else {
                // Handle errors or unexpected responses
                setTables([]);
                setDisplayedTables([]);
            }
        } catch (error) {
            console.error('Error fetching tables:', error);
            setTables([]);
            setDisplayedTables([]);
        } finally {
            setIsLoadingTables(false);
        }
    };

    useEffect(() => {
        fetchTables();
    }, [currentPage, itemsPerPage, userId, lokerId]);


    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchQuery(value); // Update the searchQuery state instead of searchTerm

        if (searchTimeout.current) {
            clearTimeout(searchTimeout.current); // Clear the previous timeout using .current
        }

        // Set a new timeout and assign its ID to searchTimeout.current
        searchTimeout.current = setTimeout(() => {
            if (value.trim()) {
                performSearch(value.trim());
            } else {
                fetchTables(); // Reload the initial table list if the search term is empty
            }
        }, 200); // 200ms debounce time
    };


    const performSearch = async (query) => {
        setIsSearching(true);
        try {
            const url = `https://pages.onepgr.com/session/get-data-bank-tables?user_id=${userId}&locker_id=${lockerId}&search=${encodeURIComponent(query)}&page_num=${currentPage}&nitems=${itemsPerPage}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            const data = await response.json();
            console.log(data);
            // Assume the data object contains a field named `data` which is an array of table objects
            setDisplayedTables(data.data || []);
        } catch (error) {
            console.error('Fetch error:', error);
            setDisplayedTables([]); // In case of error, clear displayed tables
        } finally {
            setIsSearching(false);
        }
    };

    // const clearSearch = () => {
    //     setSearchQuery('');
    //     fetchTables(); // Assuming this function fetches the initial table list without search criteria
    // };





    // const handleFileChange = (event) => {
    //     const files = event.target.files;
    //     setSelectedFiles(files);
    //     setIsFilesSelected(files.length > 0);
    // };

    // const sendSelectedFilesToRDS = async () => {

    //     const formData = new FormData();
    //     for (let i = 0; i < selectedFiles.length; i++) {
    //         formData.append('fileKeys[]', selectedFiles[i].name); // Assuming the backend expects file names
    //     }

    //     try {
    //         const response = await fetch('https://crawl.onepgr.com:3002/upload-local', {
    //             method: 'POST',
    //             body: formData,
    //         });

    //         if (!response.ok) {
    //             throw new Error('Server responded with ' + response.status);
    //         }

    //         const result = await response.json();
    //         console.log('Files sent to RDS successfully:', result);
    //         // Reset state or handle success as needed
    //         setSelectedFiles([]);
    //         setIsFilesSelected(false);
    //     } catch (error) {
    //         console.error('Error sending files to RDS:', error);
    //     }
    // };


    const fetchTableContent = async (tableName) => {
        setIsLoadingContent(true);
        setIsOverlayVisible(true);
        try {
            const response = await axios.get(`https://crawl.onepgr.com:3002/fetch-table-data/${tableName}`);
            setTableContent(response.data);
            setSelectedTable(tableName);
        } catch (error) {
            console.error('Error fetching table content:', error);
        }
        setIsLoadingContent(false);
    };


    const toggleSearch = () => {
        console.log('search toggled')
    }

    // const handleSqlQueryChange = (e) => {
    //     setSqlQuery(e.target.value);
    // };

    // const executeSqlQuery = async () => {
    //     if (!sqlQuery.trim()) {
    //         setErrorMessage('Please enter a valid SQL query.');
    //         // Optionally, clear the error message after a few seconds
    //         setTimeout(() => {
    //             setErrorMessage('');
    //         }, 5000); // Clears the message after 5 seconds
    //         return;
    //     }
    //     const validSqlQueryRegex = /^(SELECT|INSERT|UPDATE|DELETE|ALTER|DROP)\s+/i;
    //     if (!validSqlQueryRegex.test(sqlQuery.trim())) {
    //         setErrorMessage('enter a valid SQL query.');
    //         // Optionally, clear the error message after a few seconds
    //         setTimeout(() => {
    //             setErrorMessage('');
    //         }, 5000); // Clears the message after 5 seconds
    //         return;
    //     }

    //     const tableNameRegex = /FROM\s+(\w+)/i;
    //     const match = sqlQuery.match(tableNameRegex);
    //     const tableNameInQuery = match ? match[1] : '';

    //     // Check if the table name in the query matches the currently selected table
    //     if (tableNameInQuery !== selectedTable) {
    //         setErrorMessage('SQL query table does not match the selected table.');
    //         setTimeout(() => {
    //             setErrorMessage('');
    //         }, 5000);
    //         return;
    //     }


    //     try {
    //         const response = await fetch('https://crawl.onepgr.com:3002/execute-query', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({ sqlQuery }),
    //         });

    //         if (!response.ok) throw new Error('Network response was not ok');

    //         const data = await response.json();
    //         console.log('Query executed, result:', data);

    //         // Clear the textarea and show a success message
    //         setSqlQuery('');
    //         setSuccessMessage('Query executed successfully!');

    //         // Optionally, clear the success message after a few seconds
    //         setTimeout(() => {
    //             setSuccessMessage('');
    //         }, 5000); // Clears the message after 5 seconds

    //         // Additional logic for handling SELECT queries or other operations...
    //     } catch (error) {
    //         console.error('Error executing SQL query:', error);
    //         setErrorMessage(`Error: ${error.message}`);
    //         // Optionally, clear the error message after a few seconds
    //         setTimeout(() => {
    //             setErrorMessage('');
    //         }, 5000);
    //     }
    // };



    // Listen for real-time updates
    // useEffect(() => {
    //     const socket = io(ENDPOINT, { secure: true, rejectUnauthorized: false });
    //     socket.on('update', () => {
    //         if (selectedTable) {
    //             fetchTableContent(selectedTable); // Refetch table content
    //         }
    //     });
    //     return () => socket.disconnect();
    // }, [selectedTable, fetchTableContent]);


    const deleteFile = async (fileId, tableName , dataBase) => {
        setIsOverlayLoading(true);
        const FileId = fileId
        const TableName = tableName
        const database = dataBase || 'rds'
        try {
            // Endpoint for deleting data by file ID
            const endpointOne = `https://pages.onepgr.com/session/delete-data-bank-table/${FileId}`;

            // Endpoint for deleting the table by table name
            const endpointTwo = `https://crawl.onepgr.com:3002/delete-table/${TableName}?database=${database}`; // Here fileIdOrTableName is interpreted as tableName

            // Make concurrent requests to both endpoints
            await Promise.all([
                axios.delete(endpointOne),
                axios.delete(endpointTwo)
            ]);
            setIsOverlayLoading(false);
            // Handle success
            fetchTables(); // Refresh the table list after deletion
            setSuccessMessage('File successfully deleted.'); // Set the success message
            setTimeout(() => setSuccessMessage(''), 3000); // Optionally clear the message after 3 seconds

        } catch (error) {
            console.error('Error deleting file or table:', error);
            // Handle error case
            setIsOverlayLoading(false);
        }
    };


    // const saveTableContentAsCsv = () => {
    //     // Convert table content to CSV format
    //     const csvContent = tableContent.map(row => Object.values(row).join(',')).join('\n');

    //     // Prompt user to enter a filename
    //     const fileName = window.prompt('Enter a filename for the CSV file:', 'altered_table.csv');

    //     // If the user cancels or enters an empty filename, do nothing
    //     if (!fileName) return;

    //     // Create a Blob containing the CSV data
    //     const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

    //     // Create a temporary anchor tag
    //     const anchor = document.createElement('a');
    //     anchor.href = URL.createObjectURL(blob);
    //     anchor.download = fileName;

    //     // Trigger the download
    //     anchor.click();

    //     // Clean up
    //     URL.revokeObjectURL(anchor.href);
    // };

    const handleTableClick = (tableName, database) => {
        navigate(`/tables/${tableName}`, { state: { database } });
    };


    const openOverlayWithFileInfo = async (table) => {
        setCurrentFile(table);
        setOverlayInfoOpen(true);

        // Fetch file information from the API
        try {
            const response = await axios.get(`https://pages.onepgr.com/session/get-data-bank-table-info?user_id=${table.user_id}&token=${table.token}`);
            const tableInfo = response.data.data.table_info;
            console.log(tableInfo)
            setTableDetails({
                ...tableDetails,

                [table._id]: tableInfo ? {
                    comments: tableInfo.comments ?? '',
                    companyInfo: tableInfo.company_info ?? '',
                    tags: tableInfo.tags ? (typeof tableInfo.tags === 'string' ? tableInfo.tags.split(', ') : [tableInfo.tags]) : []
                } : {
                    comments: '',
                    companyInfo: '',
                    tags: []
                }
            });

        } catch (error) {
            console.error('Error fetching table information:', error);
            // Handle errors
        }
    };


    const openOverlayWithEditFileDetails = (table) => {
        setCurrentFile(table);

        // Set this to true to open the overlay in edit mode directly
        setIsEditMode(true);

        // Ensure file details for the current file are initialized
        if (!tableDetails[table._id]) {
            setTableDetails({
                ...tableDetails,
                [table._id]: { comments: '', companyInfo: '', tags: [] }
            });
        }
        setOverlayInfoOpen(true);
    };

    const updateFileDetails = (tableId, detailType, value) => {
        setTableDetails(prev => ({
            ...prev,
            [tableId]: {
                ...prev[tableId],
                [detailType]: value,
            },
        }));
    };

    const removeTag = (tableId, tagIndex) => {
        const updatedTags = [...tableDetails[tableId].tags];
        updatedTags.splice(tagIndex, 1); // Remove the tag at tagIndex
        updateFileDetails(tableId, 'tags', updatedTags);
    };



    const addTag = (newTagValue) => {
        // Guard clause to check if currentFile is set and newTagValue is not empty
        if (!currentFile || !newTagValue.trim()) return;

        const tableId = currentFile._id; // ID of the current file
        const existingTags = tableDetails[tableId]?.tags || []; // Current tags or an empty array if none

        // Create a new array with the existing tags plus the new one
        const updatedTags = [...existingTags, newTagValue.trim()];

        // Update fileDetails state with the new array of tags
        setTableDetails(prevDetails => ({
            ...prevDetails,
            [tableId]: {
                ...prevDetails[tableId],
                tags: updatedTags
            }
        }));
    };

    const toggleEditMode = () => {
        setIsEditMode((prevMode) => !prevMode);
    };

    const closeOverlayInfo = () => {
        setOverlayInfoOpen(false);
        setIsEditMode(false); // Optionally reset edit mode to off when closing the overlay
    };

    const saveChanges = async () => {
        setIsOverlayLoading(true);
        setOverlayInfoOpen(false);
        const tableId = currentFile._id;
        updateFileDetails(currentFile._id, 'name', editableFileName);
        const currentDateTime = new Date().toISOString();
        const lockerToken = selectedLockerToken

        const requestBody = {
            name: currentFile.source_url,
            type: "Check",
            table_info: {
                comments: tableDetails[tableId]?.comments || '',
                company_info: tableDetails[tableId]?.companyInfo || '',
                tags: tableDetails[tableId]?.tags.join(', ') || '' // Assuming tags are stored as an array
            },
            date: currentDateTime
        };

        if (password) {
            requestBody.password = password;
        }

        if (lockerToken) {
            requestBody.locker_id = lockerToken;
        }

        try {
            const response = await axios.put(`https://pages.onepgr.com/session/update-data-bank-table/${tableId}`, requestBody);

            console.log(response.data);

            const updatedFileDetails = { ...tableDetails, [tableId]: requestBody.file_info };
            setTableDetails(updatedFileDetails);
            setIsOverlayLoading(false);
            fetchTables()
            setSuccessMessage("File info updated successfully!");
            setTimeout(() => setSuccessMessage(''), 3000);
        } catch (error) {

            console.error("Error updating file info:", error);
            setErrorMessage("Failed to update file info.");
            setTimeout(() => setErrorMessage(''), 3000);
        }
    };

    const handleInviteTableClick = (table) => {
        setOverlayInfoOpen(false)
        // Construct the URL with current domain and table name
        const currentDomain = window.location.origin;
        const name = table.name;
        const inviteTablePath = `/tables/${name}`;
        const constructedTableUrl = `${currentDomain}${inviteTablePath}`;

        // Set the constructed table URL to the state
        setInviteUrl(constructedTableUrl);

        // Show the invite overlay for table
        setShowInviteOverlay(true);
    };

    const closeInviteOverlay = () => {
        setShowInviteOverlay(false);
    };
    const handleCopyClick = () => {
        const constructedUrl = inviteUrl;

        const textarea = document.getElementById('inviteTextarea');
        if (textarea) {
            textarea.value = constructedUrl;
            textarea.select();
            document.execCommand('copy');

            // Set copyIcon to true to display the tick icon
            setCopyIcon(true);

            // Revert to the copy icon after 2 seconds
            setTimeout(() => {
                setCopyIcon(false);
            }, 2000);
        }
    };
    const handleAdvancedSearch = () => {
        navigate('/search', { state: { lockerId } });
    };

    const handleMyFilesClick = () => {
        console.log('Locker ID:', lockerId);
        const url = `/home/lockers/${lockerId}/MyFiles`;
        console.log('Navigating to:', url); // Log the URL for debugging
        navigate(url);
    };

    const toggleDropdown = (event, fileId) => {
        event.stopPropagation();
        setShowDropdown(prevId => prevId === fileId ? null : fileId);;
    };


    return (


        <div className='rdsTables tables-container'>
            {successMessage && (
                <div className="success-message">
                    {successMessage}
                </div>
            )}
            {errorMessage && (
                <div className="error-message">
                    {errorMessage}
                </div>
            )}

            {/* <div className='tablehead-hr'>
                <p className="rdslabel">My Tables </p>
                <hr />
            </div> */}
            <div className='search-icons'>

                <div className="search-bar table-search-bar" style={{ display: 'flex' }}> {/* Adjusted for consistency, though conditional rendering makes 'style' unnecessary */}
                    <div className="input-container">
                        <div className="icon-card searchIcon" onClick={toggleSearch}>
                            <FontAwesomeIcon icon={faSearch} />
                        </div>
                        <input
                            type="text"
                            id="searchInput"
                            placeholder="Search Tables..."
                            value={searchQuery}
                            onChange={handleSearchChange} // Updated handler name

                            className='search-input'
                        />
                        {/* <i className="fas fa-times clear-icon" onClick={clearSearch}></i> */}
                    </div>
                    <button className='advanced-search-button table-advanced-search-btn' onClick={handleAdvancedSearch}>Advanced Search</button>
                </div>



                <div className="RDS-icon-container">


                    <OverlayTrigger
                        key="top-refresh"
                        placement="top"
                        overlay={<Tooltip id={`tooltip-top-refresh`}>Refresh Tables List</Tooltip>}
                    >
                        <div className="icon-card" onClick={fetchTables}>
                            <FontAwesomeIcon icon={faSyncAlt} />
                        </div>
                    </OverlayTrigger>


                </div>
            </div>

            <div className='rdsfiles'>
                <div className="table-header">
                    <span>
                        Name
                        <FontAwesomeIcon
                            icon={sortOrder === 'asc' ? faArrowDown : faArrowUp}
                            className="sort-icon"
                            onClick={toggleSort}
                        />
                    </span>
                    <span onClick={toggleSortLastUpdated}>
                        Last Updated {sortLastUpdated === 'asc' ? <FontAwesomeIcon icon={faArrowUp} className='sort-icon' /> : <FontAwesomeIcon icon={faArrowDown} className='sort-icon' />}
                    </span>
                    <span>Actions</span>
                </div>
                {isLoadingTables ? (
                    <div className="spinner"></div>
                ) : (
                    <>
                        {displayedTables && displayedTables.length > 0 ? (
                            <ul className="table-list">
                                {displayedTables.map((table) => (
                                    <li key={table._id} className='rdstable-item '>
                                        <div className='tablename-icon' title={table.source_url}>
                                            <FontAwesomeIcon icon={faTable} className="icon-folder-lock table-icon" />
                                            <span className='rdstablename' onClick={() => handleTableClick(table.name, table.database)}>{table.source_url}</span>
                                        </div>
                                        <span className='table-date'>{formatDate(table.date)}</span>
                                        <div className="table-actions-icon-rds">
                                            <div className='actions-icon-full'>
                                                <OverlayTrigger
                                                    key={`top-info-${table._id}-tableinformation`}
                                                    placement="top"
                                                    overlay={<Tooltip id={`tooltip-top-info`}>Table Information</Tooltip>}
                                                >
                                                    <Button variant="light" onClick={() => openOverlayWithFileInfo(table)}>
                                                        <FontAwesomeIcon icon={faInfoCircle} />
                                                    </Button>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip id={`tooltip-view`}>View Table</Tooltip>}
                                                >
                                                    <Button variant="light" onClick={(event) => {
                                                        event.stopPropagation();
                                                        handleTableClick(table.name)
                                                    }}>
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </Button>
                                                </OverlayTrigger>

                                                <OverlayTrigger
                                                    key={`top-info-${table._id}-editTableinfo`}
                                                    placement="top"
                                                    overlay={<Tooltip id={`tooltip-top-info`}>Edit Table Information</Tooltip>}
                                                >
                                                    <Button variant="light" onClick={() => openOverlayWithEditFileDetails(table)}>
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </Button>
                                                </OverlayTrigger>



                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip id={`tooltip-invite`}>Share</Tooltip>}
                                                >
                                                    <Button variant="light" onClick={(event) => {
                                                        event.stopPropagation();
                                                        handleInviteTableClick(table)
                                                    }}>
                                                        <FontAwesomeIcon icon={faUserPlus} />
                                                    </Button>
                                                </OverlayTrigger>


                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip id={`tooltip-delete`}>Delete</Tooltip>}
                                                >
                                                    <Button variant="light" onClick={(event) => {
                                                        event.stopPropagation();
                                                        deleteFile(table._id, table.name, table.database);
                                                    }}>
                                                        <FontAwesomeIcon icon={faTrashAlt} />
                                                    </Button>
                                                </OverlayTrigger>
                                            </div>
                                            <div className="actions-icon-ellipsis">
                                                <OverlayTrigger
                                                    key={`top-more-${table._id}`}
                                                    placement="top"
                                                    overlay={<Tooltip id={`tooltip-top-more`}>More</Tooltip>}
                                                >
                                                    <Button variant="light" onClick={(event) => toggleDropdown(event, table._id)}>
                                                        <FontAwesomeIcon icon={faEllipsisV} />
                                                    </Button>
                                                </OverlayTrigger>

                                                {showDropdown === table._id && (
                                                    <div className="actions-dropdown">
                                                        <div className="actions-dropdown-icon-button">
                                                            <Button variant="light"
                                                                onClick={() => openOverlayWithFileInfo(table)}

                                                            >
                                                                <FontAwesomeIcon icon={faInfoCircle} className='dropdown-menu-icon' />
                                                                <p>   View Table Details</p>
                                                            </Button>
                                                        </div>
                                                        <div className="actions-dropdown-icon-button">
                                                            <Button variant="light" onClick={(event) => {
                                                                event.stopPropagation();
                                                                handleTableClick(table.name)
                                                            }}>
                                                                <FontAwesomeIcon icon={faEye} className='dropdown-menu-icon' />
                                                                <p>   View Table</p>
                                                            </Button>
                                                        </div>
                                                        <div className="actions-dropdown-icon-button">
                                                            <Button variant="light"
                                                                onClick={() => openOverlayWithEditFileDetails(table)}
                                                            >
                                                                <FontAwesomeIcon icon={faEdit} className='dropdown-menu-icon' />
                                                                <p>Edit Table Information</p>
                                                            </Button>
                                                        </div>
                                                        <div className="actions-dropdown-icon-button">
                                                            <Button variant="light" onClick={(event) => {
                                                                event.stopPropagation();
                                                                handleInviteTableClick(table)
                                                            }}>
                                                                <FontAwesomeIcon icon={faUserPlus} className='dropdown-menu-icon' />
                                                                <p>Share</p>
                                                            </Button>

                                                        </div>
                                                        <div className="actions-dropdown-icon-button">
                                                            <Button variant="light" onClick={(event) => {
                                                                event.stopPropagation();
                                                                deleteFile(table._id, table.name, table.database);
                                                            }}>
                                                                <FontAwesomeIcon icon={faTrashAlt} className='dropdown-menu-icon' />
                                                                <p>Delete</p>
                                                            </Button>
                                                        </div>

                                                    </div>
                                                )}
                                            </div>


                                            {showInviteOverlay && (
                                                <InviteOverlay
                                                    showInviteOverlay={showInviteOverlay}
                                                    closeInviteOverlay={closeInviteOverlay}
                                                    inviteUrl={inviteUrl}
                                                    handleCopyClick={handleCopyClick}
                                                    copyIcon={copyIcon}
                                                />
                                            )}


                                            {overlayInfoOpen && currentFile && (
                                                <div className="fileInfo-overlay">
                                                    <div className="fileInfo-overlay-content" >
                                                        <h3>{isEditMode ? "Edit Table Details:" : "View Table Details:"}</h3>
                                                        <div className='editname'>
                                                            <p> {currentFile.source_url}</p>
                                                        </div>
                                                        <div className='locker-lable-content'>
                                                            <label className='label-locker-name'>Locker:</label>
                                                            {isEditMode ? (
                                                                <select
                                                                    value={selectedLocker}
                                                                    onChange={(e) => setSelectedLocker(e.target.value)}
                                                                    className="locker-dropdown"
                                                                >
                                                                    <option value=""> {lockerDetails.find((locker) =>
                                                                        locker.token === (currentFile?.locker_id)

                                                                    )?.name}
                                                                    </option>
                                                                    {lockerDetails?.map((locker) => (
                                                                        <option key={locker.token} value={locker.name}>
                                                                            {locker.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            ) : (
                                                                <span className='locker-name-dropdown'>
                                                                    {lockerDetails.find((locker) =>
                                                                        locker.token === (currentFile?.locker_id)

                                                                    )?.name}

                                                                </span>
                                                            )}
                                                        </div>
                                                        {isEditMode && (
                                                            <div className='file-password-label-content'>
                                                                <label className='file-label-password'>Password:</label>
                                                                <input
                                                                    type="text"
                                                                    value={password !== null ? password : currentFile?.password || ''}
                                                                    onChange={(e) => setPassword(e.target.value)}
                                                                    className="file-password-input"
                                                                    placeholder='Add Password'
                                                                />
                                                            </div>
                                                        )}
                                                        <hr />
                                                        <div className='form-content'>
                                                            <div className='editmode-icon-share-edit'>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip id={`tooltip-invite`}>Share</Tooltip>}
                                                                >
                                                                    <Button variant="light" onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        handleInviteTableClick(table)
                                                                    }}>
                                                                        <FontAwesomeIcon icon={faUserPlus} />
                                                                    </Button>
                                                                </OverlayTrigger>
                                                                <OverlayTrigger
                                                                    key={`top-info-${currentFile?._id}-edit`}
                                                                    placement="top"
                                                                    overlay={<Tooltip id={`tooltip-top-edit`}>Edit</Tooltip>}
                                                                >
                                                                    <Button variant="light" className='editinfobtn' onClick={toggleEditMode}>
                                                                        <FontAwesomeIcon icon={faEdit} />
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div className='info-form-group '>
                                                                <label>Comments:</label>
                                                                <textarea
                                                                    value={tableDetails[currentFile._id]?.comments || ''}
                                                                    onChange={(e) => updateFileDetails(currentFile._id, 'comments', e.target.value)}
                                                                    readOnly={!isEditMode}
                                                                    className={isEditMode ? 'textarea-editable' : 'textarea-readonly'}
                                                                />
                                                            </div>
                                                            <div className='info-form-group '>
                                                                <label>Company Info:</label>
                                                                <textarea
                                                                    value={tableDetails[currentFile._id]?.companyInfo || ''}
                                                                    onChange={(e) => updateFileDetails(currentFile._id, 'companyInfo', e.target.value)}
                                                                    readOnly={!isEditMode}
                                                                    className={isEditMode ? 'textarea-editable' : 'textarea-readonly'}
                                                                />
                                                            </div>
                                                            <div className={`info-form-group ${isEditMode ? 'editing-mode' : ''}`}>
                                                                <label>Tags:</label>
                                                                {isEditMode && (
                                                                    <input
                                                                        className="input-tag"
                                                                        type="text"
                                                                        value={newTag}
                                                                        onChange={(e) => setNewTag(e.target.value)}
                                                                        readOnly={!isEditMode}
                                                                        onKeyPress={(e) => {
                                                                            if (e.key === 'Enter') {
                                                                                addTag(newTag);
                                                                                setNewTag('');
                                                                                e.preventDefault();
                                                                            }
                                                                        }}
                                                                    />
                                                                )}

                                                                <div className="tag-info">
                                                                    {currentFile &&
                                                                        tableDetails[currentFile._id]?.tags &&
                                                                        tableDetails[currentFile._id].tags.map((tag, index) => (
                                                                            <span key={index} className="tag-tile tag-tile-rds">
                                                                                {tag}
                                                                                {isEditMode && (
                                                                                    <span
                                                                                        className="tag-remove-btn tag-remove-btn-rds"
                                                                                        onClick={() => removeTag(currentFile._id, index)}
                                                                                    >
                                                                                        ×
                                                                                    </span>
                                                                                )}
                                                                            </span>
                                                                        ))}
                                                                </div>
                                                            </div>
                                                            {isEditMode && (
                                                                <div className='btn-fileinfo'>
                                                                    <Button className='doneFileinfo' variant="primary" onClick={saveChanges}>Done</Button>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <button onClick={closeOverlayInfo} className='x-btn' style={{ position: 'absolute', top: '20px', right: '20px', fontSize: '20px' }}>X</button>
                                                    </div>
                                                </div>
                                            )}

                                        </div>

                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div>
                                <p className="no-tables-found">No tables found.</p>
                                {/* <p>
      Go to <span onClick={handleMyFilesClick}>MyFiles</span> and select file(s) to create a table and get started
    </p> */}
                            </div>
                        )}
                    </>
                )}
            </div>



            <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={totalItems}
                paginate={setCurrentPage}
                currentPage={currentPage}
                setItemsPerPage={setItemsPerPage} // Pass the state setter as a prop
            />
            {isOverlayLoading && (
                <div className="overlayLoading">
                    <div className="overlayspinner"></div>
                </div>
            )}

            <Outlet />
        </div>


    );
}

export default RdsUploadComponent;
