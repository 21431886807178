import React, { useState, useEffect } from 'react';
import './loginForm.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Notification from '../notification /notification';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../context/userContext';



const Login = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const location = useLocation();
    const [loginData, setLoginData] = useState({
        email: '',
        password: '',
        loginError: '',
        loginSuccess: '',
    });
   
    const { user, loginUser, isLoading: contextLoading } = useContext(UserContext);


    // useEffect(() => {
    //     const userData = localStorage.getItem('user');
    //     if (userData) {
    //         // If user data is found in local storage, log in the user
    //         loginUser(JSON.parse(userData));

    //     }
    // }, [loginUser]);

    useEffect(() => {
        if (user) {
            navigateAfterLogin();
        }
    }, [user]);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }
    // const { setUser } = useContext(UserContext);

    // const handleLogin = async () => {
    //     setIsLoading(true);
    
    //     try {
    //         const url = 'https://onepgr.com/sessions/create3?xhr_flag=1';
    
    //         const body = new FormData();
    //         body.append('login', loginData.email);
    //         body.append('password', loginData.password);
    //         body.append('clientname', '11');
    //         body.append('clientappid', '22');
    //         body.append('clientappkey', '33');
    //         body.append('service_name', 'app2');
    
    //         const response = await axios.post(url, body, {
    //             withCredentials: true,
    //             headers: {
    //                 'Content-Type': 'multipart/form-data'
    //             }
    //         });
           
    //         const parsedResponse = JSON.parse(response.data.response);
    //         console.log("Parsed Response:", parsedResponse);
    
    //         if (parsedResponse.success === "0" && parsedResponse.session_token) {
    //             const userData = {
    //                 name: parsedResponse.user_login,
    //                 profilePic: parsedResponse.user_profile_img,
    //                 userId: parsedResponse.user_id,
    //                 sessionToken: parsedResponse.session_token
    //             };
    
    //             await handleLoginSuccess(userData);
                
    //             // localStorage.setItem('profileAttributes', JSON.stringify(parsedResponse))
    
    //             setLoginData(prevState => ({
    //                 ...prevState,
    //                 loginSuccess: 'Successfully logged in.',
    //                 loginError: ''
    //             }));
    
    //             setIsTransitioning(true);
    
    //             const searchParams = new URLSearchParams(location.search);
    //             const returnTo = searchParams.get('returnTo');
    
    //             // Navigate to the returnTo URL if it exists, otherwise go to home
    //             setTimeout(() => {
    //                 if (returnTo) {
    //                     // Parse the returnTo URL
    //                     const returnToUrl = new URL(returnTo, window.location.origin);
    //                     // Get the password from the original URL, if it exists
    //                     const password = new URLSearchParams(returnToUrl.search).get('password');
    //                     // Navigate to the returnTo URL, including the password if it exists
    //                     navigate(returnTo, { state: { password } });
    //                 } else {
    //                     navigate('/home');
    //                 }
    //             }, 2000);
    
    //         } else {
    //             setLoginData(prevState => ({ 
    //                 ...prevState, 
    //                 loginError: 'Username or password is incorrect.' 
    //             }));
    //             setTimeout(() => {
    //                 setLoginData(prevState => ({ ...prevState, loginError: '' }));
    //             }, 5000);
    //         }
    //     } catch (error) {
    //         console.error('Error during login:', error);
    //         setLoginData(prevState => ({
    //             ...prevState,
    //             loginError: 'An error occurred during login. Please try again later.',
    //         }));
    //         setTimeout(() => {
    //             setLoginData(prevState => ({ ...prevState, loginError: '' }));
    //         }, 5000);
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };

    const handleLogin = async () => {
        setIsLoading(true);
    
        try {
            const url = 'https://onepgr.com/sessions/create3?xhr_flag=1';
    
            const body = new FormData();
            body.append('login', loginData.email);
            body.append('password', loginData.password);
            body.append('clientname', '11');
            body.append('clientappid', '22');
            body.append('clientappkey', '33');
            body.append('service_name', 'app2');
    
            const response = await axios.post(url, body, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
           
            const parsedResponse = JSON.parse(response.data.response);
            console.log("Parsed Response:", parsedResponse);
    
            if (parsedResponse.success === "0" && parsedResponse.session_token) {
                const userData = {
                    name: parsedResponse.user_login,
                    profilePic: parsedResponse.user_profile_img,
                    userId: parsedResponse.user_id,
                    sessionToken: parsedResponse.session_token
                };
    
                await handleLoginSuccess(userData);
    
                setLoginData(prevState => ({
                    ...prevState,
                    loginSuccess: 'Successfully logged in.',
                    loginError: ''
                }));
    
                setIsTransitioning(true);
                setTimeout(navigateAfterLogin, 2000);
    
            } else {
                setLoginData(prevState => ({ 
                    ...prevState, 
                    loginError: 'Username or password is incorrect.' 
                }));
                setTimeout(() => {
                    setLoginData(prevState => ({ ...prevState, loginError: '' }));
                }, 5000);
            }
        } catch (error) {
            console.error('Error during login:', error);
            setLoginData(prevState => ({
                ...prevState,
                loginError: 'An error occurred during login. Please try again later.',
            }));
            setTimeout(() => {
                setLoginData(prevState => ({ ...prevState, loginError: '' }));
            }, 5000);
        } finally {
            setIsLoading(false);
        }
    };

    // const handleLoginSuccess = (userData) => {
    //     console.log("User data before setting to localStorage:", userData);
    //     setUser(userData);
    //     localStorage.setItem('user', JSON.stringify(userData));

    // };

    const handleLoginSuccess = async (userData) => {
        console.log("User data before setting to localStorage:", userData);
        loginUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));

        const profileAttributes = await fetchProfileAttributes(userData.userId);
    
        if (profileAttributes) {
            localStorage.setItem('profileAttributes', JSON.stringify(profileAttributes));
        }

        const searchParams = new URLSearchParams(location.search);
        const returnTo = searchParams.get('returnTo');
        const password = location.state?.password;

        if (returnTo) {
            navigate(returnTo, { state: { password } });
        } else {
            navigate('/home/all-items');
        }
    };
    

    // const handleLoginSuccess = async (userData) => {
    //     console.log("User data before setting to localStorage:", userData);
    //     loginUser(userData);
    // };
   

    const fetchProfileAttributes = async (userId, sessionToken) => {
        console.log('Fetching profile attributes for user:', userId);
        const body = new FormData();
        body.append("id", userId);

        try {
            const response = await axios.post(
                'https://onepgr.com/users/get_profile_attributes?onepgr_apicall=1&xhr_flag=1',
                body,
                { withCredentials: true }
            );
            
            const parsedResponse = JSON.parse(response.data.response);
            console.log('Profile Attributes Response:', parsedResponse);

            // Parse nested JSON strings
            const parsedAttributes = {
                ...parsedResponse,
                api_keys: parsedResponse.api_keys ? JSON.parse(parsedResponse.api_keys) : null,
                app_flags: parsedResponse.app_flags ? JSON.parse(parsedResponse.app_flags) : null,
                profile_items: parsedResponse.profile_items ? JSON.parse(parsedResponse.profile_items) : null,
                session_flags: parsedResponse.session_flags ? JSON.parse(parsedResponse.session_flags) : null,
                session_flags2: parsedResponse.session_flags2 ? JSON.parse(parsedResponse.session_flags2) : null
            };

            // Store parsed attributes in localStorage
            localStorage.setItem('parsedProfileAttributes', JSON.stringify(parsedAttributes));
           
            return parsedAttributes;
        } catch (error) {
            console.error('Error fetching profile attributes:', error);
            return null;
        }
    };

    const navigateAfterLogin = () => {
        const searchParams = new URLSearchParams(location.search);
        const returnTo = searchParams.get('returnTo');
        const password = location.state?.password;

        if (returnTo) {
            navigate(returnTo, { state: { password } });
        } else {
            navigate('/home/all-items');
        }
    };

    return (
        <div className='login-container-div '>
            {isLoading || isTransitioning ? (
                <div className="spinner-container">
                    <div className="login-spinner"></div>
                </div>
            ) : (
                <>
                    <div className="login-card">
                        <h2 className="login-heading">Login</h2>
                        <div className='login-inputs'>
                            <div className="input-container-login">
                                <label>Email <span>(or username)</span></label>
                                <div className="input-with-icon">
                                    <input type="text" placeholder="Enter your email or username" value={loginData.email}
                                        onChange={(e) => setLoginData({ ...loginData, email: e.target.value })} />
                                </div>
                            </div>
                            <div className="input-container-login">
                                <label className='label'>Password</label>
                                <div className="input-with-icon input-with-icon-password">
                                    <input type={showPassword ? 'text' : 'password'} placeholder="Enter your password" value={loginData.password}
                                        onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
                                    />
                                    <FontAwesomeIcon
                                        icon={showPassword ? faEyeSlash : faEye}
                                        className="eye-icon"
                                        onClick={togglePasswordVisibility}
                                    />
                                </div>
                            </div>
                        </div>
                        <button className="login-button" onClick={handleLogin}>Log in</button>
                        <a href="#" className="forgot-password">Forgot password?</a>
                        <p className='dont'>Don't have an account? <Link to="https://meet.onepgr.com/signup" className="sign-up">Sign Up</Link></p>
                    </div>

                    {loginData.loginError && (
                        <Notification type="error" message={loginData.loginError} />
                    )}
                    {loginData.loginSuccess && (
                        <Notification type="success" message={loginData.loginSuccess} />
                    )}

                </>
            )}
        </div>

    );
};

export default Login;
