import React, { useEffect, useState } from 'react';
import './viewFile.css'; 
import logo from '../../images/onepgr-logo.webp';

const ViewFileComponent = () => {
    const [fileContent, setFileContent] = useState({ url: '', type: 'unsupported' });

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const user_id = params.get('user_id');
        const token = params.get('token');
    
        fetch(`https://pages.onepgr.com/session/get-data-bank-file-info?user_id=${user_id}&token=${token}`)
            .then(response => response.json())
            .then(data => {
                if (data.status === 1) {
                    const fileKey = data.data.source_url;
                    const region = 'us-west-2';
                    const bucketName = 'onepgr-databank-bucket';
    
                    // Decode if necessary
                    const isEncoded = decodeURIComponent(fileKey) !== fileKey;
                    const decodedFileKey = isEncoded ? decodeURIComponent(fileKey) : fileKey;
    
                    // Split the URL to extract only the key part if it's a full URL
                    const splitUrl = decodedFileKey.split('/');
                    const keyPath = splitUrl.slice(3).join('/');
    
                    // Re-encode the necessary parts
                    const encodedFileKey = encodeURIComponent(keyPath);
                    const fileUrl = `https://${bucketName}.s3.${region}.amazonaws.com/${encodedFileKey}`;
    
                    let fileType = 'unsupported';
    
                    // Determine the file type based on the keyPath
                    if (keyPath.match(/\.(jpe?g|png|gif|svg)$/i)) {
                        fileType = 'image';
                    } else if (keyPath.endsWith('.txt')) {
                        fileType = 'text';
                    } else if (keyPath.match(/\.(xls|xlsx|csv)$/i)) {
                        fileType = 'xls';
                    } else if (keyPath.match(/\.(ppt|pptx)$/i)) {
                        fileType = 'ppt';
                    } else if (keyPath.endsWith('.pdf')) {
                        fileType = 'pdf';
                    } else if (keyPath.match(/\.(mp4|webm|ogg)$/i)) {
                        fileType = 'video';
                    }
    
                    setFileContent({ url: fileUrl, type: fileType });
         
                } else {
                    console.error('Error: Unable to fetch file data');
                    // Handle error
                }
            })
            .catch(error => {
                console.error('Error fetching file data:', error);
                // Handle errors, e.g., display an error message to the user
            });
    }, []); // Empty dependency array to run the effect only once

    return (
        <div className='ViewFileComponent'>
            <nav className="navbar">
                <div className="logo">  
                    <img src={logo} alt="Description of the image" />
                </div>
            </nav>

            <div className="view-file-page">
                <div className="view-file-content">
                    {fileContent.type === 'image' && (
                        <img src={fileContent.url} alt="File" />
                    )}
                    {fileContent.type === 'text' && (
                        <iframe src={fileContent.url} title="Text File"></iframe>
                    )}
                    {fileContent.type === 'xls' && (
                        <iframe
                            className="xls-iframe"
                            src={`https://docs.google.com/gview?url=${encodeURIComponent(fileContent.url)}&embedded=true`}
                            frameBorder="0">
                        </iframe>
                    )}
                    {fileContent.type === 'pdf' && (
                        <iframe src={fileContent.url} title="PDF File"></iframe>
                    )}
                    {fileContent.type === 'ppt' && (
                        <p>PPT files cannot be previewed directly. <a href={fileContent.url} target="_blank" rel="noopener noreferrer">Download file</a>.</p>
                    )}
                    {fileContent.type === 'video' && (
    <video controls className='video-player' autoPlay muted={false}>
        <source src={fileContent.url} type="video/mp4" />
        Your browser does not support the video tag.
    </video>
)}
                    {fileContent.type === 'unsupported' && (
                        <p>Unsupported file type.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ViewFileComponent;
