



import React, { useState, useContext, useEffect } from 'react';
import { FaDatabase, FaProjectDiagram, FaWrench, FaLock, FaCaretDown, FaCaretRight, FaHome } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faTable, faSearch, faExchangeAlt, faLink, faTrash, faUserFriends, faFile, faLock, faDatabase, faMagic, faMagicWandSparkles, faWandMagicSparkles, faGears, faGear } from '@fortawesome/free-solid-svg-icons';
import './sideBar.css';
import { UserContext, useUser } from '../context/userContext';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { debounce } from 'lodash';



const Sidebar = ({ onMenuClick }) => {
  const { user } = useContext(UserContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const { logoutUser } = useContext(UserContext);
  const { isSidebarCollapsed, toggleSidebar } = useUser();
  const [lockers, setLockers] = useState([]);
  const [activeLockerToken, setActiveLockerToken] = useState('');
  const { setSelectedLocker } = useUser();
  const [showDatabaseSubmenu, setShowDatabaseSubmenu] = useState(false);
  const [showAppsSubmenu, setShowAppsSubmenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState('');
  const [persistedActiveMenu, setPersistedActiveMenu] = useState('');
  const [expandedLocker, setExpandedLocker] = useState(null);
  const [fetchedLockers, setFetchedLockers] = useState([]);
  const userId = user?.userId;
  const [expandedMenu, setExpandedMenu] = useState(null);
  const location = useLocation();
  const { selectLocker } = useUser();
  const [activeSubMenus, setActiveSubMenus] = useState({});
  // Replace setActiveMenu and setActiveLockerToken with a more integrated state if they are not integrated yet
  const [activeItem, setActiveItem] = useState({ menuKey: '', lockerToken: '' });

  const { setActiveTab } = useUser();


  // console.log(userId)
  // console.log(selectLocker)
  const toggleDatabaseSubmenu = () => {
    setExpandedMenu(expandedMenu === 'Databases' ? null : 'Databases');
  };


  const toggleAppsSubmenu = () => {
    setExpandedMenu(expandedMenu === 'Apps' ? null : 'Apps');
  };

  const toggleSubmenu = (menuName) => {
    const shouldExpand = expandedMenu !== menuName;
    setExpandedMenu(shouldExpand ? menuName : null);
    // localStorage.setItem('expandedMenu', shouldExpand ? menuName : "");
  };

  useEffect(() => {
    // Restore expanded menu from local storage
    const menu = localStorage.getItem('expandedMenu');
    if (menu) {
      setExpandedMenu(menu);
    }
  }, []);

  useEffect(() => {
    const newActiveMenu = getActiveMenuFromRoute(location.pathname);
    setActiveMenu(newActiveMenu);

    if (['AllItems', 'Lockers', 'Tables', 'Files', 'Links'].includes(newActiveMenu)) {
      setExpandedMenu('Databases');
    } else if (['find-duplicates', 'data-sharing-app', 'find-prospect'].includes(newActiveMenu)) {
      setExpandedMenu('Apps');
    } else {
      setExpandedMenu(null);
    }
  }, [location.pathname]);

  // useEffect(() => {
  //   const storedActiveMenu = localStorage.getItem('activeMenu');
  //   const routeActiveMenu = getActiveMenuFromRoute(location.pathname);
    
  //   if (storedActiveMenu) {
  //     setActiveMenu(storedActiveMenu);
  //   } else if (routeActiveMenu) {
  //     setActiveMenu(routeActiveMenu);
  //     localStorage.setItem('activeMenu', routeActiveMenu);
  //   }
  // }, [location.pathname]);

  const getActiveMenuFromRoute = (route) => {
    const routeToMenuMap = {
      '/home': 'Databank',
      '/home/all-items': 'AllItems',
      '/home/lockers': 'Lockers',
      '/home/tables': 'Tables',
      '/home/files': 'Files',
      '/home/links': 'Links',
      '/shared-with-me': 'shared-with-me',
      '/search': 'search',
      '/apps/find-duplicates': 'find-duplicates',
      '/apps/data-sharing-app': 'data-sharing-app',
      '/apps/find-prospect': 'find-prospect',
      '/profile/edit': 'Admin',
      '/profile/settings': 'Admin',
      '/profile/accounts': 'Admin',
      '/profile/password': 'Admin',
    };
    if (route.startsWith('/home/lockers/')) {
      return 'Lockers';
    }

    return routeToMenuMap[route] || '';
  };


  const handleMenuClick = debounce((menuKey, lockerToken = '') => {
    let route = '';
    const baseRoute = '/home';
  
    // Determine the route based on the menuKey
    switch (menuKey) {
      case 'Databank':
        route = baseRoute;
        break;
      case 'AllItems':
        route = `${baseRoute}/all-items`;
        break;
      case 'Lockers':
        route = `${baseRoute}/lockers`;
        break;
      case 'Tables':
        route = `${baseRoute}/tables`;
        break;
      case 'Files':
        route = `${baseRoute}/files`;
        break;
      case 'Links':
        route = `${baseRoute}/links`;
        break;
      case 'MyFiles':
        route = `${baseRoute}/lockers/${lockerToken}/MyFiles`;
        break;
      case 'MyTables':
        route = `${baseRoute}/lockers/${lockerToken}/MyTables`;
        break;
      case 'MyLinks':
        route = `${baseRoute}/lockers/${lockerToken}/MyLinks`;
        break;
      case 'shared-with-me':
        route = '/shared-with-me';
        break;
      case 'search':
        route = '/search';
        break;
      case 'find-duplicates':
        route = '/apps/find-duplicates';
        break;
      case 'data-sharing-app':
        route = '/apps/data-sharing-app';
        break;
      case 'find-prospect':
        route = '/apps/find-prospect';
        break;
      case 'Admin':
        route = '/profile/edit';
        break;
        
      default:
        route = baseRoute;
        break;
      }
      navigate(route);
      if (onMenuClick) onMenuClick(menuKey);
    }, 100);
  
  // const handleLockerClick = (locker) => {
  //   setSelectedLocker(locker);
  //   navigate(`/home/lockers/${locker.token}/AllItems`);
  //   setActiveLockerToken(locker.token);
  //   setActiveMenu('Lockers');
  //   setExpandedMenu('Databases');
  //   setActiveItem({ menuKey: 'Lockers', lockerToken: locker.token });
  // };


  const handleLockerClick = (locker) => {
    setSelectedLocker(locker);
    navigate(`/home/lockers/${locker.token}/AllItems`);
    setActiveLockerToken(locker.token);
  };

  



  const fetchLockerDetails = async () => {
    try {
      const response = await axios.get('https://pages.onepgr.com/session/get-data-bank-items', {
        params: {
          user_id: userId,
          root: '1'

        },
      });

      console.log('sidebar:', response.data); // Log the entire response data object

      if (response.data.data.length > 0) {
        const lockerId = response.data.data[0].token;
        const fetchedLockers = response.data.data;

        setFetchedLockers(fetchedLockers);
        console.log(lockerId);
      } else {
        console.log('Response data is empty');
      }


    } catch (error) {
      console.error('Error fetching locker details:', error);
    }
  };

  useEffect(() => {
    fetchLockerDetails();
  }, [userId]);

  // Get the persisted active menu from local storage
  useEffect(() => {
    // Get the persisted active menu and locker token from local storage
    const persistedMenu = localStorage.getItem('activeMenu');
    const persistedToken = localStorage.getItem('activeLockerToken');
    if (persistedMenu) {
      setPersistedActiveMenu(persistedMenu);
      setActiveLockerToken(persistedToken);
    }
  }, []);

  const addLocker = async () => {
    try {
      // Generate a unique locker name
      const newId = fetchedLockers.length + 1;
      const newLockerName = `Locker ${newId}`;

      // Create a new locker object with the generated name
      const newLocker = { id: newId, name: newLockerName };

      // Update the lockers state
      setLockers([...lockers, newLocker]);

      // Send the POST request to the API
      const response = await axios.post('https://pages.onepgr.com/session/create-data-bank-item', {
        user_id: userId,
        name: newLockerName, // Use the generated name
      });

      console.log('API response:', response.data);
      // Handle the response as needed
    } catch (error) {
      console.error('Error creating locker:', error);
      // Handle the error as needed
    }
  };

  // const deleteLocker = async (token) => {
  //   try {
  //     // Perform the API call to delete the locker
  //     await axios.delete(`https://pages.onepgr.com/session/delete-data-bank-item/${token}`);

  //     // Filter out the deleted locker from the lockers state


  //   } catch (error) {
  //     console.error('Error deleting locker:', error);
  //     // Handle the error as needed
  //   }
  // };

  const toggleLockerSubmenu = (token) => {
    setExpandedLocker(expandedLocker === token ? null : token);

  };
  const toggleLockersMenu = () => {
    setExpandedMenu(expandedMenu === 'Lockers' ? null : 'Lockers');
  };



  return (
    <div className={`sidebar ${isSidebarCollapsed ? 'collapsed' : ''}`}>
      <ul className="sidebar-menu">
      <li
          className={activeMenu === 'Databank' ? 'active' : ''}
          onClick={() => handleMenuClick('Databank')}
        >
          <FaHome className="font-icon" /> <span className='head-label-text'>Home</span>
          {expandedMenu === 'Databases' ?
            <FaCaretDown onClick={(e) => {
              e.stopPropagation(); // Prevent event from bubbling up to li
              toggleDatabaseSubmenu();
            }} style={{ cursor: 'pointer' }} />
            :
            <FaCaretRight onClick={(e) => {
              e.stopPropagation(); // Prevent event from bubbling up to li
              toggleDatabaseSubmenu();
            }} style={{ cursor: 'pointer' }} />
          }
        </li>
        {expandedMenu === 'Databases' && (
          <ul className={`submenu ${expandedMenu === 'Databases' ? 'expanded' : ''}`}>
            <li
              className={activeMenu === 'AllItems' || persistedActiveMenu === 'AllItems' ? 'active' : ''}
              onClick={() => handleMenuClick('AllItems')}
            >
              <FontAwesomeIcon icon={faDatabase} className="font-icon" /><span>All Items</span>
            </li>
            <li
              className={activeMenu === 'Lockers' || persistedActiveMenu === 'Lockers' ? 'active' : ''}
              onClick={() => handleMenuClick('Lockers')}
            >
              <FontAwesomeIcon icon={faLock} className="font-icon" /><span className='sidebar-locker-head'>Lockers</span>
              {expandedLocker ? <FaCaretDown onClick={(e) => {
                e.stopPropagation();
                setExpandedLocker(null);
              }} /> : <FaCaretRight onClick={(e) => {
                e.stopPropagation();
                setExpandedLocker(true);
              }} />}
            </li>
            {expandedLocker && (
              <ul className="locker-submenu">
                {fetchedLockers.map(locker => (
                  <li key={locker.token} className={`locker-item ${activeLockerToken === locker.token ? 'active' : ''}`}>
                    <span onClick={() => handleLockerClick(locker)} className='locker-name-section' title={locker.name}>
                      <FaLock className="font-icon" />
                      <span className='sidebar-locker-name'> {locker.name}</span>
                    </span>
                  </li>
                ))}
              </ul>
            )}
            <li
              className={activeMenu === 'Tables' || persistedActiveMenu === 'Tables' ? 'active' : ''}
              onClick={() => handleMenuClick('Tables')}
            >
              <FontAwesomeIcon icon={faTable} className="font-icon" /><span>Tables</span>
            </li>
            <li
              className={activeMenu === 'Files' || persistedActiveMenu === 'Files' ? 'active' : ''}
              onClick={() => handleMenuClick('Files')}
            >
              <FontAwesomeIcon icon={faFile} className="font-icon" /><span>Files</span>
            </li>
            <li
              className={activeMenu === 'Links' || persistedActiveMenu === 'Links' ? 'active' : ''}
              onClick={() => handleMenuClick('Links')}
            >
              <FontAwesomeIcon icon={faLink} className="font-icon" /><span>Links</span>
            </li>
            {/* {fetchedLockers.map(locker => (
              <li key={locker.token} className="locker-item">
                <span onClick={() => handleLockerClick(locker)} className='locker-name-section' title={locker.name}>
                  <FaLock className="font-icon" />
                  <span className='sidebar-locker-name'> {locker.name}</span>
                  {expandedLocker === locker.token ? <FaCaretDown /> : <FaCaretRight />}
                </span>
                {expandedLocker === locker.token && (
                  <ul className="nested-submenu">
                    <li className={(activeItem.menuKey === 'MyFiles' && activeItem.lockerToken === locker.token) ? 'active' : ''}
                      onClick={() => handleMenuClick('MyFiles', locker.token)}>
                      <FontAwesomeIcon icon={faFileAlt} className="font-icon" /> <span> My Files</span>
                    </li>
                    <li className={(activeItem.menuKey === 'MyTables' && activeItem.lockerToken === locker.token) ? 'active' : ''}
                      onClick={() => handleMenuClick('MyTables', locker.token)}>
                      <FontAwesomeIcon icon={faTable} className="font-icon" /> <span> My Tables</span>
                    </li>
                    <li className={(activeItem.menuKey === 'MyLinks' && activeItem.lockerToken === locker.token) ? 'active' : ''}
                      onClick={() => handleMenuClick('MyLinks', locker.token)}>
                      <FontAwesomeIcon icon={faLink} className="font-icon" /> <span> My Links</span>
                    </li>
                  </ul>
                )}
              </li>
            ))} */}

            {/* <li>
              <button className='add-locker' onClick={addLocker}> <p>+</p> <span>Add Locker</span></button>
            </li> */}
          </ul>
        )}
        <li
          className={
            activeMenu === 'shared-with-me' ||
              persistedActiveMenu === 'shared-with-me' ? 'active' : ''
          }
          onClick={() => handleMenuClick('shared-with-me')}
        >
          <FontAwesomeIcon icon={faUserFriends} className="font-icon" />
          <span> Shared With Me</span>
        </li>

        <li className={activeMenu === 'search' || persistedActiveMenu === 'search' ? 'active' : ''} onClick={() => handleMenuClick('search')}>
          <FontAwesomeIcon icon={faSearch} className="font-icon" /> <span> Search</span>
        </li>
        <li
          className={activeMenu === 'Apps' ? 'active' : ''}
          onClick={() => toggleSubmenu('Apps')}
        >
          <FaProjectDiagram className="font-icon" /> <span className='head-label-text'>Apps</span>
          {expandedMenu === 'Apps' ? <FaCaretDown /> : <FaCaretRight />}
        </li>
        {expandedMenu === 'Apps' && (
          <ul className={`submenu ${expandedMenu === 'Apps' ? 'expanded' : ''}`}>
            {/* <li className={activeMenu === 'find-duplicates' || persistedActiveMenu === 'find-duplicates' ? 'active' : ''} onClick={() => handleMenuClick('find-duplicates')}>
              <FontAwesomeIcon icon={faSearch} className="font-icon" /><span>Find Duplicates</span>
            </li>
            <li className={activeMenu === 'data-sharing-app' || persistedActiveMenu === 'data-sharing-app' ? 'active' : ''} onClick={() => handleMenuClick('data-sharing-app')}>
              <FontAwesomeIcon icon={faExchangeAlt} className="font-icon" /> <span>Data Sharing App</span>
            </li> */}
            <li className={activeMenu === 'find-prospect' || persistedActiveMenu === 'find-prospect' ? 'active' : ''} onClick={() => handleMenuClick('find-prospect')}>
              <FontAwesomeIcon icon={faWandMagicSparkles} className="font-icon" /><span>Find prospect</span>
            </li>
          </ul>
        )}

{/* 
        <li className={activeMenu === 'Admin' || persistedActiveMenu === 'Admin' ? 'active' : ''} onClick={() => handleMenuClick('Admin')}>
        <FontAwesomeIcon icon={faGear} className="font-icon" /><span >Admin</span>
        </li> */}
      </ul>

    </div>
  );
};

export default Sidebar;