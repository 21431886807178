import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faClipboard, faFileCsv, faDownload } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import './tableView.css'
import DropdownData from '../search/dropdownData';
import UploadOverlay from './uploadOverlay';
import { useRefresh } from '../refreshContext /refreshContext';
import { UserContext, useUser } from '../context/userContext';
import Notification from "../notification /notification";
const { TITLES, TEAM_SIZES, INDUSTRIES, CountriesData, } = DropdownData;


function TableView() {
    const { formatFileSize, formatDate, lockerDetails } = useUser();
    const { user } = useContext(UserContext);
    const userId = user?.userId;
    const location = useLocation();
    const navigate = useNavigate();
    console.log('location.state:', location);
    const { tableName: tableParamName } = useParams();
    const { data: initialData, tableName: tableStateName } = location.state || {};
    const { tableNames: locationTableNames } = location.state || {};
    const [tableContent, setTableContent] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sqlQuery, setSqlQuery] = useState('');
    const [notesVisible, setNotesVisible] = useState(false);
    const [notesList, setNotesList] = useState([]);
    const [isNotesVisible, setIsNotesVisible] = useState(false);
    // const { data: initialData, tableName } = location.state || {};  // Destructure to access data and tableName
    const [data, setData] = useState(initialData || []);
    const [showFilters, setShowFilters] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState('');
    const [filterInputs, setFilterInputs] = useState({});
    const dropdownRef = useRef(null);
    const tableName = tableParamName || tableStateName;
    const tableNames = locationTableNames || [];
    const [headers, setHeaders] = useState([]);
    const [selectedTables, setSelectedTables] = useState([]);
    const [notification, setNotification] = useState({ type: '', message: '' });
    const [filtersApplied, setFiltersApplied] = useState(false);
    const [showUploadOverlay, setShowUploadOverlay] = useState(false);
    const { triggerRefreshAllItems, triggerRefreshFiles } = useRefresh()
    const { database: initialDatabase } = location.state || {};
    const [currentDatabase, setCurrentDatabase] = useState(location.state?.database || 'rds');

    const tableRef = useRef(null);
    const tableBodyRef = useRef(null);

    useEffect(() => {
        console.log('Location state:', location);
    }, [location]);

    console.log(initialDatabase)

    console.log("Fetching data for table:", tableName);
    console.log('Table Names:', locationTableNames);
    console.log(data)

    const [dropdownInputs, setDropdownInputs] = useState({
        "Person Information": { firstName: false, lastName: false, email: false, title: false },
        "Lead Location": { leadCountry: false, leadState: false, leadCity: false },
        "Company Information": { companyName: false, industry: false, teamSize: false, companyDomain: false },
        "Company Location": { companyCountry: false, companyState: false, companyCity: false, companyZip: false, }

    });

    const formatCountryName = (country) => {
        return country ? country.replace(/([A-Z])/g, ' $1').trim() : '';
    };

    useEffect(() => {
        if (tableName) {
            const database = location.state?.database || 'rds'; // Default to 'rds' if not specified
            console.log('Database:', database);
            setCurrentDatabase(database);
            fetchTableContent(tableName, database);
        }
    }, [tableName, location.state]);




    useEffect(() => {
        if (data.length > 0) {
            const uniqueHeaders = getAllUniqueHeaders(data);
            setHeaders(uniqueHeaders);
        }
    }, [data]);  // Dependency on data to update headers when data changes

    useEffect(() => {
        const table = tableRef.current;
        const tableBody = tableBodyRef.current;
        if (!table || !tableBody) return;

        const handleScroll = () => {
            table.style.left = `-${tableBody.scrollLeft}px`;
        };

        tableBody.addEventListener('scroll', handleScroll);

        return () => {
            tableBody.removeEventListener('scroll', handleScroll);
        };
    }, []);



    const predefinedQueries = [
        { label: "Select a query...", value: "" },
        { label: "Insert data into a table", value: "INSERT INTO <TableName> (<ColumnName1>, <ColumnName2>, <ColumnName3>, ..., <ColumnNameN>)\nVALUES (<Value1>, <Value2>, <Value3>, ..., <ValueN>);" },
        { label: "Select all data from a table", value: "SELECT * FROM <TableName>;" },
        { label: "Select specific columns from a table", value: "SELECT <ColumnName1>, <ColumnName2> FROM <TableName>;" },
        { label: "Update data in a table", value: "UPDATE <TableName>\nSET <ColumnName1> = '<NewValue1>', <ColumnName2> = '<NewValue2>'\nWHERE <ConditionColumnName> = '<ConditionValue>';" },
        { label: "Delete data from a table", value: "DELETE FROM <TableName> WHERE <ConditionColumnName> = '<ConditionValue>';" },
        { label: "Join two tables", value: "SELECT <TableName1>.<ColumnName1>, <TableName2>.<ColumnName2>\nFROM <TableName1>\nJOIN <TableName2> ON <TableName1>.<ColumnName> = <TableName2>.<ColumnName>;" },
        { label: "Aggregate function: Calculate average", value: "SELECT AVG(<ColumnName>) AS average_value FROM <TableName>;" },
        { label: "Aggregate function: Count rows", value: "SELECT COUNT(*) AS total_rows FROM <TableName>;" },
        { label: "Grouping data", value: "SELECT <ColumnName>, COUNT(*) AS count\nFROM <TableName>\nGROUP BY <ColumnName>;" },
        { label: "Alter table: Add column", value: "ALTER TABLE <TableName> ADD <NewColumnName> <DataType>;" },
        { label: "Alter table: Modify column", value: "ALTER TABLE <TableName> MODIFY <ColumnName> <NewDataType>;" },
        { label: "Alter table: Drop column", value: "ALTER TABLE <TableName> DROP COLUMN <ColumnName>;" },
        { label: "Drop a table", value: "DROP TABLE <TableName>;" }
    ]

    const handleSqlQueryChange = (e) => {
        setSqlQuery(e.target.value);
    };

    const handlePredefinedQuerySelection = (event) => {
        const selectedQuery = event.target.value;
        const updatedSqlQuery = selectedQuery.replace(/<TableName>/g,);

        setSqlQuery(updatedSqlQuery);
    };

    const fetchData = async () => {
        setIsLoading(true)
        const params = new URLSearchParams(location.search);
        const entries = params.entries();
        const filters = {};

        // Iterate over the entries and construct the filters object
        for (const [key, value] of entries) {
            // Check if the key already exists in the filters object
            if (filters[key]) {
                // If it exists, convert the value to an array and push the new value
                if (!Array.isArray(filters[key])) {
                    filters[key] = [filters[key]];
                }
                filters[key].push(value);
            } else {
                // If the key doesn't exist, add it to the filters object
                filters[key] = value;
            }
        }

        if (filters.leadCountry) {
            filters.leadCountry = Array.isArray(filters.leadCountry)
                ? filters.leadCountry.map(formatCountryName)
                : formatCountryName(filters.leadCountry);
        }
        if (filters.companyCountry) {
            filters.companyCountry = Array.isArray(filters.companyCountry)
                ? filters.companyCountry.map(formatCountryName)
                : formatCountryName(filters.companyCountry);
        }

        const specifiedTableNames = tableNames;
        try {
            let requestData;
            let response;
            if (tableName === 'selected-tables') {
                console.log(specifiedTableNames);
                // If tableName is 'specifiedtables', pass the tableNames array to search specified tables
                requestData = {
                    filters,
                    tableNames: specifiedTableNames,
                };
                response = await axios.post(`https://crawl.onepgr.com:3002/search-specified-tables`, requestData);
            } else {
                // If tableNames is not available or is an empty array, use tableName
                requestData = {
                    filters,
                    tableNames: tableName ? [tableName] : [],
                };
                response = await axios.post(`https://crawl.onepgr.com:3002/search-specified-tables`, requestData);
            }

            console.log(response);
            setData(response.data || []);
            setIsLoading(false);
        } catch (error) {
            console.error('Failed to fetch data:', error);
            setData([]);
            setIsLoading(false);
        }
    };

    // Effect to run fetchData on component mount and when URL search params or selected tables change
    useEffect(() => {
        if (!initialData) {
            fetchData();
            setFiltersApplied(location.search !== '');
        }
    }, [location.search, selectedTables, initialData]);

    const fetchTableContent = async (tableName) => {
        console.log(currentDatabase);
        setIsLoading(true);
        try {
            const params = new URLSearchParams({ database: initialDatabase || 'rds' });
            const response = await axios.get(`https://crawl.onepgr.com:3002/fetch-table-data/${tableName}?database=${currentDatabase}`);
            setTableContent(response.data);
            // setCurrentDatabase(database || 'rds');
            setIsLoading(false);
        } catch (error) {
            console.error(`Error fetching content for table ${tableName} from ${initialDatabase}:`, error);
            setIsLoading(false);
            if (error.response && error.response.status === 404) {
                // If table not found in the specified database, you might want to handle this case
                // For example, show an error message or try the other database
            }
        }
    };

    useEffect(() => {
        if (!initialData || data.length === 0) {
            if (Object.keys(location.search).length === 0 && !Array.isArray(tableName)) {
                fetchTableContent(tableName);
            }
        }
    }, [tableName, initialData, data, location.search]);

    const executeSqlQuery = async () => {
        if (!sqlQuery.trim()) {
            alert('Please enter a valid SQL query.');
            return;
        }

        try {
            const response = await axios.post('https://crawl.onepgr.com:3002/execute-query', { sql: sqlQuery, tableName });
            console.log('Query executed, result:', response.data);
            alert('Query executed successfully!');
        } catch (error) {
            console.error('Error executing SQL query:', error);
            alert(`Error: ${error.message}`);
        }
    };


    const shouldRenderFirstTable = tableContent.length > 0 && data.length == 0;
    const shouldRenderSecondTable = data.length > 0;

    const saveTableContentAsCsv = () => {
        if (!data || data.length === 0) {
            console.error('No data to download');
            return;
        }

        // Convert the filtered table data to CSV format
        const csvContent = convertToCSV(data);

        // Prompt user to enter a filename
        const fileName = window.prompt('Enter a filename for the CSV file:', 'table_data.csv');

        // If the user cancels or enters an empty filename, do nothing
        if (!fileName) return;

        // Create a Blob containing the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

        // Create a temporary anchor tag
        const anchor = document.createElement('a');
        anchor.href = URL.createObjectURL(blob);
        anchor.download = fileName;

        // Trigger the download
        anchor.click();

        // Clean up
        URL.revokeObjectURL(anchor.href);
    };

    const toggleNotesVisibility = () => {
        setNotesVisible(!notesVisible);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                event.target.className !== 'child-filter-btn active'
            ) {
                setActiveDropdown('');
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);


    const capitalizeWords = (s) => {
        return s.replace(/([A-Z])/g, ' $1') // Add space before capital letters
            .trim() // Remove leading space
            .toLowerCase() // Convert all to lower case
            .replace(/^\w|\s\w/g, (letter) => letter.toUpperCase()); // Capitalize the first letter of each word
    };

    const toggleDropdown = (type) => {
        setActiveDropdown(prev => (prev === type ? '' : type));
    };

    const toggleInput = (category, inputName) => {
        setDropdownInputs(prev => {
            const currentInputs = { ...prev[category] }; // Copy the current state of inputs for the category
            const isCurrentlyOpen = currentInputs[inputName]; // Check if the clicked input is currently open

            // Set all inputs in this category to false first
            Object.keys(currentInputs).forEach(key => {
                currentInputs[key] = false;
            });

            // If the clicked input was not open, set it to true
            if (!isCurrentlyOpen) {
                currentInputs[inputName] = true;
            }

            return {
                ...prev,
                [category]: currentInputs
            };
        });
    };


    const handleInput = (category, key, value) => {
        // Update the filterInputs state with the new input value
        setFilterInputs((prevInputs) => ({
            ...prevInputs,
            [`${category}-${key}`]: value, // Update the entire value instead of just one character
        }));
    };

    const handleApply = () => {
        setIsLoading(true);
        const params = new URLSearchParams(location.search); // Start with existing parameters
        Object.entries(filterInputs).forEach(([key, value]) => {
            const [category, filterKey] = key.split('-'); // Extract the key name with category

            let filteredKey = filterKey; // Keep the original filter key

            // Modify the filter key for the 'companyState' filter
            if (filterKey === 'companyState') {
                filteredKey = 'companyState/Region';
            }
            if (value) {
                if (params.has(filteredKey)) {
                    // If the key already exists, append the new value to the existing values
                    const existingValues = params.get(filteredKey).split(',');
                    const newValues = [...existingValues, value];
                    params.set(filteredKey, newValues.join(','));
                } else {
                    // If the key doesn't exist, set the new value
                    params.set(filteredKey, value);
                }
            } else {
                params.delete(filterKey); // Remove the parameter if the value is empty
            }
        });
        const navigateState = {
            tableName: tableName,
            tableNames: tableNames,
        };
        const searchPath = `${location.pathname}?${params.toString()}`;
        navigate(searchPath, { replace: true, state: navigateState });
        setFilterInputs({});
        setActiveDropdown('');
        setFiltersApplied(true);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleApply();  // Call the function that applies the filters
        }
    };


    const DropdownContent = ({ category }) => {
        const inputs = dropdownInputs[category] || {};



        return (
            <div ref={dropdownRef} className="filter-dropdown-content">
                {Object.entries(inputs).map(([key, isOpen]) => (
                    <div key={key} className='drop-input'>
                        <button className="dropdown-toggle" onClick={() => toggleInput(category, key)}>
                            {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                            <span className={`arrow ${dropdownInputs[category][key] ? 'up' : ''}`}>▼</span>
                        </button>
                        {isOpen && (
                            <>
                                {category === 'Person Information' && key === 'title' ? (
                                    <div className="input-dropdown">
                                        <input
                                            type="text"
                                            placeholder={`Search by ${key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}`}
                                            value={filterInputs[`${category}-${key}`] || ''}
                                            onChange={(e) => handleInput(category, key, e.target.value)}
                                            onKeyDown={handleKeyPress}
                                            autoFocus
                                            list="titleOptions"
                                        />
                                        <datalist id="titleOptions">
                                            {TITLES.map((option) => (
                                                <option key={option} value={option} />
                                            ))}
                                        </datalist>
                                    </div>
                                ) : category === 'Lead Location' && key === 'leadCountry' ? (
                                    <div className="input-dropdown">
                                        <input
                                            type="text"
                                            placeholder="Search lead country"
                                            value={formatCountryName(filterInputs[`${category}-${key}`])}
                                            onChange={(e) => {
                                                handleInput(category, key, e.target.value);
                                                handleInput(category, 'leadState', '');
                                                handleInput(category, 'leadCity', '');
                                            }}
                                            onKeyDown={handleKeyPress}
                                            autoFocus
                                            list="lead-country-options"
                                        />
                                        <datalist id="lead-country-options">
                                            {Object.keys(CountriesData).map((country) => (
                                                <option key={country} value={country} />
                                            ))}
                                        </datalist>
                                    </div>
                                ) : category === 'Lead Location' && key === 'leadState' ? (
                                    <div className="input-dropdown">
                                        <input
                                            type="text"
                                            placeholder="Search lead state"
                                            value={filterInputs[`${category}-${key}`] || ''}
                                            onChange={(e) => {
                                                handleInput(category, key, e.target.value);
                                                handleInput(category, 'leadCity', '');
                                            }}
                                            onKeyDown={handleKeyPress}
                                            autoFocus
                                            list="lead-state-options"

                                        />
                                        <datalist id="lead-state-options">
                                            {filterInputs[`${category}-leadCountry`] &&
                                                Object.keys(CountriesData[filterInputs[`${category}-leadCountry`]]).map((state) => (
                                                    <option key={state} value={state} />
                                                ))}
                                        </datalist>
                                    </div>
                                ) : category === 'Lead Location' && key === 'leadCity' ? (
                                    <div className="input-dropdown">
                                        <input
                                            type="text"
                                            placeholder="Search lead city"
                                            value={filterInputs[`${category}-${key}`] || ''}
                                            onChange={(e) => handleInput(category, key, e.target.value)}
                                            onKeyDown={handleKeyPress}
                                            autoFocus
                                            list="lead-city-options"

                                        />
                                        <datalist id="lead-city-options">
                                            {filterInputs[`${category}-leadCountry`] && filterInputs[`${category}-leadState`] &&
                                                CountriesData[filterInputs[`${category}-leadCountry`]][filterInputs[`${category}-leadState`]].map((city) => (
                                                    <option key={city} value={city} />
                                                ))}
                                        </datalist>
                                    </div>
                                ) : category === 'Company Information' && key === 'industry' ? (
                                    <div className="input-dropdown">
                                        <input
                                            type="text"
                                            placeholder={`Search by ${key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}`}
                                            value={filterInputs[`${category}-${key}`] || ''}
                                            onChange={(e) => handleInput(category, key, e.target.value)}
                                            onKeyDown={handleKeyPress}
                                            autoFocus
                                            list="industryOptions"
                                        />
                                        <datalist id="industryOptions">
                                            {INDUSTRIES.map((option) => (
                                                <option key={option} value={option} />
                                            ))}
                                        </datalist>
                                    </div>
                                ) : category === 'Company Information' && key === 'teamSize' ? (
                                    <div className="input-dropdown">
                                        <input
                                            type="text"
                                            placeholder={`Search by ${key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}`}
                                            value={filterInputs[`${category}-${key}`] || ''}
                                            onChange={(e) => handleInput(category, key, e.target.value)}
                                            onKeyDown={handleKeyPress}
                                            autoFocus
                                            list="teamSizeOptions"
                                        />
                                        <datalist id="teamSizeOptions">
                                            {TEAM_SIZES.map((option) => (
                                                <option key={option} value={option} />
                                            ))}
                                        </datalist>
                                    </div>
                                ) : category === 'Company Location' && key === 'companyCountry' ? (
                                    <div className="input-dropdown">
                                        <input
                                            type="text"
                                            placeholder="Select company country"
                                            value={formatCountryName(filterInputs[`${category}-${key}`])}
                                            onChange={(e) => {
                                                handleInput(category, key, e.target.value);
                                                handleInput(category, 'companyState', '');
                                                handleInput(category, 'companyCity', '');
                                            }}
                                            onKeyDown={handleKeyPress}
                                            autoFocus
                                            list="company-country-options"
                                        />
                                        <datalist id="company-country-options">
                                            {Object.keys(CountriesData).map((country) => (
                                                <option key={country} value={country} />
                                            ))}
                                        </datalist>
                                    </div>
                                ) : category === 'Company Location' && key === 'companyState' ? (
                                    <div className="input-dropdown">
                                        <input
                                            type="text"
                                            placeholder="Select company state"
                                            value={filterInputs[`${category}-${key}`] || ''}
                                            onChange={(e) => {
                                                handleInput(category, key, e.target.value);
                                                handleInput(category, 'companyCity', '');
                                            }}
                                            onKeyDown={handleKeyPress}
                                            autoFocus
                                            list="company-state-options"

                                        />
                                        <datalist id="company-state-options">
                                            {filterInputs[`${category}-companyCountry`] &&
                                                Object.keys(CountriesData[filterInputs[`${category}-companyCountry`]]).map((state) => (
                                                    <option key={state} value={state} />
                                                ))}
                                        </datalist>
                                    </div>
                                ) : category === 'Company Location' && key === 'companyCity' ? (
                                    <div className="input-dropdown">
                                        <input
                                            type="text"
                                            placeholder="Select Company city"
                                            value={filterInputs[`${category}-${key}`] || ''}
                                            onChange={(e) => handleInput(category, key, e.target.value)}
                                            onKeyDown={handleKeyPress}
                                            autoFocus
                                            list="company-city-options"

                                        />
                                        <datalist id="company-city-options">
                                            {filterInputs[`${category}-companyCountry`] && filterInputs[`${category}-companyState`] &&
                                                CountriesData[filterInputs[`${category}-companyCountry`]][filterInputs[`${category}-companyState`]].map((city) => (
                                                    <option key={city} value={city} />
                                                ))}
                                        </datalist>
                                    </div>
                                ) : (
                                    <input
                                        type="text"
                                        placeholder={`Search by ${key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}`}
                                        value={filterInputs[`${category}-${key}`] || ''}
                                        onChange={(e) => handleInput(category, key, e.target.value)}
                                        onKeyDown={handleKeyPress}
                                        autoFocus
                                    />
                                )}
                            </>
                        )}
                    </div>
                ))}
                {/* <button className="filter-apply-button" onClick={handleApply}>Apply</button> */}
            </div>
        );
    };

    const removeFilter = (filterKey, filterValue) => {
        setIsLoading(true);
        const params = new URLSearchParams(location.search);
        const existingValues = params.get(filterKey)?.split(',') || [];
        const newValues = existingValues.filter(value => value !== filterValue);

        if (newValues.length === 0) {
            params.delete(filterKey);
        } else {
            params.set(filterKey, newValues.join(','));
        }

        const navigateState = {
            tableName: tableName,
            tableNames: tableNames,
        };
        navigate(`${location.pathname}?${params.toString()}`, { replace: true, state: navigateState });

        if (params.toString() === '') {
            setFiltersApplied(false);
        }
    };

    // const headers = data.length > 0 ? Object.keys(data[0]) : [];

    const getFilterTags = () => {
        const query = new URLSearchParams(location.search);
        let tags = [];
        for (const [key, valueArray] of query.entries()) {
            const values = valueArray.split(',');

            tags.push({ key, value: values });

        }
        return tags;
    };

    const filterTags = getFilterTags();


    const categories = [
        { id: 'person', label: 'Person Information' },
        { id: 'lead', label: 'Lead Location' },
        { id: 'company', label: 'Company Information' },
        { id: 'location', label: 'Company Location' },

    ];

    const getAllUniqueHeaders = (data) => {
        const headerSet = new Set();
        data.forEach(item => {
            Object.keys(item).forEach(key => {
                headerSet.add(key);
            });
        });
        return Array.from(headerSet);
    }


    const handleUploadClick = () => {
        setShowUploadOverlay(true);
    };

    const handleUploadOverlayClose = () => {
        setShowUploadOverlay(false);
    };


    const handleUpload = async (fileName, selectedLocker) => {
        try {

            if (!data || data.length === 0) {
                console.error('No data to upload');
                return;
            }
            // Convert the filtered table data to CSV format
            const csvContent = convertToCSV(data);

            // Create a Blob with the CSV content
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

            // Create a FormData object and append the CSV file
            const formData = new FormData();
            formData.append('file', blob, `${fileName}.csv`);
            formData.append('filename', fileName);

            // Upload the CSV file to the selected locker
            const uploadResponse = await fetch(`https://crawl.onepgr.com:3002/upload`, {
                method: 'POST',
                body: formData,
            });

            if (!uploadResponse.ok) {
                throw new Error('Server responded with ' + uploadResponse.status);
            }

            // Handle the upload response and update the data bank table
            const uploadResult = await uploadResponse.json();
            console.log(uploadResult)
            if (uploadResult.data && uploadResult.data.length > 0) {
                // Process each file
                for (const fileData of uploadResult.data) {
                    // Decode the file name to handle spaces and special characters
                    const decodedFileName = decodeURIComponent(fileData.Key);

                    // Use the decoded file name for further processing
                    const fileName = decodedFileName.includes('.')
                        ? decodedFileName.substring(decodedFileName.lastIndexOf('/') + 1)
                        : 'unknown';
                    const fileType = fileName.split('.').pop();
                    const lastUpdated = formatDate(fileData.LastModified);
                    const fileSize = formatFileSize(fileData.Size);
                    const sourceUrl = fileData.Location;
                    const lockerID = selectedLocker;
                    const body = {
                        user_id: userId,
                        locker_id: lockerID,
                        source_url: sourceUrl,
                        name: fileName,
                        last_updated: lastUpdated,
                        owner: user?.name.split('@')[0],
                        size: fileSize,
                        type: fileType,
                        object_type: 'file',
                    };

                    console.log('Sending to data bank:', body);

                    // Now send this information to create the data bank table entry
                    const createDataBankTableResponse = await fetch(
                        'https://pages.onepgr.com/session/create-data-bank-source-file',
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(body), // Passing the body object here
                        }
                    );

                    if (!createDataBankTableResponse.ok) {
                        throw new Error(`Server responded with ${createDataBankTableResponse.status}`);
                    }

                    setNotification({
                        type: 'success',
                        message: `Successfully uploaded ${fileName}.`,
                    });

                    setShowUploadOverlay(false);

                    console.log('Data bank table entry created successfully for:', fileName);
                }

                // Reset states after successful operations

                triggerRefreshFiles();
                triggerRefreshAllItems();
            } else {
                console.error('No files in the upload response');
            }
        } catch (error) {
            console.error('Error:', error);
            setNotification({
                type: 'error',
                message: `Error uploading`,
            });
        }
    };

    const convertToCSV = (data) => {
        if (!data || data.length === 0) {
            return '';
        }

        // Get the headers from the first row of the data
        const headers = Object.keys(data[0]);

        // Create an array to store the CSV rows
        const csvRows = [];

        // Add the header row to the csvRows array
        csvRows.push(headers.join('\t'));

        // Iterate over each row in the data
        data.forEach(row => {
            const values = headers.map(header => {
                let value = row[header];

                // Convert the value to a string if it's not already a string
                if (typeof value !== 'string') {
                    value = String(value);
                }

                // If the value contains a tab or a newline, surround it with double quotes
                if (value.includes('\t') || value.includes('\n')) {
                    value = `"${value}"`;
                }

                return value;
            });

            // Join the values with tabs and add the row to the csvRows array
            csvRows.push(values.join('\t'));
        });

        // Join the csvRows with newline characters to create the final CSV string
        return csvRows.join('\n');
    };



    return (
        <div className="table-view-component">

            <div className="tables-view-content">
                <div className='tableNameHead'>
                    <h3>Table Name(s): </h3>
                    {locationTableNames && locationTableNames.length > 0 ? (
                        <h4 title={locationTableNames.join(', ')}>
                            {locationTableNames.slice(0, 3).join(', ')}
                            {locationTableNames.length > 3 && '...'}
                        </h4>
                    ) : (
                        <h4>{tableName}</h4>
                    )}
                </div>
                {/* <div className='sqlquerySelect'>
                    <label>Enter SQL query below or select SQL query:</label>
                    <select onChange={handlePredefinedQuerySelection} className="sql-query-dropdown">
                        {predefinedQueries.map(query => (
                            <option key={query.value} value={query.value}>{query.label}</option>
                        ))}
                    </select>
                </div>
                <div className='sqlTextArea'>
                    <div className='textarea-btn'>
                        <textarea
                            value={sqlQuery}
                            onChange={handleSqlQueryChange}
                            placeholder="Enter SQL query here..."
                            rows="3"
                            className="sql-query-textarea"
                        ></textarea>
                        <button
                            onClick={executeSqlQuery}
                            className="execute-query-btn"
                            style={{ opacity: sqlQuery.trim() ? 1 : 0.5 }}
                            disabled={!sqlQuery.trim()}
                        >
                            Run Query
                        </button>
                    </div>
                    <div className='notesvisible'>
                        <div className='label-notes'>
                            <div className={`saved-notes ${isNotesVisible ? 'show' : 'hide'}`}>
                                <div className="close-icon" onClick={toggleNotesVisibility}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </div>
                                <p className="saved-notes-label"> Notes:</p>
                                {notesList.map((note, index) => (
                                    <div key={index} className="note-item">
                                        <p>{note.timestamp}</p>
                                        <p>{note.text}</p>
                                        <hr />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='notes-save'>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip-save">Save as CSV</Tooltip>}
                            >
                                <div onClick={saveTableContentAsCsv} className="save-csv-icon">
                                    <FontAwesomeIcon icon={faFileCsv} />
                                </div>
                            </OverlayTrigger>
                            {!isNotesVisible && (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="tooltip-clipboard">View Notes</Tooltip>}
                                >
                                    <div onClick={toggleNotesVisibility} className="clipboard-icon">
                                        <FontAwesomeIcon icon={faClipboard} />
                                    </div>
                                </OverlayTrigger>
                            )}
                        </div>
                    </div>
                </div> */}
                {shouldRenderSecondTable && (
                    <div>
                        <h4>Total results: ({data.length})</h4>
                        <hr />
                    </div>
                )}
                <div className='appliedFilters'>
                    <p>Applied Filters:</p>
                    <div className="tags-display">
                        {filterTags.map(tag => (
                            <div className='appliedtag-section'>
                                <span className="filter-name">{capitalizeWords(tag.key)} </span>
                                {tag.value.map((value) => (
                                    <div key={`${tag.key}-${value}`} className="filter-tag">
                                        <span className="filter-values">{value}</span>
                                        <button onClick={() => removeFilter(tag.key, value)}>x</button>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    <div className='add-filters-section'>
                        <div className={`filters-buttons-content ${showFilters ? '' : 'active'}`}>
                            {categories.map((category) => (
                                <div key={category.id}>
                                    <button
                                        onClick={() => toggleDropdown(category.label)}
                                        className={`child-filter-btn ${activeDropdown === category.label ? 'active' : ''}`}
                                    >
                                        {category.label}
                                    </button>
                                    {activeDropdown === category.label && <DropdownContent category={category.label} />}
                                </div>
                            ))}
                        </div>

                        <button onClick={() => setShowFilters(!showFilters)} className="add-filters-btn">
                            <span>{showFilters ? 'Hide' : 'Show'}</span> Filters
                        </button>
                    </div>
                </div>

                <div className='table-information'>
                    <div className='table-view-save-btns'>
                        {(filtersApplied || location.search) && data.length > 0 && (
                            <button className="save-csv-icon" onClick={handleUploadClick}>
                                Upload CSV To Locker
                            </button>
                        )}
                        {showUploadOverlay && (
                            <UploadOverlay
                                onClose={handleUploadOverlayClose}
                                onUpload={handleUpload}
                                tableName={tableName}
                            />
                        )}
                        {/* <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-save">Save as CSV</Tooltip>}
                    > */}
                        {(filtersApplied || location.search) && data.length > 0 && (
                            <button onClick={saveTableContentAsCsv} className="save-csv-icon">
                                Download As CSV
                            </button>
                        )}
                        {/* </OverlayTrigger> */}
                    </div>
                    {isLoading ? (
                        <div className="tableview-loading-spinner">
                            <div className="tableview-spinner"></div>
                        </div>
                    ) : (
                        <>
                            {shouldRenderFirstTable && (
                                <div className='table-content'>
                                    <table className="table-view" ref={tableRef}>
                                        <thead >
                                            <tr title>
                                                {tableContent.length > 0 &&
                                                    Object.keys(tableContent[0]).map((key, index) => (
                                                        <th key={index} title={key}>{key}</th>
                                                    ))
                                                }
                                            </tr>
                                        </thead>
                                        <tbody ref={tableBodyRef}>
                                            {Array.isArray(tableContent) && tableContent.map((row, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    {Object.values(row).map((value, valueIndex) => (
                                                        <td key={valueIndex} title={value?.toString() || ''}>{value}</td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            {shouldRenderSecondTable && (
                                <div className='results-table-content'>
                                    <table className="table-view" ref={tableRef}>
                                        <thead>
                                            <tr>
                                                {headers.map(header => {
                                                    const formattedHeader = header
                                                        .split('_')
                                                        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                                        .join(' ');
                                                    return (
                                                        <th key={header} title={formattedHeader}>
                                                            {formattedHeader}
                                                        </th>
                                                    );
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody ref={tableBodyRef}> 
                                            {data.map((item, index) => (
                                                <tr key={index}>
                                                    {headers.map(header => {
                                                        const cellContent = item.hasOwnProperty(header) ? item[header] : 'N/A';
                                                        return (
                                                            <td key={`${header}-${index}`} title={cellContent?.toString() || ''}>
                                                                {cellContent}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            {notification.message && (
                <Notification type={notification.type} message={notification.message} />
            )}
        </div>
    );
}

export default TableView;
