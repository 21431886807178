import React, { useState, useContext, useEffect } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faUserPlus, faTrashAlt, faSyncAlt, faSearch, faTimes, faEdit, faArrowDown, faArrowUp, faInfoCircle, faLink } from '@fortawesome/free-solid-svg-icons';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useOutletContext } from 'react-router-dom';
import './myLinks.css'
import Pagination from "../s3dataBase/fileList/pagination";
import axios from "axios";
import { UserContext, useUser } from '../context/userContext';
import Notification from "../notification /notification";
import { useRefresh } from "../refreshContext /refreshContext";

const Links = () => {
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const { lockerId } = useOutletContext();
    const { user } = useContext(UserContext);
    const [currentFile, setCurrentFile] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [isOverlayLoading, setIsOverlayLoading] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [newTag, setNewTag] = useState('');
    const [fileDetails, setFileDetails] = useState({});
    const [displayedLinks, setDisplayedLinks] = useState([]);
    const [sortOrder, setSortOrder] = useState('asc');
    const [overlayInfoOpen, setOverlayInfoOpen] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const [link, setLink] = useState('');
    const { fetchLinks, refreshLinks } = useRefresh()
    const userId = user?.userId;
    const [isLoadingLinks, setIsLoadingLinks] = useState(false);
    const [notification, setNotification] = useState({ message: '', type: '' });
    const [linksData, setLinksData] = useState(null);
    const [selectedLockerToken, setSelectedLockerToken] = useState()
    const { lockerDetails, formatDate } = useUser();
    const [sortLastUpdated, setSortLastUpdated] = useState(null);

    const [selectedLocker, setSelectedLocker] = useState(
        lockerDetails?.find((locker) =>
            locker.token === (currentFile?.locker_id)
        )?.name || ''
    );



    useEffect(() => {
        const selectedLockerObj = lockerDetails?.find(
            (locker) => locker.name === selectedLocker
        );
        if (selectedLockerObj) {
            setSelectedLockerToken(selectedLockerObj.token);

        }
    }, [selectedLocker, lockerDetails, setSelectedLockerToken]);


    useEffect(() => {
        fetchLinks();
    }, [userId, lockerId]);

    const toggleEditMode = () => {
        setIsEditMode((prevMode) => !prevMode);
    };


    const fetchLinksData = async () => {
        setIsLoadingLinks(true);
        const data = await fetchLinks(userId, lockerId, currentPage, itemsPerPage);

        setDisplayedLinks(data);
        setTotalItems(data.length);
        setIsLoadingLinks(false);
    };

    useEffect(() => {
        fetchLinksData();
    }, [refreshLinks]);





    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!link.trim()) {
            alert('Please enter or paste a link to submit.');
            setIsOverlayLoading(false);
            return;
        }
        handleCloseOverlay();
        setIsOverlayLoading(true);

        try {
            const response = await axios.post('https://crawl.onepgr.com:3002/store-link', { link });
            const storedLink = response.data.link;
            console.log('Stored link:', storedLink);

            // Send the link to the specified endpoint
            const linkData = {
                user_id: userId,
                locker_id: lockerId,
                name: storedLink,
                object_type: 'link',
                owner: user?.name.split('@')[0],
            };
            console.log(lockerId)

            const linkResponse = await axios.post(
                'https://pages.onepgr.com/session/create-data-bank-source-file',
                linkData
            );

            console.log('Create source file response:', linkResponse.data);

            await fetchLinks();
            setNotification({ message: 'Link Uploaded successfully!', type: 'success' });
        } catch (error) {
            console.error('Error:', error);
            setNotification({ message: 'Failed to upload link.', type: 'error' });
        } finally {
            setIsOverlayLoading(false);
        }
    };


    const toggleSortLastUpdated = () => {
        let sortedLinks;
        if (sortLastUpdated === 'asc') {
            // Currently ascending, toggle to original
            sortedLinks = [...displayedLinks].sort((a, b) => new Date(b.date) - new Date(a.date));
            setSortLastUpdated('desc');
        } else {
            // Either descending or original, sort by ascending (oldest to newest)
            sortedLinks = [...displayedLinks].sort((a, b) => new Date(a.date) - new Date(b.date));
            setSortLastUpdated('asc');
        }
        setDisplayedLinks(sortedLinks);
    };


    const toggleSort = () => {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };
    const closeOverlayInfo = () => {
        setOverlayInfoOpen(false);
        setIsEditMode(false); // Optionally reset edit mode to off when closing the overlay
    };

    const openOverlayWithFileInfo = async (file) => {
        setCurrentFile(file);
        setOverlayInfoOpen(true);

        try {
            const response = await axios.get(`https://pages.onepgr.com/session/get-data-bank-file-info?user_id=${file.user_id}&token=${file.token}`);
            const linkInfo = response.data.data.file_info;

            let tags = linkInfo.tags || [];

            // If tags is a string, convert it to an array
            if (typeof tags === 'string') {
                tags = tags.split(',').map(tag => tag.trim());
            }

            setFileDetails({
                ...fileDetails,
                [file._id]: {
                    comments: linkInfo.comments || '',
                    companyInfo: linkInfo.company_info || '',
                    tags: tags
                }
            });
        } catch (error) {
            console.error('Error fetching table information:', error);
        }
    };

    const openOverlayWithEditFileDetails = (table) => {
        setCurrentFile(table);

        // Set this to true to open the overlay in edit mode directly
        setIsEditMode(true);

        // Ensure file details for the current file are initialized
        if (!fileDetails[table._id]) {
            setFileDetails({
                ...fileDetails,
                [table._id]: { comments: '', companyInfo: '', tags: [] }
            });
        }
        setOverlayInfoOpen(true);
    };

    const updateFileDetails = (tableId, detailType, value) => {
        setFileDetails(prev => ({
            ...prev,
            [tableId]: {
                ...prev[tableId],
                [detailType]: value,
            },
        }));
    };

    const removeTag = (tableId, tagIndex) => {
        const updatedTags = [...fileDetails[tableId].tags];
        updatedTags.splice(tagIndex, 1); // Remove the tag at tagIndex
        updateFileDetails(tableId, 'tags', updatedTags);
    };



    const addTag = (newTagValue) => {
        // Guard clause to check if currentFile is set and newTagValue is not empty
        if (!currentFile || !newTagValue.trim()) return;

        const linkId = currentFile._id; // ID of the current file
        const existingTags = fileDetails[linkId]?.tags || []; // Current tags or an empty array if none

        // Create a new array with the existing tags plus the new one
        const updatedTags = [...existingTags, newTagValue.trim()];

        // Update fileDetails state with the new array of tags
        setFileDetails(prevDetails => ({
            ...prevDetails,
            [linkId]: {
                ...prevDetails[linkId],
                tags: updatedTags
            }
        }));
    };


    const saveChanges = async () => {
        setIsOverlayLoading(true);
        setOverlayInfoOpen(false);
        const linkId = currentFile._id;
        updateFileDetails(currentFile._id, 'name');
        const currentDateTime = new Date().toISOString();
        const lockerToken = selectedLockerToken

        const requestBody = {
            name: currentFile.source_url,
            type: "Check",
            file_info: {
                comments: fileDetails[linkId]?.comments || '',
                company_info: fileDetails[linkId]?.companyInfo || '',
                tags: fileDetails[linkId]?.tags.join(', ') || '' // Assuming tags are stored as an array
            },
            date: currentDateTime
        };

        if (lockerToken) {
            requestBody.locker_id = lockerToken;
        }

        try {
            const response = await axios.put(`https://pages.onepgr.com/session/update-data-bank-source-file/${linkId}`, requestBody);

            console.log(response.data);

            const updatedFileDetails = { ...fileDetails, [linkId]: requestBody.file_info };
            setFileDetails(updatedFileDetails);
            setIsOverlayLoading(false);
            setNotification({ message: 'Link info updated successfully!', type: 'success' });


        } catch (error) {

            console.error("Error updating file info:", error);

            setNotification({ message: 'Failed to update Link info.', type: 'error' });

        }
    };

    const handleAddLink = () => {
        setShowOverlay(true);
    };

    const handleCloseOverlay = () => {
        setShowOverlay(false);
        setLink('');
    };

    const handleLinkChange = (e) => {
        setLink(e.target.value);
    };

    const handleUpload = () => {

        console.log('Uploading link:', link);
        handleCloseOverlay();
    };

    const deleteLink = async (fileId, linkName) => {
        setIsOverlayLoading(true);
        try {
            // Make a DELETE request to the server to delete the link
            const serverResponse = await axios.delete(`https://crawl.onepgr.com:3002/delete-link/${linkName}`);

            if (serverResponse.status === 200) {
                // Make a DELETE request to the database to delete the link
                const databaseResponse = await axios.delete(`https://pages.onepgr.com/session/delete-data-bank-source-file/${fileId}`);

                if (databaseResponse.status === 200) {
                    // Refresh the links data after successful deletion
                    setIsOverlayLoading(false);
                    fetchLinksData()
                    // Show success notification
                    setNotification({ type: "success", message: "Link deleted successfully!" });
                } else {
                    setNotification({ message: 'Failed to delete the link from the database', type: 'error' });

                }
            } else {
                setNotification({ message: 'Failed to delete the link from the server', type: 'error' });

            }
        } catch (error) {
            console.error('Error deleting link:', error);
            setNotification({ message: 'Failed to delete the link.', type: 'error' });

        } finally {
            setIsOverlayLoading(false);
        }
    };


    return (
        <div className="my-links">

            <div className='rdsTables'>


                <div className='search-icons'>

                    <div className="search-bar" style={{ display: 'flex' }}> {/* Adjusted for consistency, though conditional rendering makes 'style' unnecessary */}
                        <div className="input-container">
                            <div className="icon-card searchIcon" >
                                <FontAwesomeIcon icon={faSearch} />
                            </div>
                            <input
                                type="text"
                                id="searchInput"
                                placeholder="Search Links..."
                                value=''
                                // onChange='{handleSearchChange} '

                                className='search-input'
                            />
                            {/* <i className="fas fa-times clear-icon" onClick={clearSearch}></i> */}
                        </div>

                    </div>
                    <div className="RDS-icon-container">

                        <OverlayTrigger
                            key="top-refresh"
                            placement="top"
                            overlay={<Tooltip id={`tooltip-top-refresh`}>Refresh Links List</Tooltip>}
                        >
                            <div className="icon-card"
                                onClick={fetchLinksData}
                            >
                                <FontAwesomeIcon icon={faSyncAlt} />
                            </div>
                        </OverlayTrigger>

                    </div>
                </div>
                <div className='rdsfiles'>
                    <div className="table-header">
                        <span>
                            Name
                            <FontAwesomeIcon
                                icon={sortOrder === 'asc' ? faArrowDown : faArrowUp}
                                className="sort-icon"
                                onClick={toggleSort}
                            />
                        </span>
                        <span onClick={toggleSortLastUpdated}>
                            Last Updated {sortLastUpdated === 'asc' ? <FontAwesomeIcon icon={faArrowUp} className='sort-icon' /> : <FontAwesomeIcon icon={faArrowDown} className='sort-icon' />}
                        </span>
                        <span>Actions</span>
                    </div>

                    {isLoadingLinks ? (
                        <div className="spinner"></div>
                    ) : (
                        <ul className="table-list">
                            {displayedLinks?.map((file) => (
                                <li key={file._id} className='rdstable-item '>
                                    <div className='tablename-icon' title={file.name}>
                                        <FontAwesomeIcon icon={faLink} className="icon-folder-lock table-icon" />
                                        <a href={file.name} target="_blank" rel="noopener noreferrer">
                                            <span className='rdstablename' >{file.name}</span>
                                        </a>
                                    </div>
                                    <span className='link-date'>{formatDate(file.date)}</span>
                                    <div className="table-actions-icon-rds">
                                        <OverlayTrigger
                                            key={`top-info-${file._id}`}
                                            placement="top"
                                            overlay={<Tooltip id={`tooltip-top-info`}>Link Information</Tooltip>}
                                        >
                                            <Button variant="light" onClick={() => openOverlayWithFileInfo(file)}>
                                                <FontAwesomeIcon icon={faInfoCircle} />
                                            </Button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id={`tooltip-view`}>View Link</Tooltip>}
                                        >
                                            <Button variant="light" onClick={(event) => {
                                                event.stopPropagation();
                                                window.open(file.name, '_blank');
                                            }}>
                                                <FontAwesomeIcon icon={faEye} />
                                            </Button>
                                        </OverlayTrigger>

                                        <OverlayTrigger
                                            key={`top-edit-${file._id}`}
                                            placement="top"
                                            overlay={<Tooltip id={`tooltip-top-info`}>Edit Link information</Tooltip>}
                                        >
                                            <Button variant="light" onClick={() => openOverlayWithEditFileDetails(file)}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </Button>
                                        </OverlayTrigger>

                                        {overlayInfoOpen && currentFile && (
                                            <div className="fileInfo-overlay">
                                                <div className="fileInfo-overlay-content" >
                                                    {/* <h3>{currentFile.name || 'File Info'}</h3> */}
                                                    <h3>{isEditMode ? "Edit Link Details:" : "View Link Details:"}</h3>
                                                    <div className='editname'>
                                                        <p title={currentFile.name}> {currentFile.name}</p>
                                                    </div>
                                                    <div className='locker-lable-content'>
                                                        <label className='label-locker-name'>Locker:</label>
                                                        {isEditMode ? (
                                                            <select
                                                                value={selectedLocker}
                                                                onChange={(e) => setSelectedLocker(e.target.value)}
                                                                className="locker-dropdown"
                                                            >
                                                                <option value=""> {lockerDetails.find((locker) =>
                                                                    locker.token === (currentFile?.locker_id)

                                                                )?.name}
                                                                </option>
                                                                {lockerDetails?.map((locker) => (
                                                                    <option key={locker.token} value={locker.name}>
                                                                        {locker.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        ) : (
                                                            <span className='locker-name-dropdown'>
                                                                {lockerDetails.find((locker) =>
                                                                    locker.token === (currentFile?.locker_id)

                                                                )?.name}

                                                            </span>
                                                        )}
                                                    </div>
                                                    <hr />
                                                    <div className='form-content'>
                                                        <OverlayTrigger
                                                            key={`top-info-${currentFile?._id}`}
                                                            placement="top"
                                                            overlay={<Tooltip id={`tooltip-top-edit`}>Edit</Tooltip>}
                                                        >
                                                            <Button variant="light" className='editinfobtn' onClick={toggleEditMode}>
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </Button>
                                                        </OverlayTrigger>
                                                        <div className='info-form-group '>
                                                            <label>Comments:</label>
                                                            <textarea
                                                                value={fileDetails[currentFile._id]?.comments || ''}
                                                                onChange={(e) => updateFileDetails(currentFile._id, 'comments', e.target.value)}
                                                                readOnly={!isEditMode}
                                                                className={isEditMode ? 'textarea-editable' : 'textarea-readonly'}
                                                            />
                                                        </div>
                                                        <div className='info-form-group '>
                                                            <label>Company Info:</label>
                                                            <textarea
                                                                value={fileDetails[currentFile._id]?.companyInfo || ''}
                                                                onChange={(e) => updateFileDetails(currentFile._id, 'companyInfo', e.target.value)}
                                                                readOnly={!isEditMode}
                                                                className={isEditMode ? 'textarea-editable' : 'textarea-readonly'}
                                                            />
                                                        </div>
                                                        <div className={`info-form-group link-tags ${isEditMode ? 'editing-mode' : ''}`}>
                                                            <label>Tags:</label>
                                                            {isEditMode && (
                                                                <input
                                                                    className="input-tag"
                                                                    type="text"
                                                                    value={newTag} // Assumes you have a state [newTag, setNewTag] for handling the input value
                                                                    onChange={(e) => setNewTag(e.target.value)}
                                                                    readOnly={!isEditMode}
                                                                    onKeyPress={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            addTag(newTag); // Add the tag
                                                                            setNewTag(''); // Clear the input field
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                />
                                                            )}

                                                            {/* Display tags dynamically based on the current file */}
                                                            <div className='tag-info'>
                                                                {fileDetails[currentFile._id]?.tags.map((tag, index) => (
                                                                    <span key={index} className="tag-tile">
                                                                        {tag}
                                                                        {isEditMode && (
                                                                            <span className="tag-remove-btn" onClick={() => removeTag(currentFile._id, index)}>×</span>
                                                                        )}
                                                                    </span>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        {isEditMode && (
                                                            <div className='btn-fileinfo'>

                                                                <Button className='doneFileinfo' variant="primary" onClick={saveChanges}>Done</Button>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <button onClick={closeOverlayInfo} className='x-btn' style={{ position: 'absolute', top: '20px', right: '20px', fontSize: '20px' }}>X</button>
                                                </div>
                                            </div>
                                        )}


                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id={`tooltip-invite`}>Share</Tooltip>}
                                        >
                                            <Button variant="light" onClick={(event) => {
                                                event.stopPropagation();
                                                // Add invite functionality here
                                            }}>
                                                <FontAwesomeIcon icon={faUserPlus} />
                                            </Button>
                                        </OverlayTrigger>

                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id={`tooltip-delete`}>Delete</Tooltip>}
                                        >
                                            <Button variant="light" onClick={(event) => {
                                                event.stopPropagation();
                                                deleteLink(file._id, file.name); // Call the deleteLink function with the file ID and name
                                            }}>
                                                <FontAwesomeIcon icon={faTrashAlt} />
                                            </Button>
                                        </OverlayTrigger>
                                    </div>
                                </li>
                            ))}
                        </ul>

                    )}

                </div>

                <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    paginate={setCurrentPage}
                    currentPage={currentPage}
                    setItemsPerPage={setItemsPerPage} // Pass the state setter as a prop
                />
                {isOverlayLoading && (
                    <div className="overlayLoading">
                        <div className="overlayspinner"></div>
                    </div>
                )}


            </div>
            {notification.message && (
                <Notification type={notification.type} message={notification.message} />
            )}
        </div>
    )
}

export default Links