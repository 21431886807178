// src/components/Breadcrumbs.js
import React, { useEffect, useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './breadcrumbs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGreaterThan, faLink } from '@fortawesome/free-solid-svg-icons';
import { UserContext, useUser } from '../context/userContext';
import axios from 'axios';

const capitalizeString = (str) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const [itemNames, setItemNames] = useState({});
  const [parentNames, setParentNames] = useState({});
  const [parentTokens, setParentTokens] = useState({});
  const { user } = useContext(UserContext);
  const userId = user?.userId;
  const [sharedLockerNames, setSharedLockerNames] = useState({});

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get('https://pages.onepgr.com/session/get-all-databank-objects', {
          params: {
            user_id: userId,
          },
        });
        console.log(response);
        const data = response.data.data;
        const items = data;

        const names = {};
        const parents = {};
        const tokens = {};
        for (const item of items) {
          names[item.token] = item.name;

          // Check if the item has a parent ID
          if (item.parent_id) {
            // Find the parent item based on the parent ID
            const parentItem = items.find((parentItem) => parentItem.token === item.parent_id);
            if (parentItem) {
              parents[item.token] = parentItem.name;
              tokens[item.token] = parentItem.token;
            }
          }
        }
        setItemNames(names);
        setParentNames(parents);
        setParentTokens(tokens);
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };
    fetchItems();
  }, [userId]);

  useEffect(() => {
    const fetchSharedLockers = async () => {
      try {
        const response = await axios.get('https://pages.onepgr.com/session/get-shared-lockers', {
          params: {
            user_id: userId,
          },
        });
        const sharedLockers = response.data.data;
        const names = {};
        for (const locker of sharedLockers) {
          names[locker.token] = locker.name;
        }
        setSharedLockerNames(names);
      } catch (error) {
        console.error('Error fetching shared lockers:', error);
      }
    };
    fetchSharedLockers();
  }, [userId]);


return (
  <div className="breadcrumbs">
    <Link to="/"></Link>
    {pathnames.map((value, index) => {
      const last = index === pathnames.length - 1;
      const to = `/${pathnames.slice(0, index + 1).join('/')}`;
      
      let itemName = value;
      if (index === 2 && pathnames[1] === 'shared-with-me' && pathnames[2] === 'lockers') {
        itemName = sharedLockerNames[pathnames[3]] || pathnames[3];
      } else if (index === 3 && pathnames[1] === 'shared-with-me' && pathnames[2] === 'lockers') {
        itemName = sharedLockerNames[value] || value;
      } else if (index === 2) {
        itemName = itemNames[value] || value;
      }
      
      const parentName = parentNames[value] || '';
      const parentToken = parentTokens[value] || '';
      const capitalizedItemName = last ? capitalizeString(itemName) : capitalizeString(itemName);
      const capitalizedParentName = capitalizeString(parentName);
      const parentTo = `/${pathnames.slice(0, index).join('/')}/${parentToken}`;

      return (
        <React.Fragment key={`breadcrumb-${index}`}>
          {capitalizedParentName && (
            <>
              <Link to={parentTo}>{capitalizedParentName}</Link>
              <FontAwesomeIcon icon={faGreaterThan} className="greater-than" />
            </>
          )}
          {last ? (
            <span>{capitalizedItemName}</span>
          ) : (
            <Link to={to}>
              {capitalizedItemName}
              <FontAwesomeIcon icon={faGreaterThan} className="greater-than" />
            </Link>
          )}
        </React.Fragment>
      );
    })}
  </div>
);
};


export default Breadcrumbs;