
import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import './enrichDataApp.css';
import Notification from '../../notification /notification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faBuilding, faIdBadge, faIdCard, faIndustry, faLocation, faLocationArrow, faLocationDot, faLocationPin, faLocationPinLock, faUser, faUserCircle, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { fetchSavedData } from './dummyData';
import OnePgrSaveInterface from './saveToContacts';
import { UserContext, useUser } from '../../context/userContext';
import DropdownData from '../../search/dropdownData';
import { useNavigate, useLocation } from 'react-router-dom';

const { TITLES, TEAM_SIZES, INDUSTRIES, CountriesData } = DropdownData;

const EnrichDataApp = () => {
    const navigate = useNavigate();
    const locationHook = useLocation();
    const { user, profileData } = useContext(UserContext);
    const userId = user?.userId;
    const [activeTab, setActiveTab] = useState('company');
    const [activeButtonTab, setActiveButtonTab] = useState('import');
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedColumn, setSelectedColumn] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [showTitleDropdown, setShowTitleDropdown] = useState(false);
    const [filteredTitles, setFilteredTitles] = useState(TITLES);
    const titleInputRef = useRef(null);
    const [results, setResults] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const resultsPerPage = 20;
    const [showOptionsDropdown, setShowOptionsDropdown] = useState(false);
    const [selectedItems, setSelectedItems] = useState({});
    const dropdownRef = useRef(null);
    const [showSaveInterface, setShowSaveInterface] = useState(false);
    const [siteId, setSiteId] = useState('');
    const [inputs, setInputs] = useState({
        domains: '',
        page: 1,
        perPage: 20,
        locations: '',
        seniorities: '',
        employeeRanges: '1,1000000',
        titles: ''
    });
    const [selectedTitles, setSelectedTitles] = useState([]);
    const [combinedTitles, setCombinedTitles] = useState('');
    const [dragActive, setDragActive] = useState(false);
    const fileInputRef = useRef(null);
    const [showOverlay, setShowOverlay] = useState(false);
    const [notification, setNotification] = useState({ message: '', type: '' });
    const isAnyItemSelected = Object.keys(selectedItems).length > 0;
    const [enrichedRows, setEnrichedRows] = useState({});


    //     let apollo_api_key;
    // try {
    //   apollo_api_key = profileData?.api_keys?.apollo_io?.key;
    //   if (!apollo_api_key) {
    //     console.error('Apollo API key not found in profile data');
    //   }
    // } catch (error) {
    //   console.error('Error accessing Apollo API key:', error);
    // }

    console.log(siteId)





    useEffect(() => {
        if (inputs.domains) {
            findPeople(inputs.page, inputs.perPage);
        }
    }, []);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowOptionsDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (titleInputRef.current && !titleInputRef.current.contains(event.target)) {
                setShowTitleDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    console.log(inputs)

    const getRowClassName = (person) => {
        if (enrichedRows[person.id]) {
            return 'enriched-row';
        }
        if (selectedItems[person.id]) {
            return 'selected-row';
        }
        return '';
    };

    const showNotification = (message, type) => {
        setNotification({ message, type });
        setTimeout(() => setNotification({ message: '', type: '' }), 3000);
    };

    // const toggleOptionsDropdown = () => {
    //     if (Object.keys(selectedItems).length > 0) {
    //         setShowOptionsDropdown(!showOptionsDropdown);
    //     } else {
    //         alert('Select contact to proceed');
    //     }
    // };

    const handleOptionsClick = (e) => {
        e.preventDefault();
        const isAnyItemSelected = Object.keys(selectedItems).length > 0;

        if (!isAnyItemSelected) {
            showNotification('Select contact to proceed', 'warning');
            return;
        }

        if (!siteId) {
            showNotification('Select enrich source to proceed', 'warning');
            return;
        }

        setShowOptionsDropdown(!showOptionsDropdown);
    };
    const handleCancel = () => {
        setShowSaveInterface(false);
    };



    const handleTitleButtonClick = (title) => {
        setSelectedTitles(prev =>
            prev.includes(title) ? prev.filter(t => t !== title) : [...prev, title]
        );
    };

    const handleJobTitleChange = (event) => {
        const value = event.target.value;
        setJobTitle(value);
        setFilteredTitles(TITLES.filter(title =>
            title.toLowerCase().includes(value.toLowerCase())
        ));
        setShowTitleDropdown(true);
    };

    const handleTitleSelect = (title) => {
        setJobTitle(title);
        setShowTitleDropdown(false);
    };

    useEffect(() => {
        const combinedTitles = [...selectedTitles, ...jobTitle.split(',').map(t => t.trim())]
            .filter(Boolean)
            .join(',');
        setInputs(prev => ({ ...prev, titles: combinedTitles }));
    }, [selectedTitles, jobTitle]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputs(prev => {
            let newValue = value;
            if (name === 'page' || name === 'perPage') {
                newValue = parseInt(value, 10);
                if (isNaN(newValue)) {
                    newValue = name === 'page' ? 1 : 20; // Default values
                }
            }
            console.log(`Input "${name}" changed to:`, newValue);
            return { ...prev, [name]: newValue };
        });
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleColumnChange = (event) => {
        setSelectedColumn(event.target.value);
    };

    const handlePageChange = (newPage) => {
        console.log('handlePageChange called with:', newPage);
        const parsedPage = parseInt(newPage, 10);
        if (isNaN(parsedPage)) {
            console.error('Invalid page number:', newPage);
            return;
        }
        setInputs(prev => ({ ...prev, page: parsedPage }));
        findPeople(parsedPage, inputs.perPage);
    };


    const handlePerPageChange = (e) => {
        const newPerPage = parseInt(e.target.value, 10);
        console.log('handlePerPageChange called with:', newPerPage);
        if (isNaN(newPerPage)) {
            console.error('Invalid perPage value:', e.target.value);
            return;
        }
        setInputs(prev => ({ ...prev, perPage: newPerPage, page: 1 }));
        findPeople(1, newPerPage);
    };

    useEffect(() => {
        if (results) {
            findPeople();
        }
    }, [inputs.page]);

    const downloadCSV = () => {
        const people = formatResults();
        const headers = ["Company", "Name", "Job Title", "Email", "Phone", "LinkedIn URL", "Location"];
        const csvContent = [
            headers.join(','),
            ...people.map(person => [
                person.company,
                person.name,
                person.jobTitle,
                person.email,
                person.phone,
                person.linkedin_url,
                person.location
            ].map(field => `"${field}"`).join(','))
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "people_data.csv");
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const isFormValid = () => {
        return inputs.domains.trim() !== '';
    };




    // const findPeople = async (page = inputs.page, perPage = inputs.perPage) => {
    //     if (!isFormValid()) {
    //         setError('Please fill in the required company domain field.');
    //         return;
    //     }
    //     setLoading(true);
    //     setError(null);

    //     try {
    //         const response = await axios.post('http://localhost:3002/search-people', {
    //             domains: inputs.domains.split(',').map(domain => domain.trim()),
    //             page: page,
    //             perPage: perPage,
    //             locations: inputs.locations.split(',').map(location => location.trim()),
    //             seniorities: inputs.seniorities.split(',').map(seniority => seniority.trim()),
    //             employeeRanges: [inputs.employeeRanges],
    //             titles: inputs.titles.split(',').map(title => title.trim())
    //         });

    //         setResults(response.data);
    //         console.log('API Response:', response.data);
    //         setShowOverlay(true);
    //         setTotalItems(response.data.pagination.total_entries);
    //         setInputs(prev => ({ ...prev, page: page, perPage: perPage }));
    //     } catch (err) {
    //         setError('Failed to fetch data from Apollo API');
    //         console.error(err);
    //     } finally {
    //         setLoading(false);
    //     }
    // };


    const findPeople = async (page = inputs.page, perPage = inputs.perPage) => {
        if (!isFormValid()) {
            showNotification('Please fill in the required company domain field.', 'error');
            return;
        }
        setLoading(true);
        setError(null);

        try {
            const requestData = {
                domains: inputs.domains.split(',').map(domain => domain.trim()),
                page: isNaN(page) ? 1 : parseInt(page, 10),
                perPage: isNaN(perPage) ? 20 : parseInt(perPage, 10),
                locations: inputs.locations.split(',').map(location => location.trim()),
                seniorities: inputs.seniorities.split(',').map(seniority => seniority.trim()),
                employeeRanges: [inputs.employeeRanges],
                titles: inputs.titles.split(',').map(title => title.trim())
            };

            console.log('Request data:', requestData);

            const response = await axios.post('https://crawl.onepgr.com:3002/search-people', requestData, {
                headers: {
                    'User-ID': userId,
                }
            });
            console.log('API Response:', response.data);
            if (response.data && response.data.pagination && response.data.pagination.total_entries === 0) {
                showNotification('No results found for the given criteria.', 'info');
                setResults([]);
                setTotalItems(0);
            } else if (response.data && Array.isArray(response.data.people) && response.data.people.length === 0) {
                showNotification('No results found for the given criteria.', 'info');
                setResults([]);
                setTotalItems(0);
            } else {
                setResults(response.data);
                setShowOverlay(true);
                setTotalItems(response.data.pagination.total_entries);
                setInputs(prev => ({
                    ...prev,
                    page: isNaN(page) ? 1 : parseInt(page, 10),
                    perPage: isNaN(perPage) ? 20 : parseInt(perPage, 10)
                }));
                showNotification('People data fetched successfully', 'success');
            }

            const searchParams = new URLSearchParams();
            if (inputs.domains) searchParams.set('companies', inputs.domains);
            if (inputs.locations) searchParams.set('location', inputs.locations);
            if (inputs.titles) searchParams.set('jobtitle', inputs.titles);

            navigate(`${locationHook.pathname}?${searchParams.toString()}`, { replace: true });

            navigate(`${locationHook.pathname}?${searchParams.toString()}`, { replace: true });

        } catch (error) {
            let errorMessage = 'Failed to fetch data from Apollo API';
            if (error.response && error.response.data && error.response.data.error) {
                errorMessage = error.response.data.error;
            }
            showNotification(error.response?.data?.details, 'error');
            console.error('API Error:', error);

            // If you want to display more detailed error information (for debugging)
            if (process.env.NODE_ENV === 'development') {
                console.error('Detailed error:', error.response?.data?.details);
            }
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        const searchParams = new URLSearchParams(locationHook.search);
        const companies = searchParams.get('companies');
        const locationParam = searchParams.get('location');
        const jobtitle = searchParams.get('jobtitle');

        if (companies || locationParam || jobtitle) {
            setInputs(prevInputs => ({
                ...prevInputs,
                domains: companies || '',
                locations: locationParam || '',
                titles: jobtitle || ''
            }));
        }
    }, [locationHook.search]);

    const handleOptionSelect = async (option) => {
        console.log(`Selected option: ${option}`);
        setShowOptionsDropdown(false);

        let enrichType;
        if (siteId === 'skrapp') {
            enrichType = 'email';
        } else {
            switch (option) {
                case 'enrich-email':
                    enrichType = 'email';
                    break;
                case 'enrich-phone':
                    enrichType = 'phone';
                    break;
                case 'enrich-both':
                    enrichType = 'both';
                    break;
                default:
                    console.error('Invalid option selected');
                    return;
            }
        }

        const selectedPeople = Object.values(selectedItems);
        if (selectedPeople.length === 0) {
            showNotification('No contacts selected for enrichment', 'warning');
            return;
        }
        await updateEnrichedData(selectedPeople, enrichType);
    };

    const enrichPeople = async (peopleData, enrichType) => {
        try {
            const response = await fetch('https://crawl.onepgr.com:3002/enrich-people', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    people: peopleData,
                    enrichType: enrichType,
                    userId: userId,
                    siteId: siteId,
                }),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Network response was not ok');
            }

            console.log('Enriched people data:', data);

            // Check if the data is [null] or an empty array
            if (Array.isArray(data) && (data.length === 0 || data.every(item => item === null))) {
                showNotification('No enrichment data found', 'warning');
                return { success: false, data: [], message: 'No enrichment data found' };
            }

            showNotification('Contacts enriched successfully', 'success');
            return { success: true, data };
        } catch (error) {
            console.error('Error enriching people data:', error);
            showNotification(`Error: ${error}`, 'error');
            return { success: false, error: error.message };
        }
    };



    const updateEnrichedData = async (selectedPeople, enrichType) => {
        try {
            setLoading(true);
            const result = await enrichPeople(selectedPeople, enrichType);

            if (result.success && result.data.length > 0) {
                const enrichedData = result.data;
                setResults(prevResults => {
                    if (!prevResults || !prevResults.people || !Array.isArray(prevResults.people)) {
                        console.error('Invalid prevResults structure:', prevResults);
                        return prevResults;
                    }

                    const updatedPeople = prevResults.people.map(person => {
                        const enrichedPerson = enrichedData.find(ep => ep && ep.id === person.id);
                        if (enrichedPerson) {
                            console.log('Enriched person data:', enrichedPerson);
                            const updatedFields = {};
                            Object.keys(enrichedPerson).forEach(key => {
                                if (enrichedPerson[key] !== person[key] && enrichedPerson[key] != null) {
                                    updatedFields[key] = true;
                                }
                            });

                            return {
                                ...person,
                                ...enrichedPerson,
                                first_name: enrichedPerson.first_name || person.first_name,
                                last_name: enrichedPerson.last_name || person.last_name,
                                name: `${enrichedPerson.first_name || person.first_name} ${enrichedPerson.last_name || person.last_name}`,
                                email: enrichedPerson.email || 'Email not found',
                                phone: enrichedPerson.phone || enrichedPerson.mobile_number || 'Phone not found',
                                organization: {
                                    ...(person.organization || {}),
                                    name: enrichedPerson.company || enrichedPerson.organization_name || person.organization?.name,
                                    website_url: enrichedPerson.domain || person.organization?.website_url,
                                },
                                updatedFields: updatedFields
                            };
                        }
                        return { ...person, updatedFields: {} };
                    });

                    console.log('Updated people:', updatedPeople);
                    return {
                        ...prevResults,
                        people: updatedPeople
                    };
                });

                setSelectedItems(prevSelected => {
                    const newSelected = { ...prevSelected };
                    enrichedData.forEach(enrichedPerson => {
                        if (enrichedPerson && enrichedPerson.id) {
                            console.log('Updating selected item:', enrichedPerson.id); // Debug log
                            newSelected[enrichedPerson.id] = {
                                ...enrichedPerson,
                                email: enrichedPerson.email || 'Email not found',
                                phone: enrichedPerson.phone || enrichedPerson.mobile_number || 'Phone not found',
                                company: enrichedPerson.company || enrichedPerson.organization_name,
                            };
                        }
                    });
                    console.log('Updated selected items:', newSelected); // Debug log
                    return newSelected;
                });

                setEnrichedRows(prevEnrichedRows => {
                    const newEnrichedRows = { ...prevEnrichedRows };
                    enrichedData.forEach(enrichedPerson => {
                        if (enrichedPerson && enrichedPerson.id) {
                            newEnrichedRows[enrichedPerson.id] = true;
                        }
                    });
                    return newEnrichedRows;
                });

                showNotification('Contacts enriched successfully', 'success');
            } else if (result.message === 'No enrichment data found') {
                showNotification('No enrichment data found for selected contacts', 'warning');
                // Optionally, you can update the state to reflect that enrichment was attempted but no data was found
                setEnrichedRows(prevEnrichedRows => {
                    const newEnrichedRows = { ...prevEnrichedRows };
                    selectedPeople.forEach(person => {
                        newEnrichedRows[person.id] = 'no_data';
                    });
                    return newEnrichedRows;
                });
            } else {
                throw new Error(result.error || 'Failed to enrich contacts');
            }
        } catch (error) {
            console.error('Error updating enriched data:', error);
            showNotification(`Failed to enrich contacts: ${error}`, 'error');
        } finally {
            setLoading(false);
        }
    };

    const formatResults = () => {
        if (!results || !results.people) return [];
        return results.people.map(person => ({
            id: person.id || Math.random().toString(36).substr(2, 9),
            name: person.name,
            first_name: person.first_name || '',
            last_name: person.last_name || '',
            company: person.organization ? person.organization.name : 'N/A',
            domain: person.organization?.website_url || '',
            email: person.email || 'N/A',
            hashed_email: person.hashed_email || '',
            organization_name: person.organization?.name || '',
            linkedin_url: person.linkedin_url || '',
            // Include any other fields you want to display in the table
            jobTitle: person.title,
            phone: person.mobile_number || person.phone_numbers?.[0]?.raw_number || '',
            location: `${person.city || ''}, ${person.state || ''}, ${person.country || ''}`,
            updatedFields: person.updatedFields || {}
        }));
    };



    const handleDomainPaste = (e) => {
        e.preventDefault();
        const pastedText = e.clipboardData.getData('text');
        const formattedText = pastedText
            .split(/[\s,]+/) // Split by whitespace or commas
            .map(item => item.trim())
            .filter(Boolean) // Remove empty items
            .join(', '); // Join with comma and space

        setInputs(prev => ({
            ...prev,
            domains: prev.domains ? `${prev.domains}, ${formattedText}` : formattedText
        }));
    };

    const closeOverlay = () => {
        setShowOverlay(false);
        setResults(null);
        setSelectedItems({});
        console.log('close overlay');
    };

    const calculateEntryRange = () => {
        if (!results || !results.pagination) return '';
        const start = (inputs.page - 1) * inputs.perPage + 1;
        const end = Math.min(inputs.page * inputs.perPage, results.pagination.total_entries);
        return `${start}-${end} of ${results.pagination.total_entries}`;
    };


    const handleClear = () => {
        setInputs({
            domains: '',
            page: 1,
            perPage: 20,
            locations: '',
            seniorities: '',
            employeeRanges: '1,1000000',
            titles: ''
        });
        setSelectedTitles([]);
        setJobTitle('');
        setError(null);
        navigate(locationHook.pathname, { replace: true });
    };

    const handleCheckboxChange = (person) => {
        setSelectedItems(prevSelected => {
            const newSelected = { ...prevSelected };
            if (newSelected[person.id]) {
                delete newSelected[person.id];
            } else {
                newSelected[person.id] = person;
            }
            return newSelected;
        });
    };

    const isItemSelected = (person) => {
        return !!selectedItems[person.id];
    };

    useEffect(() => {
        console.log('Selected items:', Object.values(selectedItems));
    }, [selectedItems]);


    const downloadSelectedCSV = () => {
        const selectedContacts = Object.values(selectedItems);
        if (selectedContacts.length === 0) {
            showNotification('No contacts selected', 'warning');
            return;
        }

        const headers = ["Name", "Company", "Job Title", "Email", "Phone", "LinkedIn URL", "Location"];
        const csvContent = [
            headers.join(','),
            ...selectedContacts.map(contact => [
                contact.name,
                contact.company,
                contact.jobTitle,
                contact.email,
                contact.phone,
                contact.linkedin_url,
                contact.location
            ].map(field => `"${field || ''}"`).join(','))
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "selected_contacts.csv");
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        showNotification('Selected contacts downloaded successfully', 'success');
    };
    const isAllSelected = () => {
        const currentPageContacts = formatResults();
        return currentPageContacts.every(contact => selectedItems[contact.id]);
    };

    const handleSelectAll = (event) => {
        const isChecked = event.target.checked;
        const currentPageContacts = formatResults();

        setSelectedItems(prevSelected => {
            const newSelected = { ...prevSelected };
            currentPageContacts.forEach(contact => {
                if (isChecked) {
                    newSelected[contact.id] = contact;
                } else {
                    delete newSelected[contact.id];
                }
            });
            return newSelected;
        });
    };

    const handleSaveToOnePgr = () => {
        setShowSaveInterface(true);
    };


    const createContactList = (name) => {
        const body = new FormData();
        body.append("name", name);
        body.append("description", "1");
        body.append("list_type", "contact");
        return axios.post("https://onepgr.com/lists/create_api?onepgr_apicall=1&xhr_flag=1", body, { withCredentials: true });
    };

    const uploadMultipleContacts = (contacts_list) => {
        console.log(contacts_list);
        const body = new FormData();
        body.append("contacts_list", JSON.stringify(contacts_list));
        body.append("import_source_id", "3");
        body.append("import_source_name", "import-contact");
        return axios.post("https://onepgr.com/contacts/create2_multi_contacts_api?onepgr_apicall=1&xhr_flag=1", body, { withCredentials: true });
    };

    const handleSave = async (listName) => {
        if (Object.keys(selectedItems).length === 0) {
            setNotification({ message: 'No contacts selected', type: 'warning' });
            return;
        }
        setLoading(true);
        setShowSaveInterface(false);
        try {
            const listResponse = await createContactList(listName);
            console.log(listResponse.data.response);
            const listData = JSON.parse(listResponse.data.response);
            const listId = listData.list_id;
            console.log(listId);

            const selectedContacts = Object.values(selectedItems).map(contact => {
                const [city, state, country] = contact.location.split(', ');
                return {
                    contact_id: "",
                    name: contact.name || null,
                    email: contact.email || null,
                    phone: contact.phone || null,
                    mobile: null,
                    twitter: "",
                    facebook: null,
                    linkedin: contact.linkedin_url || null,
                    skype: null,
                    status: null,
                    add_nu: null,
                    add_str1: null,
                    add_str2: null,
                    add_city: city || null,
                    add_state: state || null,
                    add_zip: null,
                    add_country: country || null,
                    notes: null,
                    company: contact.company || null,
                    account_id: "",
                    account_name: "",
                    account_state: "",
                    tags: null,
                    page_id: "",
                    contact_type: "",
                    title: contact.jobTitle || "",
                    role_sales_process: "",
                    import_source_id: "3",
                    import_source_name: "import-contact",
                    ref_user_id: "",
                    firstname: contact.first_name || null,
                    lastname: contact.last_name || null,
                    email_2: null,
                    email_3: null,
                    company_url: contact.domain || null,
                    list_id: listId,
                    autom_seq_disabled: null,
                    unsubscribed: null,
                    segment_id: null,
                    crm_object_value: null,
                    co_employee_size: null,
                    co_street1: null,
                    co_street2: null,
                    co_city: city || null,
                    co_zip: null,
                    co_country: country || null,
                    co_phone: null,
                    co_linkedin: null,
                    co_twitter: null,
                    lead_status: null,
                    custom_fields_json: JSON.stringify({
                        "Lead Location": contact.location,
                        "Industry": "N/A",
                        "Upload Date": new Date().toLocaleDateString(),
                        "Full Name": contact.name,
                        "List Name": listName
                    }),
                    updated_at: new Date().toLocaleString('en-US', {
                        day: '2-digit',
                        month: 'short',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true
                    })
                };
            });

            const contactList = {
                contacts: selectedContacts
            };
            const response = await uploadMultipleContacts(contactList);
            console.log(response.data.response);
            const responseData = JSON.parse(response.data.response);
            console.log(responseData);

            if (responseData.success === '0' && responseData.success_msg) {
                showNotification(`${responseData.success_count} contacts saved to OnePgr list "${listName}"`, 'success');
                setSelectedItems({}); // Clear selected items after successful save
            } else if (responseData.success === '1') {
                showNotification(`Contacts saved to OnePgr list "${listName}" successfully`, 'success');
                setSelectedItems({}); // Clear selected items after successful save
            } else {
                throw new Error('Unexpected response from server');
            }
        } catch (error) {
            console.error('Error saving contacts to OnePgr:', error);
            showNotification(`Failed to save contacts to OnePgr list "${listName}"`, 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleSourceChange = (event) => {
        const selectedSource = event.target.value;
        let newSiteId = '';

        switch (selectedSource) {
            case 'apollo':
                newSiteId = 'apollo'; // Assuming '1' is the siteId for Apollo.io
                break;
            case 'leadmagic':
                newSiteId = 'leadmagic'; // Assuming '2' is the siteId for LeadMagic.io
                break;
            case 'skrapp':
                newSiteId = 'skrapp';
                break;
            default:
                newSiteId = '';
        }

        setSiteId(newSiteId);
    };



    return (
        <div className="enrich-data-container">
            <div className="header-tabs">
                <h2>Find prospect</h2>

                {/* <div className="enrich-tabs">
                    <button
                        onClick={() => setActiveTab('company')}
                        className={activeTab === 'company' ? 'active-tab' : ''}
                    >
                        <p>Find People</p>
                    </button>
                    <button
                        onClick={() => setActiveTab('people')}
                        className={activeTab === 'people' ? 'active-tab' : ''}
                    >
                        <p>Enrich People</p>
                    </button>
                </div> */}
            </div>

            <div className="content">

                <div className="enrich-filter-section">
                    <div className="filter-section-container">
                        <div className="companies-section">
                            <h3> <FontAwesomeIcon icon={faBuilding} className='user-icon'></FontAwesomeIcon>Companies</h3>
                            <div className="comapny-filter-section">
                                {/* <div >
                                    <label htmlFor="columnSelect">Column: </label>
                                    <select id="columnSelect" value={selectedColumn} onChange={handleColumnChange}>
                                        <option value="">Select a column</option>
                                        <option value="name">Name</option>
                                        <option value="domain">Domain</option>
                                    </select>
                                </div> */}
                                <div>
                                    <label htmlFor="columnSelect">Enter company domain: </label>
                                    <input
                                        type="text"
                                        id="domains"
                                        name="domains"
                                        value={inputs.domains}
                                        onChange={handleInputChange}
                                        onPaste={handleDomainPaste}
                                        required
                                        placeholder="e.g. google.com, apple.com"

                                    />
                                </div>
                                <div className="location-section">
                                    <h3><FontAwesomeIcon icon={faLocationDot} className='user-icon'></FontAwesomeIcon>Location</h3>
                                    <div className='filter-location-input'>
                                        <label htmlFor="columnSelect">Enter city, state, or country: </label>
                                        <input
                                            type="text"
                                            id="locations"
                                            name="locations"
                                            value={inputs.locations}
                                            onChange={handleInputChange}
                                            placeholder="e.g. california, canada"

                                        />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="job-title-section">
                            <h3><FontAwesomeIcon icon={faIdCard} className='user-icon'></FontAwesomeIcon>Job Title</h3>
                            <div className="filter-buttons">
                                {['Owner', 'Partner', 'C Suite', 'VP', 'Manager', 'Director', 'Head'].map((title) => (
                                    <button
                                        key={title}
                                        className={`enrich-button ${selectedTitles.includes(title) ? 'selected' : ''}`}
                                        onClick={() => handleTitleButtonClick(title)}
                                    >
                                        {title}
                                    </button>
                                ))}
                            </div>
                            <div className="title-input-container" ref={titleInputRef}>
                                <label>Enter job title:</label>
                                <input
                                    type="text"
                                    placeholder="e.g. founder, Vice President, CEO"
                                    value={jobTitle}
                                    onChange={handleJobTitleChange}
                                    onFocus={() => setShowTitleDropdown(true)}
                                />
                                {showTitleDropdown && (
                                    <ul className="title-dropdown">
                                        {filteredTitles.map((title, index) => (
                                            <li key={index} onClick={() => handleTitleSelect(title)}>
                                                {title}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                        {/* <div className="location-section">
                            <h3>Location</h3>
                            <div className='filter-location-input'>
                                <label htmlFor="columnSelect">Include city, state, or country: </label>
                                <input
                                    type="text"
                                    placeholder="e.g. california, canada"

                                />
                            </div>
                        </div> */}
                    </div>
                    <div className="filter-container-buttons">
                        <button type="button" className="enrich-button" onClick={handleClear}>Clear</button>
                        <button
                            type="button"
                            className="enrich-button"
                            onClick={findPeople}
                        // disabled={!isFormValid() || loading}
                        >
                            {loading ? 'Searching...' : 'Find People'}
                        </button>
                    </div>
                </div>

            </div>

            {error && <p className="enrich-data-error">{error}</p>}

            {results && (

                <div className='enrich-data-results'>
                    <div className='enrich-data-table-container'>
                        <button className="close-overlay" onClick={closeOverlay}>×</button>
                        <div className='result-data-heading'>
                            <h3> <FontAwesomeIcon icon={faUserCircle} className='user-icon'></FontAwesomeIcon> Contacts found:{totalItems}   </h3>
                            {/* <hr /> */}
                        </div>
                        <div className="options-dropdown-container">
                            <div className="source-dropdown">
                                <label htmlFor="source">Enrich source: </label>
                                <select
                                    id="source"
                                    onChange={handleSourceChange}
                                    value={siteId || ''}
                                    className="source-select"
                                >
                                    <option value="">-- Select a source --</option>
                                    <option value="apollo">Apollo.io</option>
                                    <option value="leadmagic">LeadMagic.io</option>
                                    <option value="skrapp">Skrapp.io</option>
                                </select>
                            </div>
                            <button
                                className='option-btn-enrich-data'
                                onClick={handleOptionsClick}
                                style={{
                                    opacity: Object.keys(selectedItems).length > 0 ? 1 : 0.5,
                                    cursor: Object.keys(selectedItems).length > 0 ? 'pointer' : 'not-allowed'
                                }}
                            >

                                {loading ? 'enriching...' : 'Options'}
                            </button>
                            {showOptionsDropdown && (
                                <div ref={dropdownRef} className="options-dropdown-menu">
                                    <button onClick={() => handleOptionSelect('enrich-email')}>Enrich Email</button>
                                    {siteId !== 'skrapp' && (
                                        <>
                                            <button onClick={() => handleOptionSelect('enrich-phone')}>Enrich Phone No</button>
                                            <button onClick={() => handleOptionSelect('enrich-both')}>Enrich Both</button>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className='enrich-data-table'>

                            <div className="enrich-data-table-table">
                                <table >
                                    <thead>
                                        <tr>

                                            <th>
                                                <input
                                                    className='enrich-data-checkbox-all'
                                                    type="checkbox"
                                                    onChange={handleSelectAll}
                                                    checked={isAllSelected()}
                                                />
                                                Name</th>
                                            <th>Company</th>

                                            <th>Job Title</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>LinkedIn URL</th>
                                            <th>Location</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formatResults().map((person, index) => (
                                            <tr
                                                key={index}
                                                className={selectedItems[person.id] ? 'selected-row' : ''}
                                            >
                                                <td className='enrich-table-name-field'>
                                                    <input
                                                        type="checkbox"
                                                        className='enrich-data-checkbox'
                                                        checked={isItemSelected(person)}
                                                        onChange={() => handleCheckboxChange(person)}
                                                    />
                                                    <span className={person.updatedFields && person.updatedFields.name ? 'updated-field' : ''}>
                                                        {person.name}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className={person.updatedFields && person.updatedFields.company ? 'updated-field' : ''}>
                                                        {person.company}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className={person.updatedFields && person.updatedFields.jobTitle ? 'updated-field' : ''}>
                                                        {person.jobTitle}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className={person.updatedFields && person.updatedFields.email ? 'updated-field' : ''}>
                                                        {person.email}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className={person.updatedFields && person.updatedFields.phone ? 'updated-field' : ''}>
                                                        {person.phone}
                                                    </span>
                                                </td>
                                                <td>
                                                    {person.linkedin_url && person.linkedin_url !== 'N/A' ? (
                                                        <a
                                                            href={person.linkedin_url}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className={person.updatedFields?.linkedin_url ? 'updated-field' : ''}
                                                        >
                                                            LinkedIn Profile
                                                        </a>
                                                    ) : (
                                                        <span>N/A</span>
                                                    )}
                                                </td>
                                                <td>
                                                    <span className={person.updatedFields.location ? 'updated-field' : ''}>
                                                        {person.location}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>

                        </div>
                        <div className='enrich-data-footer'>
                            <div className='pagination'>
                                <button
                                    onClick={() => handlePageChange(inputs.page - 1)}
                                    disabled={inputs.page <= 1}
                                >
                                    Previous
                                </button>
                                <span>Page {inputs.page}</span>
                                <button
                                    onClick={() => handlePageChange(inputs.page + 1)}
                                    disabled={!results.pagination || inputs.page >= results.pagination.total_pages}
                                >
                                    Next
                                </button>
                                <span className="entry-range">{calculateEntryRange()}</span>
                                <div className="items-per-page">
                                    <label htmlFor="perPage">Items per page:</label>
                                    <select
                                        id="perPage"
                                        value={inputs.perPage}
                                        onChange={handlePerPageChange}
                                    >
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                    </select>
                                </div>
                            </div>
                            <div className='enrich-table-download-buttons'>
                                {Object.keys(selectedItems).length > 0 && (
                                    <button
                                        className='download-save-to-onepgr'
                                        onClick={handleSaveToOnePgr}
                                    // disabled={loading}
                                    >
                                        Save to OnePgr Contact List
                                    </button>
                                )}
                                {showSaveInterface && (
                                    <OnePgrSaveInterface onSave={handleSave} onCancel={handleCancel} />
                                )}
                                <button className='download-csv' onClick={downloadCSV}>
                                    Download page item Contacts ({inputs.perPage})
                                </button>
                                {Object.keys(selectedItems).length > 0 && (
                                    <button className='download-selected-csv' onClick={downloadSelectedCSV}>
                                        Download Selected Contacts as CSV
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {notification.message && (
                <Notification type={notification.type} message={notification.message} />
            )}
        </div>
    );
};

export default EnrichDataApp;