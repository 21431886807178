import { faSearch, faArrowDown, faArrowUp, faLock, faFilePdf, faFileCsv, faFilePowerpoint, faFileImage, faFileArchive, faFileExcel, faFile } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import './fileSearch.css'
import { useState, useContext, useCallback, useEffect } from "react";
import { UserContext, useUser } from '../context/userContext';
import axios from "axios";
import debounce from "lodash/debounce";
import Notification from "../notification /notification";
import Pagination from "../s3dataBase/fileList/pagination";


const FileSearch = () => {
    const [searchKeyword, setSearchKeyword] = useState("");
    const { formatDate } = useUser();

    const [searchResults, setSearchResults] = useState([]);
    const { user, viewFile } = useContext(UserContext);
    const [totalItems, setTotalItems] = useState(0);
    const [notification, setNotification] = useState({ message: '', type: '' });
    const [sortOrder, setSortOrder] = useState('asc');

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const userId = user?.userId;


    const toggleSort = () => {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };
   

    const fileSearch = useCallback(async (keyword) => {
        try {
            const response = await axios.get("https://pages.onepgr.com/session/get-data-bank-source-files", {
                params: {
                    user_id: userId,
                    search: keyword,
                    page_num: currentPage,
                    nitems: itemsPerPage,
                    object_type: 'file'
                },
            });
            console.log(response);
            const data = response.data.data;
            setSearchResults(data);
            setTotalItems(response.data.total_items_count);
        } catch (error) {
            console.error("Error searching links:", error);
        }
    }, [currentPage, itemsPerPage, userId]);

    const debouncedFileSearch = useCallback(
        debounce((keyword) => {
            if (keyword.trim() === "") {
                setSearchResults([]);
                setTotalItems(0);
            } else {
                fileSearch(keyword);
            }
        }, 500),
        [fileSearch]
    );

    useEffect(() => {
        debouncedFileSearch(searchKeyword);
    }, [searchKeyword, debouncedFileSearch]);

    const handleSearchChange = (e) => {
        const keyword = e.target.value;
        setSearchKeyword(keyword);
    };


    const getIconByExtension = (url) => {
        if (!url) return faLock; // Default if no URL is provided
        const extension = url.split(".").pop().toLowerCase(); // Extract extension
        switch (extension) {
            case "pdf":
                return faFilePdf;
            case "csv":
                return faFileCsv;
            case "pptx":
                return faFilePowerpoint;
            case "xlsx":
                return faFileExcel;
            case "png":
            case "jpg":
            case "jpeg":
            case "gif":
                return faFileImage;
            case "zip":
                return faFileArchive;
            default:
                return faFile; // Default icon for unsupported or unknown file types
        }
    };

    return (
        <div className="link-search-content">
            <div className="search-bar files-search-content" style={{ display: 'flex' }}> {/* Adjusted for consistency, though conditional rendering makes 'style' unnecessary */}
                <div className="input-container">
                    <label>Search For Files:</label>
                    <div className="icon-card searchIcon" >
                        <FontAwesomeIcon icon={faSearch} />
                    </div>
                    <input
                        type="text"
                        id="searchInput"
                        placeholder="Enter Keyword To Search..."
                        value={searchKeyword}
                        onChange={handleSearchChange}
                        className='search-input files-search-input'
                    />
                    {/* <i className="fas fa-times clear-icon" onClick={clearSearch}></i> */}
                </div>

                {searchResults && searchResults.length > 0 && (
                    <div className="rdsfiles locker-search-results files-search-results">
                        <div className="table-header file-search-results-header" >
                            <span>
                                Name
                                <FontAwesomeIcon
                                    icon={sortOrder === 'asc' ? faArrowDown : faArrowUp}
                                    className="sort-icon"
                                    onClick={toggleSort}
                                />
                            </span>
                            <span>Last Updated</span>
                        </div>
                        <div className='locker-search-results'>
                            <ul className="table-list file-search-item-content">
                                {searchResults.map((result) => (
                                    <li key={result.id} className="rdstable-item file-search-item">

                                        <div className="tablename-icon file-search-item-container">
                                            <div className="result-icon file-search-item-icon">

                                                <FontAwesomeIcon className="file-search-icon"  icon={getIconByExtension(result.name)} />
                                            </div>
                                            <div className="result-details file-search-result-details" title={result.name}>
                                                <span className="rdstablename file-item-name" onClick={() => viewFile(result.source_url)}>{result.name}</span>
                                            </div>
                                        </div>

                                        <div className="table-actions-icon-rds file-search-last-updated">
                                            <div className="result-date file-search-result-date">{formatDate(result.date)}</div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="file-search-pagination">
                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={totalItems}
                            paginate={setCurrentPage}
                            currentPage={currentPage}
                            setItemsPerPage={setItemsPerPage}
                        />
                        </div>
                        {notification.message && (
                            <Notification type={notification.type} message={notification.message} />
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default FileSearch;