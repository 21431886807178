// import React, { useState, useEffect } from 'react';
// import Sidebar from '../sideBar/sideBar';
// import S3dataBase from '../s3dataBase/s3DataBase';
// import FindDuplicates from '../dataApps/findDuplicates/findDuplicates';
// import RdsUploadComponent from '../RDS-Database/rdsFileRepository';
// import Links from '../links/myLinks';
// import { Search } from '@mui/icons-material';
// import SearchSection from '../search/search-section';
// import { Outlet } from 'react-router-dom';



// const Home = () =>{
//     const [activeMenu, setActiveMenu] = useState('');
//     const [activeTitle, setActiveTitle] = useState('');
  
//     const handleMenuClick = (menuKey, menuTitle) => {
//       console.log(`Menu clicked: ${menuKey}, ${menuTitle}`);
//       setActiveMenu(menuKey);
//       // setActiveTitle(menuTitle);
//     }
//   return (
//     <div className="App">
//     <Sidebar activeMenu={activeMenu} onMenuClick={handleMenuClick} />
//     <div className="Content">
//       <h1>{}</h1>
//       {activeMenu === 'MyFiles' && <S3dataBase />}
//       {activeMenu === 'find-duplicates' && <FindDuplicates />}
//       {activeMenu === 'MyTables' && <RdsUploadComponent />}
//       {activeMenu === 'MyLinks' && <Links />}
//       {activeMenu === 'search' && <SearchSection />}
//       <Outlet /> 
//     </div>
   
//   </div>
//   )
// }

// export default Home;


import React, { useState, useEffect } from 'react';
import Sidebar from '../sideBar/sideBar';
import { Outlet, useLocation } from 'react-router-dom';
import Breadcrumbs from '../breadcrumbs/breadcrumbs';

const Home = () => {
    const [activeMenu, setActiveMenu] = useState('');
    const location = useLocation(); // Get access to the location object

    useEffect(() => {
        const pathSegments = location.pathname.split('/').filter(p => p);
        const activePath = pathSegments[1] || 'home'; // Assumes that the interesting part of the path is the second segment
        setActiveMenu(activePath);
    }, [location]);

    const handleMenuClick = (menuKey, menuTitle) => {
        console.log(`Menu clicked: ${menuKey}, ${menuTitle}`);
        setActiveMenu(menuKey);
    };

    return (
        <div className="App">
            <Sidebar activeMenu={activeMenu} onMenuClick={handleMenuClick} />
            <div className="Content">
            <div className="databank-breadcrumbs">
            <Breadcrumbs />
            </div>
                <Outlet />
            </div>
        </div>
    );
};

export default Home;
