import React, { useState , useEffect ,useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHome, FaCaretDown, FaCaretRight, FaProjectDiagram, FaWrench } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faTable, faFile, faLink, faUserFriends, faSearch, faExchangeAlt,faLock } from '@fortawesome/free-solid-svg-icons';
import { FaLock } from 'react-icons/fa'; 
import { UserContext, useUser } from '../context/userContext';
import './dropdownSidebar.css';
import axios from 'axios';


const DropdownSidebar = ({ isSidebarCollapsed, toggleSidebar }) => {
  const [expandedMenu, setExpandedMenu] = useState(null);
  const [expandedLocker, setExpandedLocker] = useState(null);
  const [activeMenu, setActiveMenu] = useState('');
  const [activeLockerToken, setActiveLockerToken] = useState(null);
  const [persistedActiveMenu, setPersistedActiveMenu] = useState(localStorage.getItem('activeMenu') || '');
  const [fetchedLockers, setFetchedLockers] = useState([]);
  const { user } = useContext(UserContext);
  const { setSelectedLocker } = useUser();
  const navigate = useNavigate();
  const userId = user?.userId;


  console.log(fetchedLockers)
  const handleMenuClick = (menuKey, route) => {
    setActiveMenu(menuKey);
    setPersistedActiveMenu(menuKey);
    localStorage.setItem('activeMenu', menuKey);
    toggleSidebar();
    navigate(route);
  };

  const handleLockerClick = (locker) => {
    setSelectedLocker(locker);
    navigate(`/home/lockers/${locker.token}/AllItems`);
    setActiveLockerToken(locker.token); 
    toggleSidebar();
  };

  const toggleSubmenu = (menu) => {
    setExpandedMenu(expandedMenu === menu ? null : menu);
  };

  const toggleDatabaseSubmenu = () => {
    setExpandedMenu(expandedMenu === 'Databases' ? null : 'Databases');
    setActiveMenu('Databank');
    setPersistedActiveMenu('Databank');
    localStorage.setItem('activeMenu', 'Databank');
  };

  const fetchLockerDetails = async () => {
    try {
      const response = await axios.get('https://pages.onepgr.com/session/get-data-bank-items', {
        params: {
          user_id: userId,
          root: '1'
        },
      });

      if (response.data.data && response.data.data.length > 0) {
        const fetchedLockers = response.data.data;
        setFetchedLockers(fetchedLockers);
      } else {
        setFetchedLockers([]);
        console.log('Response data is empty');
      }
    } catch (error) {
      console.error('Error fetching locker details:', error);
    }
  };

useEffect(() => {
  fetchLockerDetails();
}, [userId]);



  return (
    <div className={`dropdown-sidebar ${isSidebarCollapsed ? 'collapsed' : ''}`}>
      <ul className="dropdown-sidebar-menu">
        <li
          className={activeMenu === 'Databank' || persistedActiveMenu === 'Databank' ? 'active' : ''}
          onClick={() => handleMenuClick('Databank', '/databank')}
        >
          <FaHome className="font-icon" /> <span className='head-label-text'>Home</span>
          {expandedMenu === 'Databases' ? (
            <FaCaretDown onClick={(e) => {
              e.stopPropagation();
              toggleDatabaseSubmenu();
            }} style={{ cursor: 'pointer' }} />
          ) : (
            <FaCaretRight onClick={(e) => {
              e.stopPropagation();
              toggleDatabaseSubmenu();
            }} style={{ cursor: 'pointer' }} />
          )}
        </li>
        {expandedMenu === 'Databases' && (
          <ul className={`submenu ${expandedMenu === 'Databases' ? 'expanded' : ''}`}>
            <li
              className={activeMenu === 'AllItems' || persistedActiveMenu === 'AllItems' ? 'active' : ''}
              onClick={() => handleMenuClick('AllItems', '/home/all-items')}
            >
              <FontAwesomeIcon icon={faDatabase} className="font-icon" /><span>All Items</span>
            </li>
            <li
              className={activeMenu === 'Lockers' || persistedActiveMenu === 'Lockers' ? 'active' : ''}
              onClick={() => handleMenuClick('Lockers', '/home/lockers')}
            >
              <FontAwesomeIcon icon={faLock} className="font-icon" /><span className='sidebar-locker-head'>Lockers</span>
              {expandedLocker ? (
                <FaCaretDown onClick={(e) => {
                  e.stopPropagation();
                  setExpandedLocker(null);
                }} />
              ) : (
                <FaCaretRight onClick={(e) => {
                  e.stopPropagation();
                  setExpandedLocker(true);
                }} />
              )}
            </li>
            {expandedLocker && (
              <ul className="locker-submenu">
                {fetchedLockers.map(locker => (
                  <li key={locker.token} className={`locker-item ${activeLockerToken === locker.token ? 'active' : ''}`}>
                    <span onClick={() => handleLockerClick(locker)} className='locker-name-section' title={locker.name}>
                      <FaLock className="font-icon" />
                      <span className='sidebar-locker-name'> {locker.name}</span>
                    </span>
                  </li>
                ))}
              </ul>
            )}
            <li
              className={activeMenu === 'Tables' || persistedActiveMenu === 'Tables' ? 'active' : ''}
              onClick={() => handleMenuClick('Tables', '/home/tables')}
            >
              <FontAwesomeIcon icon={faTable} className="font-icon" /><span>Tables</span>
            </li>
            <li
              className={activeMenu === 'Files' || persistedActiveMenu === 'Files' ? 'active' : ''}
              onClick={() => handleMenuClick('Files', '/home/files')}
            >
              <FontAwesomeIcon icon={faFile} className="font-icon" /><span>Files</span>
            </li>
            <li
              className={activeMenu === 'Links' || persistedActiveMenu === 'Links' ? 'active' : ''}
              onClick={() => handleMenuClick('Links', '/home/links')}
            >
              <FontAwesomeIcon icon={faLink} className="font-icon" /><span>Links</span>
            </li>
          </ul>
        )}
        <li
          className={activeMenu === 'shared-with-me' || persistedActiveMenu === 'shared-with-me' ? 'active' : ''}
          onClick={() => handleMenuClick('shared-with-me', '/shared-with-me')}
        >
          <FontAwesomeIcon icon={faUserFriends} className="font-icon" /> <span> Shared With Me</span>
        </li>
        <li
          className={activeMenu === 'search' || persistedActiveMenu === 'search' ? 'active' : ''}
          onClick={() => handleMenuClick('search', '/search')}
        >
          <FontAwesomeIcon icon={faSearch} className="font-icon" /> <span> Search</span>
        </li>
        <li
          className={activeMenu === 'Apps' ? 'active' : ''}
          onClick={() => toggleSubmenu('Apps')}
        >
          <FaProjectDiagram className="font-icon" /> <span className='head-label-text'>Apps</span>
          {expandedMenu === 'Apps' ? <FaCaretDown /> : <FaCaretRight />}
        </li>
        {expandedMenu === 'Apps' && (
          <ul className={`submenu ${expandedMenu === 'Apps' ? 'expanded' : ''}`}>
            <li
              className={activeMenu === 'find-duplicates' || persistedActiveMenu === 'find-duplicates' ? 'active' : ''}
              onClick={() => handleMenuClick('find-duplicates', '/find-duplicates')}
            >
              <FontAwesomeIcon icon={faSearch} className="font-icon" /><span>Find Duplicates</span>
            </li>
            <li
              className={activeMenu === 'data-sharing-app' || persistedActiveMenu === 'data-sharing-app' ? 'active' : ''}
              onClick={() => handleMenuClick('data-sharing-app', '/data-sharing-app')}
            >
              <FontAwesomeIcon icon={faExchangeAlt} className="font-icon" /> <span>Data Sharing App</span>
            </li>
          </ul>
        )}
        <li
          className={activeMenu === 'Admin' || persistedActiveMenu === 'Admin' ? 'active' : ''}
          onClick={() => handleMenuClick('Admin', '/admin')}
        >
          <FaWrench className="font-icon" /> <span >Admin</span>
        </li>
      </ul>
    </div>
  );
};

export default DropdownSidebar;