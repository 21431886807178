import FileUpload from './fileUpload/fileUpload'
import React, { useState , useEffect} from 'react';
import FileList from './fileList/fileList'
import { useParams } from 'react-router-dom';

function S3dataBase({ lockerId }) {
  
    console.log("Locker ID:", lockerId);
    const [fileListRefreshTrigger, setFileListRefreshTrigger] = useState(0);
    const [droppedFiles, setDroppedFiles] = useState([]);

    useEffect(() => {
        const handleDragOver = (e) => {
            e.preventDefault();
        };

        const handleDrop = (e) => {
            e.preventDefault();
            const files = e.dataTransfer.files;
            if (files.length) {
                setDroppedFiles(files);
            }
        };

        // Listen for drag and drop events on the window
        window.addEventListener('dragover', handleDragOver);
        window.addEventListener('drop', handleDrop);

        return () => {
            window.removeEventListener('dragover', handleDragOver);
            window.removeEventListener('drop', handleDrop);
        };
    }, []);

    const refreshFileList = () => {
        setFileListRefreshTrigger(prevTrigger => prevTrigger + 1);
    };
    return (
        <div className='s3bucket'>
            {/* <FileUpload 
                refreshFileList={refreshFileList}
                droppedFiles={droppedFiles}
                clearDroppedFiles={() => setDroppedFiles([])}
            /> */}
            <FileList refreshTrigger={fileListRefreshTrigger} />
        </div>
    );
}

export default S3dataBase;