import React, { useState, useRef, useEffect } from 'react';
import { faEdit, faShareAlt, faInfo, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './dropdownActionsMenu.css'; 
import axios from 'axios';
import { useUser } from '../../context/userContext';

function DropdownActionsMenu({ isOpen, toggleDropdown, detail, deleteLocker, deleteFile, activeTab }) {
  const dropdownRef = useRef(null);
  const [isTop, setIsTop] = useState(false);

 
  useEffect(() => {
    if (isOpen && dropdownRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const ellipsisRect = dropdownRef.current.parentElement.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const dropdownHeight = dropdownRect.height;
      const ellipsisBottom = ellipsisRect.bottom;
      const spaceToBottom = windowHeight - ellipsisBottom;
  
      // Check if there's enough space below the ellipsis button
      if (spaceToBottom >= dropdownHeight) {
        // Dropdown can open below the ellipsis button
        setIsTop(false);
        dropdownRef.current.style.top = `${ellipsisRect.top + ellipsisRect.height / 2}px`;
      } else {
        // Open the dropdown above the ellipsis button
     
        dropdownRef.current.style.top = `${ellipsisRect.bottom - dropdownHeight - 20}px`;
      }
  
      dropdownRef.current.style.right = `${window.innerWidth - ellipsisRect.right + 30}px`;
    }
  }, [isOpen]);

  const handleDelete = () => {
    if (activeTab === 'lockers') {
      deleteLocker(detail._id);
    } else if (activeTab === 'files') {
      deleteFile(detail.source_url, detail._id);
    }
    toggleDropdown(null);
  };

  console.log('DropdownActionsMenu rendered');
  return (
    <div  ref={dropdownRef}  className={`LockerDropdownActions ${isOpen ? 'active' : ''}${isTop ? 'top' : ''}`}>
      <ul className="locker-dropdown-menu">
        <li onClick={() => {
          console.log('Edit');
          toggleDropdown(null);
        }}>
          <FontAwesomeIcon icon={faEdit} className="dropdown-icon" /> Edit
        </li>
        <li onClick={() => {
          console.log('Share');
          toggleDropdown(null);
        }}>
          <FontAwesomeIcon icon={faShareAlt} className="dropdown-icon" /> Share
        </li>
        <li onClick={() => {
          console.log('File Information');
          toggleDropdown(null);
        }}>
          <FontAwesomeIcon icon={faInfo} className="dropdown-icon" /> 
          {activeTab === 'files' && 'File Information'}
          {activeTab === 'tables' && 'Table Information'}
          {activeTab === 'lockers' && 'Locker Information'}
        </li>
        <li onClick={handleDelete}>
          <FontAwesomeIcon icon={faTrashAlt} className="dropdown-icon" /> Delete
        </li>
      </ul>
    </div>
  );
}

export default DropdownActionsMenu;



