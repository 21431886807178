// NavigationBar.js

import React, { useState,useEffect } from 'react';
import './search-nav.css'; // Make sure to create a corresponding CSS file

const TablesNavigationBar = ({ setActiveTab }) => {
  const [activeTab, setActiveState] = useState('My Lockers');

  useEffect(() => {
    setActiveTab(activeTab); // Set 'My Tables' as active in the parent component on mount
  }, []);

  const handleTabClick = (tabName) => {
    setActiveState(tabName);
    setActiveTab(tabName); // This prop function can set the state in the parent component
  };

  return (
    <div className="search-navigation-bar">
      <p className='search-lable'>Search</p>
      <div className='navigation-bar' >
      <div className={`nav-item ${activeTab === 'My Lockers' ? 'active' : ''}`} onClick={() => handleTabClick('My Lockers')}>
        My Lockers
      </div>
      <div className={`nav-item ${activeTab === 'My Tables' ? 'active' : ''}`} onClick={() => handleTabClick('My Tables')}>
        My Tables
      </div>
      <div className={`nav-item ${activeTab === 'My Files' ? 'active' : ''}`} onClick={() => handleTabClick('My Files')}>
        My Files
      </div>
      <div className={`nav-item ${activeTab === 'My Links' ? 'active' : ''}`} onClick={() => handleTabClick('My Links')}>
        My Links
      </div>
      </div>
    </div>
  );
};

export default TablesNavigationBar;
