import React, { useState } from 'react';
import './SaveToContacts.css'; // We'll create this CSS file next

const OnePgrSaveInterface = ({ onSave, onCancel }) => {
  const [listName, setListName] = useState('');

  const handleSave = () => {
    onSave(listName);
  };

  return (
    <div className="onepgr-save-overlay">
      <div className="onepgr-save-modal">
        <h2>Save to OnePgr Contact List</h2>
        <div className="onepgr-input-group">
          <label htmlFor="listName">Enter List Name:</label>
          <input
            type="text"
            id="listName"
            value={listName}
            onChange={(e) => setListName(e.target.value)}
            placeholder="Enter list name"
          />
        </div>
        <div className="onepgr-button-group">
          <button className="onepgr-cancel-btn" onClick={onCancel}>
            Cancel
          </button>
          <button className="onepgr-save-btn" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnePgrSaveInterface;