import React, { useEffect, useState } from 'react';
import './notification.css'

const Notification = ({ message, type }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);  // Hide the notification after 5 seconds
    }, 3000);

    return () => clearTimeout(timer);  // Clean up the timer
  }, [message, type]);  // Dependencies ensure that the effect runs any time the message or type changes

  if (!visible) return null;  // Do not render the component if not visible

  return (
    <div className={`notification ${type}`}>
      {message}
    </div>
  );
};


export default Notification;
