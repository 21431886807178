import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext, useUser } from '../../context/userContext';

const EditProfile = () => {
    const { user , getCurrentLoggedInUser} = useContext(UserContext);
    const [profileData, setProfileData] = useState(null);
    console.log(profileData)
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    phone_mobile: '',
    purch_phone_num: '',
    onepgr_number: ''
  });

  const readOnlyStyle = {
    backgroundColor: '#f0f0f0',  // Light grey background
    cursor: 'not-allowed'        // Changes cursor to indicate field is not editable
};


  useEffect(() => {
    fetchProfileData()
  }, [])

  useEffect(() => {
    if (profileData) {
      setFormData({
        user_name: profileData.user_name || '',
        user_email: profileData.user_email || '',
        phone_mobile: profileData.phone_mobile || '',
        purch_phone_num: profileData.phone_number || '',
        onepgr_number: profileData.purch_phone_no || ''
      });
    }
  }, [profileData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const data = new FormData();
        data.append('onepgr_apicall', '1');
        data.append('xhr_flag', '1');
        data.append('clientname', 'web_meet');
        data.append('name', formData.user_name);
        data.append('phone_mobile', formData.phone_mobile);
        data.append('phone_number', formData.phone_number);

        const response = await axios.post('https://onepgr.com/users/set_about_attributes', data, {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'multipart/form-data',
            },
            withCredentials: true
        });

        if (response.data.success) {
            
            alert('Profile updated successfully!');
            
        } else {
            alert('Failed to update profile. Please try again.');
        }
    } catch (error) {
        console.error('Error updating profile:', error);
        alert('An error occurred while updating the profile.');
    }
};

  const fetchProfileData = () => {
    const storedProfileData = localStorage.getItem('parsedProfileAttributes');
    console.log(storedProfileData)
    if (storedProfileData) {
        setProfileData(JSON.parse(storedProfileData));
    } 
};

  return (
    <div className="edit-profile">
        <div className="edit-profile-header">
      <h4>Edit Profile</h4>
      <p>Set up your personal information</p>
      </div>
      <form onSubmit={handleSubmit} className="edit-profile-form">
        <div className="edit-profile-form-group">
          <label>User Name</label>
          <input 
            type="text" 
            name="user_login"
            value={formData.user_name} 
            onChange={handleInputChange}
            readOnly
          />
        </div>
        <div className="edit-profile-form-group">
          <label>User Email</label>
          <input 
            type="email" 
            name="user_email"
            value={formData.user_email} 
            onChange={handleInputChange}
            readOnly
            style={readOnlyStyle}
          />
        </div>
        <div className="edit-profile-form-group">
          <label>Mobile Number</label>
          <input 
            type="tel" 
            name="phone_mobile"
            value={formData.phone_mobile} 
            onChange={handleInputChange}
          />
        </div>
        <div className="edit-profile-form-group">
          <label>Phone Number</label>
          <input 
            type="tel" 
            name="purch_phone_num"
            value={formData.purch_phone_num} 
            onChange={handleInputChange}
          />
        </div>
        <div className="edit-profile-form-group">
          <label>OnePgr Number</label>
          <input 
            type="tel" 
            name="onepgr_number"
            value={formData.onepgr_number} 
            onChange={handleInputChange}
            readOnly
            style={readOnlyStyle}
          />
        </div>
        <div className="button-group">
          <button type="submit" className="update-profile">Update Profile</button>
          <button type="button" className="buy-number">Buy Number</button>
        </div>
      </form>
    </div>
  );
};

export default EditProfile;