import React, { useEffect, useState, useRef } from 'react';
import './resultsTable.css'; // Assuming you have some basic styles defined
import { useLocation, useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import PersonInfoFilter from '../search/tablesSearch';



const ResultsTable = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const { data: initialData, tableName } = location.state || {};  // Destructure to access data and tableName
    const [data, setData] = useState(initialData || []);
    const [showDropdown, setShowDropdown] = useState({ person: false, company: false, location: false, lead: false });
    const [showFilters, setShowFilters] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState('');
    const [filterInputs, setFilterInputs] = useState({});
    const [tempInputValue, setTempInputValue] = useState("");
    const dropdownRef = useRef(null);
    
    const [dropdownInputs, setDropdownInputs] = useState({
        "Person Information": { firstName: false, lastName: false, email: false, title: false },
        "Company Information": { companyName: false, industry: false, teamSize: false, companyDomain: false },
        "Company Location": { companyCity: false, companyState: false, companyCountry: false, companyZip: false, },
        "Lead Location": { leadCity: false, leadState: false, leadCountry: false }
    });




    const fetchData = async () => {
        const params = new URLSearchParams(location.search);
        const filters = Object.fromEntries(params.entries());
        console.log(tableName)
        try {
            const response = await axios.post(`https://crawl.onepgr.com:3002/search-tables`, {
                filters,
                tableName
            });
            setData(response.data || []);
        } catch (error) {
            console.error('Failed to fetch data:', error);
            setData([]);
        }
    };

    // Effect to run fetchData on component mount and when URL search params change
    useEffect(() => {
        fetchData();
    }, [location.search, tableName]);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                event.target.className !== 'child-filter-btn active'
            ) {
                setActiveDropdown('');
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);



    if (!data || data.length === 0) {
        return <p className='no-data'>No data available.</p>;
    }

    const removeFilter = (filterKey) => {
        const params = new URLSearchParams(location.search);
        params.delete(filterKey);
        navigate(`${location.pathname}?${params.toString()}`, { replace: true, state: { tableName } });
    };

    const headers = data.length > 0 ? Object.keys(data[0]) : [];

    const getFilterTags = () => {
        const query = new URLSearchParams(location.search);
        let tags = [];
        for (const [key, valueArray] of query.entries()) {
            const values = valueArray.split(',');
            tags.push({ key, value: values });
        }
        return tags;
    };

    const filterTags = getFilterTags();




    const saveTableContentAsCsv = (data) => {
        if (!data || data.length === 0) return;  // Check if there's data to save

        // Convert table content to CSV format
        const csvContent = [
            Object.keys(data[0]).join(','), // Add headers to CSV
            ...data.map(row =>
                Object.values(row).map(field =>
                    `"${field.toString().replace(/"/g, '""')}"` // Handle commas and quotes in fields
                ).join(',')
            )
        ].join('\n');

        // Prompt user to enter a filename
        const fileName = window.prompt('Enter a filename for the CSV file:', 'search_results.csv');
        if (!fileName) return; // If the user cancels or enters an empty filename, do nothing

        // Create a Blob containing the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        // Create a temporary anchor tag
        const anchor = document.createElement('a');
        anchor.href = URL.createObjectURL(blob);
        anchor.download = fileName;

        // Append anchor to the body to fix Firefox not starting the download
        document.body.appendChild(anchor);
        anchor.click();  // Trigger the download

        // Clean up
        document.body.removeChild(anchor);
        URL.revokeObjectURL(anchor.href);
    };

    const capitalizeWords = (s) => {
        return s.replace(/([A-Z])/g, ' $1') // Add space before capital letters
            .trim() // Remove leading space
            .toLowerCase() // Convert all to lower case
            .replace(/^\w|\s\w/g, (letter) => letter.toUpperCase()); // Capitalize the first letter of each word
    };

    const toggleDropdown = (type) => {
        setActiveDropdown(prev => (prev === type ? '' : type));
    };

    const toggleInput = (category, inputName) => {
        setDropdownInputs(prev => {
            const currentInputs = { ...prev[category] }; // Copy the current state of inputs for the category
            const isCurrentlyOpen = currentInputs[inputName]; // Check if the clicked input is currently open

            // Set all inputs in this category to false first
            Object.keys(currentInputs).forEach(key => {
                currentInputs[key] = false;
            });

            // If the clicked input was not open, set it to true
            if (!isCurrentlyOpen) {
                currentInputs[inputName] = true;
            }

            return {
                ...prev,
                [category]: currentInputs
            };
        });
    };


    const handleInput = (category, key, value) => {
        // Update the filterInputs state with the new input value
        setFilterInputs((prevInputs) => ({
            ...prevInputs,
            [`${category}-${key}`]: value, // Update the entire value instead of just one character
        }));
    };

    const handleApply = () => {
        const params = new URLSearchParams(location.search); // Start with existing parameters
        Object.entries(filterInputs).forEach(([key, value]) => {
            const [, filterKey] = key.split('-'); // Extract the key name without category
            if (value) {
                // Set or append the new value
                if (params.has(filterKey)) {
                    // Append new value if it's not already there
                    let existing = params.get(filterKey).split(',');
                    if (!existing.includes(value)) {
                        existing.push(value);
                        params.set(filterKey, existing.join(','));
                    }
                } else {
                    params.set(filterKey, value);
                }
            }
        });
        navigate(`${location.pathname}?${params.toString()}`, { replace: true, state: { tableName } });
        setFilterInputs('')
        setActiveDropdown('')
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleApply();  // Call the function that applies the filters
        }
    };


    const DropdownContent = ({ category }) => {
        const inputs = dropdownInputs[category] || {};


        return (
            <div ref={dropdownRef} className="filter-dropdown-content">
                {Object.entries(inputs).map(([key, isOpen]) => (
                    <div key={key} className='drop-input'>
                        <button className="dropdown-toggle" onClick={() => toggleInput(category, key)}>
                            {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                            <span className={`arrow ${dropdownInputs[category][key] ? 'up' : ''}`}>▼</span>
                        </button>
                        {isOpen && (
                            <input
                                type="text"
                                placeholder={`Search by ${key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}`}
                                value={filterInputs[`${category}-${key}`] || ''}
                                onChange={(e) => handleInput(category, key, e.target.value)}
                                onKeyDown={handleKeyPress}
                                autoFocus
                            />
                        )}
                    </div>
                ))}
                {/* <button className="filter-apply-button" onClick={handleApply}>Apply</button> */}
            </div>
        );
    };



    const categories = [
        { id: 'person', label: 'Person Information' },
        { id: 'company', label: 'Company Information' },
        { id: 'location', label: 'Company Location' },
        { id: 'lead', label: 'Lead Location' }
    ];

    return (
        <div className='search-result'>
            <h3>Search Type: <span>Table</span></h3>
            <h4>Total results: ({data.length})</h4>
            <hr />
            <div className='appliedFilters'>
                <p>Applied Filters:</p>
                <div className="tags-display">
                    {filterTags.map(tag => (
                        <div className='appliedtag-section'>
                            <span className="filter-name">{capitalizeWords(tag.key)} </span>
                            <div key={tag.key} className="filter-tag">
                                <span className="filter-values">{tag.value.join(', ')}</span>
                                <button onClick={() => removeFilter(tag.key)}>x</button>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='add-filters-section'>
                    <div className={`filters-buttons-content ${showFilters ? '' : 'active'}`}>
                        {categories.map((category) => (
                            <div key={category.id}>
                                <button
                                    onClick={() => toggleDropdown(category.label)}
                                    className={`child-filter-btn ${activeDropdown === category.label ? 'active' : ''}`}
                                >
                                    {category.label}
                                </button>
                                {activeDropdown === category.label && <DropdownContent category={category.label} />}
                            </div>
                        ))}
                    </div>

                    <button onClick={() => setShowFilters(!showFilters)} className="add-filters-btn">
                        <span>{showFilters ? '-' : '+'}</span> Add Filters
                    </button>
                </div>
            </div>

            <div className='results-save-btn'>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-save">Save as CSV</Tooltip>}
                >
                    <div onClick={() => saveTableContentAsCsv(data)} className="save-csv-icon">
                        <FontAwesomeIcon icon={faFileCsv} />
                    </div>
                </OverlayTrigger>
            </div>
            <div className='results-table-content'>
                <table>
                    <thead>
                        <tr>
                            {headers.map(header => (
                                <th key={header}>
                                    {header
                                        .split('_') // Split the header text at underscores
                                        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
                                        .join(' ')}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                {headers.map(header => (
                                    <td key={`${header}-${index}`}>{item[header]}</td> // Display each cell data
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ResultsTable;
