import React, { useState } from 'react';
import './nameLocker.css';
const LockerNameModal = ({ isOpen, onClose, setNewLockerName  }) => {
  const [lockerNameInput, setLockerNameInput] = useState('');

  const handleSubmit = () => {
    setNewLockerName(lockerNameInput);
    onClose(lockerNameInput);
    
  };

  const handleCancel = () => {
    onClose(null);
    setLockerNameInput('')
  };

  if (!isOpen) return null; // Don't render the modal if it's not open

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-header">
          <h3>Enter Locker Name</h3>
          <button className="modal-close" onClick={handleCancel}>
            &times;
          </button>
        </div>
        <div className="modal-body">
          <input
            type="text"
            value={lockerNameInput}
            onChange={(e) => {
              const capitalizedValue = e.target.value.replace(/\b\w/g, (char) => char.toUpperCase());
              setLockerNameInput(capitalizedValue);
            }}
            placeholder="Locker Name"
            className="modal-input"
          />
        </div>
        <div className="modal-footer">
          <button className="cancel" onClick={handleCancel}>
            Cancel
          </button>
          <button className="submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default LockerNameModal;