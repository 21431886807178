import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faEye, faEdit, faTable, faUserPlus, faTrashAlt, faCheck, faCopy, faPlus, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import './actionIcon.css'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import InviteOverlay from '../s3dataBase/fileList/shareitemComponent';
import { UserContext, useUser } from '../context/userContext';


const ActionsIcon = ({ detail, activeTab, setSelectedLockerToken, deleteLink, dropDownLockerDetails, password, setPassword, selectedItem, selectedItemDetails, showInviteOverlay, deleteTable, handleInviteLockerClick, handleInviteTableClick, handleInviteClick, closeInviteOverlay, inviteUrl, copyIcon, handleCopyClick, openOverlayWithFileInfo, openOverlayWithEditFileDetails, overlayInfoOpen, currentFile, fileDetails, isEditMode, editableFileName, setEditableFileName, updateSelectedFileDetails, updateFileDetails, removeTag, addTag, toggleEditMode, closeOverlayInfo, saveChanges, deleteLocker, deleteFile, currentItem, itemDetails, file, viewFile, newTag, setNewTag }) => {
    console.log('ActionsIcon rendered for:', detail, 'activeTab:', activeTab);
    const [showDropdown, setShowDropdown] = useState(false);
    // const { dropDownLockerDetails , triggerFetchdropDownLockerDetails} = useUser();
    const navigate = useNavigate();
    console.log(selectedItem)
    console.log(selectedItemDetails)
    const [selectedLocker, setSelectedLocker] = useState(
        dropDownLockerDetails?.find((locker) =>
            locker.token === (currentItem?.locker_id || selectedItem?.locker_id)
        )?.name || ''
    );

    console.log(selectedLocker)
    console.log(dropDownLockerDetails)

    const objectName = selectedItem ? selectedItem.object_type : '';

    console.log(itemDetails)

    const toggleDropdown = (event) => {
        event.stopPropagation();
        setShowDropdown(!showDropdown);
    };


    useEffect(() => {
        const selectedLockerObj = dropDownLockerDetails?.find(
            (locker) => locker.name === selectedLocker
        );
        if (selectedLockerObj) {
            setSelectedLockerToken(selectedLockerObj.token);

        }
    }, [selectedLocker, dropDownLockerDetails, setSelectedLockerToken]);




    useEffect(() => {
        if (currentItem && currentItem.name) {
            setEditableFileName(currentItem.name);
        }
    }, [currentItem]);


    useEffect(() => {
        if (selectedItem && selectedItem.name) {
            setEditableFileName(selectedItem.name);
        }
    }, [selectedItem]);






    const handleViewFile = (detail) => {
        if (activeTab === 'file' || activeTab === 'files' || (activeTab === 'all-items' && detail.object_type === 'file')) {
            viewFile(detail.source_url);
        } else if (activeTab === 'item' || activeTab === 'lockers' || (activeTab === 'all-items' && detail.object_type === 'item')) {
            navigate(`/home/lockers/${detail.token}`);
        } else if (activeTab === 'table' || activeTab === 'tables' || (activeTab === 'all-items' && detail.object_type === 'table')) {
            const database = detail.databse
            navigate(`/tables/${detail.name}`, { 
                state: { 
                     database
                } 
            });
        } else if (activeTab === 'link' || activeTab === 'links' || (activeTab === 'all-items' && detail.object_type === 'link')) {
            window.open(detail.name, '_blank');
        }
    };

    const handleInvite = (detail) => {

        if (activeTab === 'file' || activeTab === 'files' || (activeTab === 'all-items' && detail.object_type === 'file')) {
            handleInviteClick(detail);
        } else if (activeTab === 'item' || activeTab === 'lockers' || (activeTab === 'all-items' && detail.object_type === 'item')) {
            handleInviteLockerClick(detail);
        } else if (activeTab === 'table' || activeTab === 'tables' || (activeTab === 'all-items' && detail.object_type === 'table')) {
            handleInviteTableClick(detail);
        }
    };
    const handleDelete = () => {
        if (activeTab === 'item' || activeTab === 'lockers' || (activeTab === 'all-items' && detail.object_type === 'item')) {
            deleteLocker(detail._id);
        } else if (activeTab === 'file' || activeTab === 'files' || (activeTab === 'all-items' && detail.object_type === 'file')) {
            deleteFile(detail.source_url, detail._id);
        } else if (activeTab === 'table' || activeTab === 'tables' || (activeTab === 'all-items' && detail.object_type === 'table')) {
            deleteTable(detail._id, detail.name);
        } else if (activeTab === 'link' || activeTab === 'links' || (activeTab === 'all-items' && detail.object_type === 'link')) {
            deleteLink(detail._id, detail.name);
        }
    };

    return (
        <div className="all-actions-icon">
            <div className="actions-icon-full">
                <OverlayTrigger
                    key={`top-info-1-${detail._id}`}
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-top-info`}>
                            {(activeTab === 'file' || activeTab === 'files' || (activeTab === 'all-items' && detail.object_type === 'file')) ? 'File Information' :
                                (activeTab === 'table' || activeTab === 'tables' || (activeTab === 'all-items' && detail.object_type === 'table')) ? 'Table Information' :
                                    (activeTab === 'link' || activeTab === 'links' || (activeTab === 'all-items' && detail.object_type === 'link')) ? 'Link Information' :
                                        (activeTab === 'item' || activeTab === 'lockers' || (activeTab === 'all-items' && detail.object_type === 'item')) ? 'Locker Information' :
                                            'Information'}
                        </Tooltip>
                    }
                >
                    <Button variant="light"
                        onClick={() => openOverlayWithFileInfo(detail)}
                    >
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger
                    key={`top-view-${detail._id}`}
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-top-info`}>
                            {(activeTab === 'file' || activeTab === 'files' || (activeTab === 'all-items' && detail.object_type === 'file')) ? 'View File' :
                                (activeTab === 'table' || activeTab === 'tables' || (activeTab === 'all-items' && detail.object_type === 'table')) ? 'View Table' :
                                    (activeTab === 'link' || activeTab === 'links' || (activeTab === 'all-items' && detail.object_type === 'link')) ? 'View Link' :
                                        (activeTab === 'item' || activeTab === 'lockers' || (activeTab === 'all-items' && detail.object_type === 'item')) ? 'View Locker' :
                                            'View Details'}
                        </Tooltip>
                    }
                >
                    <Button variant="light" onClick={(event) => {
                        event.stopPropagation();
                        handleViewFile(detail);
                    }}>
                        <FontAwesomeIcon icon={faEye} />
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger
                    key={`top-info-2-${detail._id}`}
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-top-info`}>
                            {(activeTab === 'file' || activeTab === 'files' || (activeTab === 'all-items' && detail.object_type === 'file')) ? 'Edit File Information' :
                                (activeTab === 'table' || activeTab === 'tables' || (activeTab === 'all-items' && detail.object_type === 'table')) ? 'Edit Table Information' :
                                    (activeTab === 'link' || activeTab === 'links' || (activeTab === 'all-items' && detail.object_type === 'link')) ? 'Edit Link Information' :
                                        (activeTab === 'item' || activeTab === 'lockers' || (activeTab === 'all-items' && detail.object_type === 'item')) ? 'Edit Locker Information' :
                                            'Edit Information'}
                        </Tooltip>
                    }
                >
                    <Button variant="light"
                        onClick={() => openOverlayWithEditFileDetails(detail)}
                    >
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                </OverlayTrigger>



                {/* <OverlayTrigger
                key={`top-create-table-${file._id}`}
                placement="top"
                overlay={<Tooltip id={`tooltip-create-table`}>Create Table</Tooltip>}
            >
                <Button variant="light" onClick={(event) => {
                    event.stopPropagation();
                    if (!selectedFiles.includes(file._id)) {
                        selectFile(file._id);
                    }
                    handleSendToRDSClick(file);
                }}>
                    <FontAwesomeIcon icon={faTable} />
                </Button>
            </OverlayTrigger> */}

                <OverlayTrigger
                    key={`top-invite-${detail._id}`}
                    placement="top"
                    overlay={<Tooltip id={`tooltip-invite`}>Share</Tooltip>}
                >
                    <Button variant="light" onClick={(event) => {
                        event.stopPropagation();
                        handleInvite(detail);
                    }}>
                        <FontAwesomeIcon icon={faUserPlus} />
                    </Button>
                </OverlayTrigger>



                <OverlayTrigger
                    key={`top-delete-${detail._id}`}
                    placement="top"
                    overlay={<Tooltip id={`tooltip-top-delete`}>Delete</Tooltip>}
                >
                    <Button variant="light" onClick={(event) => {
                        event.stopPropagation();
                        handleDelete()
                    }}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </OverlayTrigger>
            </div>
            <div className="actions-icon-ellipsis">
                <OverlayTrigger
                    key={`top-delete-${detail._id}`}
                    placement="top"
                    overlay={<Tooltip id={`tooltip-top-more`}>More</Tooltip>}
                >
                    <Button variant="light" onClick={toggleDropdown}>
                        <FontAwesomeIcon icon={faEllipsisV} />
                    </Button>
                </OverlayTrigger>
                {showDropdown && (
                    <div className="actions-dropdown">
                        <div className="actions-dropdown-icon-button">
                            <Button variant="light"
                                onClick={() => openOverlayWithFileInfo(detail)}
                            >
                                <FontAwesomeIcon icon={faInfoCircle} className='dropdown-menu-icon' />
                                <p>      {(activeTab === 'file' || activeTab === 'files' || (activeTab === 'all-items' && detail.object_type === 'file')) ? 'File Information' :
                                    (activeTab === 'table' || activeTab === 'tables' || (activeTab === 'all-items' && detail.object_type === 'table')) ? 'Table Information' :
                                        (activeTab === 'link' || activeTab === 'links' || (activeTab === 'all-items' && detail.object_type === 'link')) ? 'Link Information' :
                                            (activeTab === 'item' || activeTab === 'lockers' || (activeTab === 'all-items' && detail.object_type === 'item')) ? 'Locker Information' :
                                                'Information'}</p>
                            </Button>
                        </div>
                        <div className="actions-dropdown-icon-button">
                            <Button variant="light" onClick={(event) => {
                                event.stopPropagation();
                                handleViewFile(detail);
                            }}>
                                <FontAwesomeIcon icon={faEye} className='dropdown-menu-icon' />
                                <p>   {(activeTab === 'file' || activeTab === 'files' || (activeTab === 'all-items' && detail.object_type === 'file')) ? 'View File' :
                                    (activeTab === 'table' || activeTab === 'tables' || (activeTab === 'all-items' && detail.object_type === 'table')) ? 'View Table' :
                                        (activeTab === 'link' || activeTab === 'links' || (activeTab === 'all-items' && detail.object_type === 'link')) ? 'View Link' :
                                            (activeTab === 'item' || activeTab === 'lockers' || (activeTab === 'all-items' && detail.object_type === 'item')) ? 'View Locker' :
                                                'View Details'}</p>
                            </Button>
                        </div>
                        <div className="actions-dropdown-icon-button">
                            <Button variant="light"
                                onClick={() => openOverlayWithEditFileDetails(detail)}
                            >
                                <FontAwesomeIcon icon={faEdit} className='dropdown-menu-icon' />
                                <p>{(activeTab === 'file' || activeTab === 'files' || (activeTab === 'all-items' && detail.object_type === 'file')) ? 'Edit File Information' :
                                    (activeTab === 'table' || activeTab === 'tables' || (activeTab === 'all-items' && detail.object_type === 'table')) ? 'Edit Table Information' :
                                        (activeTab === 'link' || activeTab === 'links' || (activeTab === 'all-items' && detail.object_type === 'link')) ? 'Edit Link Information' :
                                            (activeTab === 'item' || activeTab === 'lockers' || (activeTab === 'all-items' && detail.object_type === 'item')) ? 'Edit Locker Information' :
                                                'Edit Information'}</p>
                            </Button>
                        </div>
                        <div className="actions-dropdown-icon-button">
                            <Button variant="light" onClick={(event) => {
                                event.stopPropagation();
                                handleInvite(detail);
                            }}>
                                <FontAwesomeIcon icon={faUserPlus} className='dropdown-menu-icon' />
                                <p>Share</p>
                            </Button>

                        </div>
                        <div className="actions-dropdown-icon-button">
                            <Button variant="light" onClick={(event) => {
                                event.stopPropagation();
                                handleDelete()
                            }}>
                                <FontAwesomeIcon icon={faTrashAlt} className='dropdown-menu-icon' />
                                <p>Delete</p>
                            </Button>
                        </div>

                    </div>
                )}
            </div>

            {overlayInfoOpen && (selectedItem || currentItem) && (
                console.log('rendering overlay'),
                <div className="fileInfo-overlay">
                    <div className="fileInfo-overlay-content" >

                        <h3>
                            {isEditMode ? (
                                objectName === 'item' || activeTab === 'lockers' || (activeTab === 'all-items' && currentItem.object_type === 'item') ? "Edit Locker Details" :
                                    objectName === 'table' || activeTab === 'tables' || (activeTab === 'all-items' && currentItem.object_type === 'table') ? "Edit Table Details" :
                                        objectName === 'file' || activeTab === 'files' || (activeTab === 'all-items' && currentItem.object_type === 'file') ? "Edit File Details" :
                                            objectName === 'link' || activeTab === 'links' || (activeTab === 'all-items' && currentItem.object_type === 'link') ? "Edit Link Details" :
                                                "Edit Details"
                            ) : (
                                objectName === 'item' || activeTab === 'lockers' || (activeTab === 'all-items' && currentItem.object_type === 'item') ? "View Locker Details" :
                                    objectName === 'table' || activeTab === 'tables' || (activeTab === 'all-items' && currentItem.object_type === 'table') ? "View Table Details" :
                                        objectName === 'file' || activeTab === 'files' || (activeTab === 'all-items' && currentItem.object_type === 'file') ? "View File Details" :
                                            objectName === 'link' || activeTab === 'links' || (activeTab === 'all-items' && currentItem.object_type === 'link') ? "View Link Details" :
                                                "View Details"
                            )}
                        </h3>
                        <div className='editname'>
                            {isEditMode && activeTab !== 'tables' && objectName !== 'table' && objectName !== 'link' && !(activeTab === 'all-items' && (currentItem.object_type === 'table' || currentItem.object_type === 'link')) ? (
                                <div>
                                    <label className='label-file-name'>File Name:</label>
                                    <input
                                        type="text"
                                        value={editableFileName}
                                        onChange={(e) => setEditableFileName(e.target.value)}
                                        className="filename-editable-input"
                                    />
                                </div>
                            ) : (
                                <div className='view-info-details-content'>
                                    <label>File Name:</label>
                                    <p className="filename-text" title={editableFileName || (selectedItem ? selectedItem.name : currentItem ? currentItem.name : 'File Info')}>
                                        {editableFileName || (selectedItem ? selectedItem.name : currentItem ? currentItem.name : 'File Info')}
                                    </p>
                                </div>
                            )}

                        </div>
                        {(activeTab === 'tables' || activeTab === 'links' || objectName === 'table' || objectName === 'link' || objectName === 'item' || objectName === 'file' || (activeTab === 'all-items' && (currentItem.object_type === 'table' || currentItem.object_type === 'link' || currentItem.object_type === 'file'))) && (
                            <div className='locker-lable-content'>
                                <label className='label-locker-name'>Locker:</label>
                                {isEditMode ? (
                                    <select
                                        value={selectedLocker}
                                        onChange={(e) => setSelectedLocker(e.target.value)}
                                        className="locker-dropdown"

                                    >
                                        <option value="">
                                            {dropDownLockerDetails?.find((locker) =>
                                                locker.token === (currentItem?.locker_id || selectedItem?.locker_id)
                                            )?.name || "-- Select Locker --"}
                                        </option>
                                        {dropDownLockerDetails?.map((locker) => (
                                            <option key={locker.token} value={locker.name}>
                                                {locker.name}
                                            </option>
                                        ))}
                                    </select>
                                ) : (
                                    <span className='locker-name-dropdown'>
                                        {dropDownLockerDetails?.find((locker) =>
                                            locker.token === (currentItem?.locker_id || selectedItem?.locker_id)

                                        )?.name}

                                    </span>
                                )}
                            </div>

                        )}

                        {isEditMode && (
                            <div className='password-label-content'>
                                <label className='label-password'>Password:</label>
                                <input
                                    type="text"
                                    value={password !== null ? password : currentItem?.password || selectedItem?.password || ''}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="password-input"
                                    placeholder='Add Password'
                                />
                            </div>
                        )}
                        <hr />
                        <div className='form-content'>
                            <div className='editmode-icon-share-edit'>
                                <OverlayTrigger
                                    key={`top-invite-${detail._id}`}
                                    placement="top"
                                    overlay={<Tooltip id={`tooltip-share-icon`}>Share</Tooltip>}
                                >
                                    <Button variant="light" onClick={(event) => {
                                        event.stopPropagation();
                                        handleInvite(detail);
                                    }}>
                                        <FontAwesomeIcon icon={faUserPlus} />
                                    </Button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    key={`top-info-${currentItem?._id || selectedItem?._id}`}
                                    placement="top"
                                    overlay={<Tooltip id={`tooltip-top-edit-icon`}>Edit</Tooltip>}
                                >
                                    <Button variant="light" className='editinfobtn' onClick={toggleEditMode}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                </OverlayTrigger>
                            </div>
                            <div className='info-form-group'>
                                <label>Comments:</label>
                                <textarea
                                    value={currentItem ? itemDetails[currentItem._id]?.comments || '' : selectedItem ? selectedItemDetails[selectedItem._id]?.comments || '' : ''}
                                    onChange={(e) => {
                                        if (currentItem) {
                                            updateFileDetails(currentItem._id, 'comments', e.target.value);
                                        } else {
                                            updateSelectedFileDetails(selectedItem?._id, 'comments', e.target.value);
                                        }
                                    }}
                                    readOnly={!isEditMode}
                                    className={isEditMode ? 'textarea-editable' : 'textarea-readonly'}
                                />
                            </div>
                            <div className='info-form-group'>
                                <label>Company Info:</label>
                                <textarea
                                    value={currentItem ? itemDetails[currentItem._id]?.companyInfo || '' : selectedItem ? selectedItemDetails[selectedItem._id]?.companyInfo || '' : ''}
                                    onChange={(e) => {
                                        if (currentItem) {
                                            updateFileDetails(currentItem._id, 'companyInfo', e.target.value);
                                        } else {
                                            updateSelectedFileDetails(selectedItem?._id, 'companyInfo', e.target.value);
                                        }
                                    }}
                                    readOnly={!isEditMode}
                                    className={isEditMode ? 'textarea-editable' : 'textarea-readonly'}
                                />
                            </div>
                            <div className="info-form-group">
                                <label>Tags:</label>
                                {isEditMode && (
                                    <input
                                        className="input-tag"
                                        type="text"
                                        value={newTag}
                                        onChange={(e) => setNewTag(e.target.value)}
                                        readOnly={!isEditMode}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                addTag(newTag);
                                                setNewTag('');
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                )}
                                <div className='tag-info'>
                                    {currentItem && itemDetails[currentItem._id]?.tags
                                        ? itemDetails[currentItem._id].tags.map((tag, index) => (
                                            <span key={index} className="tag-tile">
                                                {tag}
                                                {isEditMode && (
                                                    <span className="tag-remove-btn" onClick={() => removeTag(currentItem._id, index)}>×</span>
                                                )}
                                            </span>
                                        ))
                                        : selectedItem && selectedItemDetails[selectedItem._id]?.tags
                                            ? selectedItemDetails[selectedItem._id].tags.map((tag, index) => (
                                                <span key={index} className="tag-tile">
                                                    {tag}
                                                    {isEditMode && (
                                                        <span className="tag-remove-btn" onClick={() => removeTag(selectedItem._id, index)}>×</span>
                                                    )}
                                                </span>
                                            ))
                                            : null
                                    }
                                </div>


                            </div>
                            {isEditMode && (
                                <div className='btn-fileinfo'>

                                    <Button className='doneFileinfo' variant="primary" onClick={saveChanges}>Done</Button>
                                </div>
                            )}
                        </div>
                        <button onClick={closeOverlayInfo} className='x-btn' style={{ position: 'absolute', top: '20px', right: '20px', fontSize: '20px' }}>X</button>
                    </div>
                </div>
            )}

            {showInviteOverlay && (
                <InviteOverlay
                    showInviteOverlay={showInviteOverlay}
                    closeInviteOverlay={closeInviteOverlay}
                    inviteUrl={inviteUrl}
                    handleCopyClick={handleCopyClick}
                    copyIcon={copyIcon}
                />
            )}

        </div>


    );
};

export default ActionsIcon;