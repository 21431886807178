import React from 'react';
import './pagination.css'; // Make sure to create a corresponding CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage ,setItemsPerPage ,setCurrentPage }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = Number(e.target.value);
    setItemsPerPage(newItemsPerPage); // This will now correctly update the itemsPerPage in the FileList component
    paginate(1); // Reset to the first page after changing items per page
  };



  return (
    <div className="pagination-container">
      <div className="items-per-page">
        <label htmlFor="itemsPerPage">Items per page:</label>
        <select
          id="itemsPerPage"
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </select>
      </div>

      <div className="pagination-info">
        {`${(currentPage - 1) * itemsPerPage + 1} - ${
          currentPage * itemsPerPage
        } of ${totalItems}`}
      </div>

      <div className="pagination-controls">
      <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-double-left`}>First Page</Tooltip>}
        >
          <button onClick={() => paginate(1)} disabled={currentPage === 1}>
            <FontAwesomeIcon icon={faAngleDoubleLeft} className='controllsIcon' />
          </button>
        </OverlayTrigger>

        {/* Single left */}
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-single-left`}>Previous Page</Tooltip>}
        >
          <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
            <FontAwesomeIcon icon={faAngleLeft} className='controllsIcon' />
          </button>
        </OverlayTrigger>

        {/* Single right */}
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-single-right`}>Next Page</Tooltip>}
        >
          <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
            <FontAwesomeIcon icon={faAngleRight} className='controllsIcon' />
          </button>
        </OverlayTrigger>

        {/* Double right */}
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-double-right`}>Last Page</Tooltip>}
        >
          <button onClick={() => paginate(totalPages)} disabled={currentPage === totalPages}>
            <FontAwesomeIcon icon={faAngleDoubleRight} className='controllsIcon' />
          </button>
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default Pagination;
