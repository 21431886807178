import { faSearch, faLink, faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useContext, useCallback, useEffect } from "react";
import { useUser, UserContext } from '../context/userContext';
import axios from "axios";
import debounce from "lodash/debounce";
import "./linkSearch.css";
import Pagination from "../s3dataBase/fileList/pagination";
import Notification from "../notification /notification";


const LinkSearch = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const { formatDate } = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchResults, setSearchResults] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const { user } = useContext(UserContext);
  const userId = user?.userId;
  const [notification, setNotification] = useState({ message: '', type: '' });
  const [sortOrder, setSortOrder] = useState('asc');



  const toggleSort = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };
  const handleLinkClick = (name) => {
    console.log(name)
    window.open(name, "_blank");
  }

  const searchLinks = useCallback(async (keyword) => {
    try {
      const response = await axios.get("https://pages.onepgr.com/session/get-data-bank-source-files", {
        params: {
          user_id: userId,
          search: keyword,
          page_num: currentPage,
          nitems: itemsPerPage,
          object_type: 'link'
        },
      });
      console.log(response);
      const data = response.data.data;
      setSearchResults(data);
      setTotalItems(response.data.total_items_count);
    } catch (error) {
      console.error("Error searching links:", error);
    }
  }, [currentPage, itemsPerPage, userId]);

  const debouncedLinkSearch = useCallback(
    debounce((keyword) => {
      if (keyword.trim() === "") {
        setSearchResults([]);
        setTotalItems(0);
      } else {
        searchLinks(keyword);
      }
    }, 500),
    [searchLinks]
  );

  useEffect(() => {
    debouncedLinkSearch(searchKeyword);
  }, [searchKeyword, debouncedLinkSearch]);

  const handleSearchChange = (e) => {
    const keyword = e.target.value;
    setSearchKeyword(keyword);
  };


  return (
    <div className="link-search-content">
      <div className="search-bar link-search-bar">
        <label>Search For Links:</label>
        <div className="input-container">

          <div className="icon-card searchIcon">
            <FontAwesomeIcon icon={faSearch} />
          </div>
          <input
            type="text"
            id="searchInput"
            placeholder="Enter Keyword To Search..."
            value={searchKeyword}
            onChange={handleSearchChange}
            className="search-input link-search-input"
          />
        </div>
      </div>

      {searchResults.length > 0 && (
        <div className="rdsfiles locker-search-results links-search-results">
          <div className="table-header Link-search-results-header">
            <span>
              Name
              <FontAwesomeIcon
                icon={sortOrder === 'asc' ? faArrowDown : faArrowUp}
                className="sort-icon"
                onClick={toggleSort}
              />
            </span>
            <span>Last Updated</span>
          </div>
          <div className='locker-search-results'>
            <ul className="table-list Link-list">
              {searchResults.map((result) => (
                <li key={result.id} className="rdstable-item Link-search-item">
                  {result.object_type === 'link' ? (

                    <div className="tablename-icon Link-search-item-container" title={result.name}>
                      <div className="result-icon Link-search-item-icon">
                        <FontAwesomeIcon icon={faLink} />
                      </div>
                      <div className="result-details Link-search-result-details">
                        <span className="rdstablename Link-item-name"
                          onClick={(e) => handleLinkClick(result.name)}
                        >{result.name}
                        </span>
                      </div>
                    </div>

                  ) : (
                    <div className="tablename-icon Link-search-item-container">
                      <div className="result-icon Link-search-item-icon">

                        <FontAwesomeIcon icon={faLink} />
                      </div>
                      <div className="result-details Link-search-result-details">
                        <span className="rdstablename Link-item-name">{result.name}</span>
                      </div>
                    </div>
                  )}
                  <div className="table-actions-icon-rds Link-search-last-updated">
                    <div className="result-date Link-search-result-date">{formatDate(result.date)}</div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={totalItems}
            paginate={setCurrentPage}
            currentPage={currentPage}
            setItemsPerPage={setItemsPerPage}
          />
          {notification.message && (
            <Notification type={notification.type} message={notification.message} />
          )}
        </div>
      )}
    </div>
  );
};

export default LinkSearch;