import React, { useState, useContext, useEffect } from "react"
import {
    faSearch,
    faSyncAlt,
    faArrowDown,
    faArrowUp,
    faFileAlt,
    faTable,
    faLock,
    faLink,
    faFilePdf,
    faFileCsv,
    faFilePowerpoint,
    faFileExcel,
    faFileImage,
    faFileArchive,
} from "@fortawesome/free-solid-svg-icons";
import ActionsIcon from '../actionsIcon/actionsIcon';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import './allLockerItems.css';
import Notification from "../notification /notification";
import Pagination from "../s3dataBase/fileList/pagination";
import axios from "axios";
import { UserContext, useUser } from '../context/userContext';
import { useOutletContext } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { useNavigate } from 'react-router-dom';
import { useRefresh } from "../refreshContext /refreshContext";
import { debounce } from 'lodash';
import { axiosCancel } from 'axios-cancel';

const AllLockerItems = ({ items }) => {
    const [sortOrder, setSortOrder] = useState('asc');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [isOverlayLoading, setIsOverlayLoading] = useState(false);
    const [notification, setNotification] = useState({ message: '', type: '' });
    const [lockerItems, setLockerItems] = useState([]);
    const [isLoadingItems, setIsLoadingItems] = useState(true);
    const { lockerId } = useOutletContext();
    const { user } = useContext(UserContext);
    const [showInviteOverlay, setShowInviteOverlay] = useState(false)
    const [isEditMode, setIsEditMode] = useState(false);
    const [overlayInfoOpen, setOverlayInfoOpen] = useState(false);
    const [newTag, setNewTag] = useState('');
    const [inviteUrl, setInviteUrl] = useState('');
    const [copyIcon, setCopyIcon] = useState(false);
    const [editableFileName, setEditableFileName] = useState('');
    const [dropDownLockerDetails , setDropDownLockerDetails] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const [selectedItemDetails, setSelectedItemDetails] = useState({});
    const [selectedItem, setSelectedItem] = useState(null);
    const { viewFile, formatDate , lockerDetails} = useUser();
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const { fetchLockerItems, allItemsRefresh } = useRefresh()
    const [password, setPassword] = useState(null);
    const [ selectedLockerToken, setSelectedLockerToken] = useState()
    const userId = user?.userId;

    console.log(selectedItemDetails)
    console.log(lockerDetails)
    const fetchLockerItemsData = async () => {
        setIsLoadingItems(true);
        setIsOverlayLoading(true);

        try {
           
            const data = await fetchLockerItems(userId, lockerId);
            console.log(data);
            const sortedData = data.sort((a, b) => {
                const dateA = new Date(a.last_updated || a.date);
                const dateB = new Date(b.last_updated || b.date);
                return dateB - dateA; // Sort in descending order (latest to oldest)
            });
            setLockerItems(sortedData);
            setTotalItems(sortedData.length);
        } catch (error) {
            console.error('Error fetching locker items:', error);
            setNotification({ message: 'Error fetching locker items', type: 'error' });
        }

        setIsLoadingItems(false);
        setIsOverlayLoading(false);
    };


    useEffect(() => {
        fetchLockerItemsData();
    }, [allItemsRefresh]);


    useEffect(() => {
        const fetchItemInfo = async () => {
            if (overlayInfoOpen && selectedItem) {
                try {
                    let response;
                    let itemInfo;
    
                    if (selectedItem.object_type === 'table') {
                        response = await axios.get(`https://pages.onepgr.com/session/get-data-bank-table-info?user_id=${selectedItem.user_id}&token=${selectedItem.token}`);
                        itemInfo = response.data.data.table_info;
                    } else if (selectedItem.object_type === 'item') {
                        response = await axios.get(`https://pages.onepgr.com/session/get-data-bank-item-info?user_id=${selectedItem.user_id}&token=${selectedItem.token}`);
                       console.log(response)
                        itemInfo = response.data.data.item_info;
                    } else if (selectedItem.object_type === 'file') {
                        response = await axios.get(`https://pages.onepgr.com/session/get-data-bank-file-info?user_id=${selectedItem.user_id}&token=${selectedItem.token}`);
                        itemInfo = response.data.data.file_info;
                    } else if (selectedItem.object_type === 'link') {
                        response = await axios.get(`https://pages.onepgr.com/session/get-data-bank-file-info?user_id=${selectedItem.user_id}&token=${selectedItem.token}`);
                        itemInfo = response.data.data.file_info;
                    }
    
                    console.log(itemInfo);
    
                    setSelectedItemDetails((prevItemDetails) => ({
                        ...prevItemDetails,
                        [selectedItem._id]: itemInfo ? {
                            comments: itemInfo.comments ?? '',
                            companyInfo: itemInfo.company_info ?? '',
                            tags: itemInfo.tags ? (typeof itemInfo.tags === 'string' ? itemInfo.tags.split(', ') : [itemInfo.tags]) : []
                        } : {
                            comments: '',
                            companyInfo: '',
                            tags: []
                        }
                    }));
                } catch (error) {
                    console.error(`Error fetching ${selectedItem.object_type} information:`, error);
                }
            }
        };
    
        fetchItemInfo();
    }, [overlayInfoOpen, selectedItem]);


    useEffect(() => {
        fetchDropdownLockerDetails()
    }, [])

    const fetchDropdownLockerDetails = async () => {
        try {
            const response = await axios.get('https://pages.onepgr.com/session/get-data-bank-items', {
                params: { user_id: user?.userId },
            });
            if (response.data.data.length > 0) {
                setDropDownLockerDetails(response.data.data); // Store all locker details

             
            } else {
                console.log('No locker details found');
                setDropDownLockerDetails(null);
             

            }
        } catch (error) {
            console.error('Error fetching locker details:', error);
            setDropDownLockerDetails(null);
        }
    };

    const toggleSort = (column) => {
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);

        const sortedItems = [...lockerItems].sort((a, b) => {
            if (column === 'name') {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                if (newSortOrder === 'asc') {
                    return nameA.localeCompare(nameB);
                } else {
                    return nameB.localeCompare(nameA);
                }
            } else if (column === 'lastUpdate') {
                const dateA = new Date(a.last_updated || a.date);
                const dateB = new Date(b.last_updated || b.date);
                if (newSortOrder === 'asc') {
                    return dateA - dateB;
                } else {
                    return dateB - dateA;
                }
            }
        });

        setLockerItems(sortedItems);
    };

    const handleLinkClick = (e, link) => {
        e.preventDefault();
        window.open(link, '_blank');
    };

    const handleSearchChange = debounce((event) => {
        setSearchQuery(event.target.value);
        handleSearch();
    }, 300);

    const handleSearch = async () => {
        try {
            const response = await axios.get('https://pages.onepgr.com/session/data-bank-search', {
                params: {
                    user_id: userId,
                    locker_id: lockerId,
                    search: searchQuery,
                    page_num: currentPage,
                    nitems: itemsPerPage,
                },
            });
            setLockerItems(response.data.data);
            setTotalItems(response.data.total_items);
            setIsLoadingItems(false);
        } catch (error) {
            console.error('Error searching locker items:', error);
            setNotification({ message: 'Error searching locker items', type: 'error' });
            setIsLoadingItems(false);
        }
    };

    // const handleSearchChange = (event) => {
    //     setSearchQuery(event.target.value);
    // };


    const getIconByExtension = (url) => {
        if (!url) return faLock; // Default if no URL is provided
        const extension = url.split(".").pop().toLowerCase(); // Extract extension
        switch (extension) {
            case "pdf":
                return faFilePdf;
            case "csv":
                return faFileCsv;
            case "pptx":
                return faFilePowerpoint;
            case "xlsx":
                return faFileExcel;
            case "png":
            case "jpg":
            case "jpeg":
            case "gif":
                return faFileImage;
            case "zip":
                return faFileArchive;
            default:
                return faLock; // Default icon for unsupported or unknown file types
        }
    };




    const deleteLink = async (fileId, linkName) => {
        // setIsOverlayLoading(true);
        try {
            // Make a DELETE request to the server to delete the link
            const serverResponse = await axios.delete(`https://crawl.onepgr.com:3002/delete-link/${linkName}`);

            if (serverResponse.status === 200) {
                // Make a DELETE request to the database to delete the link
                const databaseResponse = await axios.delete(`https://pages.onepgr.com/session/delete-data-bank-source-file/${fileId}`);

                if (databaseResponse.status === 200) {
                    // Refresh the links data after successful deletion
                    // setIsOverlayLoading(false);
                    fetchLockerItemsData();
                    // Show success notification
                    setNotification({ type: "success", message: "Link deleted successfully!" });
                } else {
                    setNotification({ message: 'Failed to delete the link from the database', type: 'error' });

                }
            } else {
                setNotification({ message: 'Failed to delete the link from the server', type: 'error' });

            }
        } catch (error) {
            console.error('Error deleting link:', error);
            setNotification({ message: 'Failed to delete the link.', type: 'error' });

        } finally {
            // setIsOverlayLoading(false);
        }
    };

    const deleteTable = async (fileId, tableName) => {

        const FileId = fileId
        const TableName = tableName
        try {
            // Endpoint for deleting data by file ID
            const endpointOne = `https://pages.onepgr.com/session/delete-data-bank-table/${FileId}`;

            // Endpoint for deleting the table by table name
            const endpointTwo = `https://crawl.onepgr.com:3002/delete-table/${TableName}`; // Here fileIdOrTableName is interpreted as tableName

            // Make concurrent requests to both endpoints
            await Promise.all([
                axios.delete(endpointOne),
                axios.delete(endpointTwo)
            ]);

            // Handle success
            fetchLockerItemsData();// Refresh the table list after deletion
            setNotification({ message: 'Table deleted successfully!', type: 'success' });


        } catch (error) {
            console.error('Error deleting file or table:', error);
            // Handle error case

        }
    };


    const handleInviteLockerClick = (detail) => {
        setOverlayInfoOpen(false);
        // Construct the URL with current domain and locker token
        const currentDomain = window.location.origin;
        const token = detail.token;
        const inviteLockerPath = `/home/lockers/${token}/AllItems`;
        const constructedLockerUrl = `${currentDomain}${inviteLockerPath}`;
      
        // Set the constructed locker URL to the state
        setInviteUrl( constructedLockerUrl );
      
        // Show the invite overlay for locker
        setShowInviteOverlay(true);
      };

    const handleInviteTableClick = (detail) => {
        setOverlayInfoOpen(false);
        // Construct the URL with current domain and table name
        const currentDomain = window.location.origin;
        const name = detail.name;
        const inviteTablePath = `/tables/${name}`;
        const constructedTableUrl = `${currentDomain}${inviteTablePath}`;
      
        // Set the constructed table URL to the state
        setInviteUrl(constructedTableUrl );
      
        // Show the invite overlay for table
        setShowInviteOverlay(true);
      };

    const closeInviteOverlay = () => {
        setShowInviteOverlay(false);
    };

    const closeOverlayInfo = () => {
        setOverlayInfoOpen(false);
        setIsEditMode(false); // Optionally reset edit mode to off when closing the overlay
    };
    const handleInviteClick = (detail) => {
        setOverlayInfoOpen(false);
        // Construct the URL with current domain, path, and query parameters
        const currentDomain = window.location.origin;
        const userId = detail.user_id;
        const token = detail.token;
        const invitePath = `/view?user_id=${userId}&token=${token}`;
        const constructedUrl = `${currentDomain}${invitePath}`;

        // Set the constructed URL to the state
        setInviteUrl(constructedUrl);

        // Show the invite overlay
        setShowInviteOverlay(true);
    };

    const handleCopyClick = () => {
        const constructedUrl = inviteUrl;

        const textarea = document.getElementById('inviteTextarea');
        if (textarea) {
            textarea.value = constructedUrl;
            textarea.select();
            document.execCommand('copy');

            // Set copyIcon to true to display the tick icon
            setCopyIcon(true);

            // Revert to the copy icon after 2 seconds
            setTimeout(() => {
                setCopyIcon(false);
            }, 2000);
        }
    };

    const openOverlayWithEditFileDetails = (detail) => {
        console.log(detail);
        setSelectedItem(detail);
        setIsEditMode(true);
      
        if (!selectedItemDetails[detail._id]) {
          setSelectedItemDetails((prevDetails) => ({
            ...prevDetails,
            [detail._id]: {
              comments: '',
              companyInfo: '',
              tags: [],
            },
          }));
        }
        setOverlayInfoOpen(true);
      };


      const openOverlayWithFileInfo = (detail) => {
        console.log(detail);
        setSelectedItem(detail);
      
        if (!selectedItemDetails[detail._id]) {
          setSelectedItemDetails((prevDetails) => ({
            ...prevDetails,
            [detail._id]: {
              comments: '',
              companyInfo: '',
              tags: [],
            },
          }));
        }
        setOverlayInfoOpen(true);
      };

      const updateSelectedFileDetails = (fileId, detailType, value) => {
        setSelectedItemDetails((prevDetails) => ({
          ...prevDetails,
          [fileId]: {
            ...prevDetails[fileId],
            [detailType]: value,
          },
        }));
      };

    const removeTag = (fileId, tagIndex) => {
        const updatedTags = [...selectedItemDetails[fileId].tags];
        updatedTags.splice(tagIndex, 1); // Remove the tag at tagIndex
        updateSelectedFileDetails(fileId, 'tags', updatedTags);
    };


    const addTag = (newTagValue) => {
        if (!selectedItem || !newTagValue.trim()) return;
      
        const fileId = selectedItem._id;
        const existingTags = selectedItemDetails[fileId]?.tags || [];
      
        const updatedTags = [...existingTags, newTagValue.trim()];
      
        setSelectedItemDetails((prevDetails) => ({
          ...prevDetails,
          [fileId]: {
            ...prevDetails[fileId],
            tags: updatedTags,
          },
        }));
      };


    const toggleEditMode = () => {
        setIsEditMode((prevMode) => !prevMode);
    };

    const deleteLocker = async (token) => {
        try {
            // Perform the API call to delete the locker
            const response = await axios.delete(`https://pages.onepgr.com/session/delete-data-bank-item/${token}`);

            if (response.status === 200) {
                // Show success notification
                setNotification({ message: 'Locker deleted successfully!', type: 'success' });
                fetchLockerItemsData();
            }


        } catch (error) {
            console.error('Error deleting locker:', error);
            setNotification({ message: 'Failed to delete locker.', type: 'error' });
        }
    };

    const deleteFile = async (fileKey, fileId) => {

        try {
            // Prepare requests for both endpoints
            const deleteFromS3Request = fetch('https://crawl.onepgr.com:3002/delete-from-s3', {
                method: 'POST', // Using POST as specified
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ fileName: fileKey }), // Assuming fileKey is the fileName for S3
            });

            const deleteDataSourceFileRequest = fetch(`https://pages.onepgr.com/session/delete-data-bank-source-file/${fileId}`, {
                method: 'DELETE', // Using DELETE as required by this endpoint
                headers: {
                    'Content-Type': 'application/json',
                },
                // No body required for this request
            });
            const responses = await Promise.all([deleteFromS3Request, deleteDataSourceFileRequest]);

            // Check if both responses are OK
            responses.forEach(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
            });

            // Refresh the file list after successful deletion
            fetchLockerItemsData();
            setNotification({ message: 'File deleted successfully!', type: 'success' });



        } catch (error) {
            console.error('Error deleting file:', error);

            // Optionally, display an error message to the user
            setNotification({ message: 'Failed to delete File.', type: 'error' });

        }
    };


    const saveChanges = async () => {
        setOverlayInfoOpen(false);
        const itemId = selectedItem._id;
        updateSelectedFileDetails(selectedItem._id, 'name', editableFileName);
        const currentDateTime = new Date().toISOString();
        const lockerToken = selectedLockerToken
        let requestBody;
        if (selectedItem.object_type === 'table') {
            requestBody = {
                name: editableFileName,
                source_url: selectedItem.source_url || '',
                table_info: {
                    comments: selectedItemDetails[itemId]?.comments || '',
                    company_info: selectedItemDetails[itemId]?.companyInfo || '',
                    tags: Array.isArray(selectedItemDetails[itemId]?.tags) ? selectedItemDetails[itemId]?.tags.join(', ') : ''
                },
                date: currentDateTime
            };
        } else if (selectedItem.object_type === 'item') {
            requestBody = {
                name: editableFileName,
                item_url: selectedItem.item_url || '',
                item_info: {
                    comments: selectedItemDetails[itemId]?.comments || '',
                    company_info: selectedItemDetails[itemId]?.companyInfo || '',
                    tags: Array.isArray(selectedItemDetails[itemId]?.tags) ? selectedItemDetails[itemId]?.tags.join(', ') : ''
                },
                date: currentDateTime
            };
        } else {
            requestBody = {
                name: editableFileName,
                source_url: selectedItem.source_url,
                type: "Check",
                file_info: {
                    comments: selectedItemDetails[itemId]?.comments || '',
                    company_info: selectedItemDetails[itemId]?.companyInfo || '',
                    tags: Array.isArray(selectedItemDetails[itemId]?.tags) ? selectedItemDetails[itemId]?.tags.join(', ') : ''
                },
                date: currentDateTime
            };
        }

        if (password) {
            requestBody.password = password;
          }

        if (lockerToken) {
            if (selectedItem.object_type === 'item') {
              requestBody.parent_id = lockerToken;
            } else {
              requestBody.locker_id = lockerToken;
            }
          }

        try {
            let response;
            if (selectedItem.object_type === 'table') {
                response = await axios.put(`https://pages.onepgr.com/session/update-data-bank-table/${itemId}`, requestBody);
            } else if (selectedItem.object_type === 'item') {
                response = await axios.put(`https://pages.onepgr.com/session/update-data-bank-item/${itemId}`, requestBody);
            } else {
                response = await axios.put(`https://pages.onepgr.com/session/update-data-bank-source-file/${itemId}`, requestBody);
            }
            console.log(response.data);

            const updatedselectedItemDetails = {
                ...selectedItemDetails,
                [itemId]: requestBody.item_info || requestBody.file_info
            };
            setSelectedItemDetails(updatedselectedItemDetails);

            if (selectedItem.object_type === 'table') {
                // fetchDataBankTables();
                fetchLockerItemsData();
                setNotification({ message: "Table info updated successfully!", type: 'success' });
            } else if (selectedItem.object_type === 'item') {
                // fetchLockerDetails();
                fetchLockerItemsData();
                setNotification({ message: "Locker info updated successfully!", type: 'success' });
            } else {
                // fetchDataSourceFiles();
                fetchLockerItemsData();
                setNotification({ message: "File info updated successfully!", type: 'success' });
            }
            setTimeout(() => setNotification(''), 3000);
        } catch (error) {
            console.error(`Error updating ${selectedItem.object_type === 'table' ? 'table' : selectedItem.object_type === 'item' ? 'locker' : 'file'} info:`, error);
            setNotification(`Failed to update ${selectedItem.object_type === 'table' ? 'table' : selectedItem.object_type === 'item' ? 'locker' : 'file'} info.`);
            setTimeout(() => setNotification(''), 3000);
        }
    };

    const handleTableClick = (item) => {
        console.log(item)
        const database = item.database
            navigate(`/tables/${item.name}`, { 
                state: { 
                     database
                } 
            });
        console.log("navigating")
    };
    const handleLockerclick = (item) =>{
        navigate(`/home/lockers/${item.token}`);
    }

    const handleAdvancedSearch = () => {
        navigate('/search', { state: { lockerId } });
    }


    return (
        <div className="all-locker-items">
            <div className="search-icons">
                <div className="search-bar all-items-search-bar" style={{ display: 'flex' }}> {/* Adjusted for consistency, though conditional rendering makes 'style' unnecessary */}
                    <div className="input-container">
                        <div className="icon-card searchIcon" >
                            <FontAwesomeIcon icon={faSearch} />
                        </div>
                        <input
                            type="text"
                            id="searchInput"
                            placeholder="Search Locker Items..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className='search-input'
                        />
                        {/* <i className="fas fa-times clear-icon" onClick={clearSearch}></i> */}
                    </div>
                    <button className='advanced-search-button table-advanced-search-btn' onClick={handleAdvancedSearch}>Advanced Search</button>
                </div>
                <div className="RDS-icon-container">

                    <OverlayTrigger
                        key="top-refresh"
                        placement="top"
                        overlay={<Tooltip id={`tooltip-top-refresh`}>Refresh Links List</Tooltip>}
                    >
                        <div className="icon-card"
                            onClick={fetchLockerItemsData}
                        >
                            <FontAwesomeIcon icon={faSyncAlt} />
                        </div>
                    </OverlayTrigger>

                </div>
            </div>
            <div className="rdsfiles">
                <div className="table-header all-items-header">
                    <span>
                        Name
                        <FontAwesomeIcon
                            icon={sortOrder === 'asc' ? faArrowDown : faArrowUp}
                            className="sort-icon"
                            onClick={() => toggleSort('name')}
                        />
                    </span>
                    <span>
                        Last Update
                        <FontAwesomeIcon
                            icon={sortOrder === 'asc' ? faArrowDown : faArrowUp}
                            className="sort-icon"
                            onClick={() => toggleSort('lastUpdate')}
                        />
                    </span>
                    <span className="allitems-actions-header">Actions</span>
                </div>
                {isLoadingItems ? (
                    <div className="spinner"></div>
                ) : (
                    lockerItems && lockerItems.length > 0 ? (
                    <ul className="table-list">
                        {lockerItems.map((item) => (
                            <li key={item._id} className="rdstable-item allitems-item">
                                {item.object_type === 'link' ? (
                                    <p
                                        href={item.name}
                                        target="_blank"
                                        rel="noopener noreferrer"

                                    >
                                        <div className="tablename-icon locker-item-container" title={item.name}>
                                            <div className="result-icon locker-item-icon">
                                                <FontAwesomeIcon icon={faLink} className="locker-icon" />
                                            </div>
                                            <div className="result-details locker-result-details">
                                                <span className="rdstablename locker-item-name" onClick={(e) => handleLinkClick(e, item.name)}>{item.name}</span>
                                            </div>
                                        </div>
                                    </p>
                                ) : (
                                    <div className="tablename-icon locker-item-container" title={item.name}
                                        onClick={() => {
                                            if (item.object_type === 'table') {
                                                handleTableClick(item);
                                            } else if (item.object_type === 'file') {
                                                viewFile(item.source_url);
                                            }else if (item.object_type === 'item') {
                                                navigate(`/home/lockers/${item.token}`);
                                            }
                                        }}
                                    >
                                        <div className="result-icon locker-item-icon">
                                            {item.object_type === "file" && (
                                                <FontAwesomeIcon icon={getIconByExtension(item.name)} />
                                            )}
                                            {item.object_type === 'table' && <FontAwesomeIcon icon={faTable} />}
                                            {item.object_type === 'item' && <FontAwesomeIcon icon={faLock} />}
                                        </div>
                                        <div className="result-details locker-result-details">
                                            <span className="rdstablename locker-item-name">{item.name}</span>
                                        </div>
                                    </div>
                                )}
                                <div className="allitems-last-update">  {item.last_updated ? formatDate(item.last_updated) : formatDate(item.date)}</div>

                                <div className="table-actions-icon-rds ">
                                    <ActionsIcon
                                        detail={item}
                                        activeTab={item.object_type}  // Pass the object_type as activeTab prop
                                        deleteLink={deleteLink}
                                        showInviteOverlay={showInviteOverlay}
                                        deleteTable={deleteTable}
                                        handleInviteLockerClick ={handleInviteLockerClick }
                                        handleInviteTableClick={handleInviteTableClick}
                                        handleInviteClick={handleInviteClick}
                                        closeInviteOverlay={closeInviteOverlay}
                                        inviteUrl={inviteUrl}
                                        copyIcon={copyIcon}
                                        handleCopyClick={handleCopyClick}
                                        openOverlayWithFileInfo={openOverlayWithFileInfo}
                                        openOverlayWithEditFileDetails={openOverlayWithEditFileDetails}
                                        overlayInfoOpen={overlayInfoOpen}
                                        isEditMode={isEditMode}
                                        editableFileName={editableFileName}
                                        setEditableFileName={setEditableFileName}
                                        updateSelectedFileDetails={updateSelectedFileDetails}
                                        removeTag={removeTag}
                                        addTag={addTag}
                                        toggleEditMode={toggleEditMode}
                                        closeOverlayInfo={closeOverlayInfo}
                                        saveChanges={saveChanges}
                                        deleteLocker={deleteLocker}
                                        deleteFile={deleteFile}
                                        selectedItemDetails={selectedItemDetails} 
                                        viewFile={viewFile}
                                        newTag={newTag}
                                        setNewTag={setNewTag}
                                        selectedItem={selectedItem}
                                        setSelectedLockerToken={setSelectedLockerToken}
                                        dropDownLockerDetails={dropDownLockerDetails}
                                        password={password}
                                        setPassword={setPassword}
                                    />
                                </div>
                            </li>
                        ))}
                    </ul>
                    ) : (
                        <p>No items found.</p>
                    )
                )}
            </div>
            <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={lockerItems.length}
                paginate={setCurrentPage}
                currentPage={currentPage}
                setItemsPerPage={setItemsPerPage} // Pass the state setter as a prop
            />

            {notification.message && (
                <Notification type={notification.type} message={notification.message} />
            )}
        </div>
    )
}


export default AllLockerItems;