import React, { useState } from 'react';
import './findDuplicates.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';

const StepNavigation = ({ currentStep, goToStep }) => {
    return (
        <div className="step-navigation">
            <div className='stepBtn'>
                <button
                    className={`step-button ${currentStep >= 1 ? 'active' : ''}`}
                    disabled={currentStep === 1}
                    onClick={() => goToStep(1)}
                >
                    1
                </button>
                Select Options
            </div>
            <hr />
            <div className='stepBtn'>
                <button
                    className={`step-button ${currentStep >= 2 ? 'active' : ''}`}
                    disabled={currentStep === 2}
                    onClick={() => goToStep(2)}
                >
                    2
                </button>
                Upload Files
            </div>
            <hr />
            <div className='stepBtn'>
                <button
                    className={`step-button ${currentStep >= 3 ? 'active' : ''}`}
                    disabled={currentStep === 3}
                    onClick={() => goToStep(3)}
                >
                    3
                </button>
                File processing
            </div>
            <hr />
            <div className='stepBtn'>
                <button
                    className={`step-button ${currentStep === 4 ? 'active' : ''}`}
                    disabled={currentStep === 4}
                    onClick={() => goToStep(4)}
                >
                    4
                </button>
                File generation
            </div>
            <div className="step-line"></div>
        </div>
    );
};

const StepContent = ({ step, goToStep }) => {
    const [isEmailChecked, setIsEmailChecked] = useState(false);
    const [isPhoneNumberChecked, setIsPhoneNumberChecked] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const isStep1Completed = isEmailChecked || isPhoneNumberChecked;
    const isStep2Completed = selectedFiles.length > 0;

    const handleFileSelection = (event) => {
        // Assuming you can select multiple files
        setSelectedFiles([...event.target.files]);
    };

    const handleImportFromDatabase = () => {
        // Handle import logic here
        console.log('Import from database clicked');
    };
    const handleAddFilesClick = () => {
        // Trigger the file input click event
        document.getElementById('file-input').click();
    };
    const handleFileRemove = (fileToRemove) => {
        setSelectedFiles(selectedFiles.filter(file => file !== fileToRemove));
    };

    const handleBack = () => {
        goToStep(step - 1);
    };


    const handleNextClick = () => {
        // Here you can handle the next button click, possibly using the checkbox states
        console.log('Email checked:', isEmailChecked);
        console.log('Phone Number checked:', isPhoneNumberChecked);
        // Implement what should happen next after clicking the button
        goToStep(step + 1);
    };
    switch (step) {
        case 1:
            return <div className='selectOptions'>
                <div  >
                    <input
                        type="checkbox"
                        id="duplicateEmails"
                        checked={isEmailChecked}
                        onChange={(e) => setIsEmailChecked(e.target.checked)}
                    />
                    <label htmlFor="duplicateEmails">Find duplicate email address</label>
                </div>
                <div>
                    <input
                        type="checkbox"
                        id="duplicatePhoneNumbers"
                        checked={isPhoneNumberChecked}
                        onChange={(e) => setIsPhoneNumberChecked(e.target.checked)}
                    />
                    <label htmlFor="duplicatePhoneNumbers">Find duplicate phone numbers</label>
                </div>
                <button onClick={handleNextClick}>Next</button>
            </div>;
        case 2:
            return <div className="file-management">
                <div className="file-actions">
                    <input
                        type="file"
                        id="file-input"
                        multiple
                        onChange={handleFileSelection}
                        style={{ display: 'none' }}
                        accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" // Accept CSV and XLS
                    />
                    <button onClick={handleAddFilesClick} className="addFile-Btn">Add Files</button>
                    <button onClick={handleImportFromDatabase}>Import from Database</button>
                </div>
                <div className="file-display-area">
                    {selectedFiles.length > 0 ? (
                        <ul>
                            {selectedFiles.map((file, index) => (
                                <li key={index}>
                                    {file.name}
                                    <span className="cancel-file" onClick={() => handleFileRemove(file)}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </span>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No files selected or imported</p>
                    )}
                </div>
                <div className="navigation-buttons">
                    <button id='backbtn' onClick={handleBack}>Back</button>
                    <button onClick={handleNextClick}>Next</button>
                </div>
            </div>;
        case 3:
            return (
                <div className='processingStep'>
                    <div className='stepsCompleted'>
                        <p> Selected Options
                            {isStep1Completed ? (
                                <FontAwesomeIcon icon={faCheck} className="icon-check" />
                            ) : (
                                <FontAwesomeIcon icon={faTimes} className="icon-times" />
                            )}
                        </p>
                        <p> Upload files
                            {isStep2Completed ? (
                                <FontAwesomeIcon icon={faCheck} className="icon-check" />
                            ) : (
                                <FontAwesomeIcon icon={faTimes} className="icon-times" />
                            )}
                        </p>
                    </div>
                    <div className='processcingBtns'>
                        <button id='backbtn' onClick={handleBack}>Back</button>
                        <button onClick={() => console.log('Finding Duplicates...')}>Find Duplicates</button>
                        <button onClick={() => { console.log('Merging...'); goToStep(step + 1); }}>Merge and Next</button>
                    </div>
                </div>
            );
        case 4:
            return <div>Your file has been generated.</div>;
        default:
            return null;
    }
};

const FindDuplicates = () => {
    const [currentStep, setCurrentStep] = useState(1);

    const goToStep = (stepNumber) => {
        setCurrentStep(stepNumber);
    };

    return (
        <div className='findDuplicateApp'>
            <StepNavigation currentStep={currentStep} goToStep={goToStep} />
            <StepContent step={currentStep} goToStep={goToStep} />
        </div>
    );
};

export default FindDuplicates;
