import React from 'react';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCopy, faPlus } from '@fortawesome/free-solid-svg-icons';

function InviteOverlay({ showInviteOverlay, closeInviteOverlay, inviteUrl, handleCopyClick, copyIcon }) {
  return (
    <>
     
        <div className="inviteoverlay">
          <div className="inviteoverlay-overlay-content">
            <button className="invite-close-button" onClick={closeInviteOverlay}>X</button>
            <h3>Invite Link</h3>
            <hr />
            <div className='text-btn'>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-invite">Copy Link</Tooltip>}
              >
                <Button className={`copybtn ${copyIcon ? 'green' : ''}`} variant="light" onClick={handleCopyClick}>
                  {copyIcon ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faCopy} />}
                </Button>
              </OverlayTrigger>
              <textarea
                id='inviteTextarea'
                rows="3"
                value={inviteUrl}
                readOnly
                placeholder="Invite link goes here..."
              />
            </div>
            <div className='shared-list'>
              <h3>Shared User List</h3>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-invite">Invite User</Tooltip>}
              >
                <Button className='invite-user'>
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </OverlayTrigger>
            </div>
            <hr />
            <div>
              <div className='shared-list-header'>
                <span>Email</span>
                <span>Actions</span>
              </div>
              <div className='shared-users-list'>
                <p>No shared users found.</p>
              </div>
            </div>
          </div>
        </div>
     
    </>
  );
}

export default InviteOverlay;